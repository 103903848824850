import { Info, List, Wrapper } from "template/ListTemplate.style";
import versionColumn from "../versionColumn.json";
import { useMutationCustom } from "hooks/useMutationCustom";
import { HTTP_METHOD, POST_APP_VERSION_FETCH } from "constants/pathConstant";
import { useEffect } from "react";

// 시스템 관리 -> 버전 관리 :: 현재 사용중인 버전 리스트 (http://localhost:3000/super/system/version1)
const CurrentVersion = ({ currentData, setCurrentData }) => {
  const { mutate } = useMutationCustom(
    POST_APP_VERSION_FETCH,
    HTTP_METHOD.POST
  );

  useEffect(() => {
    fetchData();
  }, []);

  const mutateData = {
    reqType: "",
    useYn: "Y",
    pageSize: 10,
    currentPage: 1,
  };

  const fetchData = () => {
    mutate(mutateData, {
      onSuccess: (res) => {
        setCurrentData(res);
      },
    });
  };

  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>현재 사용중인 버전</List.Text>
      </List.TitleContainer>

      <List.Container style={{ minHeight: "0" }}>
        {/* List Column */}
        <List.Content>
          {versionColumn.currentData.map((v, idx) => (
            <List.Row $ratio={v.ratio} key={idx}>
              {v.title}
            </List.Row>
          ))}
        </List.Content>

        <Info.Wrapper>
          {currentData.map((v, idx) => (
            <Info.Content key={idx}>
              {/* 구분 */}
              <List.Row $ratio={versionColumn.currentData[0].ratio}>
                {versionColumn.type[v.reqType]}
              </List.Row>

              {/* 강제 업데이트 여부(AOS) */}
              <List.Row $ratio={versionColumn.currentData[1].ratio}>
                {v.androidForceUpdYn}
              </List.Row>

              {/* AOS 버전 */}
              <List.Row $ratio={versionColumn.currentData[2].ratio}>
                {v.androidVersion}
              </List.Row>

              {/* 강제 업데이트 여부(IOS) */}
              <List.Row $ratio={versionColumn.currentData[3].ratio}>
                {v.iosForceUpdYn}
              </List.Row>

              {/* IOS 버전 */}
              <List.Row $ratio={versionColumn.currentData[4].ratio}>
                {v.iosVersion}
              </List.Row>
            </Info.Content>
          ))}
        </Info.Wrapper>
      </List.Container>
    </Wrapper>
  );
};

export default CurrentVersion;
