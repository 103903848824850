import React, { useState } from "react";
import { Wrapper } from "./WalletAddress.style";
import WalletAddressSearch from "./WalletAddressSearch/WalletAddressSearch";
import WalletAddressDetail from "./WalletAddressDetail/WalletAddressDetail";

// 페이 자산내역 :: (http://localhost:3000/super/asset/walletAddress)
const WalletAddress = () => {
  const [walletAddressList, setWalletAddressList] = useState({});

  return (
    <Wrapper>
      <div>
        <WalletAddressSearch setWalletAddressList={setWalletAddressList} />

        <WalletAddressDetail walletAddressList={walletAddressList} />
      </div>
    </Wrapper>
  );
};

export default WalletAddress;
