import { PLACEHOLDER } from "constants/baseStyle";
import { HTTP_METHOD, POST_SETTLE_LIST } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useEffect, useState } from "react";
import { Search, Wrapper } from "template/SearchTemplate.style";
import stateJson from "../settleState.json";
import { dateFormat } from "components/dateFormat";

// 정산 관리 -> 회수 :: 회수 검색 (http://localhost:3000/super/calculate/settle)
export const SettleSearch = ({
  setSettleList,
  setPage,
  currentPage,
  pageSize,
  setSearchData,
  searchData,
}) => {
  const toast = useToastCustom();

  const [id, setId] = useState("");
  const [useType, setUseType] = useState("");
  const [state, setState] = useState("");
  const [txId, setTxId] = useState("");
  const [acctUserNm, setAcctUserNm] = useState("");
  const [acctCd, setAcctCd] = useState("");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState(
    dateFormat(new Date(), false, 0, 0).slice(0, 10)
  );

  const { mutate } = useMutationCustom(POST_SETTLE_LIST, HTTP_METHOD.POST);

  // page, size 바뀔때
  useEffect(() => {
    if (!Object.keys(searchData)[0]) return;

    const researchData = { ...searchData, pageSize, currentPage };

    mutate(researchData, {
      onSuccess: (data) => {
        setSettleList(data);
      },

      onError: () => {
        toast("데이터 조회 실패", "error");
      },
    });
  }, [currentPage, pageSize]);

  const handleSearch = () => {
    setPage(1);

    const data = {
      id,
      useType,
      state,
      txId,
      acctUserNm,
      acctCd,
      fromDt: fromDt && dateFormat(fromDt, true),
      toDt: dateFormat(toDt, true, 1),
      pageSize,
      currentPage,
    };

    setSearchData(data);

    mutate(data, {
      onSuccess: (data) => {
        setSettleList(data);
      },
    });
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  return (
    <Wrapper>
      <Search.Text>회수 검색 조건</Search.Text>
      <Search.Container style={{ gap: "43rem" }}>
        <Search.Content style={{ width: "100%" }}>
          {/* 번호 */}
          <Search.InputWrapper>
            <div>번호</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setId(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 회원명 */}
          <Search.InputWrapper>
            <div>회원명</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setAcctUserNm(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 회원코드 */}
          <Search.InputWrapper>
            <div>회원코드</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setAcctCd(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 상태 */}
          <Search.InputWrapper>
            <div>상태</div>
            <Search.Select onChange={(e) => setState(e.target.value)}>
              <Search.Option value="">{"전체"}</Search.Option>
              {Object.keys(stateJson.state).map((v) => (
                <Search.Option key={v} value={v}>
                  {stateJson.state[v]}
                </Search.Option>
              ))}
            </Search.Select>
          </Search.InputWrapper>

          {/* 구분 */}
          <Search.InputWrapper>
            <div>구분</div>
            <Search.Select onChange={(e) => setUseType(e.target.value)}>
              <Search.Option value="">{"전체"}</Search.Option>
              {Object.keys(stateJson.useType).map((v) => (
                <Search.Option key={v} value={v}>
                  {stateJson.useType[v]}
                </Search.Option>
              ))}
            </Search.Select>
          </Search.InputWrapper>

          {/* 회수일자 */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>회수일자 (부터)</div>
            <Search.Input
              type="date"
              defaultValue={fromDt}
              onChange={(e) => setFromDt(e.target.value)}
            ></Search.Input>
          </Search.InputWrapper>

          {/* 회수일자 */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>회수일자 (까지)</div>
            <Search.Input
              type="date"
              defaultValue={toDt.slice(0, 10)}
              onChange={(e) => setToDt(e.target.value)}
            ></Search.Input>
          </Search.InputWrapper>

          {/* 지갑 주소 */}
          <Search.InputWrapper style={{ width: "36rem" }}>
            <div>txId / 보낸 지갑 주소</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setTxId(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>
        </Search.Content>

        {/* 조회 버튼 */}
        <Search.Btn onClick={handleSearch}>조회</Search.Btn>
      </Search.Container>
    </Wrapper>
  );
};
