import React, { useState } from "react";
import { List } from "./ScheduleList.style";
import scheduleJson from "./ScheduleJson.json";
import { useMutationCustom } from "hooks/useMutationCustom";
import {
  HTTP_METHOD,
  IMG_CDN_URL,
  POST_MAIN_SCHEDULE_DELETE,
  POST_MAIN_SCHEDULE_MODIFY,
} from "constants/pathConstant";
import { useToastCustom } from "hooks/useToastCustom";
import { ScheduleModal } from "components/Modal/ScheduleModal/ScheduleModal";
import { loginData } from "recoil/atom/LoginState";
import { useRecoilValue } from "recoil";

export const ScheduleDetail = ({
  data,
  setScheduleList,
  scheduleList,
  setCalendarCountObj,
}) => {
  const [open, setOpen] = useState(false);
  const [modifyMode, setModifyMode] = useState(false);

  const loginInfo = useRecoilValue(loginData);

  const { mutate: deleteMutation } = useMutationCustom(
    POST_MAIN_SCHEDULE_DELETE,
    HTTP_METHOD.POST
  );
  const toast = useToastCustom();

  const modifyHandler = () => {
    setModifyMode(true);
  };

  const deleteHandler = () => {
    if (!window.confirm("삭제하시겠습니까?")) return;
    const request = { id: data.id };
    deleteMutation(request, {
      onSuccess: () => {
        const newScheduleList = scheduleList.filter(
          (item) => item.id !== data.id
        );

        setScheduleList(newScheduleList);

        setCalendarCountObj((prev) => {
          const countDate = new Date(data.scheduleDate);
          const scheduleLevel = data.scheduleLevel;
          const newObj = { ...prev };
          newObj[countDate.getFullYear()][countDate.getMonth() + 1][
            countDate.getDate()
          ][scheduleLevel]--;
          return newObj;
        });
      },
      onError: () => {
        toast("삭제 실패", "error");
      },
    });
  };

  return (
    <List.Container>
      <List.TitleWrapper
        onClick={() => setOpen(!open)}
        $isOpen={open}
        $shareYn={data.shareYn}
      >
        <List.TitleCon1>
          {data.shareYn === "Y" ? (
            <img
              src={`${IMG_CDN_URL}/file/38d008d5speaker_icon.svg`}
              alt="공지사항 아이콘"
            />
          ) : (
            <List.TitleColor $color={scheduleJson[data.scheduleLevel]?.color} />
          )}
          <List.TitleText>{data.scheduleTitle}</List.TitleText>
        </List.TitleCon1>
        {loginInfo.adminId !== data.adminId && (
          <List.SharedWrapper>
            작성자 <span>{data.name}</span>
          </List.SharedWrapper>
        )}
        {loginInfo.adminId === data.adminId && (
          <List.TitleCon2>
            <List.BtnWrapper
              $isOpen={open}
              onClick={(e) => {
                e.stopPropagation();
                modifyHandler(e);
              }}
            >
              <List.Btn src={`${IMG_CDN_URL}/file/828ae6f6modifyBtn.svg`} />
            </List.BtnWrapper>

            <List.BtnWrapper
              $isOpen={open}
              onClick={(e) => {
                e.stopPropagation();
                deleteHandler(e);
              }}
            >
              <List.Btn src={`${IMG_CDN_URL}/file/1aa1c67edeleteBtn.svg`} />
            </List.BtnWrapper>
          </List.TitleCon2>
        )}
      </List.TitleWrapper>
      <List.ContentWrapper $isOpen={open} $shareYn={data.shareYn}>
        {data.scheduleContent}
        {data.mentionedAdmins?.length > 0 && (
          <List.JoinWrapper>
            <div>참여자</div>

            {data.mentionedAdmins &&
              data.mentionedAdmins
                .split(",")
                .map((v, i) => <span key={i}>@{v}</span>)}
          </List.JoinWrapper>
        )}
      </List.ContentWrapper>

      {modifyMode && (
        <ScheduleModal
          data={data}
          setOpenModal={setModifyMode}
          modifyMode={true}
          setScheduleList={setScheduleList}
          setCalendarCountObj={setCalendarCountObj}
        />
      )}
    </List.Container>
  );
};
