import styled from "styled-components";

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: blur(5px);
  -webkit-filter: brightness(65%);
  backdrop-filter: blur(5px);
  position: fixed;
  z-index: 22;
`;

export const ModalContaier = styled.div`
  z-index: 23;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  gap: 2rem;

  & > div {
    background: #fff;
    width: 40rem;
    height: 25rem;
    overflow-y: scroll;
    padding: 2rem;

    & > :nth-child(1) {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 4.2rem;
      border-bottom: 1px solid #d2d2d2;
      margin-bottom: 2rem;
    }

    & > :nth-child(2) {
      font-weight: 600;
      font-size: 2rem;
    }
  }
`;

export const ModalVersionCheckContainer = styled.div`
  z-index: 23;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  gap: 2rem;

  & > div {
    background: #fff;
    width: 32rem;
    padding: 3rem 3rem 2rem;
    border-radius: 0.6rem;
    font-size: 1.8rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 1rem;

    & > input {
      border: 1px solid #aaa;
      width: 100%;
      padding: 0.5rem;
      font-weight: 500;
    }

    & > div {
      background: #2db9c2;
      color: #fff;
      padding: 1rem;
      border-radius: 0.6rem;
      cursor: pointer;
    }
  }
`;
