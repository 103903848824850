import SearchUserModal from "components/Modal/SearchUserModal";
import { HTTP_METHOD, POST_FIND_WALLET_MOVE } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useEffect, useState } from "react";
import { Search, Wrapper } from "template/SearchTemplate.style";
import assetPayColumn from "../assetPayColumn.json";
import { dateFormat } from "components/dateFormat";

// 페이 자산내역 :: 자산내역 조회 (http://localhost:3000/super/asset/pay)
const AssetPaySearch = ({
  setAssetPayList,
  setPage,
  currentPage,
  pageSize,
  setSearchData,
  searchData,
  setIsSearch,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [useType, setUseType] = useState("");
  const [state, setState] = useState("");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState(
    dateFormat(new Date(), false, 0, 0).slice(0, 10)
  );

  const [userData, setUserData] = useState({});

  useEffect(() => {
    setUseType("");
    setState("");
    setFromDt("");
    setToDt(dateFormat(new Date()).slice(0, 19));
  }, [userData]);

  const { mutate } = useMutationCustom(POST_FIND_WALLET_MOVE, HTTP_METHOD.POST);

  useEffect(() => {
    if (!Object.keys(searchData)[0]) return;
    const researchData = { ...searchData, currentPage, pageSize };
    setSearchData(researchData);

    mutate(researchData, {
      onSuccess: (data) => {
        setAssetPayList(data);
      },
    });
  }, [pageSize, currentPage]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (acctCd = "") => {
    const data = {
      acctCd,
      useType,
      state,
      pageSize,
      currentPage,
      fromDt: fromDt && dateFormat(fromDt, true),
      toDt: dateFormat(toDt, true, 1),
    };

    setSearchData(data);

    mutate(data, {
      onSuccess: (data) => {
        setAssetPayList(data);
      },
    });
  };

  const handleSearch = () => {
    setPage(1);
    setIsSearch(true);
    fetchData(userData.acctCd);
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  return (
    <Wrapper>
      <Search.Text>페이 자산내역 조회</Search.Text>
      <Search.Container>
        <Search.Content>
          {/* 회원명 */}
          <Search.InputWrapper
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            <div>회원명</div>
            <Search.Input
              style={{ cursor: "pointer" }}
              placeholder="눌러서 조회"
              onKeyUp={(e) => handleEnter(e.key)}
              readOnly
              value={userData.acctUserNm}
            />
          </Search.InputWrapper>

          {/* 회원코드 */}
          <Search.InputWrapper>
            <div>회원코드</div>
            <Search.Input
              placeholder="자동 입력"
              onKeyUp={(e) => handleEnter(e.key)}
              disabled
              value={userData.acctCd}
            />
          </Search.InputWrapper>

          {/* 전화번호 */}
          <Search.InputWrapper>
            <div>전화번호</div>
            <Search.Input
              placeholder="자동 입력"
              onKeyUp={(e) => handleEnter(e.key)}
              disabled
              value={userData.cellNumber}
            />
          </Search.InputWrapper>

          {/* 유형 */}
          <Search.InputWrapper style={{ width: "15rem" }}>
            <div>세부유형</div>
            <Search.Select
              style={{ width: "13rem" }}
              onChange={(e) => setUseType(e.target.value)}
              value={useType}
            >
              <Search.Option value="">전체</Search.Option>
              {Object.keys(assetPayColumn.useType).map((v, idx) => (
                <Search.Option key={idx} value={v}>
                  {assetPayColumn.useType[v]}
                </Search.Option>
              ))}
            </Search.Select>
          </Search.InputWrapper>

          {/* 거래일자 (부터) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>거래일자 (부터)</div>
            <Search.Input
              type="date"
              defaultValue={fromDt}
              onChange={(e) => setFromDt(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            ></Search.Input>
          </Search.InputWrapper>

          {/* 거래일자 (까지) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>거래일자 (까지)</div>
            <Search.Input
              type="date"
              defaultValue={toDt.slice(0, 10)}
              onChange={(e) => setToDt(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            ></Search.Input>
          </Search.InputWrapper>

          {/* 결제상태 */}
          <Search.InputWrapper>
            <div>상태</div>
            <Search.Select onChange={(e) => setState(e.target.value)}>
              <Search.Option value="">전체</Search.Option>
              {Object.keys(assetPayColumn.state).map((v, idx) => (
                <Search.Option key={idx} value={v}>
                  {assetPayColumn.state[v]}
                </Search.Option>
              ))}
            </Search.Select>
          </Search.InputWrapper>
        </Search.Content>

        {/* 조회 버튼 */}
        <Search.Btn onClick={() => handleSearch()}>조회</Search.Btn>
      </Search.Container>

      {isOpen && (
        <SearchUserModal setIsOpen={setIsOpen} setUserData={setUserData} />
      )}
    </Wrapper>
  );
};

export default AssetPaySearch;
