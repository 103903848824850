import { useState } from "react";
import { Wrapper } from "./AdminList.style";
import AdminSearch from "./AdminSearch/AdminSearch";
import AdminInfoList from "./AdminInfoList/AdminInfoList";

// 시스템 관리 :: (http://localhost:3000/super/system/admin)
export const AdminList = () => {
  const [adminList, setAdminList] = useState([]);
  const [searchData, setSearchData] = useState({});

  return (
    <Wrapper>
      <div>
        <AdminSearch
          setAdminList={setAdminList}
          setSearchData={setSearchData}
        />

        <AdminInfoList
          adminList={adminList}
          setAdminList={setAdminList}
          searchData={searchData}
        />
      </div>
    </Wrapper>
  );
};
