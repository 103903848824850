import React from "react";
import { Memo, MemoWrapper, YYMM, YYMMDD } from "./UserModifyInfo.style";

export const EmptyCategoryList = () => {
  return (
    <MemoWrapper>
      <YYMM></YYMM>
      <YYMMDD></YYMMDD>
      <Memo.Wrapper></Memo.Wrapper>
    </MemoWrapper>
  );
};
