import { Info, List } from "template/ListTemplate.style";
import paymentPayColumn from "../paymentPayColumn.json";
import { useState } from "react";
import { dateFormat } from "components/dateFormat";
import {
  HTTP_METHOD,
  POST_FIND_PAYMENT_CANCEL,
  SYMBOL,
} from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import PaymentDetailModal from "components/Modal/PaymentDetailModal";
import { costFormat } from "components/costForamt";
import { PaymentDetailRow } from "../PaymentPay.style";

// 결제 내역 :: 결제 내역 리스트 낱개 (http://localhost:3000/super/payment/pay)
const PaymentPayDetailList = ({ data, setCancelBg }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [cancelData, setCancelData] = useState([]);

  const getStateColor = (state) => {
    if (state === "성공") return `${clicked ? "#4253F2" : "#21F650"}`;
    if (state === "주문 취소") return `${clicked ? "#4253F2" : "#555"}`;
    if (state === ("실패" || "DB 에러"))
      return `${clicked ? "#4253F2" : "#fe1717"}`;
  };

  const { mutate } = useMutationCustom(
    POST_FIND_PAYMENT_CANCEL,
    HTTP_METHOD.POST
  );

  const handlePaymentDetaiModal = (e, reqId, state) => {
    if (state !== "전체 취소" && state !== "부분 취소") return;
    e.stopPropagation();
    mutate(
      { reqId },
      {
        onSuccess: (res) => {
          setCancelData(res);
          setIsOpen(true);
          setCancelBg(true);
        },
      }
    );
  };

  return (
    <>
      <Info.Content
        onClick={() => setClicked(!clicked)}
        isClicked={clicked}
        style={{ color: clicked && "#4253F2", cursor: "pointer" }}
      >
        {/* 번호 */}
        <List.Row $ratio={paymentPayColumn.column[0].ratio}>{data.id}</List.Row>

        {/* 거래일시 */}
        <List.Row $ratio={paymentPayColumn.column[1].ratio}>
          {dateFormat(data.paymentDt)}
        </List.Row>

        {/* 가맹점명 */}
        <List.Row $ratio={paymentPayColumn.column[2].ratio}>
          <List.RowElement>
            <p>{data.storeNm}</p>
            <p>({data.storeCd})</p>
          </List.RowElement>
        </List.Row>

        {/* 결제고객 */}
        <List.Row $ratio={paymentPayColumn.column[3].ratio}>
          <List.RowElement>
            <p>{data.customerNm}</p>
            <p>({data.customerCd})</p>
          </List.RowElement>
        </List.Row>

        {/* 생년월일 */}
        <List.Row $ratio={paymentPayColumn.column[4].ratio}>
          {data.birthDay}
        </List.Row>

        {/* 결제포인트 */}
        <List.Row $ratio={paymentPayColumn.column[5].ratio}>
          <List.RowElement>
            <p>{costFormat(data.sketchPayment, SYMBOL)}</p>
            <p>({costFormat(data.krwPayment, "원")})</p>
          </List.RowElement>
        </List.Row>

        {/* 결제금액 */}
        <List.Row $ratio={paymentPayColumn.column[6].ratio}>
          <List.RowElement>
            <p>{costFormat(data.sketchBnsSeq1, SYMBOL)}</p>
            <p>({costFormat(data.krwBnsSeq1, "원")})</p>
          </List.RowElement>
        </List.Row>

        {/* 시세 */}
        <List.Row $ratio={paymentPayColumn.column[7].ratio}>
          <p>{costFormat(data.krwPerSketch)}</p>
        </List.Row>

        {/* 수수료율 */}
        <List.Row $ratio={paymentPayColumn.column[8].ratio}>
          {data.storeFeeRate}%
        </List.Row>

        {/* 배송비 */}
        <List.Row $ratio={paymentPayColumn.column[9].ratio}>
          {costFormat(data.ssDeliveryCost)}
        </List.Row>

        {/* 정산 금액 */}
        <List.Row $ratio={paymentPayColumn.column[10].ratio}>
          <List.RowElement>
            <p>{costFormat(data?.ssLastPricePaySketch, SYMBOL)}</p>
            <p>({costFormat(data?.ssLastPricePay)} 원)</p>
          </List.RowElement>
        </List.Row>

        {/* 상태 */}
        <List.Row
          $ratio={paymentPayColumn.column[11].ratio}
          color={getStateColor(data.paymentState)}
          style={{
            border:
              data.paymentState === "전체 취소" ||
              data.paymentState === "부분 취소"
                ? "1px solid #666"
                : "none",
            borderRadius: "0.4rem",
          }}
          onClick={(e) =>
            handlePaymentDetaiModal(e, data.reqId, data.paymentState)
          }
        >
          {data.paymentState}
        </List.Row>

        {/* 승인번호 */}
        <List.Row $ratio={paymentPayColumn.column[12].ratio}>
          {data.reqId}
        </List.Row>
      </Info.Content>

      {clicked && (
        <PaymentDetailRow.Wrapper>
          <PaymentDetailRow.Container>
            <PaymentDetailRow.Header>
              {paymentPayColumn.detailColumn.map((v, idx) => (
                <PaymentDetailRow.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </PaymentDetailRow.Row>
              ))}
            </PaymentDetailRow.Header>

            <PaymentDetailRow.Data>
              {/* 고객페이백 */}
              <PaymentDetailRow.Row
                $ratio={paymentPayColumn.detailColumn[0].ratio}
              >
                <List.RowElement>
                  <p>{costFormat(data.sketchBnsSeq2, SYMBOL)}</p>
                  <p>({costFormat(data.krwBnsSeq2, "원")})</p>
                </List.RowElement>
              </PaymentDetailRow.Row>

              {/* 스토어 추천보너스 */}
              <PaymentDetailRow.Row
                $ratio={paymentPayColumn.detailColumn[1].ratio}
              >
                <List.RowElement>
                  <p>{costFormat(data.sketchBnsSeq3, SYMBOL)}</p>
                  <p>({costFormat(data.krwBnsSeq3, "원")})</p>
                </List.RowElement>
              </PaymentDetailRow.Row>

              {/* 회사 보너스 */}
              <PaymentDetailRow.Row
                $ratio={paymentPayColumn.detailColumn[2].ratio}
              >
                <List.RowElement>
                  <p>{costFormat(data.sketchBnsSeq4, SYMBOL)}</p>
                  <p>({costFormat(data.krwBnsSeq4, "원")})</p>
                </List.RowElement>
              </PaymentDetailRow.Row>

              {/* 추천보너스 */}
              <PaymentDetailRow.Row
                $ratio={paymentPayColumn.detailColumn[3].ratio}
              >
                <List.RowElement>
                  <p>{costFormat(data.sketchBnsSeq5, SYMBOL)}</p>
                  <p>({costFormat(data.krwBnsSeq5, "원")})</p>
                </List.RowElement>
              </PaymentDetailRow.Row>

              {/* 추천 */}
              <PaymentDetailRow.Row
                $ratio={paymentPayColumn.detailColumn[4].ratio}
              >
                {data.refererNm && (
                  <List.RowElement>
                    <p>{data.refererNm}</p>
                    <p>({data.refererCd})</p>
                  </List.RowElement>
                )}
              </PaymentDetailRow.Row>

              {/* 스토어 추천 */}
              <PaymentDetailRow.Row
                $ratio={paymentPayColumn.detailColumn[5].ratio}
              >
                {data.storeRefererNm && (
                  <List.RowElement>
                    <p>{data.storeRefererNm}</p>
                    <p>({data.storeRefererCd})</p>
                  </List.RowElement>
                )}
              </PaymentDetailRow.Row>
            </PaymentDetailRow.Data>
          </PaymentDetailRow.Container>
        </PaymentDetailRow.Wrapper>
      )}

      {isOpen && (
        <PaymentDetailModal
          data={data}
          cancelData={cancelData}
          setIsOpen={setIsOpen}
          setCancelBg={setCancelBg}
        />
      )}
    </>
  );
};

export default PaymentPayDetailList;
