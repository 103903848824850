import { dateFormat } from "components/dateFormat";
import { useEffect, useState } from "react";
import { RadioBox, SearchBox, Wrapper } from "./style";
import PageHandler from "components/PageHandler/PageHandler";
import { useMutationCustom } from "hooks/useMutationCustom";
import { HTTP_METHOD } from "constants/pathConstant";
import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { useToastCustom } from "hooks/useToastCustom";
import { xlsxDateFormat } from "components/xlsxDateFormat";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";

// 회원검색 :: 회원검색 상세보기 -> 지갑정보 (http://localhost:3000/manage/user/:code)
const SearchCategory = ({
  API_ADDRESS,
  data,
  setResData,
  setPage,
  setSize,
  page,
  size,
  resData,
  header = true,
  withdraw = false,
  summary = false,
  XLSX_DOWNLOAD,
}) => {
  const toast = useToastCustom();
  const excelDownload = useExcelDownload();

  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState(
    dateFormat(new Date(), false, 0, 0).slice(0, 10)
  );
  const [searchData, setSearchData] = useState({});
  const [useType, setUseType] = useState("W001");

  const { mutate } = useMutationCustom(API_ADDRESS, HTTP_METHOD.POST, {});
  useEffect(() => {
    handleSearch();
  }, [page, size, useType]);

  const handleExcelDownload = () => {
    if (!resData[0]?.cnt) return toast("액셀 다운로드 실패", "error");

    const excelData = {
      ...searchData,
      pageSize: resData[0]?.cnt,
      currentPage: 1,
    };

    mutate(excelData, {
      onSuccess: (response) => {
        const updatedResponse = xlsxDateFormat(
          summary ? response : response.list
        );

        const excelData = {
          data: updatedResponse,
          headers: XLSX_DOWNLOAD.header,
          fileName: XLSX_DOWNLOAD.filename,
        };

        excelDownload(excelData);
      },

      onError: () => {
        toast("전체 데이터 조회 실패", "error");
      },
    });
  };

  const handleSearch = () => {
    const searchData = {
      ...data,
      fromDt: fromDt && dateFormat(fromDt, true),
      toDt: dateFormat(toDt, true, 1),
      useType: withdraw ? useType : "",
    };

    setSearchData(searchData);

    mutate(searchData, {
      onSuccess: (res) => {
        if (summary) setResData(res);
        else setResData(res.list);
      },
    });
  };

  const handleRadio = (e) => {
    setUseType(e.target.value);
  };

  return (
    <Wrapper header={header}>
      <div>
        <SearchBox>
          <input
            type="date"
            defaultValue={fromDt}
            onChange={(e) => setFromDt(e.target.value)}
          />
          {"-"}
          <input
            type="date"
            defaultValue={toDt.slice(0, 10)}
            onChange={(e) => setToDt(e.target.value)}
          />
          <div onClick={handleSearch}>검색</div>
        </SearchBox>

        {withdraw && (
          <RadioBox>
            <div>
              <input
                type="radio"
                id="W001"
                value="W001"
                checked={useType === "W001"}
                onChange={handleRadio}
              />
              <label for="lock">충전 스케치</label>
            </div>

            <div>
              <input
                type="radio"
                id="W002"
                value="W002"
                checked={useType === "W002"}
                onChange={handleRadio}
              />
              <label for="reward">리워드 스케치</label>
            </div>
          </RadioBox>
        )}
      </div>

      <div>
        <PageHandler setPage={setPage} setPageSize={setSize} />
        <ExcelDownloadBtn onClick={handleExcelDownload} />
      </div>
    </Wrapper>
  );
};

export default SearchCategory;
