import { FONT_WEIGHT } from "constants/baseStyle";
import { styled } from "styled-components";

export const Default = {
  Wrapper: styled.div`
    width: 100%;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  `,

  SearchIcon: styled.img`
    width: 4.6rem;
    height: 4.6rem;
  `,

  Text: styled.div`
    margin-top: 2rem;
    font-weight: ${FONT_WEIGHT.MEDIUM};
    font-size: 1.6rem;
  `,

  BlueText: styled.span`
    color: #4253f2;
  `,

  ArrowImg: styled.img`
    position: absolute;
    top: 1.8rem;
    left: 34rem;
    width: 23rem;
    height: 12rem;
  `,
};
