import {
  Bottom,
  ModalContaier,
  ModalWrapper,
  PopupList,
  PopupListImg,
  PopupListTitle,
} from "./style";
import domainColumn from "../../../pages/Support/Popup/popupDomainColumn.json";
import { useMutationCustom } from "hooks/useMutationCustom";
import {
  HTTP_METHOD,
  IMG_CDN_URL,
  POST_FIND_POPUP_LIST,
  POST_POPUP_CHANGE_ORDER,
} from "constants/pathConstant";
import { useEffect, useState } from "react";
import { useToastCustom } from "hooks/useToastCustom";

const PopupListChangeModal = ({ setIsOpen }) => {
  const toast = useToastCustom();

  const [domain, setDomain] = useState("00");
  const [noticeList, setNoticeList] = useState([]);

  const { mutate } = useMutationCustom(POST_FIND_POPUP_LIST, HTTP_METHOD.POST);

  const fetchData = () => {
    const data = {
      titleAndDesc: "",
      begDt: "",
      endDt: "",
      showYn: "Y",
      currentPage: 1,
      pageSize: 9999,
      writer: "",
      domain,
    };

    mutate(data, {
      onSuccess: (res) => {
        const result = res.filter((v) => v.activeYn === "Y");
        result.sort((a, b) => b.index - a.index);
        setNoticeList(result);
      },

      onError: () => {
        toast("데이터 조회 실패", "error");
      },
    });
  };

  const { mutate: changeOrder } = useMutationCustom(
    POST_POPUP_CHANGE_ORDER,
    HTTP_METHOD.POST
  );

  const changeOrderHandler = () => {
    const data = noticeList.map((v, idx) => ({
      id: Number(v.id),
      index: String(noticeList.length - idx),
    }));

    changeOrder(
      { data: data },
      {
        onSuccess: () => {
          toast("수정이 완료되었습니다.", "success");
          setIsOpen((prev) => !prev);
        },
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, [domain]);

  const Draggable = ({ children, idx }) => {
    const handleDragStart = (e) => {
      e.dataTransfer.setData("text/plain", "");
      e.currentTarget.classList.add("dragging");

      e.currentTarget.dataset.startIdx = idx;
    };

    const handleDragEnd = (e) => {
      e.currentTarget.classList.remove("dragging");
    };

    return (
      <PopupListTitle
        className="draggable"
        draggable="true"
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        {children}
      </PopupListTitle>
    );
  };

  const Container = ({ children }) => {
    const handleDragOver = (e) => {
      e.preventDefault();
    };

    const handleDrop = (e) => {
      const afterElement = getDragAfterElement(e.currentTarget, e.clientY);
      const draggable = document.querySelector(".dragging");

      if (afterElement === undefined) {
        e.currentTarget.appendChild(draggable);
      } else {
        e.currentTarget.insertBefore(draggable, afterElement);
      }

      const draggedIdx = draggable.dataset.startIdx;
      const droppedIdx = Array.from(e.currentTarget.children).indexOf(
        draggable
      );

      setNoticeList((prevList) => {
        const newList = [...prevList];
        const draggedItem = newList[draggedIdx];
        newList.splice(draggedIdx, 1);
        newList.splice(droppedIdx, 0, draggedItem);
        return newList;
      });
    };

    const getDragAfterElement = (container, y) => {
      const draggableElements = Array.from(
        container.querySelectorAll(".draggable:not(.dragging)")
      );

      return draggableElements.reduce(
        (closest, child) => {
          const box = child.getBoundingClientRect();
          const offset = y - (box.top + box.height / 2);
          if (offset < 0 && offset > closest.offset) {
            return { offset: offset, element: child };
          } else {
            return closest;
          }
        },
        { offset: Number.NEGATIVE_INFINITY }
      ).element;
    };

    return (
      <div
        className="container"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {children}
      </div>
    );
  };

  return (
    <>
      <ModalWrapper onClick={() => setIsOpen((prev) => !prev)} />
      <ModalContaier>
        <div>
          <p>미리보기</p>
          <p>팝업 목록</p>
        </div>
        <PopupList>
          <div>
            {noticeList.map((v, idx) => (
              <PopupListImg key={idx}>
                <img src={v.linkImgKor} alt="popup_img" />
              </PopupListImg>
            ))}
          </div>

          <Container>
            {noticeList.map((v, idx) => (
              <Draggable key={idx} idx={idx}>
                <div>
                  <img
                    src={`${IMG_CDN_URL}/file/c0aa2abcdrag.svg`}
                    alt="drag_icon"
                  />
                </div>
                <p>{v.title}</p>
              </Draggable>
            ))}
          </Container>
        </PopupList>

        <Bottom>
          <div>
            <select value={domain} onChange={(e) => setDomain(e.target.value)}>
              {Object.entries(domainColumn.domain).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div onClick={changeOrderHandler}>적용</div>
        </Bottom>
      </ModalContaier>
    </>
  );
};

export default PopupListChangeModal;
