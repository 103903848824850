import { Info, List, Wrapper } from "template/ListTemplate.style";
import ChargeSummaryDetail from "./ChargeSummaryDetail/ChargeSummaryDetail";
import chargeSummaryColumn from "./chargeSummaryColumn.json";

// 브릿지 -> 충전 :: 요약 내역 (http://localhost:3000/super/bridge/charge)
const ChargeSummary = ({ chargeList }) => {
  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>충전 내역 요약</List.Text>
        <List.BtnContainer></List.BtnContainer>
      </List.TitleContainer>

      <List.Container style={{ minHeight: "0" }}>
        <List.Content>
          {chargeSummaryColumn?.summary.map((v, idx) => (
            <List.Row $ratio={v.ratio} key={idx}>
              {v.title}
            </List.Row>
          ))}
        </List.Content>

        <Info.Wrapper>
          <ChargeSummaryDetail data={chargeList.summary} />
        </Info.Wrapper>
      </List.Container>
    </Wrapper>
  );
};

export default ChargeSummary;
