import { useEffect, useState } from "react";
import {
  BottomBtn,
  BottomWrapper,
  ContentWrapper,
  Name,
  Rectangle,
  SelectWrapper,
  TableWrapper,
  Wrapper,
  SelectBoxWrapper,
  ContentBox,
} from "./style";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import {
  POST_APP_VERSION_CREATE,
  POST_APP_VERSION_MODIFY,
} from "constants/pathConstant";
import { axiosInstance } from "api/api";
import { useMutation } from "@tanstack/react-query";
import { useToastCustom } from "hooks/useToastCustom";
import versionColumn from "../versionColumn.json";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftjsToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { VersionModal } from "components/Modal/VersionModal";

// 시스템 관리 -> 버전 관리 :: 버전 등록 (http://localhost:3000/super/system/version1-upload)
const VersionUpload = ({ modifyData }) => {
  const nav = useNavigationCustom();
  const toast = useToastCustom();

  // 기본 upload state
  const [androidForceUpdYn, setAndroidForceUpdYn] = useState("Y");
  const [iosForceUpdYn, setIosForceUpdYn] = useState("Y");
  const [androidVersion, setAndroidVersion] = useState("");
  const [iosVersion, setIosVersion] = useState("");
  const [message, setMessage] = useState("");
  const [messageEng, setMessageEng] = useState("");
  const [reqType, setReqType] = useState("00");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateKr, setEditorStateKr] = useState(EditorState.createEmpty());

  // 수정시 추가 state
  const [id, setId] = useState("");
  const [useYn, setUseYn] = useState("");

  // 모달 state
  const [isOpen, setIsOpen] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const previewHandler = () => {
    setIsOpen((prev) => !prev);
    setPreviewData({
      message,
      messageEng,
    });
  };

  useEffect(() => {
    if (!modifyData) return;

    setId(modifyData.id);
    setAndroidForceUpdYn(modifyData.androidForceUpdYn);
    setIosForceUpdYn(modifyData.iosForceUpdYn);
    setAndroidVersion(modifyData.androidVersion);
    setIosVersion(modifyData.iosVersion);
    setMessage(modifyData.message);
    setMessageEng(modifyData.messageEng);
    setReqType(modifyData.reqType);
    setUseYn(modifyData.useYn);

    // 영어 contents 기본값 설정
    const blocksFromHtml = htmlToDraft(modifyData.messageEng);
    if (blocksFromHtml) {
      const { contentBlocks, entityMap } = blocksFromHtml;
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(contentBlocks, entityMap)
        )
      );
    }
    // 한국어 contents 기본값 설정
    const blocksFromHtmlKr = htmlToDraft(modifyData.message);
    if (blocksFromHtmlKr) {
      const { contentBlocks, entityMap } = blocksFromHtmlKr;
      setEditorStateKr(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(contentBlocks, entityMap)
        )
      );
    }
  }, []);

  // 이미지 업로드 핸들러

  const { mutate } = useMutation(
    (data) => axiosInstance.post(POST_APP_VERSION_CREATE, data),
    {
      onSuccess: () => {
        toast("정상적으로 등록되었습니다.", "success");
        nav("/super/system/version1");
      },
    }
  );

  const { mutate: updateMutate } = useMutation(
    (data) => axiosInstance.post(POST_APP_VERSION_MODIFY, data),
    {
      onSuccess: () => {
        toast("정상적으로 수정되었습니다.", "success");
        nav("/super/system/version1");
      },
    }
  );

  const updateTextDescription = async (state, isKorean) => {
    // 에디터에 글쓰기 위한 state
    isKorean ? setEditorStateKr(state) : setEditorState(state);

    let html = draftjsToHtml(
      convertToRaw(
        isKorean
          ? editorStateKr.getCurrentContent()
          : editorState.getCurrentContent()
      )
    );

    // 불완전한 <p></p> 태그 수정
    html = html.replace(/<p><\/p>/g, "<br />");

    // html 기본 폰트사이즈 1rem, px을 rem으로 바꾸는 정규식
    let matchWord = html.match(/font-size: \d{1,2}px;/gi);
    if (matchWord) {
      matchWord = matchWord.map((font) => {
        const int = font.match(/\d{1,2}/g);
        return `font-size: ${int / 10}rem`;
      });

      html = html.replace(/font-size: \d{1,2}px;/gi, matchWord);
    }
    // ---------------------이슈 : &nbsp; 가 임의로 사라져버려서 띄어쓰기는 연속 2번이상 불가
    isKorean ? setMessage(html) : setMessageEng(html);
  };

  const uploadHandler = async () => {
    if (!androidVersion || !iosVersion || !message || !messageEng)
      return toast("필수 값을 모두 입력해주세요.", "error");

    const data = {
      androidForceUpdYn,
      iosForceUpdYn,
      androidVersion,
      iosVersion,
      message,
      messageEng,
      reqType,
      id: modifyData && id,
      useYn: modifyData && useYn,
    };

    setPreviewData(data);

    if (modifyData) {
      updateMutate(data);
    } else {
      mutate(data);
    }
  };

  return (
    <Wrapper>
      <Name>Version</Name>
      <TableWrapper>
        <span>
          <Rectangle />
          작성하기
        </span>
        <div>
          <div>
            <SelectBoxWrapper>
              <div>구분</div>
              <SelectWrapper>
                <select
                  onChange={(e) => setReqType(e.target.value)}
                  defaultValue={modifyData?.reqType || reqType}
                  key={modifyData?.reqType || reqType}
                >
                  {Object.entries(versionColumn.type).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </SelectWrapper>
            </SelectBoxWrapper>
          </div>

          <div>
            <SelectBoxWrapper>
              <div style={{ fontSize: "1.8rem" }}>AOS 강제 업데이트 여부</div>
              <SelectWrapper>
                <select
                  onChange={(e) => setAndroidForceUpdYn(e.target.value)}
                  defaultValue={
                    modifyData?.androidForceUpdYn || androidForceUpdYn
                  }
                  key={modifyData?.androidForceUpdYn || androidForceUpdYn}
                >
                  <option value={"Y"}>Y</option>
                  <option value={"N"}>N</option>
                </select>
              </SelectWrapper>
            </SelectBoxWrapper>
            <SelectBoxWrapper>
              <div>AOS 버전</div>
              <SelectWrapper>
                <input
                  type="text"
                  onChange={(e) => setAndroidVersion(e.target.value)}
                  placeholder="ex) 1.0.0"
                  defaultValue={modifyData?.aosLinkTarget || androidVersion}
                />
              </SelectWrapper>
            </SelectBoxWrapper>
          </div>

          <div>
            <SelectBoxWrapper>
              <div style={{ fontSize: "1.8rem" }}>IOS 강제 업데이트 여부</div>
              <SelectWrapper>
                <select
                  onChange={(e) => setIosForceUpdYn(e.target.value)}
                  defaultValue={modifyData?.iosForceUpdYn || iosForceUpdYn}
                  key={modifyData?.iosForceUpdYn || iosForceUpdYn}
                >
                  <option value={"Y"}>Y</option>
                  <option value={"N"}>N</option>
                </select>
              </SelectWrapper>
            </SelectBoxWrapper>
            <SelectBoxWrapper>
              <div>IOS 버전</div>
              <SelectWrapper>
                <input
                  type="text"
                  onChange={(e) => setIosVersion(e.target.value)}
                  placeholder="ex) 1.0.0"
                  defaultValue={modifyData?.iosVersion || iosVersion}
                />
              </SelectWrapper>
            </SelectBoxWrapper>
          </div>
        </div>
      </TableWrapper>

      <ContentBox>
        <ContentWrapper>
          <span>
            <Rectangle />
            내용(한국어)
          </span>

          <Editor
            placeholder="내용을 작성해주세요"
            editorState={editorStateKr}
            onEditorStateChange={(state) => updateTextDescription(state, true)}
            localization={{ locale: "ko" }}
            editorStyle={{
              height: "64rem",
              width: "55.8rem",
              border: "3px solid lightgray",
              padding: "20px",
              fontSize: "1.6rem",
              background: "#fff",
            }}
            toolbar={{
              // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
            }}
          />
        </ContentWrapper>
        <ContentWrapper>
          <span>
            <Rectangle />
            내용(영어)
          </span>

          <Editor
            placeholder="내용을 작성해주세요"
            editorState={editorState}
            onEditorStateChange={(state) => updateTextDescription(state, false)}
            localization={{ locale: "ko" }}
            editorStyle={{
              height: "64rem",
              width: "55.8rem",
              border: "3px solid lightgray",
              padding: "20px",
              fontSize: "1.6rem",
              background: "#fff",
            }}
            toolbar={{
              // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
            }}
          />
        </ContentWrapper>
      </ContentBox>

      <BottomWrapper>
        <div>
          <BottomBtn onClick={() => nav("/super/system/version1")}>
            목록
          </BottomBtn>
        </div>
        <div>
          <BottomBtn
            onClick={previewHandler}
            bgColor={"#2db9c2"}
            fontColor={"white"}
          >
            미리보기
          </BottomBtn>
          <BottomBtn
            onClick={uploadHandler}
            bgColor={"#4253f2"}
            fontColor={"white"}
          >
            저장
          </BottomBtn>
        </div>
      </BottomWrapper>

      {isOpen && <VersionModal data={previewData} setIsOpen={setIsOpen} />}
    </Wrapper>
  );
};

export default VersionUpload;
