import { useMutation } from "@tanstack/react-query";
import { axiosInstance } from "api/api";
import { HTTP_METHOD } from "constants/pathConstant";
import { useSetRecoilState } from "recoil";
import { RootLoadingState } from "recoil/atom/LoadingState";

/**
 *
 * @param {String} uri
 * @param {String} method
 * @param {boolean} isForm
 * @returns
 */
export const useMutationCustom = (
  uri,
  method = HTTP_METHOD.POST,
  options = {}
) => {
  const setIsLoading = useSetRecoilState(RootLoadingState.isLoading);

  const mutation = useMutation((data) => axiosInstance[method](uri, data), {
    ...options,
    onMutate: (...args) => {
      setIsLoading(true);
      options.onMutate?.(...args);
    },

    onSuccess: (...args) => {
      setIsLoading(false);
      options.onSuccess?.(...args);
    },

    onError: (...args) => {
      setIsLoading(false);
      options.onError?.(...args);
    },
  });

  const extendedMutate = (data, opts = {}) => {
    mutation.mutate(data, {
      ...opts,

      onSuccess: (response) => {
        options.onSuccess?.(response);
        opts.onSuccess?.(response);
      },

      onError: (error) => {
        options.onError?.(error);
        opts.onError?.(error);
      },
    });
  };

  return {
    ...mutation,
    mutate: extendedMutate,
  };
};
