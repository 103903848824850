import { useRecoilValue } from "recoil";
import { AdminAuthState } from "recoil/atom/AdminState";
import { Body, Header, Roles, SaveBtn, Top, Wrapper } from "./AuthModal.style";
import authRoles from "constants/authRoles.json";
import { Loading } from "components/Loading/Loading";
import { useEffect, useState } from "react";
import { useMutationCustom } from "hooks/useMutationCustom";
import {
  HTTP_METHOD,
  POST_ADMIN_LIST,
  PUT_CHANGE_ADMIN_ROLE,
} from "constants/pathConstant";
import { useToastCustom } from "hooks/useToastCustom";
import { useCloseRootModal } from "hooks/modal/useCloseRootModal";

export const AuthModal = ({ setAdminList, searchData }) => {
  const closeRootModal = useCloseRootModal();
  const toast = useToastCustom();

  const [isActive, setIsActive] = useState(null);
  const [auth, setAuth] = useState([]);

  const authData = useRecoilValue(AdminAuthState.SelectedAdmin);
  useEffect(() => {
    if (!authData.roles) return;

    setIsActive(authData.isActive);
    setAuth(authData.roles.split(","));
  }, [authData]);

  const handleCheckAuth = (e) => {
    const checkData = [...auth];
    if (e.target.checked) {
      checkData.push(e.target.value);
      setAuth(checkData);
    } else {
      const index = checkData.indexOf(e.target.value);
      checkData.splice(index, 1);
      setAuth(checkData);
    }
  };

  const { mutate } = useMutationCustom(PUT_CHANGE_ADMIN_ROLE, HTTP_METHOD.PUT);

  const { mutate: updateMutate } = useMutationCustom(
    POST_ADMIN_LIST,
    HTTP_METHOD.POST
  );

  const handleSaveAuth = () => {
    const data = { roles: auth, adminId: authData.adminId, isActive };
    mutate(data, {
      onSuccess: () => {
        updateMutate(searchData, {
          onSuccess: (res) => {
            setAdminList(res);
            closeRootModal();
            toast("권한 업데이트 성공", "success");
          },
        });
      },

      onError: () => {
        toast("권한 업데이트 실패", "error");
      },
    });
  };

  if (!authData) return <Loading />;

  return (
    <Wrapper>
      <Top.Wrapper>
        <Top.Text>사용유무</Top.Text>
        <Top.Input
          type="radio"
          id="yes"
          name="yes"
          checked={isActive === 1}
          onChange={() => setIsActive(1)}
        />
        <Top.Label htmlFor="yes">예</Top.Label>
        <Top.Input
          type="radio"
          id="no"
          checked={isActive === 0}
          onChange={() => setIsActive(0)}
        />
        <Top.Label htmlFor="no">아니요</Top.Label>
      </Top.Wrapper>

      {/* 권한들 */}
      <Body.Wrapper>
        {authRoles.data.map((data, idx) => (
          <Body.Container key={idx}>
            <Header.Wrapper>
              <Header.Title>{data.title}</Header.Title>
            </Header.Wrapper>
            <Roles.Wrapper>
              {data.body.map((sub, subIdx) => (
                <Roles.Container key={subIdx}>
                  <Roles.Input
                    type="checkbox"
                    id={sub.sub + subIdx}
                    value={sub.value}
                    checked={auth.includes(sub.value)}
                    onChange={(e) => handleCheckAuth(e)}
                  />
                  <Roles.Text htmlFor={sub.sub + subIdx}>{sub.sub}</Roles.Text>
                </Roles.Container>
              ))}
            </Roles.Wrapper>
          </Body.Container>
        ))}
      </Body.Wrapper>

      <SaveBtn.Wrapper>
        <SaveBtn.Btn onClick={handleSaveAuth}>저장</SaveBtn.Btn>
      </SaveBtn.Wrapper>
    </Wrapper>
  );
};
