import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { ListDefault } from "components/ListDefault/ListDefault";
import { HTTP_METHOD, POST_FIND_DROP_USER } from "constants/pathConstant";
import { DROP_USER_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { Info, List, Wrapper } from "template/ListTemplate.style";
import { DropUserInfoLIstDetail } from "./DropUserInfoLIstDetail";
import dropUserColumn from "../dropUserColumn.json";
import PageHandler from "components/PageHandler/PageHandler";
import { xlsxDateFormat } from "components/xlsxDateFormat";

// 검색 -> 탈퇴 회원관리 :: 회원 목록 (http://localhost:3000/super/search/gone-user)
export const DropUserInfoLIst = ({
  setPage,
  setSize,
  dropUserList,
  searchData,
  setDropUserList,
}) => {
  const excelDownload = useExcelDownload();
  const toast = useToastCustom();

  const { mutate } = useMutationCustom(POST_FIND_DROP_USER, HTTP_METHOD.POST);

  const handleExcelDownload = () => {
    if (!dropUserList[0]?.cnt) {
      toast("액셀 다운로드 실패", "error");
      return;
    }

    const data = {
      ...searchData,
      pageSize: dropUserList[0]?.cnt,
      currentPage: 1,
    };

    mutate(data, {
      onSuccess: (response) => {
        const updatedResponse = xlsxDateFormat(response);

        const excelData = {
          data: updatedResponse,
          headers: DROP_USER_XLSX_DOWNLOAD.header,
          fileName: DROP_USER_XLSX_DOWNLOAD.filename,
        };

        excelDownload(excelData);
      },

      onError: () => {
        toast("전체 데이터 조회 실패", "error");
      },
    });
  };

  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>
          회원 목록{" "}
          {dropUserList[0] && <span>(검색됨 : {dropUserList[0]?.cnt}개)</span>}
        </List.Text>

        <List.BtnContainer>
          {/* 페이지 핸들러 */}
          <PageHandler setPage={setPage} setPageSize={setSize} />
          {/* 엑셀 다운로드 버튼 */}
          <ExcelDownloadBtn onClick={handleExcelDownload} />
        </List.BtnContainer>
      </List.TitleContainer>

      {/* 리스트 */}
      <List.Container>
        {dropUserList.length === 0 ? (
          <ListDefault text={"회원을"} />
        ) : (
          <>
            {/* List Column */}
            <List.Content>
              {dropUserColumn.data.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>
            <Info.Wrapper>
              {/* 실제 데이터 */}
              {dropUserList.map((v, idx) => (
                <div key={idx}>
                  <DropUserInfoLIstDetail
                    key={v.id}
                    id={v.id}
                    crtDt={v.crtDt}
                    quitDt={v.quitDt}
                    acctUserNm={v.acctUserNm}
                    acctCd={v.acctCd}
                    refererNm={v.refererNm}
                    refererCd={v.refererCd}
                    cellNumber={v.cellNumber}
                    email={v.email}
                    walletDefault={v.walletDefault}
                    state={v.state}
                    stateNm={v.stateNm}
                    reqQuitDt={v.reqQuitDt}
                    reqCnt={v.reqCnt}
                    birthDay={v.birthDay}
                    mutate={mutate}
                    searchData={searchData}
                    setDropUserList={setDropUserList}
                  />
                </div>
              ))}
            </Info.Wrapper>
          </>
        )}
      </List.Container>
    </Wrapper>
  );
};
