import { FONT_WEIGHT } from "constants/baseStyle";
import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
`;

export const Container = {
  Top: styled.div`
    display: flex;
    gap: 0.4rem;
    align-items: center;
  `,

  Text: styled.div`
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 2.2rem;
  `,

  Content: styled.div`
    width: 38.8rem;
    height: 75.1rem;
    min-height: 14.4rem;
    border-radius: 1rem;
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 3rem;
  `,
};
