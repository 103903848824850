import React, { useState, useEffect } from "react";
import styled from "styled-components";

const MonthSelectBox = ({ setToDt, setFromDt }) => {
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");

  useEffect(() => {
    const today = new Date();
    const tempMonths = [];
    let defaultValue = "";

    for (let i = 0; i < 5; i++) {
      let year = today.getFullYear();
      let month = today.getMonth() - i + 1;
      if (month <= 0) {
        month += 12;
        year -= 1;
      }

      const monthObj = { year, month };
      tempMonths.unshift(monthObj);

      // 오늘 날짜의 년도와 월을 기본값으로 설정
      if (i === 0) {
        defaultValue = `${year}-${month}`;
      }
    }

    setMonths(tempMonths);
    setSelectedMonth(defaultValue);

    if (defaultValue) {
      const [year, month] = defaultValue.split("-").map(Number);
      const firstDay = new Date(year, month - 1, 1);
      const lastDay = new Date(year, month, 0);
      setFromDt(firstDay);
      setToDt(lastDay);
    }
  }, []);

  const handleChange = (event) => {
    setSelectedMonth(event.target.value);
    const [year, month] = event.target.value.split("-").map(Number);
    const firstDay = new Date(year, month - 1, 1);
    const lastDay = new Date(year, month, 0);
    setFromDt(firstDay);
    setToDt(lastDay);
  };

  return (
    <SelectBox value={selectedMonth} onChange={handleChange}>
      {months.map(({ year, month }, index) => (
        <option key={index} value={`${year}-${month}`}>
          {`${year}년 ${month}월`}
        </option>
      ))}
    </SelectBox>
  );
};

export default MonthSelectBox;

const SelectBox = styled.select`
  font-size: 1.2rem;
`;
