import { useEffect, useState } from "react";
import PropertyListDetail from "./PropertyListDetail";
import propertyListColumn from "./propertyListColumn.json";
import { Main, MainDetail } from "template/MainListTemplate.style";
import { useMutationCustom } from "hooks/useMutationCustom";
import { HTTP_METHOD, POST_MAIN_PROPERTY_FETCH } from "constants/pathConstant";

// 메인 :: 자산 내역 (http://localhost:3000/main)
const PropertyList = () => {
  const [propertyList, setPropertyList] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const { mutate } = useMutationCustom(
    POST_MAIN_PROPERTY_FETCH,
    HTTP_METHOD.POST
  );

  const fetchData = () => {
    mutate(
      {},
      {
        onSuccess: (response) => {
          setPropertyList(response.list);
        },
      }
    );
  };

  return (
    <Main.Wrapper>
      <Main.Content>
        {propertyListColumn.column.map((v, idx) => (
          <Main.Row $ratio={v.ratio} key={idx}>
            {v.title}
          </Main.Row>
        ))}
      </Main.Content>
      <MainDetail.Wrapper max={true}>
        {propertyList?.map((v, idx) => (
          <PropertyListDetail key={idx} data={v} />
        ))}
      </MainDetail.Wrapper>
    </Main.Wrapper>
  );
};

export default PropertyList;
