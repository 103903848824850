import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 0.6rem;
  border: 1px solid #bbb;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  border-bottom: 1px solid #bbb;
  padding: 1.3rem;
  justify-content: space-between;
  align-items: center;
  background: #f3f3f3;

  & > strong {
    color: #000;
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 1.6rem;
    margin-top: 0.3rem;
  }
`;

export const ReqTypeBox = styled.div`
  display: flex;
  gap: 1rem;
`;

export const ReqType = styled.p`
  width: 8rem;
  border: 1px solid #bbb;
  text-align: center;
  padding: 0.5rem 0;
  border-radius: 0.6rem;
  font-size: 1.2rem;
  color: #747474;
  cursor: pointer;
  background: ${(props) => (props.clicked ? "#4253F2" : "#F3F3F3")};
  color: ${(props) => (props.clicked ? "#fff" : "#747474")};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Badge = styled.span`
  width: 1.5rem;
  height: 1.5rem;
  background: #ff3c2b;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: absolute;
  top: -0.5rem;
  right: -0.2rem;
  font-size: 1rem;
`;
