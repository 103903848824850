import { useCloseRootModal } from "hooks/modal/useCloseRootModal";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 10%;
    max-width: 5rem;
  }
`;

export const Loading = ({ time = 5000 }) => {
  const closeRootModal = useCloseRootModal();

  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      alert("서버 오류, 새로고침 후 다시 시도해보세요.");
      setShowLoading(false);
      closeRootModal();
    }, time);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {showLoading && (
        <Wrapper>
          <img src="/assets/imgs/loading.gif" alt="loading" />
        </Wrapper>
      )}
    </>
  );
};
