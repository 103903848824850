import { atom } from "recoil";

export const loginState = atom({
  key: "Login",
  default: {
    isLogin: true,
  },
});

export const loginRole = atom({
  key: "LoginRole",
  default: [],
});

export const loginData = atom({
  key: "LoginData",
  default: {},
});
