import {
  dateFormatDay,
  dateFormatEnd,
  dateFormatStart,
} from "components/newDateFormat";
import { PLACEHOLDER } from "constants/baseStyle";
import { POST_PAYMENT_MONTHLY_FETCH } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useEffect, useState } from "react";
import { Wrapper } from "template/ListTemplate.style";
import { Search } from "template/SearchTemplate.style";

const PaymentMonthlySearch = ({
  setPaymentMonthlyRes,
  setSearchReq,
  currentPage,
  setCurrentPage,
  setIsSearch,
  searchReq,
  setPaymentMonthlyDetailRes,
  setSearchDetailReq,
}) => {
  const toast = useToastCustom();

  const [memberCode, setMemberCode] = useState("");
  const [memberName, setMemberName] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState(dateFormatDay(new Date()));

  useEffect(() => {
    if (!Object.keys(searchReq)[0]) return;
    fetchData();
  }, [currentPage]);

  const { mutate } = useMutationCustom(POST_PAYMENT_MONTHLY_FETCH);

  const fetchData = () => {
    const data = {
      memberCode,
      memberName,
      walletAddress,
      fromDt: fromDt && dateFormatStart(fromDt),
      toDt: dateFormatEnd(toDt),
      currentPage,
      pageSize: 6,
    };

    setSearchReq(data);

    mutate(data, {
      onSuccess: (res) => {
        setPaymentMonthlyRes(res);
        setSearchDetailReq({});
      },
      onError: () => {
        toast("조회에 실패했습니다.", "error");
      },
    });
  };

  const searchHandler = () => {
    fetchData();
    setIsSearch(true);
    setCurrentPage(1);
    setPaymentMonthlyDetailRes([]);
  };

  const handleEnter = (key) => {
    if (key === "Enter") searchHandler();
  };

  return (
    <Wrapper>
      <Search.Text>월별 결제내역 조회</Search.Text>

      <Search.Container>
        <Search.Content>
          <Search.InputWrapper>
            <div>회원명</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onKeyUp={(e) => handleEnter(e.key)}
              onChange={(e) => {
                setMemberName(e.target.value);
              }}
            />
          </Search.InputWrapper>

          <Search.InputWrapper>
            <div>회원코드</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onKeyUp={(e) => handleEnter(e.key)}
              onChange={(e) => {
                setMemberCode(e.target.value);
              }}
            />
          </Search.InputWrapper>

          <Search.InputWrapper>
            <div>지갑주소</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onKeyUp={(e) => handleEnter(e.key)}
              onChange={(e) => {
                setWalletAddress(e.target.value);
              }}
            />
          </Search.InputWrapper>

          {/* 가입일자 (부터) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>결제일자 (부터)</div>
            <Search.Input
              type="date"
              defaultValue={fromDt}
              onChange={(e) => setFromDt(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            ></Search.Input>
          </Search.InputWrapper>

          {/* 가입일자 (까지) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>결제일자 (까지)</div>
            <Search.Input
              type="date"
              defaultValue={toDt.slice(0, 10)}
              onChange={(e) => setToDt(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            ></Search.Input>
          </Search.InputWrapper>
        </Search.Content>

        <Search.Btn onClick={searchHandler}>조회</Search.Btn>
      </Search.Container>
    </Wrapper>
  );
};

export default PaymentMonthlySearch;
