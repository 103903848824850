import React, { useState } from "react";
import { Wrapper } from "./PaymentWallet.style";
import PaymentWalletSearch from "./PaymentWalletSearch/PaymentWalletSearch";
import PaymentWalletSummary from "./PaymentWalletSummary/PaymentWalletSummary";
import PaymentWalletDetail from "./PaymentWalletDetail/PaymentWalletDetail";
import { PaginationBox } from "components/Pagination/PaginationBox";

// 결제 내역 :: (http://localhost:3000/super/payment/wallet)
const PaymentWallet = () => {
  const [paymentWalletList, setPaymentWalletList] = useState({
    summary: {},
    list: [],
    listCount: 0,
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});
  const [isSearch, setIsSearch] = useState(false);

  return (
    <Wrapper>
      <div>
        <PaymentWalletSearch
          setPaymentWalletList={setPaymentWalletList}
          setPage={setPage}
          currentPage={page}
          pageSize={size}
          setSearchData={setSearchData}
          searchData={searchData}
          setIsSearch={setIsSearch}
        />

        {/* {paymentWalletList.summary && (
          <PaymentWalletSummary paymentWalletList={paymentWalletList.summary} />
        )} */}

        <PaymentWalletDetail
          paymentWalletList={paymentWalletList}
          setPage={setPage}
          setSize={setSize}
          searchData={searchData}
          isSearch={isSearch}
        />

        {isSearch && paymentWalletList.list.length !== 0 && (
          <PaginationBox
            totalItemsCount={paymentWalletList.listCount}
            page={page}
            setPage={setPage}
            size={size}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default PaymentWallet;
