import React, { useState } from "react";
import { Wrapper } from "./Migration.style";
import MigrationSearch from "./MigrationSearch/MigrationSearch";
import MigrationSummary from "./MigrationSummary/MigrationSummary";
import MigrationDetail from "./MigrationDetail/MigrationDetail";
import { PaginationBox } from "components/Pagination/PaginationBox";

// 브릿지 -> 스왑 :: (http://localhost:3000/super/bridge/migration)
const Migration = () => {
  const [migrationList, setMigrationList] = useState({ list: [], summary: {} });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});
  const [isSearch, setIsSearch] = useState(false);

  return (
    <Wrapper>
      <div>
        <MigrationSearch
          setMigrationList={setMigrationList}
          setPage={setPage}
          currentPage={page}
          pageSize={size}
          setSearchData={setSearchData}
          searchData={searchData}
          setIsSearch={setIsSearch}
        />

        <MigrationSummary migrationList={migrationList} />

        <MigrationDetail
          migrationList={migrationList}
          setPage={setPage}
          setSize={setSize}
          searchData={searchData}
          setMigrationList={setMigrationList}
          isSearch={isSearch}
        />

        {isSearch && migrationList.list && (
          <PaginationBox
            totalItemsCount={migrationList.list[0]?.cnt}
            page={page}
            setPage={setPage}
            size={size}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default Migration;
