import { AuthModal } from "components/Modal/AuthModal/AuthModal";
import { useOpenCustomModal } from "hooks/modal/useOpenCustomModal";
import { useSetRecoilState } from "recoil";
import { AdminAuthState } from "recoil/atom/AdminState";
import { Info, List } from "template/ListTemplate.style";
import { Auth } from "../AdminList.style";
import adminColumn from "../adminColumn.json";

// 시스템 관리 :: 관리자/권한 목록 낱개 (http://localhost:3000/super/system/admin)
export const AdminInfoListDetail = ({ data: props, idx, setAdminList, searchData }) => {
  const setSelectedAdmin = useSetRecoilState(AdminAuthState.SelectedAdmin);
  const openAuthModal = useOpenCustomModal(AuthModal);
  const handleModifyAuth = () => {
    setSelectedAdmin(props);
    openAuthModal({ setAdminList, searchData });
  };

  return (
    <Info.Content>
      {/* 번호 */}
      <List.Row $ratio={adminColumn.data[0].ratio}>{idx + 1}</List.Row>

      {/* 사용자명 */}
      <List.Row $ratio={adminColumn.data[1].ratio}>{props.name}</List.Row>

      {/* 아이디 */}
      <List.Row $ratio={adminColumn.data[2].ratio}>{props.adminId}</List.Row>

      {/* 사용유무 */}
      <List.Row $ratio={adminColumn.data[3].ratio} style={{ color: props.isActive === 1 ? "#21F650" : "#F00" }}>
        {props.isActive === 1 ? "활성" : "비활성"}
      </List.Row>

      {/* 권한 */}
      <List.Row $ratio={adminColumn.data[4].ratio}>
        <Auth.ModifyBtn onClick={handleModifyAuth}>설정</Auth.ModifyBtn>
      </List.Row>

      {/* 비고 */}
      <List.Row $ratio={adminColumn.data[5].ratio}>{props.memo}</List.Row>

      {/* 등록자 */}
      <List.Row $ratio={adminColumn.data[6].ratio}>{props.creator}</List.Row>

      {/* 등록일자 */}
      <List.Row $ratio={adminColumn.data[7].ratio}>{props.crtDt.slice(0, 16)}</List.Row>
    </Info.Content>
  );
};
