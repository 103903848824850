import React, { useState } from "react";
import { Wrapper } from "./AcceptStoreList.style";
import { AcceptStoreSearch } from "./StoreSearch/AcceptStoreSearch";
import { AcceptStoreInfoList } from "./StoreInfoList/AcceptStoreInfoList";
import { PaginationBox } from "components/Pagination/PaginationBox";

// 승인 -> 가맹점 검색 :: (http://localhost:3000/super/accept/store)
export const AcceptStoreList = () => {
  const [acceptStoreList, setAcceptStoreList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});

  return (
    <Wrapper>
      <AcceptStoreSearch
        setAcceptStoreList={setAcceptStoreList}
        setPage={setPage}
        currentPage={page}
        pageSize={size}
        setSearchData={setSearchData}
        searchData={searchData}
      />
      <AcceptStoreInfoList
        acceptStoreList={acceptStoreList}
        setPage={setPage}
        setSize={setSize}
        searchData={searchData}
        currentPage={page}
        pageSize={size}
        setSearchData={setSearchData}
        setAcceptStoreList={setAcceptStoreList}
      />

      {acceptStoreList.length !== 0 && (
        <PaginationBox
          totalItemsCount={acceptStoreList[0]?.cnt}
          page={page}
          setPage={setPage}
          size={size}
        />
      )}
    </Wrapper>
  );
};
