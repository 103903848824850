import { dateFormat } from "components/dateFormat";
import {
  PaymentDetailRow,
  RowElement,
} from "pages/Payment/PaymentWallet/PaymentWallet.style";
import { Info, List } from "template/ListTemplate.style";
import paymentColumn from "./paymentColumn.json";
import { useState } from "react";
import { SYMBOL } from "constants/pathConstant";
import { costFormat } from "components/costForamt";

const PaymentDetail = ({ data }) => {
  const [clicked, setClicked] = useState(false);

  return (
    <>
      <Info.Content
        onClick={() => setClicked(!clicked)}
        isClicked={clicked}
        style={{ color: clicked && "#4253F2", cursor: "pointer" }}
      >
        {/* 번호 */}
        <List.Row $ratio={paymentColumn.column[0].ratio}>{data.id}</List.Row>

        {/* 거래일시 */}
        <List.Row $ratio={paymentColumn.column[1].ratio}>
          {dateFormat(data.paymentDt)}
        </List.Row>

        {/* 가맹점명 */}
        <List.Row $ratio={paymentColumn.column[2].ratio}>
          <RowElement>
            <p>{data.storeNm}</p>
            <p>({data.storeCd})</p>
          </RowElement>
        </List.Row>

        {/* 결제고객 */}
        <List.Row $ratio={paymentColumn.column[3].ratio}>
          <RowElement>
            <p>{data.customerNm}</p>
            <p>({data.customerCd})</p>
          </RowElement>
        </List.Row>

        {/* 결제포인트 */}
        <List.Row $ratio={paymentColumn.column[4].ratio}>
          <RowElement>
            <p>{costFormat(data.sketchPayment, SYMBOL)}</p>
            <p>({data.krwPayment} 원)</p>
          </RowElement>
        </List.Row>

        {/* 결제금액 */}
        <List.Row $ratio={paymentColumn.column[5].ratio}>
          <RowElement>
            <p>{costFormat(data.sketchBnsSeq1, SYMBOL)}</p>
            <p>({data.krwBnsSeq1} 원)</p>
          </RowElement>
        </List.Row>

        {/* 시세 */}
        <List.Row $ratio={paymentColumn.column[6].ratio}>
          <p>{data.krwPerSketch}</p>
        </List.Row>

        {/* 수수료율 */}
        <List.Row $ratio={paymentColumn.column[7].ratio}>
          {data.storeFeeRate}%
        </List.Row>

        {/* 배송비 */}
        <List.Row $ratio={paymentColumn.column[8].ratio}>
          {data.ssDeliveryCost}
        </List.Row>

        {/* 결제 수단 */}
        <List.Row $ratio={paymentColumn.column[9].ratio}>
          {data.symbol}
        </List.Row>

        {/* 정산 금액 */}
        <List.Row $ratio={paymentColumn.column[10].ratio}>
          {data.ssLastPricePay}
        </List.Row>

        {/* 상태 */}
        <List.Row $ratio={paymentColumn.column[11].ratio}>
          {data.paymentState}
        </List.Row>

        {/* 승인번호 */}
        <List.Row $ratio={paymentColumn.column[12].ratio}>
          {data.reqId}
        </List.Row>
      </Info.Content>

      {clicked && (
        <PaymentDetailRow.Wrapper>
          <PaymentDetailRow.Container>
            <PaymentDetailRow.Header>
              {paymentColumn.detailColumn.map((v, idx) => (
                <PaymentDetailRow.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </PaymentDetailRow.Row>
              ))}
            </PaymentDetailRow.Header>

            <PaymentDetailRow.Data>
              {/* 고객페이백 */}
              <PaymentDetailRow.Row
                $ratio={paymentColumn.detailColumn[0].ratio}
              >
                <RowElement>
                  <p>
                    {data.sketchBnsSeq2 || 0} {SYMBOL}
                  </p>
                  <p>({data.krwBnsSeq2 || 0} 원)</p>
                </RowElement>
              </PaymentDetailRow.Row>

              {/* 스토어 추천보너스 */}
              <PaymentDetailRow.Row
                $ratio={paymentColumn.detailColumn[1].ratio}
              >
                <RowElement>
                  <p>
                    {data.sketchBnsSeq3 || 0} {SYMBOL}
                  </p>
                  <p>({data.krwBnsSeq3 || 0} 원)</p>
                </RowElement>
              </PaymentDetailRow.Row>

              {/* 회사 보너스 */}
              <PaymentDetailRow.Row
                $ratio={paymentColumn.detailColumn[2].ratio}
              >
                <RowElement>
                  <p>
                    {data.sketchBnsSeq4 || 0} {SYMBOL}
                  </p>
                  <p>({data.krwBnsSeq4 || 0} 원)</p>
                </RowElement>
              </PaymentDetailRow.Row>

              {/* 추천보너스 */}
              <PaymentDetailRow.Row
                $ratio={paymentColumn.detailColumn[3].ratio}
              >
                <RowElement>
                  <p>
                    {data.sketchBnsSeq5 || 0} {SYMBOL}
                  </p>
                  <p>({data.krwBnsSeq5 || 0} 원)</p>
                </RowElement>
              </PaymentDetailRow.Row>

              {/* 추천 */}
              <PaymentDetailRow.Row
                $ratio={paymentColumn.detailColumn[4].ratio}
              >
                {data.refererNm && (
                  <RowElement>
                    <p>{data.refererNm}</p>
                    <p>({data.refererCd})</p>
                  </RowElement>
                )}
              </PaymentDetailRow.Row>

              {/* 스토어 추천 */}
              <PaymentDetailRow.Row
                $ratio={paymentColumn.detailColumn[5].ratio}
              >
                {data.storeRefererNm && (
                  <RowElement>
                    <p>{data.storeRefererNm}</p>
                    <p>({data.storeRefererCd})</p>
                  </RowElement>
                )}
              </PaymentDetailRow.Row>
            </PaymentDetailRow.Data>
          </PaymentDetailRow.Container>
        </PaymentDetailRow.Wrapper>
      )}
    </>
  );
};

export default PaymentDetail;
