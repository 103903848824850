import paymentWalletColumn from "../paymentWalletColumn.json";
import { ClickInfo } from "../PaymentWallet.style";
import { List } from "template/ListTemplate.style";
import WalletSlice from "components/WalletSlice/WalletSlice";
import { costFormat } from "components/costForamt";

// 검색 -> 탈퇴 회원관리 :: 회원 목록 낱개 클릭 (http://localhost:3000/super/payment/wallet)
const PaymentWalletClick = ({ data }) => {
  return (
    <ClickInfo.Data>
      {/* 가맹점명 */}
      <ClickInfo.Row $ratio={paymentWalletColumn.clickInfo[0].ratio}>
        <List.RowElement>
          <p>{data.ssCd === "7" && "ebitmall"}</p>
          <p>({data.ssCd})</p>
        </List.RowElement>
      </ClickInfo.Row>

      {/* 수수료(회사) */}
      <ClickInfo.Row $ratio={paymentWalletColumn.clickInfo[1].ratio}>
        {costFormat(data.companyFee)}
      </ClickInfo.Row>

      {/* 수수료(고객) */}
      <ClickInfo.Row $ratio={paymentWalletColumn.clickInfo[2].ratio}>
        {costFormat(data.personalFee)}
      </ClickInfo.Row>

      {/* 송금수수료 코인 타입 */}
      <ClickInfo.Row $ratio={paymentWalletColumn.clickInfo[3].ratio}>
        {data.transactionFeeType}
      </ClickInfo.Row>

      {/* 송금수수료 */}
      <ClickInfo.Row $ratio={paymentWalletColumn.clickInfo[4].ratio}>
        {costFormat(data.transactionFee)}
      </ClickInfo.Row>

      {/* 추천인명 */}
      <ClickInfo.Row $ratio={paymentWalletColumn.clickInfo[5].ratio}>
        <List.RowElement>
          <p>{data.recommenderName}</p>
          {data.recommenderName && <p>({data.recommenderCode})</p>}
        </List.RowElement>
      </ClickInfo.Row>

      {/* 보낸주소 */}
      <ClickInfo.Row $ratio={paymentWalletColumn.clickInfo[6].ratio}>
        {WalletSlice(data.toWalletAddress)}
      </ClickInfo.Row>

      {/* 받은주소 */}
      <ClickInfo.Row $ratio={paymentWalletColumn.clickInfo[7].ratio}>
        {WalletSlice(data.fromWalletAddress)}
      </ClickInfo.Row>

      {/* 상품 마진(원) */}
      <ClickInfo.Row $ratio={paymentWalletColumn.clickInfo[8].ratio}>
        {costFormat(data.ssLastPricePayAmountKrw)}
      </ClickInfo.Row>

      {/* 회사 리워드(SKETCH) */}
      <ClickInfo.Row $ratio={paymentWalletColumn.clickInfo[9].ratio}>
        {costFormat(data.estimatedCompanyBackAmount)}
      </ClickInfo.Row>

      {/* 회사 리워드(원) */}
      <ClickInfo.Row $ratio={paymentWalletColumn.clickInfo[10].ratio}>
        {costFormat(data.estimatedCompanyBackAmountKrw)}
      </ClickInfo.Row>

      {/* 사용한 멤버포인트(수량) */}
      <ClickInfo.Row $ratio={paymentWalletColumn.clickInfo[11].ratio}>
        {costFormat(data.memberPoint)}
      </ClickInfo.Row>

      {/* 사용한 팝콘포인트(수량) */}
      <ClickInfo.Row $ratio={paymentWalletColumn.clickInfo[12].ratio}>
        {costFormat(data.popcornPoint)}
      </ClickInfo.Row>

      {/* txId */}
      <ClickInfo.Row $ratio={paymentWalletColumn.clickInfo[13].ratio}>
        {WalletSlice(data.txId)}
      </ClickInfo.Row>
    </ClickInfo.Data>
  );
};

export default PaymentWalletClick;
