import { Info, List } from "template/ListTemplate.style";
import { ModalContaier, ModalWrapper } from "./style";
import { Search, Wrapper } from "template/SearchTemplate.style";
import { useState } from "react";
import { PLACEHOLDER } from "constants/baseStyle";
import { useMutationCustom } from "hooks/useMutationCustom";
import { HTTP_METHOD, POST_FIND_STORE_PAYMENT } from "constants/pathConstant";

const SearchStoreModal = ({ setIsOpen, setStoreData }) => {
  const [storeNm, setStoreNm] = useState("");

  const [userList, setUserList] = useState([]);

  const { mutate } = useMutationCustom(
    POST_FIND_STORE_PAYMENT,
    HTTP_METHOD.POST
  );

  const handleSearch = () => {
    if (!storeNm) return;

    mutate(
      { storeNm },
      {
        onSuccess: (data) => {
          setUserList(data);
        },
      }
    );
  };

  const selectUser = (v) => {
    setStoreData(v);
    setIsOpen(false);
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  return (
    <>
      <ModalWrapper
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      />
      <ModalContaier>
        {/* 조회 */}
        <Wrapper style={{ width: "100%" }}>
          <List.Text>가맹점 조회</List.Text>
          <Search.Container>
            <Search.Content>
              <Search.InputWrapper>
                <div>가맹점명</div>
                <Search.Input
                  placeholder={PLACEHOLDER.SOME}
                  onChange={(e) => setStoreNm(e.target.value)}
                  onKeyUp={(e) => handleEnter(e.key)}
                />
              </Search.InputWrapper>
            </Search.Content>
            <Search.Btn onClick={() => handleSearch()}>조회</Search.Btn>
            {/* <Search.Btn>조회</Search.Btn> */}
          </Search.Container>
        </Wrapper>

        {/* 리스트 */}
        <List.ModalScrollContainer>
          <List.Content>
            {/* 번호 */}
            <List.Row>번호</List.Row>
            {/* 가맹점명 */}
            <List.Row>가맹점명</List.Row>
            {/* 가맹점코드 */}
            <List.Row>가맹점코드</List.Row>
            {/* 점주명 */}
            <List.Row>점주명</List.Row>
            {/* 점주코드 */}
            <List.Row>점주코드</List.Row>
            {/* 주소 */}
            <List.Row $ratio={1.8}>주소</List.Row>
            {/* 상세주소 */}
            <List.Row $ratio={1.5}>상세주소</List.Row>
            {/* 가맹점 생성일 */}
            <List.Row>가맹점 생성일</List.Row>
          </List.Content>

          {/* 리스트 상세 */}
          <Info.Wrapper>
            {userList.map((v) => (
              <Info.Content
                key={v.id}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  selectUser(v);
                }}
              >
                {/* 번호 */}
                <List.Row>{v.id}</List.Row>
                {/* 가맹점명 */}
                <List.Row>{v.storeNm}</List.Row>
                {/* 가맹점코드 */}
                <List.Row>{v.storeCd}</List.Row>
                {/* 점주명 */}
                <List.Row>{v.ownerNm}</List.Row>
                {/* 점주코드 */}
                <List.Row>{v.ownerCd}</List.Row>
                {/* 주소 */}
                <List.Row $ratio={1.8}>{v.addr}</List.Row>
                {/* 상세주소 */}
                <List.Row $ratio={1.5}>{v.addr2}</List.Row>
                {/* 가맹점 생성일 */}
                <List.Row>{v.crtDt}</List.Row>
              </Info.Content>
            ))}
          </Info.Wrapper>
        </List.ModalScrollContainer>
      </ModalContaier>
    </>
  );
};

export default SearchStoreModal;
