import { Info, List } from "template/ListTemplate.style";
import paymentMonthlyColumn from "../paymentMonthlyColumn";
import { useMutationCustom } from "hooks/useMutationCustom";
import { POST_PAYMENT_MONTHLY_DETAIL } from "constants/pathConstant";
import { useEffect } from "react";
import { costFormat } from "components/costForamt";
import { getColor } from "../getColor";

// 월별 결제내역 -> 월별 리스트 -> 상세 :: (http://localhost:3000/super/payment/month)
const PaymentMonthlyMonthList = ({
  data,
  setPaymentMonthlyDetailRes,
  detailPage,
  searchReq,
  setSearchDetailReq,
  searchDetailReq,
  setDetailPage,
}) => {
  const { mutate } = useMutationCustom(POST_PAYMENT_MONTHLY_DETAIL);

  useEffect(() => {
    if (!Object.keys(searchDetailReq)[0]) return;

    const researchData = { ...searchDetailReq, currentPage: detailPage };
    setSearchDetailReq(researchData);

    mutate(researchData, {
      onSuccess: (data) => {
        setPaymentMonthlyDetailRes(data);
      },
    });
  }, [detailPage]);

  const detailHandler = () => {
    setDetailPage(1);

    const searchData = {
      ...searchReq,
      currentPage: detailPage,
      pageSize: 10,
      reqDate: data.reqDate,
    };

    setSearchDetailReq(searchData);

    mutate(searchData, {
      onSuccess: (res) => {
        setPaymentMonthlyDetailRes(res);
      },
    });
  };

  return (
    <Info.Content style={{ cursor: "pointer" }} onClick={detailHandler}>
      {/* 일자(연월) */}
      <List.Row $ratio={paymentMonthlyColumn.month[0].ratio}>
        {data.yearMonth}
      </List.Row>

      {/* 결제금액 */}
      <List.Row $ratio={paymentMonthlyColumn.month[1].ratio}>
        <List.Column>
          <p>
            <span>{costFormat(data.sketchOrderAmount)}</span>
            <span style={{ color: getColor("Sketch") }}>Sketch</span>
          </p>
          <p>
            <span>{costFormat(data.polOrderAmount)}</span>
            <span style={{ color: getColor("POL") }}>POL</span>
          </p>
          <p>
            <span>{costFormat(data.ethOrderAmount)}</span>
            <span>ETH</span>
          </p>
        </List.Column>
      </List.Row>

      {/* 결제취소금액 */}
      <List.Row $ratio={paymentMonthlyColumn.month[2].ratio}>
        <List.Column>
          <p>
            <span>{costFormat(data.sketchCancelAmount)}</span>
            <span style={{ color: getColor("Sketch") }}>Sketch</span>
          </p>
          <p>
            <span>{costFormat(data.polCancelAmount)}</span>
            <span style={{ color: getColor("POL") }}>POL</span>
          </p>
          <p>
            <span>{costFormat(data.ethCancelAmount)}</span>
            <span>ETH</span>
          </p>
        </List.Column>
      </List.Row>

      {/* 총금액 */}
      <List.Row $ratio={paymentMonthlyColumn.month[3].ratio}>
        <List.Column>
          <p>
            <span>{costFormat(data.sketchResultAmount)}</span>
            <span style={{ color: getColor("Sketch") }}>Sketch</span>
          </p>
          <p>
            <span>{costFormat(data.polResultAmount)}</span>
            <span style={{ color: getColor("POL") }}>POL</span>
          </p>
          <p>
            <span>{costFormat(data.ethResultAmount)}</span>
            <span>ETH</span>
          </p>
        </List.Column>
      </List.Row>
    </Info.Content>
  );
};

export default PaymentMonthlyMonthList;
