import { FONT_WEIGHT } from "constants/baseStyle";
import { styled } from "styled-components";

export const EmptyScreenStyle = {
  Wrapper: styled.div`
    width: 100%;
    height: ${(props) => props.height || "17rem"};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,

  Img: styled.img`
    width: ${(props) => props.width || "8.5rem"};
    height: ${(props) => props.height || "8.5rem"};
  `,

  Text: styled.div`
    margin-top: 2rem;
    font-weight: ${FONT_WEIGHT.MEDIUM};
    font-size: ${(props) => props.fontSize || "1.2rem"};
    color: #d6d6d6;
  `,
};
