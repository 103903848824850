import { useMemo } from "react";
import ReactModule from "./SmartEditorModules";
import {
  Rectangle,
  SmartEditorBox,
  Category,
  Wrapper,
  Title,
} from "./SmartEditor.style";
import "react-quill/dist/quill.snow.css";

/**
 *
 * @param {string} category
 * @param {string} setTitle
 * @param {string} titleValue
 * @param {string} setMessage
 * @param {string} messageValue
 * @param {string} toolbarId
 * @param {boolean} hasTitle
 * @returns
 */
const SmartEditor = ({
  category,
  setTitle,
  titleValue,
  setMessage,
  messageValue,
  toolbarId,
  hasTitle = false,
}) => {
  const formats = ["underline", "strike", "link", "color", "background"];

  const modules = useMemo(
    () => ({
      toolbar: {
        container: `#${toolbarId}`,
      },
    }),
    [toolbarId]
  );

  return (
    <Wrapper>
      <Category>
        <Rectangle />
        <p>{category}</p>
      </Category>
      {hasTitle && (
        <Title
          onChange={(e) => setTitle(e.target.value)}
          defaultValue={titleValue}
          placeholder="제목을 입력해주세요"
        />
      )}
      <div>
        <div id={toolbarId}>
          <ReactModule />
        </div>
        <SmartEditorBox
          modules={modules}
          formats={formats}
          onChange={setMessage}
          value={messageValue}
          placeholder="내용을 작성해주세요."
        ></SmartEditorBox>
      </div>
    </Wrapper>
  );
};

export default SmartEditor;
