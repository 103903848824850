import { FONT_WEIGHT } from "constants/baseStyle";
import { styled } from "styled-components";

export const ProcessModal = {
  Wrapper: styled.div`
    position: absolute;
    right: -4rem;
    z-index: 99;
    width: 7rem;
    height: 7.5rem;
    border-radius: 10px;
    border: 0.1rem solid #dedede;
    background: #fff;
    box-shadow: 0rem 0.4rem 0.4rem 0rem rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
  `,

  Content: styled.div`
    width: 6rem;
    height: 2rem;
    border-radius: 0.4rem;
    background-color: ${(props) => props.$bgColor || "white"};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    gap: 0.5rem;

    & > img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1rem;
      height: 1rem;
    }
  `,

  Btn: styled.div``,
};

export const ProcessHoldModal = {
  Wrapper: styled.div`
    width: 13.5rem;
    height: 16rem;
    position: absolute;
    background-color: white;
    top: 6rem;
    left: 0rem;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    padding: 1rem;
    border-radius: 1rem;
    border: 0.1rem solid #dedede;
    background: #fff;
    box-shadow: 0rem 0.4rem 0.4rem 0rem rgba(0, 0, 0, 0.25);
    z-index: 99;
  `,

  Title: styled.div`
    font-size: 1rem;
    color: black;
    text-align: left;
  `,

  TextArea: styled.textarea`
    font-size: 0.6rem;
    width: 12.5rem;
    height: 9.5rem;
    padding: 1rem;
    box-sizing: border-box;
    margin-left: -0.5rem;
    resize: none;
  `,

  BtnContainer: styled.div`
    display: flex;
    gap: 0.2rem;
    justify-content: right;
  `,

  Btn: styled.div`
    background-color: ${(props) => props.color || "white"};
    color: white;
    font-weight: ${FONT_WEIGHT.BOLD};
    padding: 0.2rem 0.5rem;
    border-radius: 0.4rem;
  `,
};

export const StateMemo = {
  Wrapper: styled.div`
    width: 15rem;
    min-height: 10rem;
    position: absolute;
    background-color: white;
    border-radius: 10px;
    border: 0.1rem solid #dedede;
    background: #fff;
    box-shadow: 0rem 0.4rem 0.4rem 0rem rgba(0, 0, 0, 0.25);
    z-index: 99;
    padding: 1rem 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    top: 1rem;
    left: 5rem;
  `,

  Title: styled.div`
    font-size: 1rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    color: black;
    width: 14.2rem;
    text-align: left;
  `,

  TextWrapper: styled.div`
    color: black;
    display: flex;
    flex-direction: column;
    border-radius: 0.6rem;
    border: 0.1rem solid #777;
    font-weight: ${FONT_WEIGHT.MEDIUM};
    gap: 1rem;
    text-align: left;
    padding: 1rem;
    min-height: 5.8rem;
    background: #f4f4f4;
  `,

  PrevMemo: styled.div`
    white-space: pre-line;
  `,

  CurMemo: styled.textarea`
    font-size: 1rem;
    min-height: 10rem;
    resize: none;
  `,

  BtnContainer: styled.div`
    display: flex;
    gap: 0.2rem;
    justify-content: right;
  `,

  Btn: styled.div`
    background-color: ${(props) => props.color || "white"};
    color: white;
    font-weight: ${FONT_WEIGHT.BOLD};
    padding: 0.2rem 0.5rem;
    border-radius: 0.4rem;
    cursor: pointer;
  `,
};

export const SortCount = {
  Select: styled.select`
    font-size: 1rem;
  `,

  Option: styled.option``,
};

export const ResendBtn = styled.div`
  background: #ddd;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  cursor: pointer;
`;

export const NavImgBox = styled.div`
  width: 1.2rem;
  height: 1.2rem;

  > img {
    width: 100%;
    height: 100%;
  }
`;

export const RetryBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  color: white;
  border-radius: 0.4rem;
  cursor: pointer;
  background: #bbb;
`;

export const CheckBox = styled.input`
  width: 1.5rem;
  height: 1.5rem;
`;
