import styled from "styled-components";

export const AssignContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > :nth-child(1) {
    display: flex;
    font-size: 1.6rem;
    gap: 0.5rem;

    & > div {
      display: flex;
      font-weight: 400;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 1.2rem;
  align-items: center;

  & > input {
    padding: 1rem;
    width: 10rem;
    border-radius: 1rem;
  }

  & > div {
    background: #2db9c2;
    color: #fff;
    padding: 1rem;
    border-radius: 1rem;
    cursor: pointer;
  }
`;

export const CheckBox = styled.input`
  width: 1.6rem;
  height: 1.6rem;
`;
