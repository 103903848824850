import { useState } from "react";
import { Wrapper } from "./style";
import { PaginationBox } from "components/Pagination/PaginationBox";
import FaqSearch from "./FaqSearch";
import FaqList from "./FaqList";

// 고객 지원 -> FAQ 관리 :: http://localhost:3000/super/create/faq
const Faq = () => {
  const [faqList, setFaqList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [checked, setChecked] = useState([]);
  const [searchData, setSearchData] = useState({});

  return (
    <Wrapper>
      <div>
        <FaqSearch
          setFaqList={setFaqList}
          setPage={setPage}
          currentPage={page}
          pageSize={size}
          setChecked={setChecked}
          setSearchData={setSearchData}
        />

        <FaqList
          faqList={faqList}
          setPage={setPage}
          setSize={setSize}
          checked={checked}
          setChecked={setChecked}
          searchData={searchData}
          setFaqList={setFaqList}
        />

        {faqList.length !== 0 && (
          <PaginationBox
            totalItemsCount={faqList[0]?.cnt}
            page={page}
            setPage={setPage}
            size={size}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default Faq;
