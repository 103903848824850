import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "api/api";

/**
 *
 * @param {String} queryKey
 * @param {String} uri
 */
export const useQueryCustom = (queryKey, uri) => {
  const queryResult = useQuery(queryKey, () => axiosInstance.get(uri));

  /**
   * @returns {UseQueryResult}
   */
  return () => {
    return queryResult;
  };
};
