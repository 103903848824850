import styled from "styled-components";

export const Wrapper = styled.div`
  background: #fff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 0.6rem;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.2);
`;
