import React, { useState } from "react";
import { Info, List } from "template/ListTemplate.style";
import userColumn from "./walletAddress.json";

// 회원검색 :: 회원 목록 리스트 낱개 (http://localhost:3000/manage/user/wallet)
const WalletAddressDetailList = ({ data }) => {
  return (
    <Info.Content>
      {/* 번호 */}
      <List.Row $ratio={userColumn.data[0].ratio}>{data?.memberName}</List.Row>

      {/* 가입일자 */}
      <List.Row $ratio={userColumn.data[1].ratio}>{data?.memberCode}</List.Row>

      {/* 회원명 */}
      <List.Row $ratio={userColumn.data[2].ratio}>
        {data?.walletAddress}
      </List.Row>

      {/* 회원코드 */}
      <List.Row
        style={{ flexDirection: "column", gap: "1rem" }}
        $ratio={userColumn.data[3].ratio}
      >
        <div>{data?.useYnPol === "Y" ? "생성" : "미생성"}</div>
        <div>{data?.useYnPol === "Y" ? data.polCreateDate : ""}</div>
      </List.Row>

      {/* 생년월일 */}
      <List.Row
        style={{ flexDirection: "column", gap: "1rem" }}
        $ratio={userColumn.data[4].ratio}
      >
        <div>{data?.useYnSketch === "Y" ? "생성" : "미생성"}</div>
        <div>{data?.useYnSketch === "Y" ? data.sketchCreateDate : ""}</div>
      </List.Row>

      {/* 활동상태 */}
      <List.Row
        style={{ flexDirection: "column", gap: "1rem" }}
        $ratio={userColumn.data[5].ratio}
      >
        <div>{data?.useYnEth === "Y" ? "생성" : "미생성"}</div>
        <div>{data?.useYnEth === "Y" ? data.ethCreateDate : ""}</div>
      </List.Row>
    </Info.Content>
  );
};

export default WalletAddressDetailList;
