import { useState } from "react";
import { Wrapper } from "./style";
import { PaginationBox } from "components/Pagination/PaginationBox";
import PopupSearch from "./PopupSearch";
import PopupList from "./PopupList";

// 고객 지원 -> 팝업 관리 :: http://localhost:3000/super/create/popup
const Popup = () => {
  const [popupList, setPopupList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [checked, setChecked] = useState([]);
  const [searchData, setSearchData] = useState({});

  return (
    <Wrapper>
      <div>
        <PopupSearch
          setPopupList={setPopupList}
          setPage={setPage}
          currentPage={page}
          pageSize={size}
          setChecked={setChecked}
          setSearchData={setSearchData}
        />

        <PopupList
          popupList={popupList}
          setPage={setPage}
          setSize={setSize}
          checked={checked}
          setChecked={setChecked}
          searchData={searchData}
          setPopupList={setPopupList}
        />

        {popupList.length !== 0 && (
          <PaginationBox
            totalItemsCount={popupList[0]?.cnt}
            page={page}
            setPage={setPage}
            size={size}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default Popup;
