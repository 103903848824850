export const FONT_WEIGHT = {
  LIGHT: 300,
  MEDIUM: 400,
  BOLD: 700,
};

export const PLACEHOLDER = {
  ALL: "모두 입력",
  SOME: "부분 입력",
  AUTO: "자동 입력",
  CLICK: "눌러서 조회",
};
