import styled from "styled-components";

export const Wrapper = styled.div`
  width: 96%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-top: 6rem;
`;

export const ToggleContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

export const ToggleBackground = styled.div`
  width: 5rem;
  height: 2.6rem;
  border-radius: 30px;
  background: ${(props) => (props.isUse ? "#21f650" : "rgb(233, 233, 234)")};
  transition: 0.5s;
`;

export const ToggleCircle = styled.div`
  position: absolute;
  top: 0.1rem;
  left: ${(props) => (props.isUse ? "2.7rem" : "0.1rem")};
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  background: rgb(255, 254, 255);
  transition: 0.5s;
`;
