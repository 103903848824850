import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { HTTP_METHOD, POST_WITHDRAW_LIST } from "constants/pathConstant";
import { WITHDRAW_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import { useToastCustom } from "hooks/useToastCustom";
import { Info, List, Wrapper } from "template/ListTemplate.style";
import rewardSendColumn from "./rewardSendColumn.json";
import { useMutationCustom } from "hooks/useMutationCustom";
import PageHandler from "components/PageHandler/PageHandler";
import RewardSendInfoDetail from "./RewardSendInfoDetail";
import { xlsxDateFormat } from "components/xlsxDateFormat";

// 정산 -> 리워드 인출 :: 리워드 인출 목록 (http://localhost:3000/super/calculate/reward-send)
const RewardSendInfoList = ({
  setPage,
  setSize,
  rewardSendList,
  searchData,
  setRewardSendList,
}) => {
  const excelDownload = useExcelDownload();
  const toast = useToastCustom();

  const { mutate } = useMutationCustom(POST_WITHDRAW_LIST, HTTP_METHOD.POST);

  // Excel 다운로드
  const handleExcelDownload = () => {
    if (!rewardSendList[0]?.cnt) {
      toast("다운로드할 데이터가 없습니다", "error");
      return;
    }

    const researchCond = {
      ...searchData,
      pageSize: rewardSendList[0]?.cnt,
      currentPage: 1,
    };

    mutate(researchCond, {
      onSuccess: (response) => {
        const updatedResponse = xlsxDateFormat(response);

        const excelData = {
          data: updatedResponse,
          headers: WITHDRAW_XLSX_DOWNLOAD.header,
          fileName: WITHDRAW_XLSX_DOWNLOAD.filename,
        };

        excelDownload(excelData);
      },

      onError: () => {
        toast("데이터 전체 조회 실패", "error");
      },
    });
  };

  return (
    <Wrapper style={{ width: "120rem" }}>
      <List.TitleContainer>
        <List.TextContainer>
          <List.Text>리워드 인출 대상</List.Text>
        </List.TextContainer>

        <List.BtnContainer>
          {/* 페이지 핸들러 */}
          <PageHandler setPage={setPage} setPageSize={setSize} />
          {/* 엑셀 다운로드 버튼 */}
          <ExcelDownloadBtn onClick={handleExcelDownload} />
        </List.BtnContainer>
      </List.TitleContainer>
      <List.Container>
        {/* List Column */}
        <List.Content>
          {rewardSendColumn.data.map((v, idx) => (
            <List.Row $ratio={v.ratio} key={idx}>
              {v.title}
            </List.Row>
          ))}
        </List.Content>
        <Info.Wrapper>
          {rewardSendList?.map((v, idx) => (
            <RewardSendInfoDetail
              data={v}
              key={idx}
              setRewardSendList={setRewardSendList}
              searchData={searchData}
            />
          ))}
        </Info.Wrapper>
      </List.Container>
    </Wrapper>
  );
};

export default RewardSendInfoList;
