import { atom } from "recoil";

export const UserState = {
  userState: atom({
    key: "UserData",
    default: [],
  }),

  userPage: atom({
    key: "UserPage",
    default: 1,
  }),

  userDetail: atom({
    key: "UserDetail",
    default: {},
  }),

  // userPageSize: atom({
  //   key: "userPageSize",
  //   default: 10,
  // }),

  // searchCond: atom({
  //   key: "UserStateSearchCon",
  //   default: {},
  // }),
};

// export const SuperUserState = {
//   userState: atom({
//     key: "SuperUserData",
//     default: [],
//   }),

//   userPage: atom({
//     key: "SuperUserPage",
//     default: 1,
//   }),

//   userDetail: atom({
//     key: "SuperUserDetail",
//     default: {},
//   }),

//   userPageSize: atom({
//     key: "SuperuserPageSize",
//     default: 10,
//   }),

//   searchCond: atom({
//     key: "SuperUserStateSearchCon",
//     default: {},
//   }),
// };

// export const DropUserState = {
//   dropUserState: atom({
//     key: "DropUserData",
//     default: [],
//   }),

//   dropUserPage: atom({
//     key: "DropUserPage",
//     default: 1,
//   }),

//   dropUserDetail: atom({
//     key: "DropUserDetail",
//     default: {},
//   }),

//   dropUserPageSize: atom({
//     key: "DropUserPageSize",
//     default: 10,
//   }),

//   searchCond: atom({
//     key: "DropUserStateSearchCon",
//     default: {},
//   }),
// };
