import { atom, selector } from "recoil";

export const WalletDetailState = {
  ListIndex: atom({
    key: "WalletDetailStateListIndex",
    default: null,
  }),

  WalletListIndex: atom({
    key: "WalletListIndex",
    default: null,
  }),

  WalletList: atom({
    key: "WalletList",
    default: [],
  }),

  SelectedWallet: atom({
    key: "SelectedWallet",
    default: null,
  }),

  // StoreListIndex: atom({
  //   key: "StoreListIndex",
  //   default: null,
  // }),

  StoreList: atom({
    key: "StoreList",
    default: [],
  }),

  // StoreDetailIndex: atom({
  //   key: "StoreDetailIndex",
  //   default: null,
  // }),

  SelectedStore: atom({
    key: "SelectedStore",
    default: null,
  }),
};

// export const WalletSearchState = {
//   SendList: atom({
//     key: "WalletSearchStateSend",
//     default: null,
//   }),

//   GetList: atom({
//     key: "WalletSearchStateGet",
//     default: null,
//   }),
// };

export const UserLogsState = {
  UserLogs: atom({
    key: "UserLogs",
    default: null,
  }),

  UserCallLogs: atom({
    key: "UserCallLogs",
    default: null,
  }),

  YM: atom({
    key: "UserLogsStateYM",
    default: null,
  }),

  YMD: atom({
    key: "UserLogsStateYMD",
    default: null,
  }),

  // resetCategorySelector: selector({
  //   key: "resetCategorySelector",
  //   get: ({ get }) => {
  //     return;
  //   },
  //   set: ({ set }) => {
  //     set(UserLogsState.YM, null);
  //     set(UserLogsState.YMD, null);
  //   },
  // }),
};

export const StoreDetailState = {
  Address: atom({
    key: "StoreDetailStateAddress",
    default: null,
  }),

  ZipCode: atom({
    key: "StoreDetailStateZipCode",
    default: null,
  }),
};
