import { styled } from "styled-components";

export const UserModal = {
  Wrapper: styled.div`
    width: 40rem;
    height: 28.5rem;
    border-radius: 1rem;
    border: 0.1rem solid #f6f6f6;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,

  Title: styled.div`
    font-size: 2rem;
    display: flex;
    align-items: center;
    gap: 0.6rem;

    & > span:nth-child(2) {
      font-size: 1.2rem;
      border-radius: 1rem;
      background: #e0e3ff;
      width: 2.8rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #4253f2;
    }
  `,

  Container: styled.div`
    width: 34rem;
    height: 20rem;
    border-radius: 0.6rem;
    border: 0.1rem solid #ddd;
    background: #f4f4f4;
    overflow-y: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,

  Content: styled.div`
    font-size: 1.4rem;
    display: flex;
    gap: 1rem;

    /* & > span:nth-child(2n - 1) {
      color: #777;
    } */
  `,
};
