import { Info, List } from "template/ListTemplate.style";
import assetWalletColumn from "../assetWalletColumn.json";
import { costFormat } from "components/costForamt";
import WalletSlice from "components/WalletSlice/WalletSlice";
import { NavTxId } from "components/Navigation/NavTxId";

// 페이 자산내역 :: 페이 자산내역 리스트 낱개 (http://localhost:3000/super/asset/wallet)
const AssetWalletDetailList = ({ data }) => {
  const stateToString = (state) => {
    if (state === "실패") return "#fe1717";
    if (state === "성공") return "#21F650";
    if (state === "진행중") return "#555";
  };

  const navToLink = (coinId, txId) => {
    if (coinId === "ETH" || coinId === "SKET") return NavTxId(txId, "ETH");
    if (coinId === "BTC") return NavTxId(txId, "BTC");
    else return NavTxId(txId);
  };

  return (
    <Info.Content>
      {/* 번호 */}
      <List.Row $ratio={assetWalletColumn.data[0].ratio}>{data.id}</List.Row>

      {/* 회원명 */}
      <List.Row $ratio={assetWalletColumn.data[1].ratio}>
        {data.memberName && (
          <List.RowElement>
            <p>{data.memberName}</p>
            <p>({data.memberCode})</p>
          </List.RowElement>
        )}
      </List.Row>

      {/* 가맹점명 */}
      <List.Row $ratio={assetWalletColumn.data[2].ratio}>
        {data.ssCd && (
          <List.RowElement>
            <p>ebitmall</p>
            <p>({data.ssCd})</p>
          </List.RowElement>
        )}
      </List.Row>

      {/* 세부유형 */}
      <List.Row $ratio={assetWalletColumn.data[3].ratio}>
        <List.RowElement>
          <p>{data.typeId}</p>
          {data.cancelAll && <p>({data.cancelAll})</p>}
        </List.RowElement>
      </List.Row>

      {/* 결제 코인 타입 */}
      <List.Row $ratio={assetWalletColumn.data[4].ratio}>
        {data.coinId}
      </List.Row>

      {/* 수량 */}
      <List.Row $ratio={assetWalletColumn.data[5].ratio}>
        {costFormat(String(data.amount))}
      </List.Row>

      {/* 송금수수료 코인 타입 */}
      <List.Row $ratio={assetWalletColumn.data[6].ratio}>
        {data.transactionFeeType}
      </List.Row>

      {/* 송금수수료 */}
      <List.Row $ratio={assetWalletColumn.data[7].ratio}>
        {costFormat(String(data.transactionFee))}
      </List.Row>

      {/* 시세(원)*/}
      <List.Row $ratio={assetWalletColumn.data[8].ratio}>
        {costFormat(String(data.exchangeRateKrw))}
      </List.Row>

      {/* 거래일자 */}
      <List.Row $ratio={assetWalletColumn.data[9].ratio}>
        {data.createDate}
      </List.Row>

      {/* 상태 */}
      <List.Row
        $ratio={assetWalletColumn.data[10].ratio}
        color={stateToString(data.txTypeId)}
      >
        {data.txTypeId}
      </List.Row>

      {/* 보낸 지갑주소 */}
      <List.Row $ratio={assetWalletColumn.data[11].ratio}>
        {WalletSlice(data.fromWalletAddress)}
      </List.Row>

      {/* 받은 지갑주소 */}
      <List.Row $ratio={assetWalletColumn.data[12].ratio}>
        {WalletSlice(data.toWalletAddress)}
      </List.Row>

      {/* txId */}
      <List.Row $ratio={assetWalletColumn.data[13].ratio}>
        {navToLink(data.coinId, data.txId)}
      </List.Row>
    </Info.Content>
  );
};

export default AssetWalletDetailList;
