import { useLocation } from "react-router";
import NoticeUpload from "../NoticeUpload";

// 고객 지원 -> 공지 관리 :: 공지 수정 (http://localhost:3000/super/create/notice-modify)
const NoticeModify2 = () => {
  const location = useLocation();

  return <NoticeUpload modifyData={location.state.data} />;
};

export default NoticeModify2;
