import { FONT_WEIGHT } from "constants/baseStyle";
import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 1.4rem;
  font-weight: ${FONT_WEIGHT.BOLD};
`;

export const SaveBtn = styled.div`
  width: 3rem;
  height: 1.8rem;
  flex-shrink: 0;
  border-radius: 0.4rem;
  background: #4253f2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Container = styled.div`
  width: 35rem;
  height: 17rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #eee;
  overflow: hidden;
`;

export const MemoTop = styled.div`
  display: flex;
  height: 12rem;
  border-bottom: 1px solid #eee;
`;

export const YYMM = styled.div`
  flex: 5;
  height: 12rem;
  overflow: hidden auto;
  border-right: 1px solid #eee;
`;

export const YYMMrow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem 0.7rem;
  gap: 0.6rem;
  white-space: nowrap;
  font-size: 1.2rem;
  cursor: pointer;

  & > img {
    width: 1rem;
    height: 0.8rem;
  }
`;

export const YYMMDD = styled.div`
  flex: 3.5;
  height: 100%;
  overflow: hidden auto;
  border-right: 1px solid #eee;
`;

export const YYMMDDrow = styled.div`
  width: 100%;
  padding: 1rem 0.7rem;
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: ${FONT_WEIGHT.LIGHT};
  color: ${(props) => (props.selected ? "#4253F2" : "black")};
`;

export const Memo = {
  Wrapper: styled.div`
    background-color: #f8f8fa;
    flex: 10;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    overflow-y: auto;
    cursor: pointer;
  `,

  Row: styled.div`
    font-size: 1.2rem;
    font-weight: ${FONT_WEIGHT.LIGHT};
  `,
};

export const MemoWrite = styled.textarea`
  padding: 1rem;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  height: 5.5rem;
  font-size: 1.1rem;
  resize: none;
  border: none;
`;
