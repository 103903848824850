import { FONT_WEIGHT } from "constants/baseStyle";
import { styled } from "styled-components";

export const ExcelBtn = {
  Wrapper: styled.div`
    border-radius: 0.4rem;
    background: #2db9c2;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;
    padding: 0.5rem 1rem;
  `,

  Logo: styled.img`
    width: 1rem;
    height: 1rem;
  `,

  Text: styled.span`
    font-weight: ${FONT_WEIGHT.BOLD};
    color: white;
    font-size: 1.2rem;
  `,
};

export const MetamaskBtn = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    background: white;
    gap: 1rem;
    padding: 0 2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid #bbb;
  `,

  Logo: styled.img`
    width: 2rem;
    height: 2rem;
  `,

  Text: styled.div`
    font-weight: ${FONT_WEIGHT.BOLD};
    color: black;
    font-size: 1rem;
  `,

  Container: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 3rem;
  `,

  Wallet: styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
  `,

  Balance: styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
  `,

  Status: styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  `,
};
