import { HTTP_METHOD, POST_FAQ_FETCH } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useEffect, useState } from "react";
import { Search, Wrapper } from "template/SearchTemplate.style";
import domainColumn from "../faqDomainColumn.json";
import { PLACEHOLDER } from "constants/baseStyle";

// 고객 지원 -> FAQ 관리 :: FAQ 조회 (http://localhost:3000/super/create/faq)
const FaqSearch = ({
  setFaqList,
  setPage,
  currentPage,
  pageSize,
  setChecked,
  setSearchData,
}) => {
  const toast = useToastCustom();

  const [question_kr, setQuestion_kr] = useState("");
  const [crtId, setCrtId] = useState("");
  const [categoryCd, setCategoryCd] = useState("");
  const [reqType, setrReqType] = useState("");

  const { mutate } = useMutationCustom(POST_FAQ_FETCH, HTTP_METHOD.POST);

  const fetchData = () => {
    const data = {
      question_kr,
      crtId,
      currentPage,
      pageSize,
      categoryCd,
      reqType,
    };

    setSearchData(data);

    mutate(data, {
      onSuccess: (res) => {
        setFaqList(res);
        setChecked([]);
      },

      onError: () => {
        toast("데이터 조회 실패", "error");
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, [pageSize, currentPage]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  return (
    <Wrapper>
      <Search.Text>FAQ 조회</Search.Text>
      <Search.Container>
        <Search.Content>
          {/* 제목/설명 */}
          <Search.InputWrapper>
            <div>제목</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setQuestion_kr(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 작성자 */}
          <Search.InputWrapper>
            <div>작성자</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setCrtId(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 앱 구분 */}
          <Search.InputWrapper>
            <div>앱 구분</div>
            <Search.Select onChange={(e) => setrReqType(e.target.value)}>
              <Search.Option value="">전체</Search.Option>
              {Object.entries(domainColumn.reqType).map(([key, value]) => (
                <Search.Option key={key} value={key}>
                  {value}
                </Search.Option>
              ))}
            </Search.Select>
          </Search.InputWrapper>

          {/* 카테고리 */}
          <Search.InputWrapper>
            <div>카테고리</div>
            <Search.Select onChange={(e) => setCategoryCd(e.target.value)}>
              {reqType === "" ? (
                <Search.Option value="">구분 먼저 선택해주세요</Search.Option>
              ) : (
                <Search.Option value="">전체</Search.Option>
              )}
              {Object.entries(domainColumn.categoryCd[reqType]).map(
                ([key, value]) => (
                  <Search.Option key={key} value={key}>
                    {value}
                  </Search.Option>
                )
              )}
            </Search.Select>
          </Search.InputWrapper>
        </Search.Content>

        {/* 조회 버튼 */}
        <Search.Btn onClick={handleSearch}>조회</Search.Btn>
      </Search.Container>
    </Wrapper>
  );
};

export default FaqSearch;
