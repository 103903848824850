import { Info, List, Wrapper } from "template/ListTemplate.style";
import { AdminInfoListDetail } from "./AdminInfoListDetail";
import adminColumn from "../adminColumn.json";

// 시스템 관리 :: 관리자/권한 목록 (http://localhost:3000/super/system/admin)
const AdminInfoList = ({ adminList, setAdminList, searchData }) => {
  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>관리자/권한 목록</List.Text>
        <List.BtnContainer></List.BtnContainer>
      </List.TitleContainer>

      <List.Container>
        <List.Content>
          {adminColumn.data.map((v, idx) => (
            <List.Row $ratio={v.ratio} key={idx}>
              {v.title}
            </List.Row>
          ))}
        </List.Content>
        <Info.Wrapper>
          {adminList.map((v, idx) => (
            <AdminInfoListDetail
              key={idx}
              data={v}
              idx={idx}
              setAdminList={setAdminList}
              searchData={searchData}
            />
          ))}
        </Info.Wrapper>
      </List.Container>
    </Wrapper>
  );
};

export default AdminInfoList;
