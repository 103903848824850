import React, { useState } from "react";
import { ModifyMemo, Wrapper } from "./UserModifyByMemo.style";
import categoryJson from "../userModifyData.json";
import { useToastCustom } from "hooks/useToastCustom";
import { getUserParamsFromUri } from "utils/crypto";
import { useMutationCustom } from "hooks/useMutationCustom";
import { HTTP_METHOD, POST_INSERT_USER_LOG } from "constants/pathConstant";

// 회원검색 (검증 후 회원 정보 바로가기) :: 회원/가맹점 정보 수정 -> 수정 내용 직접 작성 (http://localhost:3000/manage/user/:code)
export const UserModifyByMemo = ({ setLogsAll }) => {
  const toast = useToastCustom();

  const [largeCd, setLargeCd] = useState(categoryJson.largeCode.user);
  const [actionNotes, setActionNotes] = useState("");
  const [actionCd, setActionCd] = useState("A0001");

  const { mutate } = useMutationCustom(POST_INSERT_USER_LOG, HTTP_METHOD.POST);

  const handleSaveMemo = () => {
    if (!actionNotes || !largeCd) return toast("내용이 입력되지 않았습니다.", "error");

    if (!actionCd) return toast("카테고리를 선택하세요.", "error");

    if (!window.confirm("메모를 등록하시겠습니까?")) return;

    const { acctCd } = getUserParamsFromUri();

    const data = { largeCd, actionNotes, acctCd, actionCd };

    mutate(data, {
      onSuccess: (response) => {
        setActionNotes("");
        setLogsAll(response);
        toast("메모 등록 성공", "success");
      },

      onError: () => {
        toast("메모 등록 실패", "error");
      },
    });
  };

  return (
    <Wrapper>
      <ModifyMemo.Top>
        <ModifyMemo.Title>수정 내용 직접 작성</ModifyMemo.Title>
        <ModifyMemo.SaveBtn onClick={handleSaveMemo}>저장</ModifyMemo.SaveBtn>
      </ModifyMemo.Top>
      <ModifyMemo.Container>
        <ModifyMemo.Category>
          <span>대분류 - (회원/가맹주)</span>
          <ModifyMemo.Select onChange={(e) => setLargeCd(e.target.value)}>
            <option value={categoryJson.largeCode.user}>회원</option>
            <option value={categoryJson.largeCode.store}>가맹점</option>
          </ModifyMemo.Select>
        </ModifyMemo.Category>
        <ModifyMemo.Category>
          <span>소분류 - (카테고리)</span>
          <ModifyMemo.Select onChange={(e) => setActionCd(e.target.value)}>
            {categoryJson.column[largeCd].slice(1).map((v, idx) => (
              <option key={idx} value={v.code}>
                {v.title}
              </option>
            ))}
          </ModifyMemo.Select>
        </ModifyMemo.Category>
        <ModifyMemo.Memo
          placeholder="수정 내용을 입력해 주세요"
          onChange={(e) => setActionNotes(e.target.value)}
          value={actionNotes}
        ></ModifyMemo.Memo>
      </ModifyMemo.Container>
    </Wrapper>
  );
};
