import { useEffect, useState } from "react";
import {
  BottomBtn,
  BottomWrapper,
  Name,
  Rectangle,
  SelectWrapper,
  TableWrapper,
  Wrapper,
  SelectBoxWrapper,
  ContentBox,
  CheckboxWrapper,
  SelectTopWrapper,
  CheckboxDiv,
  CheckboxTitle,
} from "./style";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import {
  POST_FAQ2_CREATE,
  POST_FAQ2_FETCH_CATEGORY,
  POST_FAQ2_MODIFY,
} from "constants/pathConstant";
import { axiosInstance } from "api/api";
import { useMutation } from "@tanstack/react-query";
import { useToastCustom } from "hooks/useToastCustom";
import domainColumn from "../faqDomainColumn.json";
import Faq2Modal from "components/Modal/Faq2Modal";
import { useMutationCustom } from "hooks/useMutationCustom";
import SmartEditor from "components/SmartEditor/SmartEditor";

// 고객 지원 -> FAQ 관리 :: FAQ 등록 (http://localhost:3000/super/create/faq-upload)
const FaqUpload2 = ({ modifyData }) => {
  const nav = useNavigationCustom();
  const toast = useToastCustom();

  // 기본 upload state
  const [showYn, setShowYn] = useState("Y");
  const [questionKr, setQuestionKr] = useState("");
  const [questionEng, setQuestionEng] = useState("");
  const [answerKr, setAnswerKr] = useState("");
  const [answerEng, setAnswerEng] = useState("");
  const [reqType, setReqType] = useState("00");
  const [categoryCd, setCategoryCd] = useState("W001");
  const [code, setCode] = useState([]);
  const [checked, setChecked] = useState([]);

  const [isInitialized, setIsInitialized] = useState(false);

  // 수정시 추가 state
  const [id, setId] = useState("");

  // 모달 state
  const [isOpen, setIsOpen] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const previewHandler = () => {
    setIsOpen((prev) => !prev);
    setPreviewData({ questionKr, questionEng, answerKr, answerEng });
  };

  const { mutate: mutateFetchCategory } = useMutationCustom(
    POST_FAQ2_FETCH_CATEGORY
  );

  useEffect(() => {
    mutateFetchCategory(
      { domain: "" },
      {
        onSuccess: (res) => {
          setCode(res.list);
        },
      }
    );
  }, []);

  useEffect(() => {
    if (!modifyData) return;

    mutateFetchCategory(
      { domain: reqType },
      {
        onSuccess: (res) => {
          setCode(res.list);
        },
      }
    );
  }, [reqType]);

  useEffect(() => {
    setIsInitialized(true);
    if (!modifyData) return;

    setId(modifyData.id);
    setQuestionKr(modifyData.questionKr);
    setQuestionEng(modifyData.questionEng);
    setAnswerKr(modifyData.answerKr);
    setAnswerEng(modifyData.answerEng);
    setReqType(modifyData.reqType);
    setCategoryCd(modifyData.categoryCd);
  }, []);

  const { mutate } = useMutation(
    (data) => axiosInstance.post(POST_FAQ2_CREATE, data),
    {
      onSuccess: () => {
        toast("정상적으로 등록되었습니다.", "success");
        nav("/super/support/faq");
      },
    }
  );

  const { mutate: updateMutate } = useMutation(
    (data) => axiosInstance.post(POST_FAQ2_MODIFY, data),
    {
      onSuccess: () => {
        toast("정상적으로 수정되었습니다.", "success");
        nav("/super/support/faq");
      },
    }
  );

  const uploadHandler = async () => {
    if (!questionKr || !questionEng || !answerKr || !answerEng) {
      return toast("빠진 내용 없이 다 채워주세요", "error");
    }

    const data = {
      categoryCd: modifyData ? categoryCd : checked,
      questionKr,
      questionEng,
      answerKr,
      answerEng,
      showYn: modifyData && showYn,
      id: modifyData && id,
    };

    setPreviewData(data);

    if (modifyData) {
      updateMutate(data);
    } else {
      mutate(data);
    }
  };

  if (!isInitialized) return null;

  const checkboxHandler = (e, id) => {
    if (e.target.checked) {
      setChecked((prev) => [...prev, id]);
    } else {
      setChecked((prev) => prev.filter((el) => el !== id));
    }
  };

  return (
    <Wrapper>
      <Name>FAQ</Name>
      <TableWrapper>
        <span>
          <Rectangle />
          {modifyData ? "작성하기" : "앱 구분"}
        </span>
        <div>
          {modifyData ? (
            <SelectTopWrapper>
              <SelectBoxWrapper>
                <div>앱 구분</div>
                <SelectWrapper>
                  <select
                    onChange={(e) => setReqType(e.target.value)}
                    defaultValue={modifyData?.reqType || reqType}
                    key={modifyData?.reqType || reqType}
                  >
                    {Object.entries(domainColumn.reqType).map(
                      ([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      )
                    )}
                  </select>
                </SelectWrapper>
              </SelectBoxWrapper>
              <SelectBoxWrapper>
                <div>카테고리</div>
                <SelectWrapper>
                  <select
                    onChange={(e) => setCategoryCd(e.target.value)}
                    value={categoryCd}
                    key={modifyData?.categoryCd || categoryCd}
                  >
                    {code.map((v) => (
                      <option key={v.code} value={v.code}>
                        {v.nameKr}
                      </option>
                    ))}
                  </select>
                </SelectWrapper>
              </SelectBoxWrapper>
            </SelectTopWrapper>
          ) : (
            <CheckboxWrapper>
              {/* 구분 */}
              {Object.entries(domainColumn.reqType).map(([key, value]) => (
                <div key={key} value={key}>
                  <CheckboxTitle>{value}</CheckboxTitle>{" "}
                  {code
                    .filter((item) => item.reqType === key)
                    .map((filteredItem) => (
                      <CheckboxDiv
                        key={filteredItem.code}
                        value={filteredItem.code}
                      >
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            checkboxHandler(e, filteredItem.code)
                          }
                        />
                        <p>{filteredItem.nameKr}</p>
                      </CheckboxDiv>
                    ))}
                </div>
              ))}
            </CheckboxWrapper>
          )}

          {modifyData && (
            <SelectBoxWrapper>
              <div>공개 여부</div>
              <SelectWrapper>
                <select
                  onChange={(e) => setShowYn(e.target.value)}
                  defaultValue={modifyData?.showYn || showYn}
                  key={modifyData?.showYn || showYn}
                >
                  <option value={"Y"}>공개</option>
                  <option value={"N"}>비공개</option>
                </select>
              </SelectWrapper>
            </SelectBoxWrapper>
          )}
        </div>
      </TableWrapper>

      <ContentBox>
        <SmartEditor
          category={"내용(한국어)"}
          setTitle={setQuestionKr}
          titleValue={questionKr}
          setMessage={setAnswerKr}
          messageValue={answerKr}
          toolbarId={"contentsKr"}
          hasTitle={true}
        />

        <SmartEditor
          category={"내용(영어)"}
          setTitle={setQuestionEng}
          titleValue={questionEng}
          setMessage={setAnswerEng}
          messageValue={answerEng}
          toolbarId={"contentsEng"}
          hasTitle={true}
        />
      </ContentBox>

      <BottomWrapper>
        <div>
          <BottomBtn onClick={() => nav("/super/support/faq")}>목록</BottomBtn>
        </div>
        <div>
          <BottomBtn
            onClick={previewHandler}
            bgColor={"#2db9c2"}
            fontColor={"white"}
          >
            미리보기
          </BottomBtn>
          <BottomBtn
            onClick={uploadHandler}
            bgColor={"#4253f2"}
            fontColor={"white"}
          >
            저장
          </BottomBtn>
        </div>
      </BottomWrapper>

      {isOpen && <Faq2Modal data={previewData} setIsOpen={setIsOpen} />}
    </Wrapper>
  );
};

export default FaqUpload2;
