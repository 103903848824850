import React from "react";
import { Wrapper } from "./Home.style";
import CalendarGraph from "./calendarGraph/CalendarGraph";
import TodoPropertyRegistration from "./todoPropertyRegistration/TodoPropertyRegistration";

// 메인 :: (http://localhost:3000/main)
export const Home = () => {
  return (
    <Wrapper>
      <TodoPropertyRegistration />
      <CalendarGraph />
    </Wrapper>
  );
};
