import React from "react";
import { Middle } from "./Header.style";

export const HeaderMenu = React.memo(
  ({ menu, hoverMenu, setHoverMenu, selectedMenu, moveHandler }) => {
    return (
      menu &&
      menu.map(
        (v, idx) =>
          v.isActive === "1" && (
            <Middle.Wrapper key={idx}>
              <Middle.Menu
                onClick={(e) => {
                  if (e.target !== e.currentTarget) return;
                  v.sub[0] ? moveHandler(v.sub[0].url) : moveHandler(v.url);
                }}
                onMouseEnter={() => setHoverMenu(v.url)}
                selected={selectedMenu.includes(v.url)}
              >
                {v.title}

                {v.sub[0] && hoverMenu.includes(v.url) && (
                  <Middle.SubMenuWrapper>
                    {v.sub.map(
                      (sub, subIdx) =>
                        sub.isActive === "1" && (
                          <Middle.SubMenu
                            key={subIdx}
                            selected={selectedMenu.includes(sub.url)}
                            onClick={(e) => {
                              e.stopPropagation();
                              moveHandler(sub.url);
                            }}
                          >
                            {sub.title}
                          </Middle.SubMenu>
                        )
                    )}
                  </Middle.SubMenuWrapper>
                )}
              </Middle.Menu>
            </Middle.Wrapper>
          )
      )
    );
  }
);
