import { UserLogsModal } from "components/Modal/UserDetailModal/logs/UserLogsModal";
import { useOpenCustomModal } from "hooks/modal/useOpenCustomModal";
import { useRecoilState, useRecoilValue } from "recoil";
import { UserLogsState } from "recoil/atom/UserDetailPageState";
import { Memo, MemoWrapper, YYMM, YYMMDD, YYMMDDrow, YYMMrow } from "./UserModifyInfo.style";

export const CategoryList = ({ selected, setLogsAll, largeCd }) => {
  const openLogsModal = useOpenCustomModal(UserLogsModal);

  const [YM, setYM] = useRecoilState(UserLogsState.YM);
  const [YMD, setYMD] = useRecoilState(UserLogsState.YMD);
  const logs = useRecoilValue(UserLogsState.UserLogs);

  const selectedYMLogs = logs && YM && logs[YM];
  const selectedYMDLogs = logs && YM && logs[YM][YMD];

  const handleOpenModal = () => {
    if (!YM || !YMD || !selected) return;
    openLogsModal({ selected, setLogsAll, largeCd });
  };

  return (
    <MemoWrapper>
      <YYMM>
        {logs &&
          Object.keys(logs).map((v, idx) => (
            <YYMMrow key={idx} onClick={() => setYM(v)}>
              {v === YM ? (
                <img src="/assets/imgs/userModify_checked_btn.svg" alt="" />
              ) : (
                <img src="/assets/imgs/userModify_unChecked_btn.svg" alt="" />
              )}
              <div>{v}</div>
            </YYMMrow>
          ))}
      </YYMM>
      <YYMMDD>
        {selectedYMLogs &&
          Object.keys(logs[YM]).map((v, idx) => (
            <YYMMDDrow key={idx} onClick={() => setYMD(v)} selected={v === YMD}>
              {v}
            </YYMMDDrow>
          ))}
      </YYMMDD>
      <Memo.Wrapper onClick={handleOpenModal}>
        {selectedYMDLogs && selectedYMDLogs.map((log, idx) => <Memo.Row key={idx}>{log.actionNotes}</Memo.Row>)}
      </Memo.Wrapper>
    </MemoWrapper>
  );
};
