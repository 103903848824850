import { Info, List } from "template/ListTemplate.style";
import {
  Wrapper,
  Container,
  Row,
  ImgInput,
  ImgLabel,
  ImgDiv,
  Btn,
} from "./ManageUiUpload.style";
import manageUiColumn from "../manageUiColumn.json";
import { useRef, useState } from "react";
import { useToastCustom } from "hooks/useToastCustom";
import { POST_MANAGE_UI_CREATE } from "constants/pathConstant";
import { useMutation } from "@tanstack/react-query";
import { axiosInstance } from "api/api";

const ManageUiUpload = ({ fetchData }) => {
  const toast = useToastCustom();

  const imgRef = useRef();

  const [type, setType] = useState("10");
  const [typeDetail, setTypeDetail] = useState("");
  const [description, setDescription] = useState("");
  const [useYn, setUseYn] = useState("Y");
  const [imgPreview, setImgPreview] = useState("");

  // 이미지 업로드
  const imgUploadHandler = (e) => {
    const uploadImg = e.target.files[0];

    if (!uploadImg) return;
    if (uploadImg.size / 1000 / 1000 > 5)
      return toast("파일 크기는 최대 5MB입니다.", "error");

    const fileReader = new FileReader();
    fileReader.readAsDataURL(uploadImg);
    fileReader.onloadend = () => {
      setImgPreview(fileReader.result);
    };
  };

  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const { mutate: mutateCreateUi } = useMutation(
    (data) => axiosInstance.post(POST_MANAGE_UI_CREATE, data, config),
    {
      onSuccess: () => {
        toast("정상적으로 등록되었습니다.", "success");
        setType("00");
        setTypeDetail("");
        setDescription("");
        setUseYn("Y");
        setImgPreview("");
        fetchData();
      },

      onError: (error) => {
        if (error.response.data.message) {
          toast(`${error.response.data.message}`, "error");
        } else {
          toast("필수 값을 모두 입력해주세요.", "error");
        }
      },
    }
  );

  // 등록
  const createHandler = () => {
    if (!type || !typeDetail || !useYn)
      return toast("필수 값을 모두 입력해주세요.", "error");

    const data = {
      uiType: `${type}-${typeDetail}`,
      description,
      useYn,
      value: "",
    };

    const formData = new FormData();

    for (const key in data) {
      formData.append(
        key,
        typeof data[key] === "object" ? JSON.stringify(data[key]) : data[key]
      );
    }

    formData.append("sampleImgUrl", imgRef.current.files[0]);

    mutateCreateUi(formData);
  };

  return (
    <Wrapper>
      <List.Text>신규 UI 등록</List.Text>

      <Container>
        <List.Content>
          {manageUiColumn.data.map((v, idx) => (
            <Row $ratio={v.ratio} key={idx}>
              {v.title}
            </Row>
          ))}
        </List.Content>

        <Info.Wrapper>
          <Info.Content>
            {/* 이미지 */}
            <Row $ratio={manageUiColumn.data[0].ratio}>
              <ImgDiv>
                <img src={imgPreview} alt="ui_img_preview" />
              </ImgDiv>
              <ImgInput
                id="ui_img"
                type="file"
                onChange={(e) => imgUploadHandler(e)}
                accept="image/*"
                ref={imgRef}
              />
              <ImgLabel htmlFor="ui_img">파일선택</ImgLabel>
            </Row>

            {/* 타입 */}
            <Row $ratio={manageUiColumn.data[1].ratio}>
              <select value={type} onChange={(e) => setType(e.target.value)}>
                {Object.entries(manageUiColumn.uiType).map(([key, value]) => (
                  <option value={key} key={key}>
                    {value}
                  </option>
                ))}
              </select>
            </Row>

            {/* 타입상세 */}
            <Row $ratio={manageUiColumn.data[2].ratio}>
              <input
                value={typeDetail}
                onChange={(e) => setTypeDetail(e.target.value)}
                placeholder="필수"
              />
            </Row>

            {/* 메모 */}
            <Row $ratio={manageUiColumn.data[3].ratio}>
              <input
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="선택"
              />
            </Row>

            {/* 사용유무 */}
            <Row $ratio={manageUiColumn.data[4].ratio}>
              <select value={useYn} onChange={(e) => setUseYn(e.target.value)}>
                {Object.entries(manageUiColumn.useYn).map(([key, value]) => (
                  <option value={key} key={key}>
                    {value}
                  </option>
                ))}
              </select>
            </Row>

            {/* 등록 버튼 */}
            <Row $ratio={manageUiColumn.data[4].ratio}>
              <Btn
                fontSize="1.5rem"
                background="#4253f2"
                onClick={createHandler}
              >
                등록
              </Btn>
            </Row>
          </Info.Content>
        </Info.Wrapper>
      </Container>
    </Wrapper>
  );
};

export default ManageUiUpload;
