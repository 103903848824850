import { FONT_WEIGHT } from "constants/baseStyle";
import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const Container = {
  Top: styled.div`
    display: flex;
    gap: 0.4rem;
    align-items: center;
  `,

  Text: styled.div`
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 2.2rem;
  `,

  Content: styled.div`
    width: 90rem;
    /* min-height: 42rem; */
    border-radius: 1rem;
    background: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,
};

export const Header = {
  Wrapper: styled.div`
    width: 100%;
    height: 4.5rem;
    padding: 0.5rem;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    align-items: center;
  `,

  BigCategory: styled.div`
    /* width: 22.2rem; */
    height: 3.4rem;
    display: flex;
    padding: 0.3rem;
    border-radius: 0.7rem;
    border: 0.2rem solid #fff;
    background: #f3f3f3;
  `,

  CategoryDiv: styled.div`
    width: 10.8rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 0.4rem;
    font-size: 1.2rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    color: ${(props) => (props.selected ? "white" : "black")};
    background-color: ${(props) => (props.selected ? "#4253F2" : "")};
  `,

  SmallCategory: styled.div`
    display: flex;
    margin-left: 3rem;
    gap: 1.5rem;
    width: 48rem;
  `,

  SmallCategoryDiv: styled.div`
    font-size: 1.2rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    color: ${(props) => (props.selected ? "black" : "#aaa")};
    border-bottom: ${(props) => (props.selected ? "0.2rem solid blue" : "")};
  `,
};

export const List = {
  Wrapper: styled.div`
    display: flex;
  `,
};
