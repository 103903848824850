import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const ListContaier = styled.section`
  display: flex;
  margin: 1rem;
  border-radius: 0.4rem;
  overflow: hidden;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  font-size: 1.1rem;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  background-color: #f8f8fa;
  color: #888;
`;

export const ListColumn = styled.div`
  text-align: center;
  position: relative;
  display: flex;
  word-break: break-all;
  align-items: center;
  padding: 1rem;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 0.1rem solid #e7e7e7;
  }
`;

export const InfoWrapper = styled.div`
  flex: 3.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 1.1rem;

  & > p {
    width: 100%;
    padding: 1rem;
    display: flex;
    gap: 0.5rem;

    & > span:last-child {
      font-weight: 800;
    }

    &:not(:last-child) {
      border-bottom: 0.1rem solid #e7e7e7;
    }
  }
`;
