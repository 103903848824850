import styled from "styled-components";

export const Wrapper = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
  gap: 3rem;
`;
