import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 1rem;
  margin: 0 auto;

  & > strong {
    font-size: 2.2rem;
    font-weight: 700;
  }
`;

export const Container = styled.div`
  display: flex;
  background: #fff;
  border-radius: 1rem;
  padding: 2rem;
  justify-content: space-between;
  align-items: center;
  font-size: 1.6rem;

  & > section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    & > div {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
    }
  }
`;

export const Button = styled.div`
  padding: 1.3rem;
  background: #4253f2;
  color: #fff;
  border-radius: 1rem;
  cursor: pointer;
`;

export const Copy = styled.p`
  cursor: pointer;
`;
