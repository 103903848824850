import { useRecoilValue, useSetRecoilState } from "recoil";
import { loginRole, loginState } from "recoil/atom/LoginState";
import roleJson from "constants/authRoles.json";
import { useLocation } from "react-router";
import { useNavigationCustom } from "./useNavigationCustom";
import { useToastCustom } from "./useToastCustom";
import { getUserFromLocalStorage } from "utils/localStorage";

export const useRouteRoleCheck = () => {
  const userRole = useRecoilValue(loginRole);
  const setLogin = useSetRecoilState(loginState);
  const location = useLocation();
  const nav = useNavigationCustom();
  const toast = useToastCustom();

  // TODO : 리팩터링 - 가독성 매우 떨어짐
  return () => {
    if (!getUserFromLocalStorage()) {
      nav("/login");
      setLogin(false);
      return;
    }

    if (!userRole[0]) return;
    if (location.pathname === "/" || location.pathname === "/login") return;

    console.log("role check");

    const data = Object.values(roleJson.menu)
      .filter((menu) => location.pathname.includes(menu.url))
      .map((v) => v.role);

    let flag = false;

    // 현재페이지가 user detail 페이지인 경우
    if (location.pathname.includes("/manage/user/")) {
      data[0].push("MANAGER_USER"); // 회원 검색
      data[0].push("MANAGER_STORE"); // 가맹점 검색
      data[0].push("SUPER_USER"); // [관] 회원검색
      data[0].push("SUPER_STORE"); // [관] 가맹점검색
      data[0].push("ACCEPT_STORE"); // 가맹점승인
    }

    if (userRole.includes("DEV") || userRole.includes("SUPER")) {
      flag = true;
    } else {
      data.forEach((roles) =>
        roles.forEach((role) => {
          userRole.forEach((user) => {
            if (user === role) flag = true;
          });
        })
      );
    }

    if (!flag) {
      if (location.pathname === "/main") {
        nav("/");
      } else {
        nav("/");
        toast("잘못된 접근입니다", "error");
      }
    }
  };
};
