import {
  POST_MIGRATION_CONFIRM_CHECK,
  POST_MIGRATION_FETCH,
  POST_MIGRATION_STATE_MODIFY,
} from "constants/pathConstant";
import { Info, List, MouseHoverEffect } from "template/ListTemplate.style";
import { CheckBox, RetryBtn } from "./MigrationDetail.style";
import migrationColumn from "../migrationColumn.json";
import { costFormat } from "components/costForamt";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useState } from "react";
import { NavTxId } from "components/Navigation/NavTxId";

// 브릿지 -> 스왑 :: 스왑 신청/처리 목록 낱개 (http://localhost:3000/super/bridge/migration)
const MigrationDetailList = ({ data, setMigrationList, searchData }) => {
  const toast = useToastCustom();

  const [isChecked, setIsChecked] = useState(false);

  const { mutate: updateMutate } = useMutationCustom(POST_MIGRATION_FETCH);

  const { mutate: updateStateMutate } = useMutationCustom(
    POST_MIGRATION_STATE_MODIFY
  );

  const handleRetry = (e) => {
    e.stopPropagation();
    if (data.state !== "35" && data.state !== "30") return;

    if (window.confirm("상태를 변경하시겠습니까?")) {
      updateStateMutate(
        { id: data.id },
        {
          onSuccess: () => {
            updateMutate(searchData, {
              onSuccess: (res) => {
                setMigrationList(res);
                toast("상태 변경에 성공하였습니다.", "success");
              },
            });
          },

          onError: () => {
            toast("상태 변경에 실패하였습니다.", "error");
          },
        }
      );
    } else {
      alert("상태 변경을 취소하였습니다.");
    }
  };

  const { mutate: confirmMutate } = useMutationCustom(
    POST_MIGRATION_CONFIRM_CHECK
  );

  // 에러 확인 체크박스
  const handleErrorCheck = (e) => {
    e.stopPropagation();

    if (
      window.confirm(
        "해당 내용을 확인 처리하시는 경우, \n메인페이지 TODO 목록에서 사라집니다. \n진행하시겠습니까?"
      )
    ) {
      confirmMutate(
        {
          id: data.id,
        },
        {
          onSuccess: () => {
            updateMutate(searchData, {
              onSuccess: (res) => {
                setMigrationList(res);
                toast("처리에 성공하였습니다.", "success");
              },
            });
          },

          onError: () => {
            toast("처리에 실패하였습니다.", "error");
          },
        }
      );
    } else {
      alert("처리를 취소하였습니다.");
      setIsChecked(false);
    }
  };

  return (
    <>
      <Info.Content>
        {/* 번호 */}
        <List.Row $ratio={migrationColumn?.data[0]?.ratio}>{data.id}</List.Row>

        {/* 회원명 */}
        <List.Row $ratio={migrationColumn?.data[1]?.ratio}>
          {data.acctUserNm}
        </List.Row>

        {/* 회원코드 */}
        <List.Row $ratio={migrationColumn?.data[2]?.ratio}>
          {data.acctCd}
        </List.Row>

        {/* 생년월일 */}
        <List.Row $ratio={migrationColumn?.data[3]?.ratio}>
          {data.birthDay}
        </List.Row>

        {/* 휴대폰 번호 */}
        <List.Row $ratio={migrationColumn?.data[4]?.ratio}>
          {data.cellNumber}
        </List.Row>

        {/* 수량 */}
        <List.Row $ratio={migrationColumn?.data[5]?.ratio}>
          {costFormat(data.amount)}
        </List.Row>

        {/* 수수료 */}
        <List.Row $ratio={migrationColumn?.data[6]?.ratio}>
          {costFormat(data.sketFee)}
        </List.Row>

        {/* 실제 전송 수량 */}
        <List.Row $ratio={migrationColumn?.data[7]?.ratio}>
          {costFormat(data.totalAmount)}
        </List.Row>

        {/* 신청 시간 */}
        <List.Row
          $ratio={migrationColumn?.data[8]?.ratio}
          style={{ fontSize: "1rem" }}
        >
          {data.crtDt.slice(0, 16)}
        </List.Row>

        {/* 최종 반영 시간 */}
        <List.Row
          $ratio={migrationColumn?.data[9]?.ratio}
          style={{ fontSize: "1rem" }}
        >
          {data.updDt.slice(0, 16)}
        </List.Row>

        {/* 상태 */}
        <List.Row
          $ratio={migrationColumn?.data[10]?.ratio}
          color={migrationColumn.stateColor[data.state]}
        >
          {migrationColumn.state[data.state]}
          {(data.state === "30" || data.state === "40") && (
            <MouseHoverEffect style={{ left: "7.5rem" }}>
              {data.stateMemo}
            </MouseHoverEffect>
          )}
        </List.Row>

        {/* 체크 박스 */}
        <List.Row $ratio={migrationColumn?.data[11]?.ratio}>
          {(data.state === "30" ||
            data.state === "35" ||
            data.state === "40") && (
            <CheckBox
              type="checkbox"
              checked={isChecked}
              disabled={data.confirmYn === "Y"}
              onClick={(e) => {
                handleErrorCheck(e);
              }}
            />
          )}
        </List.Row>

        {/* 재시도 */}
        <List.Row
          $ratio={migrationColumn?.data[12]?.ratio}
          onClick={(e) => handleRetry(e, data.state)}
        >
          {(data.state === "30" || data.state === "35") && (
            <RetryBtn>상태 변경</RetryBtn>
          )}
        </List.Row>

        {/* 바로가기 */}
        <List.Row $ratio={migrationColumn?.data[13].ratio}>
          {NavTxId(data.userToComTxid, "ETH")}
        </List.Row>

        {/* 바로가기 */}
        <List.Row $ratio={migrationColumn?.data[14].ratio}>
          {NavTxId(data.comToUserTxid)}
        </List.Row>
      </Info.Content>
    </>
  );
};

export default MigrationDetailList;
