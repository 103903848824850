import { Info, List } from "template/ListTemplate.style";
import { ModalContaier, ModalWrapper } from "./style";
import { Search, Wrapper } from "template/SearchTemplate.style";
import { useState } from "react";
import { PLACEHOLDER } from "constants/baseStyle";
import { POST_WALLET_USER_NAME_FETCH } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";

const SearchWalletUserModal = ({ setIsOpen, setUserData }) => {
  const [memberName, setMemberName] = useState("");

  const [userList, setUserList] = useState([]);

  const { mutate } = useMutationCustom(POST_WALLET_USER_NAME_FETCH);

  const handleSearch = () => {
    if (!memberName) return;

    mutate(
      { memberName },
      {
        onSuccess: (res) => {
          setUserList(res.list);
        },
      }
    );
  };

  const selectUser = (v) => {
    setUserData({ ...v, useTypeList: userList.useTypeList });
    setIsOpen(false);
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  return (
    <>
      <ModalWrapper
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      />
      <ModalContaier>
        {/* 조회 */}
        <Wrapper style={{ width: "100%" }}>
          <List.Text>회원 조회</List.Text>
          <Search.Container>
            <Search.Content>
              <Search.InputWrapper>
                <div>회원명</div>
                <Search.Input
                  placeholder={PLACEHOLDER.ALL}
                  onChange={(e) => setMemberName(e.target.value)}
                  onKeyUp={(e) => handleEnter(e.key)}
                />
              </Search.InputWrapper>
            </Search.Content>
            <Search.Btn onClick={() => handleSearch()}>조회</Search.Btn>
          </Search.Container>
        </Wrapper>

        {/* 리스트 */}
        <List.ModalScrollContainer>
          <List.Content>
            {/* 번호 */}
            <List.Row>번호</List.Row>
            {/* 회원명 */}
            <List.Row>회원명</List.Row>
            {/* 회원코드 */}
            <List.Row>회원코드</List.Row>
            {/* 생년월일 */}
            <List.Row>생년월일</List.Row>
            {/* 이메일 */}
            <List.Row $ratio={1.8}>이메일</List.Row>
            {/* 전화번호 */}
            <List.Row $ratio={1.5}>전화번호</List.Row>
            {/* 상태 */}
            <List.Row $ratio={1}>상태</List.Row>
          </List.Content>

          {/* 리스트 상세 */}
          <Info.Wrapper>
            {userList?.map((v) => (
              <Info.Content
                key={v.id}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  selectUser(v);
                }}
              >
                {/* 번호 */}
                <List.Row>{v.id}</List.Row>
                {/* 회원명 */}
                <List.Row>{v.memberName}</List.Row>
                {/* 회원코드 */}
                <List.Row>{v.memberCode}</List.Row>
                {/* 생년월일 */}
                <List.Row>{v.birthDay}</List.Row>
                {/* 이메일 */}
                <List.Row $ratio={1.8}>{v.email}</List.Row>
                {/* 전화번호 */}
                <List.Row $ratio={1.5}>{v.mobile}</List.Row>
                {/* 상태 */}
                <List.Row $ratio={1}>{v.state}</List.Row>
              </Info.Content>
            ))}
          </Info.Wrapper>
        </List.ModalScrollContainer>
      </ModalContaier>
    </>
  );
};

export default SearchWalletUserModal;
