import { useState } from "react";
import { Wrapper } from "./PaymentMonthly.style";
import PaymentMonthlySearch from "./PaymentMonthlySearch/PaymentMonthlySearch";
import PaymentMonthlySummary from "./PaymentMonthlySummary/PaymentMonthlySummary";
import PaymentMonthlyMonth from "./PaymentMonthlyMonth/PaymentMonthlyMonth";
import PaymentMonthlyDetail from "./PaymentMonthlyDetail/PaymentMonthlyDetail";

const PaymentMonthly = () => {
  // 연월별 조회 state
  const [paymentMonthlyRes, setPaymentMonthlyRes] = useState({
    list: [],
    summary: {},
    listCount: 0,
  });
  const [searchReq, setSearchReq] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isSearch, setIsSearch] = useState(false);

  // 상세 조회 state
  const [paymentMonthlyDetailRes, setPaymentMonthlyDetailRes] = useState({
    list: [],
    listCount: 0,
  });
  const [detailPage, setDetailPage] = useState(1);
  const [searchDetailReq, setSearchDetailReq] = useState({});

  return (
    <Wrapper>
      <PaymentMonthlySearch
        setPaymentMonthlyRes={setPaymentMonthlyRes}
        setSearchReq={setSearchReq}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setIsSearch={setIsSearch}
        searchReq={searchReq}
        setPaymentMonthlyDetailRes={setPaymentMonthlyDetailRes}
        setSearchDetailReq={setSearchDetailReq}
      />

      <PaymentMonthlySummary data={paymentMonthlyRes.summary} />
      <PaymentMonthlyMonth
        data={paymentMonthlyRes}
        isSearch={isSearch}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setPaymentMonthlyDetailRes={setPaymentMonthlyDetailRes}
        detailPage={detailPage}
        setDetailPage={setDetailPage}
        searchReq={searchReq}
        setSearchDetailReq={setSearchDetailReq}
        searchDetailReq={searchDetailReq}
      />

      <PaymentMonthlyDetail
        data={paymentMonthlyDetailRes}
        detailPage={detailPage}
        setDetailPage={setDetailPage}
        searchDetailReq={searchDetailReq}
      />
    </Wrapper>
  );
};

export default PaymentMonthly;
