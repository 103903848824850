import { Info, List, Wrapper } from "template/ListTemplate.style";
import WithdrawSummaryDetail from "./WithdrawSummaryDetail/WithdrawSummaryDetail";
import withdrawSummaryColumn from "./withdrawSummaryColumn.json";

// 브릿지 -> 출금 :: 요약 (http://localhost:3000/super/bridge/withdraw)
const WithdrawSummary = ({ withdrawList }) => {
  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>
          인출 내역 요약
          {/* {` (${withdrawList.storeInfo.storeNm ? withdrawList.storeInfo.storeNm : "전체"})`} */}
        </List.Text>
        <List.BtnContainer></List.BtnContainer>
      </List.TitleContainer>

      <List.Container style={{ minHeight: "0" }}>
        <List.Content>
          {withdrawSummaryColumn?.summary.map((v, idx) => (
            <List.Row $ratio={v.ratio} key={idx}>
              {v.title}
            </List.Row>
          ))}
        </List.Content>

        <Info.Wrapper>
          <WithdrawSummaryDetail data={withdrawList.summary} />
        </Info.Wrapper>
      </List.Container>
    </Wrapper>
  );
};

export default WithdrawSummary;
