import { useEffect, useState } from "react";
import {
  BottomBtn,
  BottomWrapper,
  Name,
  Rectangle,
  SelectWrapper,
  TableWrapper,
  Wrapper,
  SelectBoxWrapper,
  CheckboxWrapper,
} from "./style";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import {
  POST_NOTICE2_CREATE,
  POST_NOTICE2_MODIFY,
} from "constants/pathConstant";
import { axiosInstance } from "api/api";
import { useMutation } from "@tanstack/react-query";
import { useToastCustom } from "hooks/useToastCustom";
import domainColumn from "../noticeDomainColumn.json";
import { ContentBox } from "pages/Support/Faq/FaqUpload/style";
import { NoticeModal } from "components/Modal/NoticeModal";
import SmartEditor from "components/SmartEditor/SmartEditor";

// 고객 지원 -> 공지 관리 :: 공지 등록 (http://localhost:3000/super/create/notice)
const NoticeUpload2 = ({ modifyData }) => {
  const nav = useNavigationCustom();
  const toast = useToastCustom();

  const [alertYn, setAlertYn] = useState("Y");
  const [titleKr, setTitleKr] = useState("");
  const [titleEng, setTitleEng] = useState("");
  const [contentsKr, setContentsKr] = useState("");
  const [contentsEng, setContentsEng] = useState("");
  const [showYn, setShowYn] = useState("Y");
  const [reqType, setReqType] = useState("00");
  const [checked, setChecked] = useState([]);

  // 수정시 추가 state
  const [id, setId] = useState("");

  // 모달 state
  const [isOpen, setIsOpen] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const previewHandler = () => {
    setIsOpen((prev) => !prev);
    setPreviewData({
      titleKr,
      titleEng,
      contentsKr,
      contentsEng,
    });
  };

  useEffect(() => {
    if (!modifyData) return;

    setId(modifyData.id);
    setTitleKr(modifyData.titleKr);
    setTitleEng(modifyData.titleEng);
    setContentsKr(modifyData.contentsKr);
    setContentsEng(modifyData.contentsEng);
  }, []);

  const { mutate } = useMutation(
    (data) => axiosInstance.post(POST_NOTICE2_CREATE, data),
    {
      onSuccess: () => {
        toast("정상적으로 등록되었습니다.", "success");
        nav("/super/support/notice");
      },
      onError: () => {
        toast("등록에 실패하였습니다", "error");
      },
    }
  );

  const { mutate: updateMutate } = useMutation(
    (data) => axiosInstance.post(POST_NOTICE2_MODIFY, data),
    {
      onSuccess: () => {
        toast("정상적으로 수정되었습니다.", "success");
        nav("/super/support/notice");
      },
      onError: () => {
        toast("수정에 실패하였습니다", "error");
      },
    }
  );

  const uploadHandler = async () => {
    if (!titleKr || !titleEng || !contentsKr || !contentsEng)
      return toast("빠짐없이 입력해주세요.", "error");

    const data = {
      titleKr,
      titleEng,
      contentsKr,
      contentsEng,
      alertYn,
      showYn: modifyData && showYn,
      id: modifyData && id,
      reqType: modifyData ? reqType : checked,
    };

    setPreviewData(data);

    if (modifyData) {
      updateMutate(data);
    } else {
      mutate(data);
    }
  };

  const checkHandler = (e, id) => {
    if (e.target.checked) {
      setChecked((prev) => [...prev, id]);
    } else {
      setChecked(checked.filter((el) => el !== id));
    }
  };

  return (
    <Wrapper>
      <Name>Notice</Name>
      <TableWrapper>
        <span>
          <Rectangle />
          작성하기
        </span>
        <div>
          <div>
            <SelectBoxWrapper>
              <div>앱 구분</div>
              {modifyData ? (
                <SelectWrapper>
                  <select
                    onChange={(e) => setReqType(e.target.value)}
                    defaultValue={modifyData?.reqType || reqType}
                    key={modifyData?.reqType || reqType}
                  >
                    {Object.entries(domainColumn.reqType).map(
                      ([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      )
                    )}
                  </select>
                </SelectWrapper>
              ) : (
                <CheckboxWrapper>
                  {Object.entries(domainColumn.reqType).map(([key, value]) => (
                    <div key={key}>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          checkHandler(e, key);
                        }}
                      />
                      <p>{value}</p>
                    </div>
                  ))}
                </CheckboxWrapper>
              )}
            </SelectBoxWrapper>

            <SelectBoxWrapper>
              <div>알림 여부</div>
              <SelectWrapper>
                <select
                  onChange={(e) => setAlertYn(e.target.value)}
                  defaultValue={modifyData?.alertYn || alertYn}
                  key={modifyData?.alertYn || alertYn}
                >
                  <option value={"Y"}>알림</option>
                  <option value={"N"}>미알림</option>
                </select>
              </SelectWrapper>
            </SelectBoxWrapper>
          </div>

          {modifyData && (
            <div>
              <SelectBoxWrapper>
                <div>공개 여부</div>
                <SelectWrapper>
                  <select
                    onChange={(e) => setShowYn(e.target.value)}
                    defaultValue={modifyData?.showYn || showYn}
                    key={modifyData?.showYn || showYn}
                  >
                    <option value={"Y"}>공개</option>
                    <option value={"N"}>비공개</option>
                  </select>
                </SelectWrapper>
              </SelectBoxWrapper>
            </div>
          )}
        </div>
      </TableWrapper>

      <ContentBox>
        <SmartEditor
          category={"내용(한국어)"}
          setTitle={setTitleKr}
          titleValue={titleKr}
          setMessage={setContentsKr}
          messageValue={contentsKr}
          toolbarId={"contentsKr"}
          hasTitle={true}
        />

        <SmartEditor
          category={"내용(영어)"}
          setTitle={setTitleEng}
          titleValue={titleEng}
          setMessage={setContentsEng}
          messageValue={contentsEng}
          toolbarId={"contentsEng"}
          hasTitle={true}
        />
      </ContentBox>

      <BottomWrapper>
        <div>
          <BottomBtn onClick={() => nav("/super/support/notice")}>
            목록
          </BottomBtn>
        </div>
        <div>
          <BottomBtn
            onClick={previewHandler}
            bgColor={"#2db9c2"}
            fontColor={"white"}
          >
            미리보기
          </BottomBtn>
          <BottomBtn
            onClick={uploadHandler}
            bgColor={"#4253f2"}
            fontColor={"white"}
          >
            저장
          </BottomBtn>
        </div>
      </BottomWrapper>

      {isOpen && <NoticeModal data={previewData} setIsOpen={setIsOpen} />}
    </Wrapper>
  );
};

export default NoticeUpload2;
