const ReactModule = () => {
  return (
    <>
      <div className="ql-formats">
        <button className="ql-underline" />
        <button className="ql-strike" />
      </div>

      <div className="ql-formats">
        <select className="ql-color" />
        <select className="ql-background" />
      </div>

      <div className="ql-formats">
        <button className="ql-link" />
      </div>
    </>
  );
};

export default ReactModule;
