import Graph from "./Graph/Graph";
import Calendar from "./Calendar/Calendar";
import { Wrapper } from "./CalendarGraph.style";

const CalendarGraph = () => {
  return (
    <Wrapper>
      <Calendar />
      {/* <Graph /> */}
    </Wrapper>
  );
};

export default CalendarGraph;
