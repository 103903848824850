import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 100rem;
  max-height: 70rem;
  border: 2px solid black;
  border-radius: 1.5rem;
  overflow: hidden;
  & > img {
    width: 100%;
    height: 100%;
  }
`;
