import {
  HTTP_METHOD,
  POST_FIND_DROP_USER,
  POST_HOLD_WHITDRAW_REWARD,
  POST_RESTORE_USER_ACCT,
  POST_WITHDRAW_LIST,
} from "constants/pathConstant";
import { useCloseRootModal } from "hooks/modal/useCloseRootModal";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { Btn, BtnWrapper, Sub, TextAreaBox, Title, Wrapper } from "./RestoreUserModal.style";
import { useState } from "react";

export const RestoreUserModal = ({ acctUserNm, acctCd, setDropUserList, searchData, id, setWithdrawList }) => {
  const closeRootModal = useCloseRootModal();
  const toast = useToastCustom();

  const [acctQuitMemo, setaAcctQuitMemo] = useState("");

  // 회원 탈퇴 반려
  const { mutate: restoreAcct } = useMutationCustom(POST_RESTORE_USER_ACCT, HTTP_METHOD.POST);

  // 회원 탈퇴 반려 업데이트
  const { mutate: updateDropUserList } = useMutationCustom(POST_FIND_DROP_USER, HTTP_METHOD.POST);

  // 인출 반려
  const { mutate: mutateHoldWithdraw } = useMutationCustom(POST_HOLD_WHITDRAW_REWARD, HTTP_METHOD.POST);

  // 인출 반려 업데이트
  const { mutate: updateWithdrawList } = useMutationCustom(POST_WITHDRAW_LIST, HTTP_METHOD.POST);

  const handleRestore = () => {
    if (id) {
      // 인출 반려
      mutateHoldWithdraw(
        {
          id,
          memo: acctQuitMemo,
          state: "09",
        },
        {
          onSuccess: () => {
            updateWithdrawList(searchData, {
              onSuccess: (res) => {
                setWithdrawList(res);
                toast("반려 처리되었습니다.", "success");
                closeRootModal();
              },
            });
          },
          onError: () => {
            toast("반려 처리에 실패하였습니다.", "error");
            closeRootModal();
          },
        }
      );
    } else {
      // 회원 탈퇴 반려
      restoreAcct(
        { acctCd, acctQuitMemo },
        {
          onSuccess: () => {
            updateDropUserList(searchData, {
              onSuccess: (res) => {
                setDropUserList(res);
                toast("반려 처리되었습니다.", "success");
                closeRootModal();
              },
            });
          },
          onError: () => {
            toast("반려 처리에 실패하였습니다.", "error");
            closeRootModal();
          },
        }
      );
    }
  };

  return (
    <Wrapper>
      <Title>
        {id ? (
          <p>해당 건을 보류 하시겠습니까?</p>
        ) : (
          <p>
            <span>{acctUserNm}</span>님을 반려처리 하시겠습니까?
          </p>
        )}
      </Title>

      <TextAreaBox>
        <Sub>사유</Sub>
        <textarea onChange={(e) => setaAcctQuitMemo(e.target.value)} />
      </TextAreaBox>

      <BtnWrapper>
        <Btn $bg={"#ECECEC"} color={"black"} onClick={closeRootModal}>
          취소
        </Btn>
        <Btn $bg={"#4253F2"} color={"white"} onClick={handleRestore}>
          반려
        </Btn>
      </BtnWrapper>
    </Wrapper>
  );
};
