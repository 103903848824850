import { Info, List, Wrapper } from "template/ListTemplate.style";
import paymentMonthlyColumn from "../paymentMonthlyColumn.json";
import { costFormat } from "components/costForamt";
import { getColor } from "../getColor";

const PaymentMonthlySummary = ({ data }) => {
  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>월별 결제내역 요약</List.Text>
      </List.TitleContainer>

      <List.Container style={{ minHeight: 0 }}>
        <List.Content>
          {paymentMonthlyColumn?.summary.map((v, idx) => (
            <List.Row $ratio={v.ratio} key={idx}>
              {v.title}
            </List.Row>
          ))}
        </List.Content>

        <Info.Wrapper>
          <Info.Content>
            <List.Row $ratio={paymentMonthlyColumn.summary[0].ratio}>
              <List.Column>
                <p>
                  <span>{costFormat(data.sketchOrderAmount)}</span>
                  <span style={{ color: getColor("Sketch") }}>Sketch</span>
                </p>
                <p>
                  <span>{costFormat(data.polOrderAmount)}</span>
                  <span style={{ color: getColor("POL") }}>POL</span>
                </p>
                <p>
                  <span>{costFormat(data.ethOrderAmount)}</span>
                  <span>ETH</span>
                </p>
              </List.Column>
            </List.Row>

            <List.Row $ratio={paymentMonthlyColumn.summary[1].ratio}>
              <List.Column>
                <p>
                  <span>{costFormat(data.sketchCancelAmount)}</span>
                  <span style={{ color: getColor("Sketch") }}>Sketch</span>
                </p>
                <p>
                  <span>{costFormat(data.polCancelAmount)}</span>
                  <span style={{ color: getColor("POL") }}>POL</span>
                </p>
                <p>
                  <span>{costFormat(data.ethCancelAmount)}</span>
                  <span>ETH</span>
                </p>
              </List.Column>
            </List.Row>

            <List.Row $ratio={paymentMonthlyColumn.summary[2].ratio}>
              <List.Column>
                <p>
                  <span>{costFormat(data.sketchResultAmount)}</span>
                  <span style={{ color: getColor("Sketch") }}>Sketch</span>
                </p>
                <p>
                  <span>{costFormat(data.polResultAmount)}</span>
                  <span style={{ color: getColor("POL") }}>POL</span>
                </p>
                <p>
                  <span>{costFormat(data.ethResultAmount)}</span>
                  <span>ETH</span>
                </p>
              </List.Column>
            </List.Row>
          </Info.Content>
        </Info.Wrapper>
      </List.Container>
    </Wrapper>
  );
};

export default PaymentMonthlySummary;
