import styled from "styled-components";

export const SfuelContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  gap: 5rem;

  & > input {
    padding: 1rem;
    font-size: 1.4rem;
    width: 28rem;
  }

  & > div {
    display: flex;
    justify-content: center;
  }

  & > :nth-child(1) {
    flex: 3;
    font-size: 1.6rem;
  }

  & > :nth-child(2) {
    flex: 1;
    > button {
      font-size: 1.6rem;
    }
  }

  & > :nth-child(3) {
    flex: 1;
  }
`;

export const OpenedUrl = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: ${(props) => (props.clicked ? "green" : "tomato")};
`;
