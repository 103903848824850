import { useState } from "react";
import { Wrapper } from "./ChargeList.style";
import ChargeSearch from "./ChargeSearch/ChargeSearch";
import ChargeInfoList from "./ChargeInfoList/ChargeInfoList";
import { PaginationBox } from "components/Pagination/PaginationBox";
import ChargeSummary from "./ChargeSummary/ChargeSummary";

// 브릿지 -> 충전 :: (http://localhost:3000/super/bridge/charge)
const ChargeList = () => {
  const [chargeList, setChargeList] = useState({ list: [], summary: {} });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});
  const [isSearch, setIsSearch] = useState(false);

  return (
    <Wrapper>
      <div>
        <ChargeSearch
          setChargeList={setChargeList}
          setPage={setPage}
          currentPage={page}
          pageSize={size}
          setSearchData={setSearchData}
          searchData={searchData}
          setIsSearch={setIsSearch}
        />

        <ChargeSummary chargeList={chargeList} />

        <ChargeInfoList
          chargeList={chargeList}
          setPage={setPage}
          setSize={setSize}
          searchData={searchData}
          isSearch={isSearch}
        />

        {isSearch && chargeList.list && (
          <PaginationBox
            totalItemsCount={chargeList.list[0]?.cnt}
            page={page}
            setPage={setPage}
            size={size}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default ChargeList;
