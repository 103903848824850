import { Info, List, Wrapper } from "template/ListTemplate.style";
import sfuleManageColumn from "./sfuelManageColumn.json";
import { ListDefault } from "components/ListDefault/ListDefault";
import SfuelProcessDetail from "./SfuelProcessDetail";
import { AssignContainer, CheckBox, InputContainer } from "./style";
import { useEffect, useState } from "react";
import { useMutationCustom } from "hooks/useMutationCustom";
import {
  GET_SFUEL_ASSIGNABLE,
  GET_SFUEL_WORKERS,
  HTTP_METHOD,
  POST_SFUEL_WALLET_ENROLL,
  POST_SFUEL_WORKERS_ASSIGN,
} from "constants/pathConstant";
import { useToastCustom } from "hooks/useToastCustom";
import { useQueryCustom } from "hooks/useQueryCustom";
import { useQueryClient } from "@tanstack/react-query";

const SfuelProcess = () => {
  const toast = useToastCustom();
  const queryClient = useQueryClient();

  const [checked, setChecked] = useState([]);
  const [walletSize, setWalletSize] = useState();
  const [worker, setWorker] = useState([]);
  const [assignSize, setAssignSize] = useState();

  const { data } = useQueryCustom(["sfuelWorker"], GET_SFUEL_WORKERS)();

  const { data: assignableCount } = useQueryCustom(
    ["assignableCount"],
    GET_SFUEL_ASSIGNABLE
  )();

  useEffect(() => {
    if (!data) return;
    setWorker(data);
  }, [data, worker]);

  // 전체 선택
  const allCheckHandler = (e) => {
    if (e.target.checked) {
      const tempArr = [];
      worker.data.forEach((el) => {
        tempArr.push(el.admin.id);
      });
      setChecked(tempArr);
    } else {
      setChecked([]);
    }
  };

  // 단일 선택
  const checkHandler = (e, id) => {
    if (e.target.checked) {
      setChecked((prev) => [...prev, id]);
    } else {
      setChecked(checked.filter((el) => el !== id));
    }
  };

  const onChangeCreateWallet = (e) => {
    if (e.target.value > 1000) return (e.target.value = 1000);
    setWalletSize(e.target.value);
  };

  const { mutate } = useMutationCustom(
    POST_SFUEL_WALLET_ENROLL,
    HTTP_METHOD.POST
  );

  const handleCreateWallet = () => {
    if (!walletSize) return toast("값을 입력해주세요.", "error");

    mutate(
      { walletSize },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["assignableCount"]);
          toast(`${walletSize}개의 지갑 생성이 완료되었습니다.`, "success");
          setWalletSize("");
        },
      }
    );
  };

  const { mutate: assignMutate } = useMutationCustom(
    POST_SFUEL_WORKERS_ASSIGN,
    HTTP_METHOD.POST
  );

  const handleAssignSize = () => {
    if (!assignableCount?.assignableCount)
      return toast("할당 가능한 지갑이 없습니다!", "error");
    const tmp = [...checked].map((v) => {
      return { adminId: v, assignSize };
    });

    assignMutate(
      { data: tmp },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["sfuelWorker"]);
          queryClient.invalidateQueries(["assignableCount"]);
          toast(
            `${checked.length}명의 작업자에게 ${assignSize}개씩 할당이 완료되었습니다.`,
            "success"
          );
          setAssignSize("");
          setChecked([]);
        },
      }
    );
  };

  return (
    <Wrapper style={{ width: "70%" }}>
      <List.TitleContainer>
        <List.Text>진행상황</List.Text>
        <InputContainer>
          <input
            type="number"
            placeholder="최대 1000개"
            onChange={onChangeCreateWallet}
            value={walletSize}
          />
          <div onClick={handleCreateWallet}>지갑 생성하기(개수 입력)</div>
        </InputContainer>
      </List.TitleContainer>

      <List.Container>
        {worker.length === 0 ? (
          <ListDefault text={"진행상황을"} />
        ) : (
          <>
            <List.Content>
              <List.Row>
                <CheckBox
                  type="checkbox"
                  $ratio={1}
                  onChange={(e) => allCheckHandler(e)}
                  checked={checked.length === worker.data.length ? true : false}
                />
              </List.Row>
              {sfuleManageColumn.column.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>

            <Info.Wrapper>
              {worker.data?.map((v, idx) => (
                <SfuelProcessDetail
                  key={idx}
                  data={v}
                  checkHandler={checkHandler}
                  checked={checked}
                  idx={idx}
                />
              ))}
            </Info.Wrapper>
          </>
        )}
      </List.Container>

      <AssignContainer>
        <div>
          <p>할당 가능 수:</p>
          <div>
            <p>{assignableCount?.assignableCount}</p>
            <p>개</p>
          </div>
        </div>
        <InputContainer>
          <input
            type="number"
            placeholder="인당 n개씩"
            onChange={(e) => setAssignSize(e.target.value)}
            value={assignSize}
          />
          <div onClick={handleAssignSize}>할당하기(개수 입력)</div>
        </InputContainer>
      </AssignContainer>
    </Wrapper>
  );
};

export default SfuelProcess;
