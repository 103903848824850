import React, { useEffect, useRef, useState } from "react";
import {
  CalendarTag,
  ColorPicker,
  Container,
  ContentWrapper,
  CreateCalendarBtn,
  ScheduleInput,
  TagAmin,
  Title,
  Wrapper,
} from "./ScheduleModal.style";
import { useToastCustom } from "hooks/useToastCustom";
import { useMutationCustom } from "hooks/useMutationCustom";
import {
  HTTP_METHOD,
  IMG_CDN_URL,
  POST_MAIN_SCHEDULE_CREATE,
  POST_MAIN_SCHEDULE_MODIFY,
  POST_MAIN_SCHEDULE_SEARCH_ADMIN,
} from "constants/pathConstant";
import scheduleJson from "pages/Home/calendarGraph/Calendar/ScheduleList/ScheduleJson.json";
import { dateFormat } from "components/dateFormat";
import { debounce } from "utils/debounce";

export const ScheduleModal = ({
  setOpenModal,
  date,
  setScheduleList,
  modifyMode = false,
  data = {},
  setCalendarCountObj,
}) => {
  const [scheduleLevel, setScheduleLevel] = useState(data.scheduleLevel || "0");
  const [legacyScheduleLevel, setLegacyScheduleLevel] = useState("0");
  const [scheduleTitle, setScheduleTitle] = useState(data.scheduleTitle || "");
  const [scheduleContent, setScheduleContent] = useState(
    data.scheduleContent || ""
  );
  const [alarmYn, setAlarmYn] = useState(data.alarmYn || "N");
  const [shareYn, setShareYn] = useState(data.shareYn || "N");
  const [letterCount, setLetterCount] = useState(
    data.scheduleTitle?.length || 0
  );
  const [searchAdmin, setSearchAdmin] = useState("");
  const [searchAdminResult, setSearchAdminResult] = useState([]);
  const [tagAdminList, setTagAdminList] = useState(
    data?.mentionedAdmins?.split(",") || []
  );

  const searchBoxRef = useRef(null);

  const toast = useToastCustom();

  const { mutate: addScheduleMutation } = useMutationCustom(
    POST_MAIN_SCHEDULE_CREATE,
    HTTP_METHOD.POST
  );

  const { mutate: modifyScheduleMutation } = useMutationCustom(
    POST_MAIN_SCHEDULE_MODIFY,
    HTTP_METHOD.POST
  );

  const { mutate: searchAdminMutation } = useMutationCustom(
    POST_MAIN_SCHEDULE_SEARCH_ADMIN,
    HTTP_METHOD.POST
  );

  const closeModalHandler = (e) => {
    if (e.target === e.currentTarget) {
      setOpenModal(false);
    }
  };

  const addScheduleHandler = () => {
    date.setHours(9);
    date.setMinutes(0);
    date.setSeconds(0);
    const scheduleDate = date
      .toISOString()
      .substring(0, 16)
      .split("T")
      .join(" ");

    if (
      !scheduleTitle ||
      !scheduleTitle.trim() ||
      !scheduleContent ||
      !scheduleContent.trim()
    ) {
      toast("제목과 내용을 모두 입력해주세요.", "error");
      return;
    }

    const createData = {
      scheduleTitle,
      scheduleContent,
      alarmYn,
      shareYn,
      scheduleLevel,
      scheduleDate,
      mentionedAdmins: tagAdminList,
    };
    addScheduleMutation(createData, {
      onSuccess: (response) => {
        setOpenModal(false);
        setScheduleList((prev) => [...prev, response]);
        setCalendarCountObj((prev) => {
          const countDate = new Date(scheduleDate);
          const newObj = { ...prev };

          if (!newObj[countDate.getFullYear()]) {
            newObj[countDate.getFullYear()] = {};
          }

          if (!newObj[countDate.getFullYear()][countDate.getMonth() + 1]) {
            newObj[countDate.getFullYear()][countDate.getMonth() + 1] = {};
          }

          if (
            !newObj[countDate.getFullYear()][countDate.getMonth() + 1][
              countDate.getDate()
            ]
          ) {
            newObj[countDate.getFullYear()][countDate.getMonth() + 1][
              countDate.getDate()
            ] = {};
          }

          if (
            !newObj[countDate.getFullYear()][countDate.getMonth() + 1][
              countDate.getDate()
            ][scheduleLevel]
          ) {
            newObj[countDate.getFullYear()][countDate.getMonth() + 1][
              countDate.getDate()
            ][scheduleLevel] = 0;
          }

          newObj[countDate.getFullYear()][countDate.getMonth() + 1][
            countDate.getDate()
          ][scheduleLevel]++;
          return newObj;
        });
      },
      onError: () => {
        toast("일정 생성 실패. 잠시후 다시 시도하세요.", "error");
      },
    });
  };

  const modifyScheduleHandler = () => {
    if (
      !scheduleTitle ||
      !scheduleTitle.trim() ||
      !scheduleContent ||
      !scheduleContent.trim()
    ) {
      toast("제목과 내용을 모두 입력해주세요.", "error");
      return;
    }

    const modifyData = {
      id: data.id,
      scheduleTitle,
      scheduleContent,
      alarmYn,
      shareYn,
      scheduleLevel,
      mentionedAdmins: tagAdminList,
    };
    modifyScheduleMutation(modifyData, {
      onSuccess: (response) => {
        setOpenModal(false);
        setScheduleList((prev) =>
          prev.map((item) => (item.id === response.id ? response : item))
        );
        const countDate = new Date(response.scheduleDate);
        setCalendarCountObj((prev) => {
          const newObj = { ...prev };
          newObj[countDate.getFullYear()][countDate.getMonth() + 1][
            countDate.getDate()
          ][legacyScheduleLevel]--;
          newObj[countDate.getFullYear()][countDate.getMonth() + 1][
            countDate.getDate()
          ][scheduleLevel]++;
          return newObj;
        });
      },
      onError: () => {
        toast("일정 수정 실패. 잠시후 다시 시도하세요.", "error");
      },
    });
  };

  const adminSearchHandler = (admin) => {
    debouncedSetSearchAdmin(admin);
  };

  const debouncedSetSearchAdmin = debounce((admin) => {
    setSearchAdmin(admin);
  }, 500);

  useEffect(() => {
    if (searchAdmin === "") {
      setSearchAdminResult([]);
      return;
    }

    const request = { admin: searchAdmin };
    searchAdminMutation(request, {
      onSuccess: (response) => {
        setSearchAdminResult(response);
      },
      onError: () => {},
    });
  }, [searchAdmin]);

  const tagAdminHandler = (adminName) => {
    setTagAdminList((prev) => {
      const isExist = prev.some((prevObj) => prevObj === adminName);
      if (isExist) {
        resetSearchHandler();
        return prev;
      }
      return [...prev, adminName];
    });
  };

  useEffect(() => {
    if (tagAdminList.length === 0) return;
    resetSearchHandler();
  }, [tagAdminList]);

  const resetSearchHandler = () => {
    setSearchAdminResult([]);
    setSearchAdmin("");
    searchBoxRef.current.value = "";
  };

  return (
    <>
      <Container onClick={(e) => closeModalHandler(e)}></Container>
      <Wrapper>
        <Title.Wrapper>
          <div>{modifyMode ? "일정 수정하기" : "일정 등록하기"}</div>
          <img
            src={`${IMG_CDN_URL}/file/04e5b375modalCloseBtn.svg`}
            alt="modal close btn"
            onClick={(e) => closeModalHandler(e)}
          />
        </Title.Wrapper>

        <ContentWrapper.Wrapper>
          <ContentWrapper.TopWrapper>
            <ColorPicker.Wrapper>
              {Object.values(scheduleJson).map((v) => {
                if (v.level !== "9")
                  return (
                    <ColorPicker.Container
                      $color={v.color}
                      $isSelected={scheduleLevel === v.level}
                      onClick={() => {
                        setScheduleLevel((prev) => {
                          setLegacyScheduleLevel(prev);
                          return v.level;
                        });
                      }}
                      key={v.level}
                    ></ColorPicker.Container>
                  );
              })}
            </ColorPicker.Wrapper>
            <ContentWrapper.ShareContainer
              onClick={() => {
                setShareYn(shareYn === "Y" ? "N" : "Y");
                setScheduleLevel(shareYn === "N" ? "9" : "0");
              }}
              $isShared={shareYn}
            >
              <img
                src={
                  shareYn === "Y"
                    ? `${IMG_CDN_URL}/file/23548d31calendar_notice_group.svg`
                    : `${IMG_CDN_URL}/file/7c85ce16calendar_notice_group_unchecked.svg.svg`
                }
                alt="공지로 등록 버튼"
              />
            </ContentWrapper.ShareContainer>
          </ContentWrapper.TopWrapper>

          <ScheduleInput.LetterCountWrapper $count={letterCount}>
            {letterCount} / 40
          </ScheduleInput.LetterCountWrapper>
          <ScheduleInput.TitleInput
            maxLength={40}
            placeholder="일정 제목을 입력해주세요. (40자 제한)"
            onChange={(e) => {
              setScheduleTitle(e.target.value);
              setLetterCount(e.target.value.length);
            }}
            defaultValue={scheduleTitle}
          />
          <ScheduleInput.ContentsInput
            placeholder="일정에 대한 설명을 입력해주세요"
            onChange={(e) => setScheduleContent(e.target.value)}
            defaultValue={scheduleContent}
          />

          <CalendarTag.Wrapper>
            <div>@관리자 태그</div>
            <CalendarTag.SearchBoxContainer>
              <CalendarTag.SearchBox
                type="text"
                placeholder="관리자 이름/ID로 검색"
                onChange={(e) => adminSearchHandler(e.target.value)}
                ref={searchBoxRef}
              />

              {searchAdminResult.length > 0 && (
                <CalendarTag.SearchResult>
                  {searchAdminResult.map((v, i) => (
                    <div key={i} onClick={() => tagAdminHandler(v.name)}>
                      {v.name} ({v.adminId})
                    </div>
                  ))}
                </CalendarTag.SearchResult>
              )}
            </CalendarTag.SearchBoxContainer>
          </CalendarTag.Wrapper>

          {tagAdminList.length > 0 && (
            <TagAmin.Wrapper>
              {tagAdminList.map((v, i) => (
                <TagAmin.Container key={i}>
                  <div>@{v}</div>
                  <img
                    src={`${IMG_CDN_URL}/file/149aa92btag_close_btn.svg`}
                    alt="close button"
                    onClick={() =>
                      setTagAdminList((prev) => {
                        const prevArray = [...prev];
                        prevArray.splice(i, 1);
                        return prevArray;
                      })
                    }
                  />
                </TagAmin.Container>
              ))}
            </TagAmin.Wrapper>
          )}

          <CreateCalendarBtn
            onClick={modifyMode ? modifyScheduleHandler : addScheduleHandler}
          >
            {modifyMode ? "일정 수정" : "일정 등록"}
          </CreateCalendarBtn>
        </ContentWrapper.Wrapper>
      </Wrapper>
    </>
  );
};
