import CryptoJS from "crypto-js";
const secretKey = process.env.REACT_APP_AES_SECRETKEY;

export const uriEncoder = (acctCd = "", id = "", state = "") => {
  const str = acctCd.toString() + " " + id.toString() + " " + state.toString();

  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(str), secretKey);

  return encodeURIComponent(encrypted).toString();
};

export const uriDecoder = (encrypted) => {
  try {
    encrypted = decodeURIComponent(encrypted);

    return CryptoJS.AES.decrypt(encrypted, secretKey)
      .toString(CryptoJS.enc.Utf8)
      .slice(1, -1);
  } catch (error) {}
};

export const getUserParamsFromUri = () => {
  const str = uriDecoder(window.location.pathname.split("/").at(-1));

  if (str === undefined) return;

  const params = str.split(" ");
  const acctCd = params[0];
  const id = params[1];
  const state = params[2];
  return { acctCd, id, state };
};

export const getStoreParamsFromUri = () => {
  const str = uriDecoder(window.location.search.split("=").at(1));

  const params = str.split(" ");
  const hashedStoreId = params[0];

  return { hashedStoreId };
};
