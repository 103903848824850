import { useState } from "react";
import { Wrapper } from "./style";
import { PaginationBox } from "components/Pagination/PaginationBox";
import NoticeSearch from "./NoticeSearch";
import NoticeList from "./NoticeList";

// 고객 지원 -> 공지 관리 :: http://localhost:3000/super/create/notice
const Notice2 = () => {
  const [noticeList, setNoticeList] = useState({ list: [], cnt: "0" });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [checked, setChecked] = useState([]);
  const [searchData, setSearchData] = useState({});

  return (
    <Wrapper>
      <div>
        <NoticeSearch
          setNoticeList={setNoticeList}
          setPage={setPage}
          currentPage={page}
          pageSize={size}
          setChecked={setChecked}
          setSearchData={setSearchData}
        />

        <NoticeList
          noticeList={noticeList.list}
          setPage={setPage}
          setSize={setSize}
          checked={checked}
          setChecked={setChecked}
          searchData={searchData}
          setNoticeList={setNoticeList}
        />

        {noticeList.cnt > 0 && (
          <PaginationBox
            totalItemsCount={noticeList.cnt}
            page={page}
            setPage={setPage}
            size={size}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default Notice2;
