import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .pagination {
    display: flex;
    gap: 1rem;

    li {
      width: 1.8rem;
      height: 1.8rem;
      font-weight: ${FONT_WEIGHT.MEDIUM};
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;

      & > a {
        text-decoration: none;
        color: black;
      }
    }

    .active {
      background-color: #4253f2;

      a {
        color: white;
      }
    }
  }
`;
