import { ModalContaier, ModalWrapper } from "./style";

const Notice2Modal = ({ data, setIsOpen }) => {
  return (
    <>
      <ModalWrapper onClick={() => setIsOpen((prev) => !prev)} />
      <ModalContaier>
        <div>
          <div>{data.titleKr}</div>
          <div dangerouslySetInnerHTML={{ __html: data.contentsKr }}></div>
        </div>
        <div>
          <div>{data.titleEng}</div>
          <div dangerouslySetInnerHTML={{ __html: data.contentsEng }}></div>
        </div>
      </ModalContaier>
    </>
  );
};

export default Notice2Modal;
