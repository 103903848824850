import { useState } from "react";
import { Container, Header, List, Wrapper } from "./WalletInfo.style";

import SummaryByUser from "./WalletCategory/SummaryByUser";
import PaymentByUser from "./WalletCategory/PaymentByUser";
import DropByUser from "./WalletCategory/DropByUser";
import BackByUser from "./WalletCategory/BackByUser";

// 회원검색 (검증 후 회원 정보 바로가기) :: 지갑정보 (http://localhost:3000/manage/user/:code)
export const WalletInfo = ({ id }) => {
  const [walletCategory, setWalletCategory] = useState("summary");

  return (
    <Wrapper>
      <Container.Top>
        <Container.Text>지갑정보</Container.Text>
      </Container.Top>
      <Container.Content>
        <Header.Wrapper>
          <Header.SmallCategory>
            <Header.SmallCategoryDiv
              selected={walletCategory === "summary"}
              onClick={() => setWalletCategory("summary")}
            >
              요약
            </Header.SmallCategoryDiv>
            <Header.SmallCategoryDiv
              onClick={() => setWalletCategory("payment")}
              selected={walletCategory === "payment"}
            >
              결제
            </Header.SmallCategoryDiv>
            <Header.SmallCategoryDiv
              onClick={() => setWalletCategory("drop")}
              selected={walletCategory === "drop"}
            >
              스케치 드랍
            </Header.SmallCategoryDiv>
            <Header.SmallCategoryDiv
              onClick={() => setWalletCategory("back")}
              selected={walletCategory === "back"}
            >
              스케치 백
            </Header.SmallCategoryDiv>
          </Header.SmallCategory>
        </Header.Wrapper>

        <List.Wrapper>
          {/* 요약 */}
          {walletCategory === "summary" && (
            <SummaryByUser id={id}></SummaryByUser>
          )}

          {/* 결제 */}
          {walletCategory === "payment" && (
            <PaymentByUser id={id}></PaymentByUser>
          )}

          {/* 드랍 */}
          {walletCategory === "drop" && <DropByUser id={id}></DropByUser>}

          {/* 백 */}
          {walletCategory === "back" && <BackByUser id={id}></BackByUser>}
        </List.Wrapper>
      </Container.Content>
    </Wrapper>
  );
};
