import React, { useState } from "react";
import { Wrapper } from "./ActivityLog.style";
import { ActivityLogSearch } from "./ActivityLogSearch/ActivityLogSearch";
import { ActivityLogInfoList } from "./ActivityLogInfoList/ActivityLogInfoList";
import { PaginationBox } from "components/Pagination/PaginationBox";

// 시스템 관리 -> 활동 로그 관리 :: (http://localhost:3000/super/system/log)
export const ActivityLog = () => {
  const [activityLogList, setActivityLogList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});

  return (
    <Wrapper>
      <ActivityLogSearch
        setActivityLogList={setActivityLogList}
        setPage={setPage}
        currentPage={page}
        pageSize={size}
        setSearchData={setSearchData}
        searchData={searchData}
      />
      <ActivityLogInfoList
        activityLogList={activityLogList}
        setPage={setPage}
        setSize={setSize}
        searchData={searchData}
        currentPage={page}
        pageSize={size}
        setSearchData={setSearchData}
        setActivityLogList={setActivityLogList}
      />

      {activityLogList.length !== 0 && (
        <PaginationBox
          totalItemsCount={activityLogList[0]?.cnt}
          page={page}
          setPage={setPage}
          size={size}
        />
      )}
    </Wrapper>
  );
};
