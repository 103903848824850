import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  font-weight: ${FONT_WEIGHT.BOLD};
`;

export const Container = styled.div`
  width: 100%;
  min-height: 10rem;
  background-color: white;
  border-radius: 1rem;
  overflow: hidden;
`;

export const Row = styled.span`
  flex: ${(props) => (props.$ratio ? props.$ratio : 1)};
  text-align: center;
  color: ${(props) => props.color && props.color};
  cursor: ${(props) => props.cursor || "default"};
  position: relative;
  display: flex;
  justify-content: center;
  word-break: break-all;
  align-items: center;
  padding: 0.5rem;

  > input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #000;
    font-size: 1.5rem;
  }

  > select {
    width: 80%;
    height: 130%;
    font-size: 1.5rem;
    padding: 0.5rem;
  }

  > img {
    width: 4rem;
    height: 4rem;
  }
`;

export const Btn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.fontSize && props.fontSize};
  font-weight: 600;
  padding: 0.5rem 1rem;
  color: ${(props) => (props.color ? props.color : "white")};
  border: ${(props) => (props.border ? `1px solid ${props.border}` : "none")};
  border-radius: 0.4rem;
  cursor: pointer;
  background: ${(props) => (props.background ? props.background : "#bbb")};
`;

export const BtnBox = styled.div`
  display: flex;
  gap: 1rem;
`;

export const ImgInput = styled.input`
  display: none;
`;

export const ImgLabel = styled.label`
  border: 1px solid #000;
  padding: 0.5rem;
  border-radius: 0.6rem;
  cursor: pointer;
`;

export const ImgDiv = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  margin-right: 1rem;

  > img {
    width: 100%;
    height: 100%;
  }
`;
