import { Info, List } from "template/ListTemplate.style";
import sfuleManageColumn from "../sfuelManageColumn.json";
import { getCostFormat } from "utils/numberFormat";
import { CheckBox } from "../style";

const SfuelProcessDetail = ({ data, checkHandler, checked, idx }) => {
  return (
    <Info.Content>
      <List.Row>
        <CheckBox
          type="checkbox"
          $ratio={1}
          onChange={(e) => checkHandler(e, data.admin.id)}
          checked={checked.includes(data.admin.id) ? true : false}
          // checked={checked.length === data.content.length ? true : false}
        />
      </List.Row>
      {/* 번호 */}
      <List.Row $ratio={sfuleManageColumn.column[0].ratio}>{idx + 1}</List.Row>

      {/* 이름 */}
      <List.Row $ratio={sfuleManageColumn.column[1].ratio}>
        {data.admin.name}
      </List.Row>

      {/* 완료 지갑 수 */}
      <List.Row $ratio={sfuleManageColumn.column[2].ratio}>
        {data.completeCounts}
      </List.Row>

      {/* 미완료 지갑 수 */}
      <List.Row $ratio={sfuleManageColumn.column[3].ratio}>
        {data.notCompleteCounts}
      </List.Row>

      {/* 진행률 */}
      <List.Row $ratio={sfuleManageColumn.column[4].ratio}>
        {getCostFormat(data.totalSfuelCounts)}
      </List.Row>
    </Info.Content>
  );
};

export default SfuelProcessDetail;
