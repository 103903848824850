import { Header, Wrapper } from "./Property.style";
import PropertyList from "./PropertyList/PropertyList";

// 메인 :: 자산 (http://localhost:3000/main)
const Property = () => {
  return (
    <Wrapper>
      <Header>
        <strong>회사 지갑 자산 내역</strong>
      </Header>
      <PropertyList />
    </Wrapper>
  );
};

export default Property;
