import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 8.6rem;
  padding: 0 3rem;
  background-color: white;
`;

export const Logo = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 0.6rem;
    cursor: pointer;
  `,

  Img: styled.img`
    width: 3.6rem;
    height: 3.6rem;
  `,

  Text: styled.span`
    font-size: 2.2rem;
    font-weight: ${FONT_WEIGHT.BOLD};
  `,
};

export const Middle = {
  Wrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  Menu: styled.div`
    position: relative;
    padding: 1rem 1.5rem;
    border-radius: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    color: ${(props) => (props.selected ? "#4252f2" : "black")};
    border: ${(props) => (props.selected ? "0.2rem solid #4252f2" : "0.2rem solid transparent")};
    cursor: pointer;
  `,

  SubMenuWrapper: styled.div`
    position: absolute;
    top: 130%;
    left: 50%;
    width: fit-content;
    transform: translate(-50%);
    display: flex;
    gap: 1.5rem;
    background-color: rgba(0, 0, 0);
    border-radius: 3rem;
    padding: 2rem;
    z-index: 2;

    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: -15%;
      left: 50%;
      transform: translate(-50%);
      border-bottom: 2rem solid rgba(0, 0, 0);
      border-right: 1.5rem solid transparent;
      border-left: 1.5rem solid transparent;
    }
  `,

  SubMenu: styled.div`
    color: ${(props) => (props.selected ? "white" : "rgba(170, 170, 170, 1)")};
    border-bottom: ${(props) => (props.selected ? "0.1rem solid white" : "")};
    white-space: nowrap;
  `,
};

// TODO: 헤더 수정
// export const Middle = {
//   Wrapper: styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   `,

//   Menu: styled.div`
//     position: relative;
//     padding: 1rem 1.5rem;
//     border-radius: 2.5rem;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     font-size: 1.6rem;
//     font-weight: bold;
//     color: ${(props) => (props.selected ? "#4252f2" : "black")};
//     border: ${(props) => (props.selected ? "0.2rem solid #4252f2" : "0.2rem solid transparent")};
//     cursor: pointer;

//     &:hover > div {
//       visibility: visible;
//       opacity: 1;
//     }
//   `,

//   SubMenuWrapper: styled.div`
//     position: absolute;
//     top: 130%;
//     left: 50%;
//     width: fit-content;
//     transform: translate(-50%);
//     display: flex;
//     gap: 1.5rem;
//     background-color: rgba(0, 0, 0, 1);
//     border-radius: 3rem;
//     padding: 2rem;
//     z-index: 2;
//     visibility: hidden;
//     opacity: 0;
//     transition: visibility 0s, opacity 0.2s linear;

//     &:before {
//       content: "";
//       position: absolute;
//       width: 0;
//       height: 0;
//       top: -15%;
//       left: 50%;
//       transform: translate(-50%);
//       border-bottom: 2rem solid rgba(0, 0, 0, 1);
//       border-right: 1.5rem solid transparent;
//       border-left: 1.5rem solid transparent;
//     }
//   `,

//   SubMenu: styled.div`
//     color: ${(props) => (props.selected ? "white" : "rgba(170, 170, 170, 1)")};
//     border-bottom: ${(props) => (props.selected ? "0.1rem solid white" : "")};
//     white-space: nowrap;
//   `,
// };

export const End = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  `,

  Text: styled.div`
    font-size: 1.4rem;
    font-weight: ${FONT_WEIGHT.MEDIUM};
    & > span {
      color: #4253f2;
    }
  `,

  Btn: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    border-radius: 1.4rem;
    border: 0.1rem solid #ccc;
    padding: 1rem;
    font-weight: ${FONT_WEIGHT.MEDIUM};
    color: rgba(136, 136, 136, 1);
    cursor: pointer;
  `,
};
