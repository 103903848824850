import { useLocation } from "react-router";
import VersionUpload2 from "../VersionUpload2";

// 시스템 관리 -> 버전2 관리 :: 버전2 수정 (http://localhost:3000/super/system/version2-modify)
const VersionModify2 = () => {
  const location = useLocation();

  return <VersionUpload2 modifyData={location.state.data} />;
};

export default VersionModify2;
