import { useEffect, useState } from "react";
import {
  BottomBtn,
  BottomWrapper,
  ContentWrapper,
  Name,
  Rectangle,
  SelectWrapper,
  TableWrapper,
  Wrapper,
  SelectBoxWrapper,
  Title,
  CheckboxWrapper,
} from "./style";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import { POST_NOTICE_CREATE, POST_NOTICE_MODIFY } from "constants/pathConstant";
import { axiosInstance } from "api/api";
import { useMutation } from "@tanstack/react-query";
import { useToastCustom } from "hooks/useToastCustom";
import domainColumn from "../noticeDomainColumn.json";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftjsToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentBox } from "pages/Support/Faq/FaqUpload/style";
import { NoticeModal } from "components/Modal/NoticeModal";

// 고객 지원 -> 공지 관리 :: 공지 등록 (http://localhost:3000/super/create/notice)
const NoticeUpload = ({ modifyData }) => {
  const nav = useNavigationCustom();
  const toast = useToastCustom();

  const [alertYn, setAlertYn] = useState("Y");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateKr, setEditorStateKr] = useState(EditorState.createEmpty());
  const [title_kr, setTitle_kr] = useState("");
  const [title_eng, setTitle_eng] = useState("");
  const [contents_kr, setContents_kr] = useState("");
  const [contents_eng, setContents_eng] = useState("");
  const [useYn, setUseYn] = useState("Y");
  const [reqType, setReqType] = useState("00");
  const [checked, setChecked] = useState([]);

  // 수정시 추가 state
  const [id, setId] = useState("");

  // 모달 state
  const [isOpen, setIsOpen] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const previewHandler = () => {
    setIsOpen((prev) => !prev);
    setPreviewData({ title_kr, title_eng, contents_kr, contents_eng });
  };

  useEffect(() => {
    if (!modifyData) return;

    setId(modifyData.id);
    setTitle_kr(modifyData.title_kr);
    setTitle_eng(modifyData.title_eng);
    setContents_kr(modifyData.contents_kr);
    setContents_eng(modifyData.contents_eng);

    // 영어 contents 기본값 설정
    const blocksFromHtml = htmlToDraft(modifyData.contents_eng);
    if (blocksFromHtml) {
      const { contentBlocks, entityMap } = blocksFromHtml;
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(contentBlocks, entityMap)
        )
      );
    }

    // 한국어 contents 기본값 설정
    const blocksFromHtmlKr = htmlToDraft(modifyData.contents_kr);
    if (blocksFromHtmlKr) {
      const { contentBlocks, entityMap } = blocksFromHtmlKr;
      setEditorStateKr(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(contentBlocks, entityMap)
        )
      );
    }
  }, []);

  const { mutate } = useMutation(
    (data) => axiosInstance.post(POST_NOTICE_CREATE, data),
    {
      onSuccess: () => {
        toast("정상적으로 등록되었습니다.", "success");
        nav("/super/create/notice");
      },
      onError: () => {
        toast("등록에 실패하였습니다", "error");
      },
    }
  );

  const { mutate: updateMutate } = useMutation(
    (data) => axiosInstance.post(POST_NOTICE_MODIFY, data),
    {
      onSuccess: () => {
        toast("정상적으로 수정되었습니다.", "success");
        nav("/super/create/notice");
      },
      onError: () => {
        toast("수정에 실패하였습니다", "error");
      },
    }
  );

  const updateTextDescription = async (state, isKorean) => {
    // 에디터에 글쓰기 위한 state
    isKorean ? setEditorStateKr(state) : setEditorState(state);

    let html = draftjsToHtml(
      convertToRaw(
        isKorean
          ? editorStateKr.getCurrentContent()
          : editorState.getCurrentContent()
      )
    );

    // 불완전한 <p></p> 태그 수정
    html = html.replace(/<p><\/p>/g, "<br />");

    // html 기본 폰트사이즈 1rem, px을 rem으로 바꾸는 정규식
    let matchWord = html.match(/font-size: \d{1,2}px;/gi);
    if (matchWord) {
      matchWord = matchWord.map((font) => {
        const int = font.match(/\d{1,2}/g);
        return `font-size: ${int / 10}rem`;
      });

      html = html.replace(/font-size: \d{1,2}px;/gi, matchWord);
    }
    // ---------------------이슈 : &nbsp; 가 임의로 사라져버려서 띄어쓰기는 연속 2번이상 불가
    isKorean ? setContents_kr(html) : setContents_eng(html);
  };

  const uploadHandler = async () => {
    if (!title_kr || !title_eng || !contents_kr || !contents_eng)
      return toast("빠짐없이 입력해주세요.", "error");

    const data = {
      title_kr,
      title_eng,
      contents_kr,
      contents_eng,
      alertYn,
      useYn: modifyData && useYn,
      id: modifyData && id,
      reqType: modifyData ? reqType : checked,
    };

    setPreviewData(data);

    if (modifyData) {
      updateMutate(data);
    } else {
      mutate(data);
    }
  };

  const checkHandler = (e, id) => {
    if (e.target.checked) {
      setChecked((prev) => [...prev, id]);
    } else {
      setChecked(checked.filter((el) => el !== id));
    }
  };

  return (
    <Wrapper>
      <Name>Notice</Name>
      <TableWrapper>
        <span>
          <Rectangle />
          작성하기
        </span>
        <div>
          <div>
            <SelectBoxWrapper>
              <div>앱 구분</div>
              {modifyData ? (
                <SelectWrapper>
                  <select
                    onChange={(e) => setReqType(e.target.value)}
                    defaultValue={modifyData?.reqType || reqType}
                    key={modifyData?.reqType || reqType}
                  >
                    {Object.entries(domainColumn.reqType).map(
                      ([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      )
                    )}
                  </select>
                </SelectWrapper>
              ) : (
                <CheckboxWrapper>
                  {Object.entries(domainColumn.reqType).map(([key, value]) => (
                    <div key={key}>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          checkHandler(e, key);
                        }}
                      />
                      <p>{value}</p>
                    </div>
                  ))}
                </CheckboxWrapper>
              )}
            </SelectBoxWrapper>

            <SelectBoxWrapper>
              <div>알림 여부</div>
              <SelectWrapper>
                <select
                  onChange={(e) => setAlertYn(e.target.value)}
                  defaultValue={modifyData?.alertYn || alertYn}
                  key={modifyData?.alertYn || alertYn}
                >
                  <option value={"Y"}>알림</option>
                  <option value={"N"}>미알림</option>
                </select>
              </SelectWrapper>
            </SelectBoxWrapper>
          </div>

          <div>
            {modifyData && (
              <SelectBoxWrapper>
                <div>공개 여부</div>
                <SelectWrapper>
                  <select
                    onChange={(e) => setUseYn(e.target.value)}
                    defaultValue={modifyData?.useYn || useYn}
                    key={modifyData?.useYn || useYn}
                  >
                    <option value={"Y"}>공개</option>
                    <option value={"N"}>비공개</option>
                  </select>
                </SelectWrapper>
              </SelectBoxWrapper>
            )}
          </div>
        </div>
      </TableWrapper>

      <ContentBox>
        <ContentWrapper>
          <span>
            <Rectangle />
            내용(한국어)
          </span>
          <div>
            <Title
              onChange={(e) => setTitle_kr(e.target.value)}
              defaultValue={modifyData?.title_kr || ""}
              placeholder="제목을 입력해주세요"
            />
          </div>
          <Editor
            placeholder="내용을 작성해주세요"
            editorState={editorStateKr}
            onEditorStateChange={(state) => updateTextDescription(state, true)}
            localization={{ locale: "ko" }}
            editorStyle={{
              height: "64rem",
              width: "55.8rem",
              border: "3px solid lightgray",
              padding: "20px",
              fontSize: "1.6rem",
              background: "#fff",
            }}
            toolbar={{
              // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
            }}
          />
        </ContentWrapper>
        <ContentWrapper>
          <span>
            <Rectangle />
            내용(영어)
          </span>
          <div>
            <Title
              onChange={(e) => setTitle_eng(e.target.value)}
              defaultValue={modifyData?.title_eng || ""}
              placeholder="제목을 입력해주세요"
            />
          </div>
          <Editor
            placeholder="내용을 작성해주세요"
            editorState={editorState}
            onEditorStateChange={(state) => updateTextDescription(state, false)}
            localization={{ locale: "ko" }}
            editorStyle={{
              height: "64rem",
              width: "55.8rem",
              border: "3px solid lightgray",
              padding: "20px",
              fontSize: "1.6rem",
              background: "#fff",
            }}
            toolbar={{
              // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
            }}
          />
        </ContentWrapper>
      </ContentBox>

      <BottomWrapper>
        <div>
          <BottomBtn onClick={() => nav("/super/create/notice")}>
            목록
          </BottomBtn>
        </div>
        <div>
          <BottomBtn
            onClick={previewHandler}
            bgColor={"#2db9c2"}
            fontColor={"white"}
          >
            미리보기
          </BottomBtn>
          <BottomBtn
            onClick={uploadHandler}
            bgColor={"#4253f2"}
            fontColor={"white"}
          >
            저장
          </BottomBtn>
        </div>
      </BottomWrapper>

      {isOpen && <NoticeModal data={previewData} setIsOpen={setIsOpen} />}
    </Wrapper>
  );
};

export default NoticeUpload;
