import { HTTP_METHOD, POST_USER_DETAIL } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { useSetRecoilState } from "recoil";
import { WalletDetailState } from "recoil/atom/UserDetailPageState";
import { UserState } from "recoil/atom/UserState";
import { getUserParamsFromUri } from "utils/crypto";
import { Referral } from "./Referral/Referral";
import { Flex, Wrapper } from "./UserDetail.style";
import { UserInfo } from "./UserInfo/UserInfo";
import { UserModify } from "./UserModify/UserModify";
import { WalletInfo } from "./WalletInfo/WalletInfo";

// 회원검색 (검증 후 회원 정보 바로가기) :: 전체 (http://localhost:3000/manage/user/:code)
export const UserDetail = () => {
  const { acctCd, id, state } = getUserParamsFromUri();

  const location = useLocation();

  const setUserDetail = useSetRecoilState(UserState.userDetail);
  const setSelectedStore = useSetRecoilState(WalletDetailState.SelectedStore);
  const setListIndex = useSetRecoilState(WalletDetailState.ListIndex);

  const { mutate, isLoading } = useMutationCustom(POST_USER_DETAIL, HTTP_METHOD.POST);

  // 유저 정보 + 추천정보 받아오기
  useEffect(() => {
    const data = { acctCd, id, state };

    mutate(data, {
      onSuccess: (response) => {
        setUserDetail(response);
      },
    });
  }, [location.pathname]);

  // 가맹점 검색으로 들어온 경우 -> 가맹점 리스트 보여주는것까지 진행
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const storeId = params.get("storeId");

    if (!storeId) {
      setListIndex(null);
      setSelectedStore(null);
      return;
    }

    setListIndex("store");
    setSelectedStore(storeId);
  }, []);

  return (
    <Wrapper>
      <Flex.First>
        <Flex.Second>
          {/* 유저 정보 */}
          <UserInfo isLoading={isLoading} />
          {/* 추천 현황 */}
          <Referral isLoading={isLoading} />
        </Flex.Second>
        {/* 지갑정보 */}
        <WalletInfo acctCd={acctCd} />
      </Flex.First>
      {/* 수정 */}
      <UserModify />
    </Wrapper>
  );
};
