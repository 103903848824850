import Registration from "./Registration/Registration";
import TodoProperty from "./TodoProperty/TodoProperty";
import { Wrapper } from "./TodoPropertyRegistration.style";

// 메인 :: TODO/자산/가입회원 (http://localhost:3000/main)
const TodoPropertyRegistration = () => {
  return (
    <Wrapper>
      <TodoProperty />
      {/* <Registration /> */}
    </Wrapper>
  );
};

export default TodoPropertyRegistration;
