import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 15rem;
  color: black;
  position: relative;

  & > :nth-child(4) {
    display: flex;
    flex-direction: row;
  }
`;

export const Name = styled.div`
  padding-top: 10rem;
  font-size: 4rem;
  font-weight: 900;
`;

export const TableWrapper = styled.div`
  margin-top: 5rem;
  font-size: 2.5rem;

  & > span:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  & > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    /* :nth-child(2),
    :nth-child(3) {
      display: flex;
      height: 10rem;
      border: none;
    } */
    & > div {
      display: flex;
      height: 10rem;
      border: none;
    }
  }

  p {
    font-size: 1.4rem;
    color: #999;
    margin: 0.5rem 1rem 0;
  }
`;

export const SelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  > :nth-child(1) {
    min-width: 20rem;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f4;
    border: 1px solid #fff;
    font-weight: 700;
  }

  > input {
    width: 100%;
    padding: 0 3rem;
    outline: none;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  height: 100%;
  background: #fff;
  align-items: center;
  padding: 0 2rem;

  & > select {
    width: 100%;
    /* height: 5rem; */
    border: none;
    background-color: white;
    outline: none;
    font-size: 2.5rem;

    & > option {
      height: 5rem;
    }
  }

  & > input {
    width: 100%;
    font-size: 2.2rem;
    outline: none;
  }
`;

export const Rectangle = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  background-color: black;
  box-sizing: border-box;
`;

export const Title = styled.input`
  border: none;
  outline: none;
  width: 100%;
  font-size: 2.5rem;
  padding: 2rem;
  box-sizing: border-box;
`;

export const ContentWrapper = styled.div`
  margin-top: 5rem;
  font-size: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.7rem;
  width: 100%;

  & > span:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  > textarea {
    resize: none;
    height: 20rem;
    padding: 2rem;
    outline: none;
    border: none;
  }
`;

export const ThumbnailWrapper = styled.div`
  margin-top: 10rem;
  display: flex;
  height: 15rem;
  font-size: 1.5rem;
  width: 100%;

  & > div {
    &:nth-child(1) {
      width: 20rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      background-color: #f1f1f4;
      border: 1px solid #fff;
    }

    &:nth-child(2) {
      padding: 0 2rem;
      width: 100%;
      height: 100%;
      background-color: white;
      display: flex;
      align-items: center;
      gap: 5rem;
      & > img {
        width: 10rem;
        height: 10rem;
      }
    }
  }
`;

export const UploadImage = styled.input`
  display: none;
`;

export const UploadImageLabel = styled.label`
  display: block;
  width: 10rem;
  height: 5rem;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  border-radius: 1rem;
`;

export const BottomWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 10rem;
  & > div {
    display: flex;
    gap: 3rem;
  }
`;

export const BottomBtn = styled.div`
  width: 15rem;
  height: 6rem;
  border-radius: 6px;
  border: 1px solid #d1d1d1;
  background: ${(props) => props.bgColor};
  color: ${(props) => (props.fontColor ? props.fontColor : "black")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;

  &:nth-child(3) {
    position: absolute;
    right: 0;
  }
  margin-bottom: 20rem;
`;

export const Modal = styled.div`
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(15px);
  top: 0rem;
  left: 0;
  display: flex;
  justify-content: center;
  gap: 10rem;
`;

export const CheckboxWrapper = styled(SelectWrapper)`
  flex-wrap: wrap;
  gap: 0;

  & > div {
    display: flex;
    width: 14rem;

    & > input {
      width: 1.5rem;
    }

    & > p {
      font-size: 2rem;
      color: #000;
    }
  }
`;
