export const REFERRAL_TYPE = {
  meToOtherW: "rt",
  otherToMeW: "rf",
  meToOtherS: "sa",
  otherToMeS: "sb",
};

export const TYPE_TO_TITLE = {
  rt: "월렛 - 나의 추천",
  rf: "월렛 - 나를 추천",
  sa: "체인(가맹점) - 나의 추천",
  sb: "체인(가맹점) - 나를 추천",
};
