import { Info, List, Wrapper } from "template/ListTemplate.style";
import migrationColumn from "../migrationColumn.json";
import MigrationSummaryList from "./MigrationSummaryList/MigrationSummaryList";

// 브릿지 -> 스왑 :: 요약 (http://localhost:3000/super/bridge/migration)
const MigrationSummary = ({ migrationList }) => {
  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>월렛 스왑 내역 요약</List.Text>
        <List.BtnContainer></List.BtnContainer>
      </List.TitleContainer>

      <List.Container style={{ minHeight: "0" }}>
        <List.Content>
          {migrationColumn?.summary.map((v, idx) => (
            <List.Row $ratio={v.ratio} key={idx}>
              {v.title}
            </List.Row>
          ))}
        </List.Content>

        <Info.Wrapper>
          <MigrationSummaryList data={migrationList.summary} />
        </Info.Wrapper>
      </List.Container>
    </Wrapper>
  );
};

export default MigrationSummary;
