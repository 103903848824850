import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const NavImgBox = styled.div`
  width: 1.2rem;
  height: 1.2rem;

  > img {
    width: 100%;
    height: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 1.5rem 1rem;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  border-bottom: 0.1rem solid #e2e2e2;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  background-color: #f3f3f3;
  color: #888;
`;

export const WithdrawDetailRow = {
  Wrapper: styled.div`
    padding: 1rem;
    padding-left: 5rem;
  `,

  Container: styled.div`
    width: 100%;
    border: 0.1rem solid black;
  `,

  Header: styled.div`
    padding: 0.5rem 1rem;
    height: 2.5rem;
    color: white;
    background: #4253f2;
    display: flex;
    align-items: center;
  `,

  Data: styled.div`
    padding: 0.5rem 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
  `,

  Row: styled.div`
    flex: ${(props) => props.$ratio};
    text-align: center;
  `,
};

export const Container = styled.div`
  min-height: 30rem;
  background-color: white;
  border-radius: 1rem;
  margin: 0 1rem 1rem;
  border-radius: 0.4rem;
  overflow: hidden;
`;
