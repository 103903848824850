import { Info, List, Wrapper } from "template/ListTemplate.style";
import sketchBackColumn from "../sketchBackColumn.json";
import SketchBackDetailList from "./SketchBackDetailList";
import { ListDefault } from "components/ListDefault/ListDefault";
import { PaginationBox } from "components/Pagination/PaginationBox";
import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { useToastCustom } from "hooks/useToastCustom";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import {
  POST_SKETCH_BACK_BATCH_EXECUTE,
  POST_SKETCH_BACK_DETAIL,
  POST_SKETCH_BACK_EXCLUDE,
  POST_SKETCH_BACK_FETCH,
} from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { SKETCH_BACK_DETAIL_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";
import { Btn } from "../SketchBack.style";
import PageHandler from "components/PageHandler/PageHandler";

const SketchBackDetail = ({
  data,
  checked,
  setChecked,
  detailPage,
  setDetailPage,
  searchDetailReq,
  searchReq,
  setSketchBackRes,
  setSketchBackDetailRes,
  setDetailSize,
  detailSize,
  txState,
  setTxState,
}) => {
  const toast = useToastCustom();
  const excelDownload = useExcelDownload();

  const formatDate = (dateStr) => {
    const year = dateStr.substring(0, 4); // 첫 4자리는 연도
    const month = dateStr.substring(4, 6); // 그 다음 2자리는 월

    return `${year}년 ${month}월`;
  };

  // 전체 선택
  const allCheckHandler = (e) => {
    if (e.target.checked) {
      const allCheckedArr = [];
      data.list.forEach((el) => allCheckedArr.push(el.id));
      setChecked(allCheckedArr);
    } else {
      setChecked([]);
    }
  };

  // 개별 선택
  const checkHandler = (e, id) => {
    if (e.target.checked) {
      setChecked((prev) => [...prev, id]);
    } else {
      setChecked(checked.filter((el) => el !== id));
    }
  };

  const { mutate } = useMutationCustom(POST_SKETCH_BACK_DETAIL);

  // 엑셀 다운로드
  const handleExcelDownload = () => {
    if (!data?.cnt) return toast("다운로드할 데이터가 없습니다", "error");

    const mutateData = {
      ...searchDetailReq,
      pageSize: data?.cnt,
      currentPage: 1,
    };

    mutate(mutateData, {
      onSuccess: (response) => {
        const excelData = {
          data: response.list,
          headers: SKETCH_BACK_DETAIL_XLSX_DOWNLOAD.header,
          fileName: SKETCH_BACK_DETAIL_XLSX_DOWNLOAD.filename,
        };

        excelDownload(excelData);
      },

      onError: () => {
        toast("전체 데이터 조회 실패", "error");
      },
    });
  };

  const { mutate: mutateExcludeYn } = useMutationCustom(
    POST_SKETCH_BACK_EXCLUDE
  );

  const { mutate: mutateUpdateMonth } = useMutationCustom(
    POST_SKETCH_BACK_FETCH
  );

  // 제외, 제외취소
  const exclusionHandler = () => {
    if (
      window.confirm(
        `${checked.length}건을 ${
          data.list[0].excludeYn === "대상" ? "제외" : "제외 취소"
        }하시겠습니까?`
      )
    ) {
      const requestData = {
        excludeIdList: checked,
        excludeYn: data.list[0].excludeYn === "대상" ? "Y" : "N",
      };

      mutateExcludeYn(requestData, {
        onSuccess: () => {
          toast("처리가 완료되었습니다.", "success");
          mutate(searchDetailReq, {
            onSuccess: (res) => {
              setSketchBackDetailRes(res);
              mutateUpdateMonth(searchReq, {
                onSuccess: (res) => {
                  setSketchBackRes(res);
                },
              });
            },
          });
        },

        onError: () => {
          toast("처리에 실패하였습니다.", "error");
        },
      });
    }
  };

  // 스케치백 일괄 전송
  const { mutate: mutateBatchExecute } = useMutationCustom(
    POST_SKETCH_BACK_BATCH_EXECUTE
  );
  const sendSketchBackHandler = () => {
    if (!data.list[0].reqDate) return;
    const date = formatDate(data.list[0].reqDate);

    if (
      window.confirm(
        `${date}에 해당되는 대상의 스케치백을 진행하시겠습니까? \n*대상에서 제외된 건은 전송되지 않습니다. \n**상태가 성공 및 실패인 건은 전송되지 않습니다.`
      )
    ) {
      mutateBatchExecute(
        {
          reqDate: data.list[0].reqDate,
        },
        {
          onSuccess: () => {
            toast("스케치백에 성공하였습니다.", "success");
            mutate(searchDetailReq, {
              onSuccess: (res) => {
                setSketchBackDetailRes(res);
              },
            });
          },
          onError: () => {
            toast("스케치백에 실패하였습니다.", "error");
          },
        }
      );
    }
  };

  return (
    <Wrapper>
      <List.TitleContainer>
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <List.Text>연월 스케치 백 상세 리스트</List.Text>
          {data.list && data.list[0]?.excludeYn === "대상" && (
            <Btn onClick={sendSketchBackHandler}>일괄전송</Btn>
          )}

          <select value={txState} onChange={(e) => setTxState(e.target.value)}>
            <option value={""}>전체</option>
            {Object.entries(sketchBackColumn.txState).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </select>
        </div>
        <List.BtnContainer>
          <PageHandler setPage={setDetailPage} setPageSize={setDetailSize} />
          <ExcelDownloadBtn onClick={handleExcelDownload} />
        </List.BtnContainer>
      </List.TitleContainer>

      <List.Container>
        {!data.list?.length ? (
          <ListDefault text={"상단 목록에서 연월을"} />
        ) : (
          <>
            <List.Content>
              <List.Row $ratio={sketchBackColumn.detail[0].ratio}>
                <input
                  type="checkbox"
                  checked={checked.length === data.list?.length}
                  onChange={(e) => {
                    allCheckHandler(e);
                  }}
                />
              </List.Row>
              {sketchBackColumn.detail.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>

            <Info.Wrapper>
              {data.list?.map((v, idx) => (
                <SketchBackDetailList
                  key={idx}
                  data={v}
                  checked={checked}
                  checkHandler={checkHandler}
                  searchDetailReq={searchDetailReq}
                  setSketchBackDetailRes={setSketchBackDetailRes}
                />
              ))}
            </Info.Wrapper>
          </>
        )}
      </List.Container>

      {data.list?.length ? (
        <>
          <Btn disable={!checked.length} onClick={exclusionHandler}>
            {data.list[0].excludeYn === "대상"
              ? `${checked.length}/10 제외하기`
              : `${checked.length}/10 제외취소`}
          </Btn>
          <PaginationBox
            totalItemsCount={data.cnt}
            page={detailPage}
            setPage={setDetailPage}
            size={detailSize}
          />
        </>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

export default SketchBackDetail;
