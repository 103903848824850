import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  top: 7rem;
  left: 1.15rem;
  width: 23.6rem;
  background-color: white;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.6rem;
  z-index: 99;
  border: 0.2rem solid #eeeeee;
  padding: 1.5rem 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;

export const Title = {
  Wrapper: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 1.4rem;
    font-weight: ${FONT_WEIGHT.BOLD};

    & > img {
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }
  `,
};

export const ContentWrapper = {
  Wrapper: styled.div``,
  TopWrapper: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  ShareContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;

    cursor: pointer;

    & > img {
      width: 6rem;
    }
  `,
};

export const ColorPicker = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
  `,

  Container: styled.div`
    width: 1.9rem;
    height: 1.9rem;
    border-radius: 50%;
    background-color: ${(props) => props.$color};
    border: ${(props) => (props.$isSelected ? ".25rem solid #bbb" : "")};
    cursor: pointer;
  `,
};

export const ScheduleInput = {
  TitleInput: styled.input`
    width: 100%;
    border: 0.1rem solid #bbb;
    padding: 0.5rem;
    border-radius: 0.3rem;
    font-size: 1rem;
    margin-top: 0.5rem;
  `,

  ContentsInput: styled.textarea`
    width: 100%;
    height: 8rem;
    margin-top: 1rem;
    border-radius: 0.3rem;
    padding: 0.5rem;
    resize: none;
    font-size: 1rem;

    box-sizing: border-box;
  `,

  LetterCountWrapper: styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 1rem;
    color: ${(props) => (props.$count > 40 ? "#ff3c2b" : "grey")};
    font-size: 1rem;
  `,
};

export const CalendarTag = {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  `,

  SearchBoxContainer: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
  `,
  SearchBox: styled.input`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0.5rem;
    width: 10rem;
    border: 0.1rem solid #bbbbbb;
    outline: none;
    border-radius: 0.3rem;
    z-index: 999;
  `,
  SearchResult: styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 1.8rem;
    width: 10rem;
    text-overflow: ellipsis;
    word-break: break-all;
    cursor: pointer;
    border: 0.1rem solid #bbb;
    border-radius: 0.3rem;
    overflow: hidden;

    & > div {
      padding: 0.3rem 0.5rem;
      background-color: white;
      border-top: 0.1rem solid #bbb;
    }
    & > div:hover {
      background-color: #bbb;
    }
  `,
};

export const TagAmin = {
  Wrapper: styled.div`
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem 1rem;
  `,

  Container: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    padding: 0.6rem 0.5rem;
    border-radius: 0.3rem;
    gap: 0.4rem;
    color: #4253f2;
    font-weight: 800;

    & > img {
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }
  `,
};

export const CreateCalendarBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  background-color: #4253f2;
  padding: 1rem 6rem;
  color: white;
  border-radius: 0.3rem;
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: 1.2rem;
  cursor: pointer;
`;
