import { dateFormat } from "components/dateFormat";
import { PLACEHOLDER } from "constants/baseStyle";
import { HTTP_METHOD, POST_ADMIN_LIST } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useEffect, useState } from "react";
import { Search, Wrapper } from "template/SearchTemplate.style";
import adminColumn from "../adminColumn.json";

// 시스템 관리 :: 관리자/권한 검색 (http://localhost:3000/super/system/admin)
const AdminSearch = ({ setAdminList, setSearchData }) => {
  const [name, setName] = useState("");
  const [isActive, setIsActive] = useState("");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState(
    dateFormat(new Date(), false, 0, 0).slice(0, 10)
  );
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const { mutate } = useMutationCustom(POST_ADMIN_LIST, HTTP_METHOD.POST);

  const fetchData = () => {
    const data = {
      name,
      isActive,
      fromDt: fromDt && dateFormat(fromDt, true),
      toDt: dateFormat(toDt, true, 1),
      roles,
    };

    setSearchData(data);

    mutate(data, {
      onSuccess: (res) => {
        setAdminList(res);
      },
    });
  };

  const handleEnter = (key) => {
    if (key === "Enter") fetchData();
  };

  return (
    <Wrapper>
      <Search.Text>관리자 조회</Search.Text>
      <Search.Container>
        <Search.Content style={{ width: "90rem" }}>
          <Search.InputWrapper>
            {/* 관리자명 */}
            <div>관리자명</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setName(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 활동여부 */}
          <Search.InputWrapper>
            <div>활동 여부</div>
            <Search.Select onChange={(e) => setIsActive(e.target.value)}>
              <Search.Option value="">전체</Search.Option>
              <Search.Option value="1">활성</Search.Option>
              <Search.Option value="0">비활성</Search.Option>
            </Search.Select>
          </Search.InputWrapper>

          {/* 권한 */}
          <Search.InputWrapper>
            <div>권한</div>
            <Search.Select
              onChange={(e) => {
                const selectRoles = JSON.parse(e.target.value);
                setRoles(selectRoles);
              }}
            >
              <Search.Option value="[]">전체</Search.Option>
              {Object.entries(adminColumn.role).map(([key, value]) => (
                <option key={key} value={JSON.stringify(value)}>
                  {key}
                </option>
              ))}
            </Search.Select>
          </Search.InputWrapper>

          {/* 가입일자 */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>등록일자 (부터)</div>
            <Search.Input
              type="date"
              defaultValue={fromDt}
              onChange={(e) => setFromDt(e.target.value)}
            ></Search.Input>
          </Search.InputWrapper>

          {/* 가입일자 */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>등록일자 (까지)</div>
            <Search.Input
              type="date"
              defaultValue={toDt.slice(0, 10)}
              onChange={(e) => setToDt(e.target.value)}
            ></Search.Input>
          </Search.InputWrapper>
        </Search.Content>

        <Search.Btn onClick={fetchData}>조회</Search.Btn>
      </Search.Container>
    </Wrapper>
  );
};

export default AdminSearch;
