import { Main, MainDetail } from "template/MainListTemplate.style";
import propertyListColumn from "./propertyListColumn.json";
import { costFormat } from "components/costForamt";
import WalletSlice from "components/WalletSlice/WalletSlice";

// 메인 :: 자산 내역 낱개 (http://localhost:3000/main)
const PropertyListDetail = ({ data }) => {
  return (
    <MainDetail.Content>
      {/* 번호 */}
      <Main.Row $ratio={propertyListColumn.column[0].ratio}>{data.id}</Main.Row>

      {/* 지갑주소 */}
      <Main.Row $ratio={propertyListColumn.column[1].ratio}>
        {WalletSlice(data.masterWa)}
      </Main.Row>

      {/* 용도 */}
      <Main.Row $ratio={propertyListColumn.column[2].ratio}>
        {data.wallTypeNm}
      </Main.Row>

      {/* 잔액(SKETCH) */}
      <Main.Row $ratio={propertyListColumn.column[3].ratio}>
        {costFormat(data.sketchBalance, "", false)}
      </Main.Row>

      {/* 잔액(MATIC) */}
      <Main.Row $ratio={propertyListColumn.column[4].ratio}>
        {costFormat(data.polBalance, "", false)}
      </Main.Row>

      {/* 잔액(ETH) */}
      <Main.Row $ratio={propertyListColumn.column[4].ratio}>
        {costFormat(data.ethBalance, "")}
      </Main.Row>
    </MainDetail.Content>
  );
};

export default PropertyListDetail;
