import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { ListDefault } from "components/ListDefault/ListDefault";
import { HTTP_METHOD, POST_FIND_STORE_SUPER } from "constants/pathConstant";
import { SUPER_STORE_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { Info, List, Wrapper } from "template/ListTemplate.style";
import storeColumn from "./AcceptStoreColumn.json";
import { AcceptStoreDetail } from "./AcceptStoreDetail";
import { getStoreParamsFromUri } from "utils/crypto";
import PageHandler from "components/PageHandler/PageHandler";

// 승인 -> 가맹점 승인 :: 가맹점 목록 리스트 (http://localhost:3000/super/accept/store)
export const AcceptStoreInfoList = ({
  setPage,
  setSize,
  acceptStoreList,
  searchData,
  currentPage,
  pageSize,
  setSearchData,
  setAcceptStoreList,
}) => {
  const toast = useToastCustom();
  const excelDownload = useExcelDownload();

  const { mutate } = useMutationCustom(POST_FIND_STORE_SUPER, HTTP_METHOD.POST);

  // Excel 다운로드
  const handleExcelDownload = () => {
    if (!acceptStoreList[0]?.cnt) {
      toast("다운로드할 데이터가 없습니다", "error");
      return;
    }

    const researchCond = {
      ...searchData,
      pageSize: acceptStoreList[0]?.cnt,
      currentPage: 1,
    };

    mutate(researchCond, {
      onSuccess: (response) => {
        const excelData = {
          data: response,
          headers: SUPER_STORE_XLSX_DOWNLOAD.header,
          fileName: SUPER_STORE_XLSX_DOWNLOAD.filename,
        };

        excelDownload(excelData);
      },
      onError: () => {
        toast("데이터 전체 조회 실패", "error");
      },
    });
  };

  // 유저 디테일에서 넘어온 경우 체크
  const location = useLocation();

  useEffect(() => {
    if (!location.search) return;

    const { hashedStoreId } = getStoreParamsFromUri();

    const data = {
      storeId: hashedStoreId,
      pageSize,
      currentPage,
    };

    setSearchData(data);

    mutate(data, {
      onSuccess: (data) => {
        setAcceptStoreList(data);
      },
      onError: () => {
        toast("가맹점 정보 불러오기 실패", "error");
      },
    });
  }, []);

  return (
    <Wrapper style={{ width: "70%" }}>
      <List.TitleContainer>
        <List.Text>
          가맹점 목록{" "}
          {acceptStoreList[0] && (
            <span>(검색됨 : {acceptStoreList[0]?.cnt}개)</span>
          )}
        </List.Text>
        <List.BtnContainer>
          {/* 페이지 핸들러 */}
          <PageHandler setPage={setPage} setPageSize={setSize} />
          {/* 엑셀 다운로드 버튼 */}
          <ExcelDownloadBtn onClick={handleExcelDownload} />
        </List.BtnContainer>
      </List.TitleContainer>

      {/* 리스트 */}
      <List.Container>
        {acceptStoreList.length === 0 ? (
          //  default page
          <ListDefault text={"가맹점을"} />
        ) : (
          <>
            {/* List Column */}
            <List.Content>
              {storeColumn.data.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>
            <Info.Wrapper>
              {acceptStoreList.map((v) => (
                <AcceptStoreDetail
                  key={v.id}
                  id={v.id}
                  crtDt={v.crtDt}
                  storeNm={v.storeNm}
                  storeId={v.storeId}
                  ceoNm={v.ceoNm}
                  ceoCellNumber={v.ceoCellNumber}
                  contactNum={v.contactNum}
                  ceoEmail={v.ceoEmail}
                  refererCd={v.refererCd}
                  feeRate={v.feeRate}
                  serviceYn={v.serviceYn}
                  state={v.state}
                  confirmStateNm={v.confirmStateNm}
                  ownerAcctId={v.ownerAcctId}
                  ownerAcctIndexId={v.ownerAcctIndexId}
                  confirmState={v.confirmState}
                  addr={v.addr || ""}
                  addr2={v.addr2 || ""}
                  zipCode={v.zipCode || ""}
                  linkUrl={v.linkUrl}
                />
              ))}
            </Info.Wrapper>
          </>
        )}
      </List.Container>
    </Wrapper>
  );
};
