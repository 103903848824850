import { useRef, useState } from "react";
import { Container, UploadBtn, UploadLabel, Wrapper } from "./style";
import { useMutation } from "@tanstack/react-query";
import { axiosInstance } from "api/api";
import { IMG_CDN_URL } from "constants/pathConstant";

const File = () => {
  const imgRef = useRef();
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState(null);

  const imageHandler = (file) => {
    if (!file) return;
    setFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      imgRef.current.src = reader.result;
    };
  };

  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const { mutate } = useMutation(
    (data) => axiosInstance.post("/api/v1/file/upload/single", data, config),
    {
      onSuccess: (res) => {
        setUrl(`${IMG_CDN_URL}/${res}`);
      },
    }
  );

  const uploadHandler = () => {
    const formData = new FormData();
    formData.append("file", file);
    mutate(formData);
  };

  return (
    <Wrapper>
      <Container>
        <input
          type="file"
          onChange={(e) => imageHandler(e.target.files[0])}
          id="image"
        />
        <UploadLabel htmlFor="image">이미지 선택</UploadLabel>
        <img loading="lazy" src="" alt="이미지 미리보기" ref={imgRef} />
        <UploadBtn onClick={uploadHandler}>S3 서버에 올리기</UploadBtn>
        {url !== null ? <div>이미지 url : {url}</div> : <></>}
      </Container>
    </Wrapper>
  );
};

export default File;
