import { useEffect, useState } from "react";
import ManageUiInfoList from "./ManageUiInfoList/ManageUiInfoList";
import ManageUiUpload from "./ManageUiUpload/ManageUiUpload";
import { Wrapper } from "./style";
import { useMutationCustom } from "hooks/useMutationCustom";
import { POST_MANAGE_UI_FETCH } from "constants/pathConstant";
import { PaginationBox } from "components/Pagination/PaginationBox";

const ManageUi = () => {
  const [manageUiList, setManageUiList] = useState({ cnt: 0, list: [] });
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const { mutate } = useMutationCustom(POST_MANAGE_UI_FETCH);

  const fetchData = () => {
    const data = {
      pageSize: 10,
      currentPage,
    };

    mutate(data, {
      onSuccess: (res) => {
        setManageUiList(res);
      },
    });
  };

  return (
    <Wrapper>
      <ManageUiUpload fetchData={fetchData} />

      <ManageUiInfoList manageUiList={manageUiList} fetchData={fetchData} />

      {manageUiList.cnt !== 0 && (
        <PaginationBox
          totalItemsCount={manageUiList?.cnt}
          page={currentPage}
          setPage={setCurrentPage}
          size={10}
        />
      )}
    </Wrapper>
  );
};

export default ManageUi;
