import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-top: 6rem;
  align-items: center;

  & > div {
    width: 76%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
`;

export const DeleteBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: ${FONT_WEIGHT.BOLD};
  width: 4rem;
  height: 2rem;
  color: white;
  border-radius: 0.4rem;
  cursor: ${(props) => (props.state ? "default" : "pointer")};
  background: ${(props) => (props.state ? "#bbb" : "#F00")};
`;

export const RestoreBtn = styled(DeleteBtn)`
  background: ${(props) => (props.state ? "#bbb" : "#FEBD17")};
`;

export const DoneBtn = styled(DeleteBtn)`
  background: #bbb;
`;

export const BtnBox = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const DropUserDetailRow = {
  Wrapper: styled.div`
    padding: 1rem;
    padding-left: 5rem;
    font-size: 1.2rem;
  `,

  Container: styled.div`
    width: 100%;
    border: 0.1rem solid black;
  `,

  Header: styled.div`
    padding: 0.5rem 1rem;
    height: 2.5rem;
    color: white;
    background: #4253f2;
    display: flex;
    align-items: center;
  `,

  Data: styled.div`
    padding: 0.5rem 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
  `,

  Row: styled.div`
    flex: ${(props) => props.$ratio};
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
  `,
};

export const RowElement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const ImgBox = styled.div`
  width: 1.2rem;
  height: 1.5rem;
  cursor: pointer;

  > img {
    width: 100%;
    height: 100%;
  }
`;

export const DetailBtn = styled.div`
  width: 100%;
  border-radius: 10rem;
  border: 0.1rem solid #4253f2;
  cursor: pointer;
  color: #4253f2;
  font-size: 1rem;
  font-weight: ${FONT_WEIGHT.BOLD};
  padding: 0.6rem 0.8rem;
`;
