import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Modal = {
  Wrapper: styled.div`
    border-radius: 1rem;
    border: 0.1rem solid #f6f6f6;
    background: #fff;
    box-shadow: 0rem 0.4rem 0.4rem 0rem rgba(0, 0, 0, 0.25);
    background-color: white;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,

  Text: styled.div`
    width: 100%;
    word-break: keep-all;
    margin: 1rem auto;
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 1.6rem;
  `,

  TextArea: styled.textarea`
    background-color: #f4f4f4;
    border: 0.1rem solid #777;
    width: 100%;
    border-radius: 0.6rem;
    font-size: 1.4rem;
    height: 10rem;
  `,

  SendText: styled.div`
    white-space: pre-wrap;
  `,

  BtnWrapper: styled.div`
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
  `,

  Btn: styled.div`
    color: ${(props) => props.color || "black"};
    background-color: ${(props) => props.$bg || "white"};
    border-radius: 0.6rem;
    font-size: 1.4rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    padding: 1rem 1.8rem;
    cursor: pointer;
  `,
};
