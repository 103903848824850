import { useSetRecoilState } from "recoil";
import { modalState } from "recoil/atom/ModalState";

export const useCloseRootModal = () => {
  const setCloseModal = useSetRecoilState(modalState);

  return () => {
    setCloseModal({ isOpen: false });
  };
};
