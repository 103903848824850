import { Info, List } from "template/ListTemplate.style";
import backColumn from "../backByUserColumn.json";
import { costFormat } from "components/costForamt";
import { NavTxId } from "components/Navigation/NavTxId";
import { RowElement } from "pages/DropUser/DropUser.style";

// 회원검색 (검증 후 회원 정보 바로가기) :: 지갑정보 -> 스케치 백 낱개 (http://localhost:3000/manage/user/:code)
const BackByUserDetail = ({ data }) => {
  return (
    <Info.Content>
      {/* 번호 */}
      <List.Row $ratio={backColumn?.data[0]?.ratio}>{data.id}</List.Row>

      {/* 요청일자 */}
      <List.Row $ratio={backColumn?.data[1]?.ratio}>{data.createDate}</List.Row>

      {/* 회원명 */}
      <List.Row $ratio={backColumn?.data[2]?.ratio}>
        <RowElement>
          <p>{data.memberName}</p>
          <p>({data.memberCode})</p>
        </RowElement>
      </List.Row>

      {/* 스케치백(수량) */}
      <List.Row $ratio={backColumn?.data[3]?.ratio}>
        {costFormat(data.amount)}
      </List.Row>

      {/* 스케치백(원) */}
      <List.Row $ratio={backColumn?.data[4]?.ratio}>
        {costFormat(data.exchangeFromKrw)}
      </List.Row>

      {/* 시세(원) */}
      <List.Row $ratio={backColumn?.data[5]?.ratio}>
        {costFormat(data.exchangeRateKrw)}
      </List.Row>

      {/* 지급일자 */}
      <List.Row $ratio={backColumn?.data[6]?.ratio}>{data.updateDate}</List.Row>

      {/* txId */}
      <List.Row $ratio={backColumn?.data[7]?.ratio}>
        {NavTxId(data.txId)}
      </List.Row>
    </Info.Content>
  );
};

export default BackByUserDetail;
