import { useEffect, useState } from "react";
import { HTTP_METHOD, POST_FIND_STORE_BASIC } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { Search, Wrapper } from "template/SearchTemplate.style";
import { PLACEHOLDER } from "constants/baseStyle";

// 가맹점 검색 :: 가맹점 조회 (http://localhost:3000/manage/store)
export const StoreSearch = ({
  setStoreList,
  setPage,
  currentPage,
  pageSize,
  setSearchData,
  searchData,
}) => {
  const [storeNm, setStoreNm] = useState("");
  const [storeId, setStoreId] = useState("");
  const [ceoNm, setCeoNm] = useState("");

  const { mutate } = useMutationCustom(POST_FIND_STORE_BASIC, HTTP_METHOD.POST);

  const searchHandler = (storeNm, storeId, ceoNm) => {
    setPage(1);

    const data = { storeNm, storeId, ceoNm, pageSize, currentPage };

    setSearchData(data);

    mutate(data, {
      onSuccess: (data) => {
        setStoreList(data);
      },
    });
  };

  useEffect(() => {
    // if (!storeNm && !storeId && !ceoNm) return;
    if (!Object.keys(searchData)[0]) return;

    const researchData = { ...searchData, currentPage, pageSize };

    setSearchData(researchData);

    mutate(researchData, {
      onSuccess: (data) => {
        setStoreList(data);
      },
    });
  }, [pageSize, currentPage]);

  const handleEnter = (key) => {
    if (key === "Enter") searchHandler(storeNm, storeId, ceoNm);
  };

  return (
    <Wrapper>
      <Search.Text>가맹점 조회</Search.Text>
      <Search.Container>
        <Search.Content>
          <Search.InputWrapper>
            <div>가맹점명</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setStoreNm(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            ></Search.Input>
          </Search.InputWrapper>
          <Search.InputWrapper>
            <div>가맹점코드</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setStoreId(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            ></Search.Input>
          </Search.InputWrapper>
          <Search.InputWrapper>
            <div>대표자명</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setCeoNm(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            ></Search.Input>
          </Search.InputWrapper>
        </Search.Content>
        <Search.Btn
          onClick={() => searchHandler(storeNm, storeId, ceoNm)}
          style={{ background: "#FEBD17" }}
        >
          조회
        </Search.Btn>
      </Search.Container>
    </Wrapper>
  );
};
