import React, { useState } from "react";
import { Wrapper } from "./StoreList.style";
import { SuperStoreInfoList } from "./StoreInfoList/SuperStoreInfoList";
import { SuperStoreSearch } from "./StoreSearch/SuperStoreSearch";
import { PaginationBox } from "components/Pagination/PaginationBox";

// 검색 -> 가맹점 검색 :: (http://localhost:3000/super/search/store)
export const SuperStoreList = () => {
  const [superStoreList, setSuperStoreList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});

  return (
    <Wrapper>
      <SuperStoreSearch
        setSuperStoreList={setSuperStoreList}
        setPage={setPage}
        currentPage={page}
        pageSize={size}
        setSearchData={setSearchData}
        searchData={searchData}
      />
      <SuperStoreInfoList
        superStoreList={superStoreList}
        setPage={setPage}
        setSize={setSize}
        searchData={searchData}
      />

      {superStoreList.length !== 0 && (
        <PaginationBox
          totalItemsCount={superStoreList[0]?.cnt}
          page={page}
          setPage={setPage}
          size={size}
        />
      )}
    </Wrapper>
  );
};
