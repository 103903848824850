import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 6rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: center;

  & > div {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
`;

export const Auth = {
  ModifyBtn: styled.div`
    color: #4256f2;
    border: 0.1rem solid #4256f2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 1rem;
    padding: 0.3rem 0.7rem;
    cursor: pointer;

    &:hover {
      color: white;
      background-color: #4256f2;
    }
  `,
};
