import { Info, List } from "template/ListTemplate.style";
import paymentColumn from "../paymentByUserColumn.json";
import { costFormat } from "components/costForamt";
import { NavTxId } from "components/Navigation/NavTxId";

// 회원검색 (검증 후 회원 정보 바로가기) :: 지갑정보 -> 결제 낱개 (http://localhost:3000/manage/user/:code)
const PaymentByUserDetail = ({ data }) => {
  return (
    <>
      <Info.Content>
        {/* 번호 */}
        <List.Row $ratio={paymentColumn.column[0].ratio}>{data.id}</List.Row>

        {/* 결제 일자 */}
        <List.Row $ratio={paymentColumn.column[1].ratio}>
          {data.createDate}
        </List.Row>

        {/* 스토어 코드 */}
        <List.Row $ratio={paymentColumn.column[2].ratio}>
          <p>{data.ssCd}</p>
        </List.Row>

        {/* 결제에서 사용한 스케치 */}
        <List.Row $ratio={paymentColumn.column[3].ratio}>
          <p>{costFormat(data.paymentAmount)}</p>
        </List.Row>

        {/* 결제 취소 금액 */}
        <List.Row $ratio={paymentColumn.column[4].ratio}>
          <p>{costFormat(data.cancellationAmount)}</p>
        </List.Row>

        {/* 환불 해줄 금액 */}
        <List.Row $ratio={paymentColumn.column[5].ratio}>
          <p>{costFormat(data.refundAmount)}</p>
        </List.Row>

        {/* 시세(원) */}
        <List.Row $ratio={paymentColumn.column[6].ratio}>
          {data.exchangeRateKrw}
        </List.Row>

        {/* 상태 */}
        <List.Row $ratio={paymentColumn.column[7].ratio}>
          <List.RowElement>
            <p>{data.typeId}</p>
            {data.cancelAll && <p>({data.cancelAll})</p>}
          </List.RowElement>
        </List.Row>

        {/* txId */}
        <List.Row $ratio={paymentColumn.column[8].ratio}>
          {NavTxId(data.txId)}
        </List.Row>
      </Info.Content>
    </>
  );
};

export default PaymentByUserDetail;
