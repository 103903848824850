import React from "react";
import { Default } from "./ListDefault.style";

export const ListDefault = ({ text }) => {
  return (
    <Default.Wrapper>
      <Default.SearchIcon src="/assets/imgs/searchIcon.png" />
      <Default.Text>
        {text} <Default.BlueText>조회</Default.BlueText>해 주세요
      </Default.Text>
    </Default.Wrapper>
  );
};
