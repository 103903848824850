import { FONT_WEIGHT } from "constants/baseStyle";
import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ModifyMemo = {
  Top: styled.div`
    display: flex;
    justify-content: space-between;
  `,

  Title: styled.div`
    font-size: 1.4rem;
    font-weight: ${FONT_WEIGHT.BOLD};
  `,

  SaveBtn: styled.div`
    width: 3rem;
    height: 1.8rem;
    flex-shrink: 0;
    border-radius: 0.4rem;
    background: #4253f2;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,

  Container: styled.div`
    width: 100%;
    min-height: 17rem;
    border-radius: 4px;
    border: 0.1rem solid #eee;
    background: #fff;
  `,

  Category: styled.div`
    height: 3.5rem;
    border-bottom: 0.1rem solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  `,

  Select: styled.select`
    width: 15rem;
    height: 2.2rem;
    font-size: 1.5rem;
  `,

  Memo: styled.textarea`
    padding: 1rem;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    height: 10rem;
    font-size: 1.1rem;
    resize: none;
    border: none;
  `,
};
