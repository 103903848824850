import { atom } from "recoil";

export const modalState = atom({
  key: "swapModal",
  default: {
    isOpen: false,
    style: {},
    children: "",
  },
});
