import React, { useState } from "react";
import { Wrapper } from "./PaymentPay.style";
import PaymentPaySearch from "./PaymentPaySearch/PaymentPaySearch";
import PaymentPaySummary from "./PaymentPaySummary/PaymentPaySummary";
import PaymentPayDetail from "./PaymentPayDetail/PaymentPayDetail";
import { PaginationBox } from "components/Pagination/PaginationBox";

// 결제 내역 :: (http://localhost:3000/super/payment/pay)
const PaymentPay = () => {
  const [paymentPayList, setPaymentPayList] = useState({
    summary: {},
    list: [],
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});
  const [isSearch, setIsSearch] = useState(false);

  return (
    <Wrapper>
      <div>
        <PaymentPaySearch
          setPaymentPayList={setPaymentPayList}
          setPage={setPage}
          currentPage={page}
          pageSize={size}
          setSearchData={setSearchData}
          searchData={searchData}
          setIsSearch={setIsSearch}
        />

        {paymentPayList.summary && (
          <PaymentPaySummary paymentPayList={paymentPayList.summary} />
        )}

        <PaymentPayDetail
          paymentPayList={paymentPayList}
          setPage={setPage}
          setSize={setSize}
          searchData={searchData}
          isSearch={isSearch}
        />

        {isSearch && paymentPayList.list && (
          <PaginationBox
            totalItemsCount={paymentPayList.list[0]?.cnt}
            page={page}
            setPage={setPage}
            size={size}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default PaymentPay;
