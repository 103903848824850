import { useEffect, useState } from "react";
import {
  BottomBtn,
  BottomWrapper,
  ContentWrapper,
  Name,
  Rectangle,
  SelectWrapper,
  TableWrapper,
  Title,
  Wrapper,
  SelectBoxWrapper,
  ContentBox,
  CheckboxWrapper,
  SelectTopWrapper,
  CheckboxDiv,
  CheckboxTitle,
} from "./style";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import { POST_FAQ_CREATE, POST_FAQ_MODIFY } from "constants/pathConstant";
import { axiosInstance } from "api/api";
import { useMutation } from "@tanstack/react-query";
import { useToastCustom } from "hooks/useToastCustom";
import domainColumn from "../faqDomainColumn.json";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftjsToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FaqModal } from "components/Modal/FaqModal";

// 고객 지원 -> FAQ 관리 :: FAQ 등록 (http://localhost:3000/super/create/faq-upload)
const FaqUpload = ({ modifyData }) => {
  const nav = useNavigationCustom();
  const toast = useToastCustom();

  // 기본 upload state
  const [useYn, setUseYn] = useState("Y");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateKr, setEditorStateKr] = useState(EditorState.createEmpty());
  const [question_kr, setQuestion_kr] = useState("");
  const [question_eng, setQuestion_eng] = useState("");
  const [answer_kr, setAnswer_kr] = useState("");
  const [answer_eng, setAnswer_eng] = useState("");
  const [reqType, setReqType] = useState("00");
  const [categoryCd, setCategoryCd] = useState("W001");
  const [checked, setChecked] = useState([]);

  const [isInitialized, setIsInitialized] = useState(false);

  // 수정시 추가 state
  const [id, setId] = useState("");

  // 모달 state
  const [isOpen, setIsOpen] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const previewHandler = () => {
    setIsOpen((prev) => !prev);
    setPreviewData({ question_kr, question_eng, answer_kr, answer_eng });
  };

  useEffect(() => {
    setIsInitialized(true);
    if (!modifyData) return;

    setId(modifyData.id);
    setQuestion_kr(modifyData.question_kr);
    setQuestion_eng(modifyData.question_eng);
    setAnswer_kr(modifyData.answer_kr);
    setAnswer_eng(modifyData.answer_eng);
    setReqType(modifyData.reqType);
    setCategoryCd(modifyData.categoryCd);

    // 영어 contents 기본값 설정
    const blocksFromHtml = htmlToDraft(modifyData.answer_eng);
    if (blocksFromHtml) {
      const { contentBlocks, entityMap } = blocksFromHtml;
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(contentBlocks, entityMap)
        )
      );
    }

    // 한국어 contents 기본값 설정
    const blocksFromHtmlKr = htmlToDraft(modifyData.answer_kr);
    if (blocksFromHtmlKr) {
      const { contentBlocks, entityMap } = blocksFromHtmlKr;
      setEditorStateKr(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(contentBlocks, entityMap)
        )
      );
    }
  }, []);

  const { mutate } = useMutation(
    (data) => axiosInstance.post(POST_FAQ_CREATE, data),
    {
      onSuccess: () => {
        toast("정상적으로 등록되었습니다.", "success");
        nav("/super/create/faq");
      },
    }
  );

  const { mutate: updateMutate } = useMutation(
    (data) => axiosInstance.post(POST_FAQ_MODIFY, data),
    {
      onSuccess: () => {
        toast("정상적으로 수정되었습니다.", "success");
        nav("/super/create/faq");
      },
    }
  );

  const updateTextDescription = async (state, isKorean) => {
    // 에디터에 글쓰기 위한 state
    isKorean ? setEditorStateKr(state) : setEditorState(state);

    let html = draftjsToHtml(
      convertToRaw(
        isKorean
          ? editorStateKr.getCurrentContent()
          : editorState.getCurrentContent()
      )
    );

    // 불완전한 <p></p> 태그 수정
    html = html.replace(/<p><\/p>/g, "<br />");

    // html 기본 폰트사이즈 1rem, px을 rem으로 바꾸는 정규식
    let matchWord = html.match(/font-size: \d{1,2}px;/gi);
    if (matchWord) {
      matchWord = matchWord.map((font) => {
        const int = font.match(/\d{1,2}/g);
        return `font-size: ${int / 10}rem`;
      });

      html = html.replace(/font-size: \d{1,2}px;/gi, matchWord);
    }
    // ---------------------이슈 : &nbsp; 가 임의로 사라져버려서 띄어쓰기는 연속 2번이상 불가
    isKorean ? setAnswer_kr(html) : setAnswer_eng(html);
  };

  const uploadHandler = async () => {
    if (!question_kr || !question_eng || !answer_kr || !answer_eng) {
      return toast("빠진 내용 없이 다 채워주세요", "error");
    }

    const data = {
      categoryCd: modifyData ? categoryCd : checked,
      question_kr,
      question_eng,
      answer_kr,
      answer_eng,
      linkUrl: "",
      useYn: modifyData && useYn,
      id: modifyData && id,
    };

    setPreviewData(data);

    if (modifyData) {
      updateMutate(data);
    } else {
      mutate(data);
    }
  };

  if (!isInitialized) return null;

  const checkboxHandler = (e, id) => {
    if (e.target.checked) {
      setChecked((prev) => [...prev, id]);
    } else {
      setChecked((prev) => prev.filter((el) => el !== id));
    }
  };

  return (
    <Wrapper>
      <Name>FAQ</Name>
      <TableWrapper>
        <span>
          <Rectangle />
          {modifyData ? "작성하기" : "앱 구분"}
        </span>
        <div>
          {modifyData ? (
            <SelectTopWrapper>
              <SelectBoxWrapper>
                <div>앱 구분</div>
                <SelectWrapper>
                  <select
                    onChange={(e) => setReqType(e.target.value)}
                    defaultValue={modifyData?.reqType || reqType}
                    key={modifyData?.reqType || reqType}
                  >
                    {Object.entries(domainColumn.reqType).map(
                      ([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      )
                    )}
                  </select>
                </SelectWrapper>
              </SelectBoxWrapper>
              <SelectBoxWrapper>
                <div>카테고리</div>
                <SelectWrapper>
                  <select
                    onChange={(e) => setCategoryCd(e.target.value)}
                    defaultValue={modifyData?.categoryCd || categoryCd}
                    key={modifyData?.categoryCd || categoryCd}
                  >
                    {Object.entries(domainColumn.categoryCd[reqType]).map(
                      ([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      )
                    )}
                  </select>
                </SelectWrapper>
              </SelectBoxWrapper>
            </SelectTopWrapper>
          ) : (
            <CheckboxWrapper>
              {/* 구분 */}
              {Object.entries(domainColumn.categoryCd)
                .slice(1)
                .map(([key, value]) => (
                  <div key={key} value={key}>
                    <CheckboxTitle>{domainColumn.reqType[key]}</CheckboxTitle>

                    {/* 구분 별 카테고리 */}
                    {Object.entries(value).map(([subKey, subValue]) => (
                      <CheckboxDiv key={subKey} value={subKey}>
                        <input
                          type="checkbox"
                          onChange={(e) => checkboxHandler(e, subKey)}
                        />
                        <p>{subValue}</p>
                      </CheckboxDiv>
                    ))}
                  </div>
                ))}
            </CheckboxWrapper>
          )}

          {modifyData && (
            <SelectBoxWrapper>
              <div>공개 여부</div>
              <SelectWrapper>
                <select
                  onChange={(e) => setUseYn(e.target.value)}
                  defaultValue={modifyData?.useYn || useYn}
                  key={modifyData?.useYn || useYn}
                >
                  <option value={"Y"}>공개</option>
                  <option value={"N"}>비공개</option>
                </select>
              </SelectWrapper>
            </SelectBoxWrapper>
          )}
        </div>
      </TableWrapper>

      <ContentBox>
        <ContentWrapper>
          <span>
            <Rectangle />
            내용(한국어)
          </span>
          <div>
            <Title
              onChange={(e) => setQuestion_kr(e.target.value)}
              defaultValue={modifyData?.question_kr || ""}
              placeholder="제목을 입력해주세요"
            />
          </div>
          <Editor
            placeholder="내용을 작성해주세요"
            editorState={editorStateKr}
            onEditorStateChange={(state) => updateTextDescription(state, true)}
            localization={{ locale: "ko" }}
            editorStyle={{
              height: "64rem",
              width: "55.8rem",
              border: "3px solid lightgray",
              padding: "20px",
              fontSize: "1.6rem",
              background: "#fff",
            }}
            toolbar={{
              // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
            }}
          />
        </ContentWrapper>
        <ContentWrapper>
          <span>
            <Rectangle />
            내용(영어)
          </span>
          <div>
            <Title
              onChange={(e) => setQuestion_eng(e.target.value)}
              defaultValue={modifyData?.question_eng || ""}
              placeholder="제목을 입력해주세요"
            />
          </div>
          <Editor
            placeholder="내용을 작성해주세요"
            editorState={editorState}
            onEditorStateChange={(state) => updateTextDescription(state, false)}
            localization={{ locale: "ko" }}
            editorStyle={{
              height: "64rem",
              width: "55.8rem",
              border: "3px solid lightgray",
              padding: "20px",
              fontSize: "1.6rem",
              background: "#fff",
            }}
            toolbar={{
              // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
            }}
          />
        </ContentWrapper>
      </ContentBox>

      <BottomWrapper>
        <div>
          <BottomBtn onClick={() => nav("/super/create/faq")}>목록</BottomBtn>
        </div>
        <div>
          <BottomBtn
            onClick={previewHandler}
            bgColor={"#2db9c2"}
            fontColor={"white"}
          >
            미리보기
          </BottomBtn>
          <BottomBtn
            onClick={uploadHandler}
            bgColor={"#4253f2"}
            fontColor={"white"}
          >
            저장
          </BottomBtn>
        </div>
      </BottomWrapper>

      {isOpen && <FaqModal data={previewData} setIsOpen={setIsOpen} />}
    </Wrapper>
  );
};

export default FaqUpload;
