import { Loading } from "components/Loading/Loading";
// import { HTTP_METHOD, POST_MODIFY_USER_DETAIL } from "constants/pathConstant";
// import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useRecoilState } from "recoil";
import { UserState } from "recoil/atom/UserState";
import { Container, Info, Wrapper } from "./UserInfo.style";
import { useCopyCustom } from "hooks/useCopyCustom";
import { dateFormat } from "components/dateFormat";

// 회원검색 (검증 후 회원 정보 바로가기) :: 회원정보 (http://localhost:3000/manage/user/:code)
export const UserInfo = ({ isLoading }) => {
  const toast = useToastCustom();
  const copyToClipBoardHandler = useCopyCustom();

  const [id, setId] = useState("");
  const [modifyMode, setModifyMode] = useState(false);
  const [acctUserNm, setAcctUserNm] = useState("");
  const [acctCd, setAcctCd] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [email, setEmail] = useState("");

  const [userDetailData, setUserDetailData] = useRecoilState(UserState.userDetail);

  useEffect(() => {
    setId(userDetailData.id);
    setAcctCd(userDetailData.acctCd);
    setAcctUserNm(userDetailData.acctUserNm);
    setEmail(userDetailData.email);
    setCellNumber(userDetailData.cellNumber);
  }, [userDetailData]);

  // const cancelModify = () => {
  //   if (!window.confirm("수정을 취소하시겠습니까?")) return;

  //   setAcctUserNm(userDetailData.acctUserNm);
  //   setEmail(userDetailData.email);
  //   setCellNumber(userDetailData.cellNumber);
  //   setModifyMode(false);
  // };

  // const modifyUserInfoHandler = () => {
  //   if (!window.confirm("수정하시겠습니까?")) return;
  //   setModifyMode(false);

  //   userModifyMutation();
  // };

  // 정보 수정 API
  // const { mutate } = useMutationCustom(POST_MODIFY_USER_DETAIL, HTTP_METHOD.POST);

  // // const userModifyMutation = () => {
  // //   const data = {
  // //     id,
  // //     acctCd,
  // //     acctUserNm,
  // //     cellNumber,
  // //     email,
  // //   };

  // //   mutate(data, {
  // //     onSuccess: (response) => {
  // //       setUserDetailData(response);
  // //       toast("유저 정보 수정 성공", "success");
  // //       setModifyMode(false);
  // //     },

  // //     onError: () => {
  // //       toast("유저 정보 수정 실패", "error");
  // //     },
  // //   });
  // // };

  if (isLoading) return <Loading />;

  return (
    <Wrapper>
      <Container.Top>
        <Container.Text>회원정보</Container.Text>
        {/* <Container.ModifyBtnWrapper>
          {modifyMode ? (
            <>
              <Container.ModifyBtn onClick={() => modifyUserInfoHandler()} $bg={"#4253F2"}>
                저장
              </Container.ModifyBtn>
              <Container.ModifyBtn onClick={cancelModify} $bg={"#888"}>
                취소
              </Container.ModifyBtn>
            </>
          ) : (
            <Container.ModifyBtn onClick={() => setModifyMode(!modifyMode)} $bg={"#888"}>
              수정
            </Container.ModifyBtn>
          )}
        </Container.ModifyBtnWrapper> */}
      </Container.Top>

      <Container.Content>
        <Info.Wrapper>
          {/* 회원명 */}
          <Info.Title>회원명</Info.Title>
          <Info.ModifyWrapper>
            <Info.ContentInput
              value={acctUserNm || ""}
              readOnly={!modifyMode}
              $modify={modifyMode}
              onChange={(e) => setAcctUserNm(e.target.value)}
            />
          </Info.ModifyWrapper>
        </Info.Wrapper>

        {/* 회원코드 */}
        <Info.Wrapper>
          <Info.Title>회원코드</Info.Title>
          <Info.Content>{userDetailData.acctCd}</Info.Content>
        </Info.Wrapper>

        <Info.Wrapper>
          {/* 추천인명 */}
          <Info.Title>추천인명</Info.Title>
          <Info.Content>{userDetailData.recToOtherUserNm}</Info.Content>
        </Info.Wrapper>

        <Info.Wrapper>
          {/* 추천인코드 */}
          <Info.Title>추천인코드</Info.Title>
          <Info.ModifyWrapper>
            <Info.Content>{userDetailData.refererCd}</Info.Content>
          </Info.ModifyWrapper>
        </Info.Wrapper>

        <Info.Wrapper>
          {/* 휴대전화 */}
          <Info.Title>휴대전화</Info.Title>
          <Info.ModifyWrapper>
            <Info.ContentInput
              value={cellNumber || ""}
              readOnly={!modifyMode}
              $modify={modifyMode}
              onChange={(e) => setCellNumber(e.target.value)}
            />
            <Info.ModifyBtn data-tooltip-id="cellNumberTooltip" $modified={userDetailData.cellNumberChanged}>
              수정됨
            </Info.ModifyBtn>
            <ReactTooltip id="cellNumberTooltip" place="top" content={userDetailData.cellNumberChangedDt} />
          </Info.ModifyWrapper>
        </Info.Wrapper>

        <Info.Wrapper>
          {/* 생년월일 */}
          <Info.Title>생년월일</Info.Title>
          <Info.Content>{userDetailData.birthDay}</Info.Content>
        </Info.Wrapper>

        <Info.Wrapper>
          {/* 이메일 */}
          <Info.Title>이메일</Info.Title>
          <Info.ModifyWrapper>
            <Info.ContentInput
              value={email || ""}
              readOnly={!modifyMode}
              $modify={modifyMode}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Info.ModifyWrapper>
        </Info.Wrapper>

        <Info.Wrapper>
          {/* 회원가입일자 */}
          <Info.Title>회원가입일자</Info.Title>
          <Info.Content>{String(userDetailData.crtDt).slice(0, 16)}</Info.Content>
        </Info.Wrapper>

        <Info.Wrapper>
          {/* 지갑주소 */}
          <Info.Title>SEKTCH 지갑주소</Info.Title>
          <Info.Content
            style={{ cursor: "pointer" }}
            onClick={() => {
              copyToClipBoardHandler(userDetailData.walletDefault);
            }}
          >
            {userDetailData.walletDefault &&
              userDetailData.walletDefault.slice(0, 8) + "...." + userDetailData.walletDefault.slice(-8)}
          </Info.Content>
        </Info.Wrapper>

        <Info.Wrapper>
          {/* 추천인 등록일자 */}
          <Info.Title>추천인 등록일자</Info.Title>
          <Info.Content>{dateFormat(userDetailData.refererDt)}</Info.Content>
        </Info.Wrapper>
      </Container.Content>
      {/* 인증완료 서류 들어갈곳 */}
      {/* <Container.Doc></Container.Doc> */}
    </Wrapper>
  );
};
