import { ModalContaier, ModalWrapper } from "./style";

export const VersionModal = ({ data, setIsOpen }) => {
  return (
    <>
      <ModalWrapper onClick={() => setIsOpen((prev) => !prev)} />
      <ModalContaier>
        <div>
          <p>메세지</p>
          <div dangerouslySetInnerHTML={{ __html: data.message }}></div>
        </div>
        <div>
          <p>message</p>
          <div dangerouslySetInnerHTML={{ __html: data.messageEng }}></div>
        </div>
        {data.serverUpdateMessage && (
          <div>
            <p>서버 메세지</p>
            <div
              dangerouslySetInnerHTML={{ __html: data.serverUpdateMessage }}
            ></div>
          </div>
        )}
        {data.serverUpdateMessageEng && (
          <div>
            <p>server message</p>
            <div
              dangerouslySetInnerHTML={{ __html: data.serverUpdateMessageEng }}
            ></div>
          </div>
        )}
      </ModalContaier>
    </>
  );
};
