import { dateFormat } from "components/dateFormat";
import { DropUserDetailRow, ImgBox } from "../DropUser.style";
import dropUserColumn from "../dropUserColumn.json";
import { useState } from "react";
import MemoModal from "components/Modal/MemoModal/MemoModal";
import { useMutationCustom } from "hooks/useMutationCustom";
import {
  HTTP_METHOD,
  IMG_CDN_URL,
  POST_MEMO_MODIFY,
} from "constants/pathConstant";

// 검색 -> 탈퇴 회원관리 :: 회원 목록 낱개 클릭 (http://localhost:3000/super/search/gone-user)
const DropUserClickDetail = ({ data, idx, updateMutate, setHistory }) => {
  const [isMemoOpen, setIsMemoOpen] = useState(false);

  const { mutate: mutateUpdateMemo } = useMutationCustom(
    POST_MEMO_MODIFY,
    HTTP_METHOD.POST
  );

  return (
    <DropUserDetailRow.Data>
      {/* 번호 */}
      <DropUserDetailRow.Row $ratio={dropUserColumn.detailData[0].ratio}>
        {idx + 1}
      </DropUserDetailRow.Row>

      {/* 회원명 */}
      <DropUserDetailRow.Row $ratio={dropUserColumn.detailData[1].ratio}>
        {data.acctUserNm}
      </DropUserDetailRow.Row>

      {/* 회원코드 */}
      <DropUserDetailRow.Row $ratio={dropUserColumn.detailData[2].ratio}>
        {data.acctCd}
      </DropUserDetailRow.Row>

      {/* 탈퇴신청일자 */}
      <DropUserDetailRow.Row $ratio={dropUserColumn.detailData[3].ratio}>
        {dateFormat(data.reqQuitDt)}
      </DropUserDetailRow.Row>

      {/* 처리일자 */}
      <DropUserDetailRow.Row $ratio={dropUserColumn.detailData[4].ratio}>
        {data.quitDt
          ? dateFormat(data.quitDt)
          : data.restoreDt
          ? dateFormat(data.restoreDt)
          : ""}
      </DropUserDetailRow.Row>

      {/* 상태 */}
      <DropUserDetailRow.Row $ratio={dropUserColumn.detailData[5].ratio}>
        {dropUserColumn.state[data.state]}
      </DropUserDetailRow.Row>

      {/* 메모 */}
      <DropUserDetailRow.Row
        $ratio={dropUserColumn.detailData[6].ratio}
        onClick={(e) => {
          e.stopPropagation();
          setIsMemoOpen((prev) => !prev);
        }}
      >
        <ImgBox>
          {data.acctQuitMemo ? (
            <img src={`${IMG_CDN_URL}/file/2da722b5memoY.png`} alt="memoImg" />
          ) : (
            <img src={`${IMG_CDN_URL}/file/8d24dcc4memo.png`} alt="memoImg" />
          )}
        </ImgBox>
        {isMemoOpen && (
          <MemoModal
            setIsOpen={setIsMemoOpen}
            memoData={{
              acctCd: data.acctCd,
              id: data.id,
              memo: data.acctQuitMemo,
              admId: data.memoAdmId || data.admId,
              updatedAt: data.memoUpdatedAt,
            }}
            mutateUpdateData={updateMutate}
            mutateUpdateMemo={mutateUpdateMemo}
            setUpdateMemoList={setHistory}
            searchData={{ acctCd: data.acctCd }}
          />
        )}
      </DropUserDetailRow.Row>
    </DropUserDetailRow.Data>
  );
};

export default DropUserClickDetail;
