import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { ListDefault } from "components/ListDefault/ListDefault";
import { POST_WALLET_USER_BASIC_FETCH } from "constants/pathConstant";
import { WALLET_USER_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import { useMutationCustom } from "hooks/useMutationCustom";
import { Info, List, Wrapper } from "template/ListTemplate.style";
import userColumn from "./userColumn.json";
import PageHandler from "components/PageHandler/PageHandler";
import { useToastCustom } from "hooks/useToastCustom";
import WalletUserDetailList from "./WalletUserDetailList";

// 회원검색 :: 회원 목록 리스트 (http://localhost:3000/manage/user/wallet)
const WalletUserDetail = ({ setPage, setSize, userList, searchData }) => {
  const excelDownload = useExcelDownload();
  const toast = useToastCustom();

  const { mutate } = useMutationCustom(POST_WALLET_USER_BASIC_FETCH);

  const handleExcelDownload = () => {
    if (!userList[0]?.cnt) return toast("액셀 다운로드 실패", "error");

    const data = {
      ...searchData,
      pageSize: Number(userList[0]?.cnt),
      currentPage: 1,
    };

    mutate(data, {
      onSuccess: (response) => {
        const excelData = {
          data: response,
          headers: WALLET_USER_XLSX_DOWNLOAD.header,
          fileName: WALLET_USER_XLSX_DOWNLOAD.filename,
        };

        excelDownload(excelData);
      },

      onError: () => {
        toast("전체 데이터 조회 실패", "error");
      },
    });
  };

  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>
          월렛 회원 목록
          {userList.length > 0 && <span>(검색됨 : {userList[0]?.cnt}개)</span>}
        </List.Text>

        <List.BtnContainer>
          <PageHandler setPage={setPage} setPageSize={setSize} />
          <ExcelDownloadBtn onClick={handleExcelDownload} />
        </List.BtnContainer>
      </List.TitleContainer>

      <List.Container>
        {userList.length === 0 ? (
          <ListDefault text={"회원을"} />
        ) : (
          <>
            <List.Content>
              {userColumn.data.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>

            <Info.Wrapper>
              {userList?.map((v, idx) => (
                <WalletUserDetailList data={v} key={idx} />
              ))}
            </Info.Wrapper>
          </>
        )}
      </List.Container>
    </Wrapper>
  );
};

export default WalletUserDetail;
