import SearchWalletUserModal from "components/Modal/SearchWalletUserModal";
import { PLACEHOLDER } from "constants/baseStyle";
import { HTTP_METHOD, POST_SKETCH_DROP_FETCH } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useEffect, useState } from "react";
import { Search, Wrapper } from "template/SearchTemplate.style";

// 정산 관리 -> 스케치 드랍 :: 스케치 드랍 조회  (http://localhost:3000/super/calculate/sketch-drop)
const SketchDropSearch = ({
  setSketchDropRes,
  setPage,
  currentPage,
  setSearchReq,
  searchReq,
  setIsSearch,
  userData,
  setUserData,
  setSketchDropDetailRes,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  console.log(userData);

  useEffect(() => {
    if (!Object.keys(searchReq)[0]) return;
    const researchData = { ...searchReq, currentPage };
    setSearchReq(researchData);

    mutate(researchData, {
      onSuccess: (data) => {
        setSketchDropRes(data);
      },
    });
  }, [currentPage]);

  useEffect(() => {
    fetchData();
  }, []);

  const { mutate } = useMutationCustom(
    POST_SKETCH_DROP_FETCH,
    HTTP_METHOD.POST
  );

  const fetchData = () => {
    const data = {
      acctUserNm: userData.memberName || "",
      acctCd: userData.memberCode || "",
      pageSize: 6,
      currentPage,
    };

    setSearchReq(data);

    mutate(data, {
      onSuccess: (response) => {
        setSketchDropRes(response);
      },
    });
  };

  const handleSearch = () => {
    setPage(1);
    setIsSearch(true);
    fetchData();
    setSketchDropDetailRes([]);
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  return (
    <Wrapper>
      <Search.Text>
        <span>스케치 드랍 조회</span>
      </Search.Text>
      <Search.Container style={{ gap: "19rem" }}>
        <Search.Content style={{ width: "100%" }}>
          {/* 회원명 */}
          <Search.InputWrapper
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            <div>회원명</div>
            <Search.Input
              style={{ cursor: "pointer" }}
              placeholder={PLACEHOLDER.CLICK}
              onKeyUp={(e) => handleEnter(e.key)}
              readOnly
              value={userData.memberName}
            />
          </Search.InputWrapper>

          {/* 회원코드 */}
          <Search.InputWrapper>
            <div>회원코드</div>
            <Search.Input
              placeholder={PLACEHOLDER.AUTO}
              onKeyUp={(e) => handleEnter(e.key)}
              disabled
              value={userData.memberCode}
            />
          </Search.InputWrapper>
        </Search.Content>

        {/* 조회 버튼 */}
        <Search.Btn onClick={handleSearch}>조회</Search.Btn>
      </Search.Container>

      {isOpen && (
        <SearchWalletUserModal
          setIsOpen={setIsOpen}
          setUserData={setUserData}
        />
      )}
    </Wrapper>
  );
};

export default SketchDropSearch;
