import React, { useEffect, useState } from "react";
import { EmptyList, List, Schedule, Wrapper } from "./ScheduleList.style";
import { ScheduleModal } from "components/Modal/ScheduleModal/ScheduleModal";
import { useMutationCustom } from "hooks/useMutationCustom";
import {
  HTTP_METHOD,
  IMG_CDN_URL,
  POST_MAIN_SCHEDULE_FETCH,
} from "constants/pathConstant";
import { useToastCustom } from "hooks/useToastCustom";
import { dateFormat } from "components/dateFormat";
import { ScheduleDetail } from "./ScheduleDetail";

export const ScheduleList = ({ date, setCalendarCountObj }) => {
  const [openModal, setOpenModal] = useState(false);
  const [scheduleList, setScheduleList] = useState([]);

  const toast = useToastCustom();

  const openModalHandler = () => {
    setOpenModal(true);
  };

  const { mutate } = useMutationCustom(
    POST_MAIN_SCHEDULE_FETCH,
    HTTP_METHOD.POST
  );

  useEffect(() => {
    const data = {
      fromDt: dateFormat(date, true),
      toDt: dateFormat(date, true, 1),
    };
    mutate(data, {
      onSuccess: (response) => {
        setScheduleList(response);
      },
      onError: () => {
        toast("일정 조회에 실패하였습니다", "error");
      },
    });
  }, [date]);

  const renderScheduleDetails = ({ shareYn }) => {
    return scheduleList
      .filter((schedule) => schedule.shareYn === shareYn)
      .map((schedule) => (
        <ScheduleDetail
          key={schedule.id}
          data={schedule}
          setScheduleList={setScheduleList}
          scheduleList={scheduleList}
          date={date}
          setCalendarCountObj={setCalendarCountObj}
        />
      ));
  };

  return (
    <Wrapper>
      <Schedule.Title>Schedule</Schedule.Title>
      <Schedule.AddWrapper>
        <Schedule.DateWrapper>
          {date.getFullYear()}년 {date.getMonth() + 1}월 {date.getDate()}일
        </Schedule.DateWrapper>
        <Schedule.AddBtn
          src={`${IMG_CDN_URL}/file/8681b718add_calendar_btn.svg`}
          onClick={() => openModalHandler(date)}
        />

        {openModal && (
          <ScheduleModal
            setOpenModal={setOpenModal}
            date={date}
            setScheduleList={setScheduleList}
            setCalendarCountObj={setCalendarCountObj}
          />
        )}
      </Schedule.AddWrapper>

      <List.Wrapper>
        {/* 공지사항 */}
        {renderScheduleDetails({ shareYn: "Y" })}
        {/* 일반 메모 */}
        {renderScheduleDetails({ shareYn: "N" })}
        {scheduleList.length === 0 && (
          <EmptyList.Wrapper>
            <EmptyList.Container>
              <img
                src={`${IMG_CDN_URL}/file/2126ead8calendar.svg`}
                alt="empty_schedule"
              />
              <div>등록된 일정이 없습니다</div>
            </EmptyList.Container>
          </EmptyList.Wrapper>
        )}
      </List.Wrapper>
    </Wrapper>
  );
};
