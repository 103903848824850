import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { Info, List, Wrapper } from "template/ListTemplate.style";
import sketchDropColumn from "../../sketchDropColumn.json";
import { ListDefault } from "components/ListDefault/ListDefault";
import SketchDropSelectList from "./SketchDropSelectList";
import { PaginationBox } from "components/Pagination/PaginationBox";
import { useToastCustom } from "hooks/useToastCustom";
import { useMutationCustom } from "hooks/useMutationCustom";
import { HTTP_METHOD, POST_SKETCH_DROP_FETCH } from "constants/pathConstant";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import { SKETCH_DROP_SELECT_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";

// 정산 관리 -> 스케치 드랍 :: 연월별 드랍 목록  (http://localhost:3000/super/calculate/sketch-drop)
const SketchDropSelect = ({
  isSearch,
  data,
  setSketchDropDetailRes,
  userData,
  page,
  setPage,
  detailPage,
  setDetailPage,
  searchDetailReq,
  setSearchDetailReq,
  searchReq,
  detailSize,
  sortOption,
}) => {
  const toast = useToastCustom();
  const excelDownload = useExcelDownload();

  const { mutate } = useMutationCustom(
    POST_SKETCH_DROP_FETCH,
    HTTP_METHOD.POST
  );

  const handleExcelDownload = () => {
    if (!isSearch || !data[0]?.totalCnt)
      return toast("다운로드할 데이터가 없습니다", "error");

    const mutateData = {
      ...searchReq,
      pageSize: data[0]?.totalCnt,
      currentPage: 1,
    };
    mutate(mutateData, {
      onSuccess: (response) => {
        const excelData = {
          data: response.list,
          headers: SKETCH_DROP_SELECT_XLSX_DOWNLOAD.header,
          fileName: SKETCH_DROP_SELECT_XLSX_DOWNLOAD.filename,
        };
        excelDownload(excelData);
      },
      onError: () => {
        toast("전체 데이터 조회 실패", "error");
      },
    });
  };

  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>연월별 드랍 목록</List.Text>
        <List.BtnContainer>
          {/* 엑셀 다운로드 버튼 */}
          <ExcelDownloadBtn onClick={handleExcelDownload} />
        </List.BtnContainer>
      </List.TitleContainer>

      <List.Container>
        {!isSearch || data.length === 0 ? (
          <ListDefault text={"연월별 목록을"} />
        ) : (
          <>
            <List.Content>
              {sketchDropColumn.select.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>

            <Info.Wrapper style={{ cursor: "pointer" }}>
              {isSearch &&
                data.map((v, idx) => (
                  <SketchDropSelectList
                    key={idx}
                    data={v}
                    setSketchDropDetailRes={setSketchDropDetailRes}
                    userData={userData}
                    detailPage={detailPage}
                    setDetailPage={setDetailPage}
                    searchDetailReq={searchDetailReq}
                    setSearchDetailReq={setSearchDetailReq}
                    detailSize={detailSize}
                    sortOption={sortOption}
                  />
                ))}
            </Info.Wrapper>
          </>
        )}
      </List.Container>

      {isSearch && data.length !== 0 && (
        <PaginationBox
          totalItemsCount={data[0]?.totalCnt}
          page={page}
          setPage={setPage}
          size={6}
        />
      )}
    </Wrapper>
  );
};

export default SketchDropSelect;
