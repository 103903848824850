import { Info, List } from "template/ListTemplate.style";
import faqColumn from "../faqColumn.json";
import { CheckBox } from "../style";
import { useNavigate } from "react-router";
import { dateFormat } from "components/dateFormat";
import domainColumn from "../../faqDomainColumn.json";

// 고객 지원 -> FAQ 관리 :: FAQ 리스트 낱개 (http://localhost:3000/super/create/faq)
const FaqDetail = ({ data, checkHandler, checked, setIsOpen, setId }) => {
  const nav = useNavigate();

  const openHandelr = (id) => {
    setIsOpen((prev) => !prev);
    setId(id);
  };

  return (
    <>
      <Info.Content>
        {/* 체크박스 */}
        <List.Row>
          <CheckBox
            type="checkbox"
            $ratio={1}
            onChange={(e) => checkHandler(e, data.id)}
            checked={checked.includes(data.id)}
          />
        </List.Row>

        {/* 번호 */}
        <List.Row $ratio={faqColumn.data[0].ratio}>{data.id}</List.Row>

        {/* 구분 */}
        <List.Row $ratio={faqColumn.data[1].ratio}>
          {domainColumn.reqType[data.reqType]}
        </List.Row>

        {/* 카테고리 */}
        <List.Row $ratio={faqColumn.data[2].ratio}>
          {data.categoryNm_kr}
        </List.Row>

        {/* 제목 */}
        <List.Row
          $ratio={faqColumn.data[3].ratio}
          style={{ cursor: "pointer" }}
          onClick={() => openHandelr(data.id)}
        >
          {data.question_kr}
        </List.Row>

        {/* 작성자 */}
        <List.Row $ratio={faqColumn.data[4].ratio}>{data.crtId}</List.Row>

        {/* 게시일 */}
        <List.Row $ratio={faqColumn.data[5].ratio}>
          {dateFormat(data.crtDt).slice(0, 16)}
        </List.Row>

        {/* 게시 여부 */}
        <List.Row $ratio={faqColumn.data[6].ratio}>{data.useYn}</List.Row>

        {/* 마지막 수정인 */}
        <List.Row $ratio={faqColumn.data[7].ratio}>
          {data.updId || data.crtId}
        </List.Row>

        {/* 마지막 수정일 */}
        <List.Row $ratio={faqColumn.data[8].ratio}>
          {dateFormat(data.updDt || data.crtDt).slice(0, 16)}
        </List.Row>

        {/* 수정 버튼 */}
        <List.Row
          $ratio={faqColumn.data[9].ratio}
          style={{
            cursor: "pointer",
            background: "#ddd",
            padding: "1rem 0",
            borderRadius: "2rem",
          }}
          onClick={() => {
            nav(`/super/create/faq-modify`, { state: { data } });
          }}
        >
          수정
        </List.Row>
      </Info.Content>
    </>
  );
};

export default FaqDetail;
