import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { ListDefault } from "components/ListDefault/ListDefault";
import { HTTP_METHOD, POST_FIND_STORE_BASIC } from "constants/pathConstant";
import { STORE_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { Info, List, Wrapper } from "template/ListTemplate.style";
import { StoreInfoListDetail } from "./StoreInfoListDetail";
import storeColumn from "./storeColumn.json";
import PageHandler from "components/PageHandler/PageHandler";

// 가맹점 검색 :: 가맹점 목록 리스트 (http://localhost:3000/manage/store)
export const StoreInfoList = ({ setPage, setSize, storeList, searchData }) => {
  const toast = useToastCustom();
  const excelDownload = useExcelDownload();

  const { mutate } = useMutationCustom(POST_FIND_STORE_BASIC, HTTP_METHOD.POST);

  const handleExcelDownload = () => {
    if (storeList.length === 0) {
      toast("다운로드할 데이터가 없습니다.", "error");
      return;
    }

    const researchData = {
      ...searchData,
      pageSize: storeList[0]?.cnt,
      currentPage: 1,
    };

    mutate(researchData, {
      onSuccess: (response) => {
        const excelData = {
          data: response,
          headers: STORE_XLSX_DOWNLOAD.header,
          fileName: STORE_XLSX_DOWNLOAD.filename,
        };
        excelDownload(excelData);
      },

      onError: () => {
        toast("전체 데이터 조회 실패", "error");
      },
    });
  };

  return (
    <Wrapper style={{ width: "70%" }}>
      <List.TitleContainer>
        <List.Text>
          가맹점 목록
          {storeList[0] && <span>(검색됨 : {storeList[0]?.cnt}개 )</span>}
        </List.Text>
        <List.BtnContainer>
          {/* 페이지 핸들러 */}
          <PageHandler setPage={setPage} setPageSize={setSize} />
          {/* 엑셀 다운로드 버튼 */}
          <ExcelDownloadBtn onClick={handleExcelDownload} />
        </List.BtnContainer>
      </List.TitleContainer>

      <List.Container>
        {storeList.length === 0 ? (
          //  default page
          <ListDefault text={"가맹점을"} />
        ) : (
          <>
            {/* List Column */}
            <List.Content>
              {storeColumn.data.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>
            <Info.Wrapper>
              {storeList.map((v, idx) => (
                <StoreInfoListDetail key={idx} data={v} />
              ))}
            </Info.Wrapper>
          </>
        )}
      </List.Container>
    </Wrapper>
  );
};
