import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { ListDefault } from "components/ListDefault/ListDefault";
import { HTTP_METHOD, POST_FIND_USER_SUPER } from "constants/pathConstant";
import { SUPER_USER_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { Info, List, Wrapper } from "template/ListTemplate.style";
import { SuperUserInfoListDetail } from "./SuperUserInfoListDetail";
import userColumn from "./superUserColumn.json";
import PageHandler from "components/PageHandler/PageHandler";
import { xlsxDateFormat } from "components/xlsxDateFormat";

// 검색 -> 회원검색 :: 회원 목록 리스트 (http://localhost:3000/super/search/user)
export const SuperUserInfoList = ({
  setPage,
  setSize,
  superUserList,
  searchData,
}) => {
  const toast = useToastCustom();
  const excelDownload = useExcelDownload();

  const { mutate } = useMutationCustom(POST_FIND_USER_SUPER, HTTP_METHOD.POST);

  // Excel 다운로드
  const handleExcelDownload = () => {
    if (!superUserList[0]?.cnt)
      return toast("다운로드할 데이터가 없습니다", "error");

    const researchCond = {
      ...searchData,
      pageSize: superUserList[0]?.cnt,
      currentPage: 1,
    };

    mutate(researchCond, {
      onSuccess: (response) => {
        const updatedResponse = xlsxDateFormat(response);

        const excelData = {
          data: updatedResponse,
          headers: SUPER_USER_XLSX_DOWNLOAD.header,
          fileName: SUPER_USER_XLSX_DOWNLOAD.filename,
        };

        excelDownload(excelData);
      },

      onError: () => {
        toast("데이터 전체 조회 실패", "error");
      },
    });
  };

  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>
          회원 목록
          {superUserList[0] && (
            <span>(검색됨 : {superUserList[0]?.cnt}개)</span>
          )}
        </List.Text>
        <List.BtnContainer>
          {/* 페이지 핸들러 */}
          <PageHandler setPage={setPage} setPageSize={setSize} />
          {/* 엑셀 다운로드 버튼 */}
          <ExcelDownloadBtn onClick={handleExcelDownload} />
        </List.BtnContainer>
      </List.TitleContainer>

      <List.Container>
        {superUserList.length === 0 ? (
          <ListDefault text={"회원을"} />
        ) : (
          <>
            {/* List Column */}
            <List.Content>
              {userColumn.data.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>
            <Info.Wrapper>
              {superUserList.map((v) => (
                <SuperUserInfoListDetail
                  key={v.id}
                  id={v.id}
                  crtDt={v.crtDt}
                  acctUserNm={v.acctUserNm}
                  acctCd={v.acctCd}
                  refererNm={v.refererNm}
                  refererCd={v.refererCd}
                  cellNumber={v.cellNumber}
                  email={v.email}
                  walletDefault={v.walletDefault}
                  stateNm={v.stateNm}
                  state={v.state}
                  storeId={v.storeId}
                  birthDay={v.birthDay}
                />
              ))}
            </Info.Wrapper>
          </>
        )}
      </List.Container>
    </Wrapper>
  );
};
