import React, { useState } from "react";
import { Wrapper } from "./RewardSend.style";
import { PaginationBox } from "components/Pagination/PaginationBox";
import RewardSendSearch from "./RewardSendSearch/RewardSendSearch";
import RewardSendInfoList from "./RewardSendInfoList/RewardSendInfoList";

// 정산 -> 리워드 인출 :: (http://localhost:3000/super/calculate/reward-send)
const RewardSendList = () => {
  const [rewardSendList, setRewardSendList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});

  return (
    <Wrapper>
      <RewardSendSearch
        setRewardSendList={setRewardSendList}
        setPage={setPage}
        currentPage={page}
        pageSize={size}
        setSearchData={setSearchData}
        searchData={searchData}
      />
      <RewardSendInfoList
        setPage={setPage}
        setSize={setSize}
        searchData={searchData}
        rewardSendList={rewardSendList}
        setRewardSendList={setRewardSendList}
      />

      {rewardSendList.length !== 0 && (
        <PaginationBox
          totalItemsCount={rewardSendList[0]?.cnt}
          page={page}
          setPage={setPage}
          size={size}
        />
      )}
    </Wrapper>
  );
};

export default RewardSendList;
