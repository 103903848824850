import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const LogsModal = {
  Wrapper: styled.div`
    background-color: white;
    width: 100rem;
    height: 63rem;
    border-radius: 1rem;
    border: 0.1rem solid #f6f6f6;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,
  Title: styled.div`
    font-size: 2rem;
    font-weight: ${FONT_WEIGHT.BOLD};
  `,
  Container: styled.div`
    border: 0.1rem solid #ddd;
    height: 100%;
    background-color: #f4f4f4;
    padding: 2rem;
    overflow-y: auto;
  `,
  Writer: styled.div`
    display: flex;
    gap: 0.5rem;
    color: #777;
    font-size: 2rem;
    margin-bottom: 1rem;
  `,
  Content: styled.div`
    white-space: pre-wrap;
    font-size: 2.5rem;
  `,

  List: styled.div`
    display: flex;
    margin-bottom: 2rem;
    flex-direction: column;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 1rem;

    & > :nth-child(1) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }
  `,

  SettingBox: styled.div`
    display: flex;
    gap: 1rem;
    font-size: 1.6rem;

    > p {
      cursor: pointer;
    }
  `,
};
