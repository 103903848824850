import { HTTP_METHOD, POST_INSERT_USER_LOG } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useState } from "react";
import { getUserParamsFromUri } from "utils/crypto";
import { CallInfoList } from "./CallInfoList";
import { Container, MemoWrite, SaveBtn, Title, TitleContainer, Wrapper } from "./UserCallInfo.style";

// 회원검색 (검증 후 회원 정보 바로가기) :: 회원/가맹점 정보 수정 -> 통화 내용 / 특이사항 기록 (http://localhost:3000/manage/user/:code)
export const UserCallInfo = () => {
  const toast = useToastCustom();

  const [actionNotes, setActionNotes] = useState("");
  const [callLogsAll, setCallLogsAll] = useState([]);

  const { mutate } = useMutationCustom(POST_INSERT_USER_LOG, HTTP_METHOD.POST);

  const handleSaveMemo = () => {
    if (!actionNotes) return toast("내용이 입력되지 않았습니다.", "error");

    if (!window.confirm("메모를 등록하시겠습니까?")) return;

    const { acctCd } = getUserParamsFromUri();
    const largeCd = "T";
    const actionCd = "T0001";

    const data = { largeCd, actionNotes, acctCd, actionCd };

    mutate(data, {
      onSuccess: (response) => {
        setCallLogsAll(response.filter((v) => v.largeCd === "T"));
        toast("메모 등록 성공", "success");
        setActionNotes("");
      },

      onError: () => {
        toast("메모 등록 실패", "error");
      },
    });
  };

  return (
    <Wrapper>
      <TitleContainer>
        <Title>통화 내용 / 특이사항 기록</Title>
        <SaveBtn onClick={handleSaveMemo}>저장</SaveBtn>
      </TitleContainer>
      <Container>
        <CallInfoList callLogsAll={callLogsAll} setCallLogsAll={setCallLogsAll} />
        <MemoWrite
          placeholder="특이사항을 입력해 주세요"
          onChange={(e) => setActionNotes(e.target.value)}
          value={actionNotes}
        ></MemoWrite>
      </Container>
    </Wrapper>
  );
};
