import { atom } from "recoil";

export const AdminAuthState = {
  // AdminState: atom({
  //   key: "AdminAuthStateAdminState",
  //   default: [],
  // }),

  // AdminPage: atom({
  //   key: "AdminAuthStateAdminPage",
  //   default: 1,
  // }),

  // AdminPageSize: atom({
  //   key: "AdminAuthStateAdminPageSize",
  //   default: 1,
  // }),

  SelectedAdmin: atom({
    key: "AdminAuthStateSelectedAdmin",
    default: null,
  }),
};
