import { useEffect, useState } from "react";

import { POST_WALLET_USER_BASIC_FETCH } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { Search, Wrapper } from "template/SearchTemplate.style";
import { PLACEHOLDER } from "constants/baseStyle";

// 회원검색 :: 회원 조회 (http://localhost:3000/manage/user/wallet)
const WalletUserSearch = ({
  setUserMoveList,
  setPage,
  currentPage,
  pageSize,
  setSearchData,
  searchData,
}) => {
  const toast = useToastCustom();

  const [memberName, setMemberName] = useState("");
  const [memberCode, setMemberCode] = useState("");

  const { mutate } = useMutationCustom(POST_WALLET_USER_BASIC_FETCH);

  useEffect(() => {
    if (!Object.keys(searchData)[0]) return;

    const researchCond = { ...searchData, pageSize, currentPage };

    setSearchData(researchCond);

    mutate(researchCond, {
      onSuccess: (data) => {
        setUserMoveList(data);
      },
    });
  }, [currentPage, pageSize]);

  const handleSearch = () => {
    setPage(1);

    const data = { memberName, memberCode, pageSize, currentPage };

    setSearchData(data);

    mutate(data, {
      onSuccess: (data) => {
        setUserMoveList(data);
      },

      onError: () => {
        toast("조회 실패", "error");
      },
    });
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  return (
    <Wrapper>
      <Search.Text>월렛 회원 조회</Search.Text>
      <Search.Container>
        <Search.Content>
          <Search.InputWrapper>
            <div>회원명</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setMemberName(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            ></Search.Input>
          </Search.InputWrapper>
          <Search.InputWrapper>
            <div>회원코드</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setMemberCode(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            ></Search.Input>
          </Search.InputWrapper>
        </Search.Content>
        <Search.Btn onClick={handleSearch}>조회</Search.Btn>
      </Search.Container>
    </Wrapper>
  );
};

export default WalletUserSearch;
