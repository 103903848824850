import {
  HTTP_METHOD,
  POST_ADMIN_ME,
  POST_REISSUE,
} from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useNavigate } from "react-router";
import { useSetRecoilState } from "recoil";
import { loginData, loginRole, loginState } from "recoil/atom/LoginState";
import {
  getUserFromLocalStorage,
  setUserInLocalStorage,
} from "utils/localStorage";

export const useLoginCheck = () => {
  const nav = useNavigate();

  const setLogin = useSetRecoilState(loginState);
  const setRole = useSetRecoilState(loginRole);
  const setLoginData = useSetRecoilState(loginData);

  const { mutate: reissueMutate } = useMutationCustom(
    POST_REISSUE,
    HTTP_METHOD.POST
  );

  const { mutate: meMutate } = useMutationCustom(
    POST_ADMIN_ME,
    HTTP_METHOD.GET
  );

  return async () => {
    const storageData = getUserFromLocalStorage();

    if (!storageData) return;

    const refreshToken = storageData.refreshToken;
    const accessToken = storageData.accessToken;
    const data = { accessToken, refreshToken };

    reissueMutate(data, {
      onSuccess: (result) => {
        setUserInLocalStorage(result);
        meMutate("", {
          onSuccess: (userResult) => {
            setLoginData(userResult);
            setRole(userResult.roles.split(","));
          },

          onError: () => {},
        });
      },

      onError: () => {
        localStorage.removeItem("user");
        setLogin({ isLogin: false });
        setRole([]);
        alert("로그인 세션이 만료되었습니다.");
        nav("/login");
      },
    });
  };
};
