import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
  gap: 1rem;
  border-right: 0.2rem #eeeeee solid;

  & > p:nth-of-type(1) {
    color: #000;
    font-weight: 700;
    font-size: 1.6rem;
    margin-top: 0.3rem;
  }

  & > p:nth-of-type(2) {
    font-size: 1.2rem;
    color: grey;
    font-weight: ${FONT_WEIGHT.BOLD};
  }

  & > div:nth-of-type(2) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;

    & > p:nth-last-of-type(1) {
      color: grey;
      font-weight: ${FONT_WEIGHT.BOLD};
    }
  }
`;
