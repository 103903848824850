import { Loading } from "components/Loading/Loading";
import { useRecoilValue } from "recoil";
import { UserState } from "recoil/atom/UserState";
import { Container, Info, Wrapper } from "./UserInfo.style";
import WalletSlice from "components/WalletSlice/WalletSlice";

// 회원검색 (검증 후 회원 정보 바로가기) :: 회원정보 (http://localhost:3000/manage/user/:code)
export const UserInfo = ({ isLoading }) => {
  const userDetailData = useRecoilValue(UserState.userDetail);

  if (isLoading) return <Loading />;

  return (
    <Wrapper>
      <Container.Top>
        <Container.Text>회원정보</Container.Text>
      </Container.Top>

      <Container.Content>
        <Info.Wrapper>
          {/* 회원명 */}
          <Info.Title>회원명</Info.Title>

          <Info.Content>{userDetailData.memberName}</Info.Content>
        </Info.Wrapper>

        {/* 회원코드 */}
        <Info.Wrapper>
          <Info.Title>회원코드</Info.Title>
          <Info.Content>{userDetailData.memberCode}</Info.Content>
        </Info.Wrapper>

        <Info.Wrapper>
          {/* 추천인명 */}
          <Info.Title>추천인명</Info.Title>
          <Info.Content>{userDetailData.recommenderName}</Info.Content>
        </Info.Wrapper>

        <Info.Wrapper>
          {/* 추천인코드 */}
          <Info.Title>추천인코드</Info.Title>
          <Info.Content>{userDetailData.recommenderCode}</Info.Content>
        </Info.Wrapper>

        <Info.Wrapper>
          {/* 휴대전화 */}
          <Info.Title>휴대전화</Info.Title>

          <Info.Content>{userDetailData.mobile}</Info.Content>
        </Info.Wrapper>

        <Info.Wrapper>
          {/* 생년월일 */}
          <Info.Title>생년월일</Info.Title>
          <Info.Content>{userDetailData.birthDay}</Info.Content>
        </Info.Wrapper>

        <Info.Wrapper>
          {/* 이메일 */}
          <Info.Title>이메일</Info.Title>

          <Info.Content>{userDetailData.email}</Info.Content>
        </Info.Wrapper>

        <Info.Wrapper>
          {/* 회원가입일자 */}
          <Info.Title>회원가입일자</Info.Title>
          <Info.Content>{String(userDetailData.createDate)}</Info.Content>
        </Info.Wrapper>

        <Info.Wrapper>
          {/* 지갑주소 */}
          <Info.Title>SEKTCH 지갑주소</Info.Title>
          <Info.Content>
            {userDetailData.walletAddress &&
              WalletSlice(userDetailData.walletAddress, 8)}
          </Info.Content>
        </Info.Wrapper>

        <Info.Wrapper>
          {/* 추천인 등록일자 */}
          {/* <Info.Title>추천인 등록일자</Info.Title> */}
          <Info.Title></Info.Title>
          {/* <Info.Content>{dateFormat(userDetailData.refererDt)}</Info.Content> */}
          <Info.Content></Info.Content>
        </Info.Wrapper>
      </Container.Content>
      {/* 인증완료 서류 들어갈곳 */}
      {/* <Container.Doc></Container.Doc> */}
    </Wrapper>
  );
};
