import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 70%;
  border-right: 0.2rem #eeeeee solid;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = {
  Wrapper: styled.div`
    margin-top: 2rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 1.5rem;
    & > span {
      cursor: pointer;
      color: grey;
    }
  `,
};

export const CalendarHeader = {
  Wrapper: styled.div`
    margin-top: 2.5rem;
    display: flex;

    & > div:nth-child(1),
    & > div:nth-child(7) {
      color: #4253f2;
    }
  `,

  Header: styled.div`
    display: flex;
    justify-content: center;
    width: 8rem;
    background-color: white;
    font-size: 1.2rem;
    font-weight: ${FONT_WEIGHT.BOLD};
  `,
};

export const CalendarDetail = {
  Wrapper: styled.div`
    margin: 0.5rem 2.2rem 2rem 2.2rem;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    border: 0.1rem solid #eeeeee;
    border-radius: 0.7rem;

    & > div:nth-child(1) {
      border-top-left-radius: 0.6rem;
    }

    & > div:nth-child(7) {
      border-top-right-radius: 0.6rem;
    }

    & > div:nth-child(36) {
      border-bottom-left-radius: 0.6rem;
    }

    & > div:nth-child(42) {
      border-bottom-right-radius: 0.6rem;
    }
  `,

  Body: styled.div`
    width: calc(100% / 7);
    height: 5rem;
    background-color: ${(props) => (props.$notInMonth ? "white" : "#fbfbfb")};
    font-weight: ${FONT_WEIGHT.BOLD};
    color: ${(props) => (props.$notInMonth ? "#bbb" : "black")};
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    font-size: 1.2rem;
    gap: 0.4rem;
    border: 0.1rem solid #eee;
    padding-top: 0.3rem;

    & > div:nth-child(1) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      background-color: ${(props) =>
        props.$isToday
          ? "#4253F2"
          : props.$isSelectDate
          ? "#D0D5FF"
          : "inherit"};
      color: ${(props) =>
        props.$isToday ? "white" : props.$isSelectDate ? "#4253F2" : "inherit"};

      position: relative;

      & > span {
        position: absolute;
        top: 0.2rem;
        left: 2rem;

        & > img {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  `,

  CounterWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  `,

  CounterContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.1rem;
    opacity: ${(props) => (props.$notInMonth ? "0.5" : "1")};
  `,

  CounterDot: styled.div`
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background-color: ${(props) => props.$bgColor};
  `,
  Count: styled.div`
    color: ${(props) => props.$color};
    font-size: 0.9rem;
    padding-top: 0.2rem;
    font-weight: ${FONT_WEIGHT.BOLD};
  `,
};
