import { Info } from "template/ListTemplate.style";
import coinColumn from "../coinColumn.json";
import { useState } from "react";
import { ActiveBtn, BtnBox, InactiveBtn, Row } from "../CoinList.style";
import { dateFormat } from "components/dateFormat";
import { useMutationCustom } from "hooks/useMutationCustom";
import { HTTP_METHOD, POST_COIN_MODIFY } from "constants/pathConstant";

// 시스템 관리 -> 코인 관리 :: 코인 리스트 낱개 (http://localhost:3000/super/system/coin)
const CoinDetail = ({ data, fetchData }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState(data.name);
  const [symbol, setSymbol] = useState(data.symbol);
  const [marketName, setMarketName] = useState(data.marketName);
  const [marketUrl, setMarketUrl] = useState(data.marketUrl);
  const [priceApiUrl, setPriceApiUrl] = useState(data.priceApiUrl);
  const [useYn, setUseYn] = useState(data.useYn);

  const { mutate } = useMutationCustom(POST_COIN_MODIFY, HTTP_METHOD.POST);

  const modifyHandler = () => {
    if (!isEdit) return;

    const mutateData = {
      id: data.id,
      name,
      symbol,
      marketName,
      marketUrl,
      priceApiUrl,
      useYn,
    };

    mutate(mutateData, {
      onSuccess: () => {
        fetchData();
        setIsEdit(false);
      },
    });
  };

  return (
    <Info.Content>
      {/* 이름 */}
      <Row $ratio={coinColumn.data[0].ratio}>
        {isEdit ? (
          <input
            defaultValue={data.name}
            onChange={(e) => setName(e.target.value)}
          />
        ) : (
          <p>{data.name}</p>
        )}
      </Row>

      {/* 심볼 */}
      <Row $ratio={coinColumn.data[1].ratio}>
        {isEdit ? (
          <input
            defaultValue={data.symbol}
            onChange={(e) => setSymbol(e.target.value)}
          />
        ) : (
          <p>{data.symbol}</p>
        )}
      </Row>

      {/* 최근가격 */}
      <Row $ratio={coinColumn.data[2].ratio}>{data.recentPrice}</Row>
      {/* 최근가격 */}
      <Row $ratio={coinColumn.data[2].ratio}>{data.recentPriceUSD}</Row>

      {/* 사용여부 */}
      <Row $ratio={coinColumn.data[4].ratio}>
        {isEdit ? (
          <select
            onChange={(e) => setUseYn(e.target.value)}
            defaultValue={data.useYn}
          >
            <option value={"Y"}>사용</option>
            <option value={"N"}>미사용</option>
          </select>
        ) : (
          <p>{coinColumn.useYn[data.useYn]}</p>
        )}
      </Row>

      {/* 수정버튼 */}
      {/* <Row $ratio={coinColumn.data[10].ratio}>
        {isEdit ? (
          <BtnBox>
            <InactiveBtn
              onClick={() => {
                setIsEdit(false);
              }}
            >
              취소
            </InactiveBtn>
            <ActiveBtn onClick={modifyHandler}>수정</ActiveBtn>
          </BtnBox>
        ) : (
          <InactiveBtn
            onClick={() => {
              setIsEdit(true);
            }}
          >
            수정
          </InactiveBtn>
        )}
      </Row> */}
    </Info.Content>
  );
};

export default CoinDetail;
