import { Info, List } from "template/ListTemplate.style";
import withdrawSummaryColumn from "../withdrawSummaryColumn.json";
import { costFormat } from "components/costForamt";

// 브릿지 -> 출금 :: 요약 낱개 (http://localhost:3000/super/bridge/withdraw)
const WithdrawSummaryDetail = ({ data }) => {
  return (
    <Info.Content>
      {/* 총 충전 금액 */}
      <List.Row $ratio={withdrawSummaryColumn.summary[0].ratio}>
        {costFormat(data?.totalAmount)}
      </List.Row>

      {/* 성공한 충전 금액 */}
      <List.Row $ratio={withdrawSummaryColumn.summary[1].ratio}>
        {costFormat(data?.lockTotalAmount)}
      </List.Row>

      {/* 실패한 충전 금액 */}
      <List.Row $ratio={withdrawSummaryColumn.summary[2].ratio}>
        {costFormat(data?.rewardTotalAmount)}
      </List.Row>

      {/* 총 충전 횟수 */}
      <List.Row $ratio={withdrawSummaryColumn.summary[3].ratio}>
        {costFormat(data?.reqAmount)}
      </List.Row>

      {/* 실패한 충전 금액 */}
      <List.Row $ratio={withdrawSummaryColumn.summary[4].ratio}>
        {costFormat(data?.totalFee)}
      </List.Row>

      {/* 실패한 충전 금액 */}
      <List.Row $ratio={withdrawSummaryColumn.summary[5].ratio}>
        {costFormat(data?.lockFee)}
      </List.Row>

      {/* 실패한 충전 금액 */}
      <List.Row $ratio={withdrawSummaryColumn.summary[6].ratio}>
        {costFormat(data?.rewardFee)}
      </List.Row>

      {/* 실패한 충전 금액 */}
      <List.Row $ratio={withdrawSummaryColumn.summary[7].ratio}>
        {data?.cnt}
      </List.Row>

      {/* 실패한 충전 금액 */}
      <List.Row $ratio={withdrawSummaryColumn.summary[8].ratio}>
        {data?.successCnt}
      </List.Row>

      {/* 실패한 충전 금액 */}
      <List.Row $ratio={withdrawSummaryColumn.summary[9].ratio}>
        {data?.failCnt}
      </List.Row>
    </Info.Content>
  );
};

export default WithdrawSummaryDetail;
