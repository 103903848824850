import { styled } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 6rem;
  gap: 2rem;
`;

export const Flex = {
  First: styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
  `,

  Second: styled.div`
    display: flex;
    gap: 2rem;
  `,
};
