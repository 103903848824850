import { ExcelBtn } from "./Btn.style";

export const ExcelDownloadBtn = ({ onClick }) => {
  return (
    <ExcelBtn.Wrapper onClick={onClick}>
      <ExcelBtn.Logo src="/assets/imgs/btn_excel_download.svg" />
      <ExcelBtn.Text>엑셀파일 다운로드</ExcelBtn.Text>
    </ExcelBtn.Wrapper>
  );
};
