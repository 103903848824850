import { useEffect, useState } from "react";
import { SfuelStateContainer, SfuelTableName, Wrapper } from "./style";
import SfuelWorkDetail from "./SfuelWorkDetail";
import { useQueryCustom } from "hooks/useQueryCustom";
import {
  GET_SFUEL_WORKER_WALLETS,
  HTTP_METHOD,
  POST_SFUEL_BALANCE,
} from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useQueryClient } from "@tanstack/react-query";

const SfuelWork = () => {
  const queryClient = useQueryClient();
  const [workList, setWorkList] = useState([]);

  const { data } = useQueryCustom(["workList"], GET_SFUEL_WORKER_WALLETS)();

  useEffect(() => {
    if (!data) return;
    setWorkList(data);
  }, [data, workList]);

  const { mutate } = useMutationCustom(POST_SFUEL_BALANCE, HTTP_METHOD.POST);

  const handleBalanceCheck = () => {
    const tmp = workList.data.map(({ walletId }) => ({ walletId }));

    mutate(
      { data: tmp },
      {
        onSuccess: () => {
          setWorkList(data);
          queryClient.invalidateQueries(["workList"]);
        },
      }
    );
  };

  return (
    <Wrapper>
      <SfuelStateContainer>
        <div>
          <p>완료: </p>
          <p>{workList?.completeCounts}개</p>
        </div>
        <div>
          <p>미완료: </p>
          <p>{workList?.notCompleteCounts}개</p>
        </div>
        <div onClick={handleBalanceCheck}>밸런스 체크</div>
      </SfuelStateContainer>
      <SfuelTableName>
        <p>주소</p>
        <p>열기버튼</p>
        <p>열기여부</p>
      </SfuelTableName>
      {workList.data?.length ? (
        workList.data?.map((arr, index) => (
          <SfuelWorkDetail key={index} arr={arr} />
        ))
      ) : (
        <p>할당 받은 작업이 없습니다.</p>
      )}
    </Wrapper>
  );
};

export default SfuelWork;
