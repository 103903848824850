import { useState } from "react";
import { OpenedUrl, SfuelContainer } from "./style";

const SfuelWorkDetail = ({ arr }) => {
  const [isClick, setIsClick] = useState(false);

  const openWindow = (value) => {
    if (value.trim()) {
      window.open("https://www.sfuelstation.com/claim-sfuel/" + value);
      setIsClick(true);
    }
  };

  return (
    <SfuelContainer key={arr}>
      <div>
        <input type="text" defaultValue={arr.address} disabled />
      </div>
      <div>
        <button onClick={() => openWindow(arr.address)}>열기</button>
      </div>
      <div>
        <OpenedUrl clicked={isClick}></OpenedUrl>
      </div>
    </SfuelContainer>
  );
};

export default SfuelWorkDetail;
