import { Wrapper } from "../style";
import SearchCategory from "../../SearchCategory";
import { useState } from "react";
import { POST_FIND_WALLET_MOVE, SYMBOL } from "constants/pathConstant";
import { Content, InfoWrapper, ListColumn, ListContaier } from "./style";
import summaryColumn from "./summaryByUserColumn.json";
import { costFormat } from "components/costForamt";

// 회원검색 (검증 후 회원 정보 바로가기) :: 지갑정보 -> 요약 (http://localhost:3000/manage/user/:code)
const SummaryByUser = ({ acctCd }) => {
  const [resData, setResData] = useState([]);

  const API_ADDRESS = POST_FIND_WALLET_MOVE;

  const data = {
    acctCd,
    useType: "",
    state: "",
    pageSize: 10,
    currentPage: 1,
  };

  return (
    <Wrapper>
      <SearchCategory
        API_ADDRESS={API_ADDRESS}
        data={data}
        setResData={setResData}
        header={false}
        summary={true}
      />

      <ListContaier>
        <Content>
          {summaryColumn.balanceInfo.map((v, idx) => (
            <ListColumn>{v.title}</ListColumn>
          ))}
        </Content>

        <InfoWrapper>
          <p>
            <span>
              {costFormat(resData.balanceInfo?.usedPaymentLockBalance)}
            </span>
            <span>{SYMBOL}</span>
          </p>
          <p>
            <span>
              {costFormat(resData.balanceInfo?.usedPaymentRewardBalance)}
            </span>
            <span>{SYMBOL}</span>
          </p>
          <p>
            <span>{costFormat(resData.balanceInfo?.withdrawLockBalance)}</span>
            <span>{SYMBOL}</span>
          </p>
          <p>
            <span>
              {costFormat(resData.balanceInfo?.withdrawRewardBalance)}
            </span>
            <span>{SYMBOL}</span>
          </p>
          <p>
            <span>
              {costFormat(resData.balanceInfo?.chargeTotalLockBalance)}
            </span>
            <span>{SYMBOL}</span>
          </p>
          <p>
            <span>
              {costFormat(resData.balanceInfo?.getTotalRewardBalance)}
            </span>
            <span>{SYMBOL}</span>
          </p>
          <p>
            <span>{costFormat(resData.balanceInfo?.cancelRewardBalance)}</span>
            <span>{SYMBOL}</span>
          </p>
          <p>
            <span>{costFormat(resData.balanceInfo?.amount)}</span>
            <span>{SYMBOL}</span>
          </p>
          <p>
            <span>{costFormat(resData.balanceInfo?.rewardAmount)}</span>
            <span>{SYMBOL}</span>
          </p>
          <p>
            <span>{costFormat(resData.balanceInfo?.totalAmount)}</span>
            <span>{SYMBOL}</span>
          </p>
        </InfoWrapper>
      </ListContaier>
    </Wrapper>
  );
};

export default SummaryByUser;
