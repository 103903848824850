import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 1.3rem;
  border: 1px solid #eee;
`;

export const NavImgBox = styled.div`
  width: 1.2rem;
  height: 1.2rem;

  > img {
    width: 100%;
    height: 100%;
  }
`;
