import { Info, List, Wrapper } from "template/ListTemplate.style";
import paymentMonthlyColumn from "../paymentMonthlyColumn.json";
import { ListDefault } from "components/ListDefault/ListDefault";
import { PaginationBox } from "components/Pagination/PaginationBox";
import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { useToastCustom } from "hooks/useToastCustom";
import { useMutationCustom } from "hooks/useMutationCustom";
import { POST_PAYMENT_MONTHLY_FETCH } from "constants/pathConstant";
import { PAYMENT_MONTH_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import PaymentMonthlyMonthList from "./PaymentMonthlyMonthList";

// 월별 결제내역 -> 월별 리스트 :: (http://localhost:3000/super/payment/month)
const PaymentMonthlyMonth = ({
  data,
  isSearch,
  currentPage,
  setCurrentPage,
  setPaymentMonthlyDetailRes,
  detailPage,
  searchReq,
  setSearchDetailReq,
  setIsMonthSearch,
  searchDetailReq,
  setDetailPage,
}) => {
  const toast = useToastCustom();
  const excelDownload = useExcelDownload();

  const { mutate } = useMutationCustom(POST_PAYMENT_MONTHLY_FETCH);

  // 엑셀 다운로드
  const handleExcelDownload = () => {
    if (!isSearch || !data?.listCount) {
      toast("다운로드할 데이터가 없습니다", "error");
      return;
    }

    const mutateData = {
      ...searchReq,
      pageSize: data?.listCount,
      currentPage: 1,
    };

    mutate(mutateData, {
      onSuccess: (response) => {
        const excelData = {
          data: response.list,
          headers: PAYMENT_MONTH_XLSX_DOWNLOAD.header,
          fileName: PAYMENT_MONTH_XLSX_DOWNLOAD.filename,
        };

        excelDownload(excelData);
      },

      onError: () => {
        toast("전체 데이터 조회 실패", "error");
      },
    });
  };

  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>연월별 결제내역 리스트</List.Text>
        <List.BtnContainer>
          <ExcelDownloadBtn onClick={handleExcelDownload} />
        </List.BtnContainer>
      </List.TitleContainer>

      <List.Container>
        {!isSearch ? (
          <ListDefault text={"연월별 목록을"} />
        ) : (
          <>
            <List.Content>
              {paymentMonthlyColumn.month.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>

            <Info.Wrapper>
              {data.list.map((v, idx) => (
                <PaymentMonthlyMonthList
                  key={idx}
                  data={v}
                  setPaymentMonthlyDetailRes={setPaymentMonthlyDetailRes}
                  detailPage={detailPage}
                  searchReq={searchReq}
                  setSearchDetailReq={setSearchDetailReq}
                  setIsMonthSearch={setIsMonthSearch}
                  searchDetailReq={searchDetailReq}
                  setDetailPage={setDetailPage}
                />
              ))}
            </Info.Wrapper>
          </>
        )}
      </List.Container>

      {isSearch && (
        <PaginationBox
          totalItemsCount={data.listCount}
          page={currentPage}
          setPage={setCurrentPage}
          size={6}
        />
      )}
    </Wrapper>
  );
};

export default PaymentMonthlyMonth;
