import {
  dateFormatDay,
  dateFormatEnd,
  dateFormatStart,
} from "components/newDateFormat";
import { POST_ASSET_LIST } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useEffect, useState } from "react";
import { Search, Wrapper } from "template/SearchTemplate.style";
import assetWalletColumn from "../assetWalletColumn.json";
import { dateFormat } from "components/dateFormat";
import SearchWalletUserModal from "components/Modal/SearchWalletUserModal";
import { PLACEHOLDER } from "constants/baseStyle";

// 페이 자산내역 :: 자산내역 조회 (http://localhost:3000/super/asset/wallet)
const AssetWalletSearch = ({
  setAssetWalletList,
  setPage,
  currentPage,
  pageSize,
  setSearchData,
  searchData,
  setIsSearch,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [typeId, setTypeId] = useState("");
  const [txTypeId, setTxTypeId] = useState("");
  const [coinId, setCoinId] = useState("");
  const [sendType, setSendType] = useState("");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState(dateFormatDay(new Date()));
  const [fromWalletAddress, setFromWalletAddress] = useState("");
  const [toWalletAddress, setToWalletAddress] = useState("");
  const [txId, setTxId] = useState("");

  const [userData, setUserData] = useState({});

  useEffect(() => {
    setTypeId("");
    setTxTypeId("");
    setFromDt("");
    setToDt(dateFormat(new Date()).slice(0, 19));
  }, [userData]);

  useEffect(() => {
    if (typeId === "2") {
      setSendType("00");
    } else if (typeId === "20") {
      setSendType("10");
    } else {
      setSendType("");
    }
  }, [typeId]);

  const { mutate } = useMutationCustom(POST_ASSET_LIST);

  useEffect(() => {
    if (!Object.keys(searchData)[0]) return;
    const researchData = { ...searchData, currentPage, pageSize };
    setSearchData(researchData);

    mutate(researchData, {
      onSuccess: (data) => {
        setAssetWalletList(data);
      },
    });
  }, [pageSize, currentPage]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const data = {
      memberName: userData.memberName || "",
      memberCode: userData.memberCode || "",
      mobile: userData.mobile || "",
      typeId: typeId === "20" ? "2" : typeId,
      txTypeId,
      pageSize,
      currentPage,
      coinId,
      sendType,
      fromDt: fromDt && dateFormatStart(fromDt),
      toDt: dateFormatEnd(toDt),
      fromWalletAddress,
      toWalletAddress,
      txId,
    };

    setSearchData(data);

    mutate(data, {
      onSuccess: (data) => {
        setAssetWalletList(data);
      },
    });
  };

  const handleSearch = () => {
    setPage(1);
    setIsSearch(true);
    fetchData();
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  const handleTypeId = (e) => {
    setTypeId(e.target.value);
  };

  return (
    <Wrapper>
      <Search.Text>월렛 자산내역 조회</Search.Text>
      <Search.Container>
        <Search.Content style={{ width: "77rem" }}>
          {/* 회원명 */}
          <Search.InputWrapper
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            <div>회원명</div>
            <Search.Input
              style={{ cursor: "pointer" }}
              placeholder="눌러서 조회"
              onKeyUp={(e) => handleEnter(e.key)}
              readOnly
              value={userData.memberName}
            />
          </Search.InputWrapper>

          {/* 회원코드 */}
          <Search.InputWrapper>
            <div>회원코드</div>
            <Search.Input
              placeholder="자동 입력"
              onKeyUp={(e) => handleEnter(e.key)}
              disabled
              value={userData.memberCode}
            />
          </Search.InputWrapper>

          {/* 전화번호 */}
          <Search.InputWrapper>
            <div>전화번호</div>
            <Search.Input
              placeholder="자동 입력"
              onKeyUp={(e) => handleEnter(e.key)}
              disabled
              value={userData.mobile}
            />
          </Search.InputWrapper>

          {/* 유형 */}
          <Search.InputWrapper style={{ width: "15rem" }}>
            <div>유형</div>
            <Search.Select
              style={{ width: "13rem" }}
              onChange={(e) => {
                handleTypeId(e);
              }}
              // value={typeId}
            >
              <Search.Option value="">전체</Search.Option>
              {Object.keys(assetWalletColumn.typeId).map((v, idx) => (
                <Search.Option key={idx} value={v}>
                  {assetWalletColumn.typeId[v]}
                </Search.Option>
              ))}
            </Search.Select>
          </Search.InputWrapper>

          {/* 거래일자 (부터) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>거래일자 (부터)</div>
            <Search.Input
              type="date"
              defaultValue={fromDt}
              onChange={(e) => setFromDt(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            ></Search.Input>
          </Search.InputWrapper>

          {/* 거래일자 (까지) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>거래일자 (까지)</div>
            <Search.Input
              type="date"
              defaultValue={toDt.slice(0, 10)}
              onChange={(e) => setToDt(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            ></Search.Input>
          </Search.InputWrapper>

          {/* 거래 코인 타입 */}
          <Search.InputWrapper>
            <div>거래 코인 타입</div>
            <Search.Select onChange={(e) => setCoinId(e.target.value)}>
              <Search.Option value="">전체</Search.Option>
              {Object.keys(assetWalletColumn.coinId).map((v, idx) => (
                <Search.Option key={idx} value={v}>
                  {assetWalletColumn.coinId[v]}
                </Search.Option>
              ))}
            </Search.Select>
          </Search.InputWrapper>

          {/* 결제상태 */}
          <Search.InputWrapper>
            <div>상태</div>
            <Search.Select onChange={(e) => setTxTypeId(e.target.value)}>
              <Search.Option value="">전체</Search.Option>
              {Object.keys(assetWalletColumn.txTypeId).map((v, idx) => (
                <Search.Option key={idx} value={v}>
                  {assetWalletColumn.txTypeId[v]}
                </Search.Option>
              ))}
            </Search.Select>
          </Search.InputWrapper>

          {/* 보낸 지갑주소 */}
          <Search.InputWrapper>
            <div>보낸 지갑주소</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setFromWalletAddress(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 받은 지갑주소 */}
          <Search.InputWrapper>
            <div>받은 지갑주소</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setToWalletAddress(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* txId */}
          <Search.InputWrapper>
            <div>txId</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setTxId(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>
        </Search.Content>

        {/* 조회 버튼 */}
        <Search.Btn onClick={() => handleSearch()}>조회</Search.Btn>
      </Search.Container>

      {isOpen && (
        <SearchWalletUserModal
          setIsOpen={setIsOpen}
          setUserData={setUserData}
        />
      )}
    </Wrapper>
  );
};

export default AssetWalletSearch;
