import styled from "styled-components";

export const costFormat = (text, sket = "", includeDecimal = true) => {
  if (!text) return <>0 {sket}</>;

  const [integerPart, decimalPart] = text.split(".");
  const formattedInteger = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
  }).format(integerPart);

  if (
    !includeDecimal ||
    !decimalPart ||
    decimalPart.replace(/0+$/, "") === ""
  ) {
    return (
      <>
        {formattedInteger} {sket}
      </>
    );
  }

  const trimmedDecimal = decimalPart.slice(0, 8);

  return (
    <Cost>
      {formattedInteger}
      <DecimalPoint>.{trimmedDecimal}</DecimalPoint>
      {` ${sket}`}
    </Cost>
  );
};

const Cost = styled.div`
  display: flex;
  align-items: end;
  justify-content: center;
`;

const DecimalPoint = styled.span`
  color: #5a5a5a;
  font-size: 1rem;
`;
