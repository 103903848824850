import { useLocation } from "react-router";
import PopupUpload from "../PopupUpload";

// 고객 지원 -> 팝업 관리 :: 팝업 수정 (http://localhost:3000/super/create/popup-modify)
const PopupModify = () => {
  const location = useLocation();

  return <PopupUpload modifyData={location.state.data} />;
};

export default PopupModify;
