import { useEffect, useState } from "react";
import { AdminData, Btn, Container, FooterWrapper, Wrapper } from "./MemoModal.style";
import { dateFormat } from "components/dateFormat";

const MemoModal = ({
  setIsOpen,
  memoData,
  mutateUpdateData,
  mutateUpdateMemo,
  setUpdateMemoList,
  searchData,
  isWithdraw,
}) => {
  const [memo, setMemo] = useState("");

  useEffect(() => {
    if (!memoData) return;
    setMemo(memoData.memo);
  }, []);

  const handleModify = () => {
    const mutateData = isWithdraw
      ? {
          // 인출 메모 수정
          id: memoData.id,
          memo,
          state: memoData.state,
        }
      : {
          // 탈퇴 회원 메모 수정
          id: memoData.id,
          acctQuitMemo: memo,
          acctCd: memoData.acctCd,
        };

    mutateUpdateMemo(mutateData, {
      onSuccess: () => {
        mutateUpdateData(searchData, {
          onSuccess: (res) => {
            setUpdateMemoList(res);
            setIsOpen(false);
          },
        });
      },
      onError: () => {},
    });
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const modalCloseHandler = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Container onClick={() => modalCloseHandler} />
      <Wrapper
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <p>메모</p>
        <textarea defaultValue={memoData.memo && memoData.memo} onChange={(e) => setMemo(e.target.value)} />
        <FooterWrapper>
          <AdminData>
            <p>{memoData.admId}</p>
            <p>{dateFormat(memoData.updatedAt)}</p>
          </AdminData>
          <Btn>
            <div onClick={handleCancel}>취소</div>
            <div onClick={handleModify}>수정</div>
          </Btn>
        </FooterWrapper>
      </Wrapper>
    </>
  );
};

export default MemoModal;
