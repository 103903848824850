import { useState } from "react";
import { Section, Wrapper } from "./SketchDrop.style";
import SketchDropDetail from "./SketchDropDetail/SketchDropDetail";
import SketchDropLeft from "./SketchDropLeft/SketchDropLeft";
import SketchDropSearch from "./SketchDropSearch/SketchDropSearch";

// 정산 관리 -> 스케치 드랍 :: (http://localhost:3000/super/calculate/sketch-drop)
const SketchDrop = () => {
  const [sketchDropRes, setSketchDropRes] = useState({
    list: [],
    dropInfo: {},
  });
  const [page, setPage] = useState(1);
  const [searchReq, setSearchReq] = useState({});

  const [isSearch, setIsSearch] = useState(false);
  const [userData, setUserData] = useState({});

  const [sketchDropDetailRes, setSketchDropDetailRes] = useState([]);
  const [detailPage, setDetailPage] = useState(1);
  const [detailSize, setDetailSize] = useState(10);
  const [searchDetailReq, setSearchDetailReq] = useState({});
  const [sortOption, setSortOption] = useState("txValue");

  return (
    <Wrapper>
      <div>
        <SketchDropSearch
          setSketchDropRes={setSketchDropRes}
          currentPage={page}
          setPage={setPage}
          setSearchReq={setSearchReq}
          searchReq={searchReq}
          setIsSearch={setIsSearch}
          userData={userData}
          setUserData={setUserData}
          setSketchDropDetailRes={setSketchDropDetailRes}
        />

        <Section>
          <SketchDropLeft
            sketchDropRes={sketchDropRes}
            isSearch={isSearch}
            setSketchDropDetailRes={setSketchDropDetailRes}
            userData={userData}
            page={page}
            setPage={setPage}
            detailPage={detailPage}
            setDetailPage={setDetailPage}
            detailSize={detailSize}
            searchDetailReq={searchDetailReq}
            setSearchDetailReq={setSearchDetailReq}
            searchReq={searchReq}
            sortOption={sortOption}
          />

          <SketchDropDetail
            sketchDropDetailRes={sketchDropDetailRes}
            detailPage={detailPage}
            setDetailPage={setDetailPage}
            detailSize={detailSize}
            setDetailSize={setDetailSize}
            searchDetailReq={searchDetailReq}
            setSortOption={setSortOption}
            sortOption={sortOption}
          />
        </Section>
      </div>
    </Wrapper>
  );
};

export default SketchDrop;
