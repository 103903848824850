import React, { useState } from "react";
import { Wrapper } from "./Withdraw.style";
import { WithdrawSearch } from "./WithdrawSearch/WithdrawSearch";
import { WithdrawInfoList } from "./WithdrawInfoList/WithdrawInfoList";
import { PaginationBox } from "components/Pagination/PaginationBox";
import WithdrawSummary from "./WithdrawSummary/WithdrawSummary";

// 브릿지 -> 출금 :: (http://localhost:3000/super/bridge/withdraw)
export const WithdrawList = () => {
  const [withdrawList, setWithdrawList] = useState({ list: [], summary: {} });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});
  const [isSearch, setIsSearch] = useState(false);

  return (
    <Wrapper>
      <div>
        <WithdrawSearch
          setWithdrawList={setWithdrawList}
          setPage={setPage}
          currentPage={page}
          pageSize={size}
          setSearchData={setSearchData}
          searchData={searchData}
          setIsSearch={setIsSearch}
        />

        <WithdrawSummary withdrawList={withdrawList} />

        <WithdrawInfoList
          withdrawList={withdrawList}
          setPage={setPage}
          setSize={setSize}
          searchData={searchData}
          setWithdrawList={setWithdrawList}
          isSearch={isSearch}
        />

        {isSearch && withdrawList.list && (
          <PaginationBox totalItemsCount={withdrawList.list[0]?.cnt} page={page} setPage={setPage} size={size} />
        )}
      </div>
    </Wrapper>
  );
};
