import React from "react";
import { Info, List } from "template/ListTemplate.style";
import { uriEncoder } from "utils/crypto";
import userColumn from "./superUserColumn.json";
import { DetailBtn } from "../UserList.style";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import { NAV_USER_DETAIL_BASE_WALLET } from "constants/pathConstant";
import WalletSlice from "components/WalletSlice/WalletSlice";

// 검색 -> 회원검색 :: 회원 목록 리스트 낱개 (http://localhost:3000/super/search/user/wallet)
const SuperWalletUserDetailList = React.memo(
  ({
    id,
    createDate,
    memberName,
    memberCode,
    recommenderName,
    recommenderCode,
    mobile,
    email,
    walletAddress,
    state,
    birthDay,
  }) => {
    const nav = useNavigationCustom();

    const navToDetailHandler = (code) => {
      nav(NAV_USER_DETAIL_BASE_WALLET + code);
    };

    const handleStateColor = (state) => {
      if (state === "정상") return "#21F650";
      if (state === "탈퇴") return "#F00";
      if (state === "탈퇴 신청") return "#888";
    };

    return (
      <Info.Content>
        {/* 번호 */}
        <List.Row $ratio={userColumn.data[0].ratio}>{id}</List.Row>

        {/* 가입일자 */}
        <List.Row $ratio={userColumn.data[1].ratio}>
          {createDate.slice(0, 10)}
        </List.Row>

        {/* 회원명 */}
        <List.Row $ratio={userColumn.data[2].ratio}>{memberName}</List.Row>

        {/* 회원코드 */}
        <List.Row $ratio={userColumn.data[3].ratio}>{memberCode}</List.Row>

        {/* 생년월일 */}
        <List.Row $ratio={userColumn.data[4].ratio}>{birthDay}</List.Row>

        {/* 추천인명 */}
        <List.Row $ratio={userColumn.data[5].ratio}>{recommenderName}</List.Row>

        {/* 추천인코드 */}
        <List.Row $ratio={userColumn.data[6].ratio}>{recommenderCode}</List.Row>

        {/* 전화번호 */}
        <List.Row $ratio={userColumn.data[7].ratio}>{mobile}</List.Row>

        {/* 이메일 */}
        <List.Row $ratio={userColumn.data[8].ratio}>{email}</List.Row>

        {/* 지갑주소 */}
        <List.Row $ratio={userColumn.data[9].ratio}>
          {WalletSlice(walletAddress)}
        </List.Row>

        {/* 활동상태 */}
        <List.Row
          $ratio={userColumn.data[10].ratio}
          style={{ color: handleStateColor(state) }}
        >
          {state}
        </List.Row>

        {/* 바로가기 버튼 */}
        <List.Row $ratio={userColumn.data[11].ratio}>
          <DetailBtn.Wrapper
            onClick={() =>
              navToDetailHandler(uriEncoder(memberCode, id, state))
            }
          >
            바로가기
          </DetailBtn.Wrapper>
        </List.Row>
      </Info.Content>
    );
  }
);

export default SuperWalletUserDetailList;
