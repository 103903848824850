import { RefuseStoreModal } from "components/Modal/StoreModal/RefuseStoreModal";
import {
  HTTP_METHOD,
  NAV_USER_DETAIL_BASE_PAY,
  POST_MODIFY_STORE_STATE,
} from "constants/pathConstant";
import { useOpenCustomModal } from "hooks/modal/useOpenCustomModal";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import { Info, List } from "template/ListTemplate.style";
import { uriEncoder } from "utils/crypto";
import { DetailBtn, StateOption, StateSelect } from "../AcceptStoreList.style";
import storeColumn from "./AcceptStoreColumn.json";
import React, { useEffect, useState } from "react";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { dateFormat } from "components/dateFormat";

// 승인 -> 가맹점 승인 :: 가맹점 목록 리스트 낱개 (http://localhost:3000/super/accept/store)
export const AcceptStoreDetail = React.memo(
  ({
    id,
    crtDt,
    storeNm,
    storeId,
    ceoNm,
    ceoCellNumber,
    contactNum,
    refererCd,
    feeRate,
    state,
    ownerAcctId,
    ownerAcctIndexId,
    confirmState,
    addr,
    addr2,
    zipCode,
    linkUrl,
  }) => {
    const nav = useNavigationCustom();
    const openRefuseStoreModal = useOpenCustomModal(RefuseStoreModal);

    const [acceptState, setAcceptState] = useState("");
    const toast = useToastCustom();

    const { mutate: updateStateMutate } = useMutationCustom(
      POST_MODIFY_STORE_STATE,
      HTTP_METHOD.POST
    );

    const navToDetailHandler = (code) => {
      const query = `?storeId=${storeId}`;
      nav(NAV_USER_DETAIL_BASE_PAY + code + query);
    };

    useEffect(() => {
      setAcceptState(confirmState);
    }, []);

    const stateColor = {
      "00": "#ddd", // 가승인
      90: "#53FD64", //최종승인
      10: "#FDC353", //재승인
      30: "#FE4F4F", //승인거절
      99: "#FE4F4F", //탈퇴
    };

    const handleChangeState = (value) => {
      const data = {
        confirmState: value,
        storeId,
        acctCd: ownerAcctId,
        content: "",
        title: "",
      };
      switch (value) {
        case "90":
          if (window.confirm("최종승인 하시겠습니까?")) {
            updateStateMutate(data, {
              onSuccess: () => {
                toast("최종 승인 성공", "success");
                setAcceptState(value);
              },
              onError: () => {
                toast("업데이트 실패", "error");
              },
            });
          }
          break;
        case "30":
          // 승인 거절
          openRefuseStoreModal({ data, setAcceptState, acceptState });

          break;
        default:
          break;
      }
    };

    const openLink = () => {
      window.open(linkUrl);
    };

    return (
      <Info.Content>
        {/* 번호 */}
        <List.Row $ratio={storeColumn.data[0].ratio}>{id}</List.Row>

        {/* 가입일자 */}
        <List.Row $ratio={storeColumn.data[1].ratio}>
          {dateFormat(crtDt)}
        </List.Row>

        {/* 승인상태 */}
        <List.Row $ratio={storeColumn.data[2].ratio}>
          <StateSelect
            onChange={(e) => {
              handleChangeState(e.target.value);
            }}
            $bg={stateColor[acceptState]}
            value={acceptState}
          >
            <StateOption value={"00"} style={{ display: "none" }}>
              가승인
            </StateOption>
            <StateOption value={"10"} style={{ display: "none" }}>
              재승인
            </StateOption>
            <StateOption value={"90"}>최종승인</StateOption>
            <StateOption value={"30"}>승인거절</StateOption>
            <StateOption value={"99"} style={{ display: "none" }}>
              탈퇴
            </StateOption>
          </StateSelect>
        </List.Row>

        {/* 가맹점명 */}
        <List.Row $ratio={storeColumn.data[3].ratio}>{storeNm}</List.Row>

        {/* 가맹점코드 */}
        <List.Row $ratio={storeColumn.data[4].ratio}>{storeId}</List.Row>

        {/* 대표자명 */}
        <List.Row $ratio={storeColumn.data[5].ratio}>{ceoNm}</List.Row>

        {/* 휴대전화번호 */}
        <List.Row $ratio={storeColumn.data[6].ratio}>{ceoCellNumber}</List.Row>

        {/* 사업장 전화번호 */}
        <List.Row $ratio={storeColumn.data[7].ratio}>{contactNum}</List.Row>

        {/* 주소 */}
        <List.Row $ratio={storeColumn.data[8].ratio}>
          {[zipCode, addr, addr2].join(" ")}
        </List.Row>

        {/* URL */}
        <List.Row
          $ratio={storeColumn.data[9].ratio}
          onClick={openLink}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer",
            textDecoration: "underline",
            color: "#4253F2",
          }}
        >
          {linkUrl}
        </List.Row>

        {/* 추천인코드 */}
        <List.Row $ratio={storeColumn.data[10].ratio}>{refererCd}</List.Row>

        {/* 수수료율 */}
        <List.Row $ratio={storeColumn.data[11].ratio}>{feeRate}</List.Row>

        {/* 바로가기 버튼 */}
        <List.Row $ratio={storeColumn.data[12].ratio}>
          <DetailBtn.Wrapper
            onClick={() =>
              navToDetailHandler(
                uriEncoder(ownerAcctId, ownerAcctIndexId, state)
              )
            }
          >
            바로가기
          </DetailBtn.Wrapper>
        </List.Row>
      </Info.Content>
    );
  }
);
