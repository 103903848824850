import { Info, List, Wrapper } from "template/ListTemplate.style";
import paymentMonthlyColumn from "../paymentMonthlyColumn.json";
import { ListDefault } from "components/ListDefault/ListDefault";
import { PaginationBox } from "components/Pagination/PaginationBox";
import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { useToastCustom } from "hooks/useToastCustom";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import { POST_PAYMENT_MONTHLY_DETAIL } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { PAYMENT_MONTH_DETAIL_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";
import PaymentMonthlyDetailList from "./PaymentMonthlyDetailList";

const PaymentMonthlyDetail = ({
  data,
  detailPage,
  setDetailPage,
  searchDetailReq,
}) => {
  const toast = useToastCustom();
  const excelDownload = useExcelDownload();

  const { mutate } = useMutationCustom(POST_PAYMENT_MONTHLY_DETAIL);

  // 엑셀 다운로드
  const handleExcelDownload = () => {
    if (!data?.listCount) return toast("다운로드할 데이터가 없습니다", "error");

    const mutateData = {
      ...searchDetailReq,
      pageSize: data?.listCount,
      currentPage: 1,
    };

    mutate(mutateData, {
      onSuccess: (response) => {
        const excelData = {
          data: response.list,
          headers: PAYMENT_MONTH_DETAIL_XLSX_DOWNLOAD.header,
          fileName: PAYMENT_MONTH_DETAIL_XLSX_DOWNLOAD.filename,
        };

        excelDownload(excelData);
      },

      onError: () => {
        toast("전체 데이터 조회 실패", "error");
      },
    });
  };

  return (
    <Wrapper>
      <List.TitleContainer>
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <List.Text>연월 결제내역 상세 리스트</List.Text>
        </div>
        <List.BtnContainer>
          <ExcelDownloadBtn onClick={handleExcelDownload} />
        </List.BtnContainer>
      </List.TitleContainer>

      <List.Container>
        {!data.list?.length ? (
          <ListDefault text={"상단 목록에서 연월을"} />
        ) : (
          <>
            <List.Content>
              {paymentMonthlyColumn.detail.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>

            <Info.Wrapper>
              {data.list?.map((v, idx) => (
                <PaymentMonthlyDetailList key={idx} data={v} />
              ))}
            </Info.Wrapper>
          </>
        )}
      </List.Container>

      {data.list?.length ? (
        <>
          <PaginationBox
            totalItemsCount={data.listCount}
            page={detailPage}
            setPage={setDetailPage}
            size={10}
          />
        </>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

export default PaymentMonthlyDetail;
