import { Info, List } from "template/ListTemplate.style";
import sketchBackColumn from "../sketchBackColumn";
import { useMutationCustom } from "hooks/useMutationCustom";
import { POST_SKETCH_BACK_DETAIL } from "constants/pathConstant";
import { useEffect } from "react";

const SketchBackMonthList = ({
  data,
  setSketchBackDetailRes,
  detailPage,
  searchReq,
  setSearchDetailReq,
  setChecked,
  searchDetailReq,
  setDetailPage,
  detailSize,
  txState,
  setTxState,
}) => {
  const { mutate } = useMutationCustom(POST_SKETCH_BACK_DETAIL);

  useEffect(() => {
    if (!Object.keys(searchDetailReq)[0]) return;

    const researchData = {
      ...searchDetailReq,
      currentPage: detailPage,
      pageSize: detailSize,
      txState: txState,
    };
    setSearchDetailReq(researchData);

    mutate(researchData, {
      onSuccess: (data) => {
        setSketchBackDetailRes(data);
      },
    });
  }, [detailPage, detailSize, txState]);

  const detailHandler = () => {
    setDetailPage(1);
    setTxState("");

    const searchData = {
      ...searchReq,
      currentPage: detailPage,
      pageSize: detailSize,
      reqDate: data.reqDate,
      txState: txState,
    };

    setSearchDetailReq(searchData);

    mutate(searchData, {
      onSuccess: (res) => {
        setSketchBackDetailRes(res);
        setChecked([]);
      },
    });
  };

  return (
    <Info.Content style={{ cursor: "pointer" }} onClick={detailHandler}>
      {/* 번호 */}
      <List.Row $ratio={sketchBackColumn.month[0].ratio}>{data.idx}</List.Row>

      {/* 일자 */}
      <List.Row $ratio={sketchBackColumn.month[1].ratio}>
        {data.reqDate}
      </List.Row>

      {/* 스케치 합계(SKETCH) */}
      <List.Row $ratio={sketchBackColumn.month[2].ratio}>
        {data.totalAmountSketch}
      </List.Row>

      {/* 원화 합계(원) */}
      <List.Row $ratio={sketchBackColumn.month[3].ratio}>
        {data.totalAmountKrw}
      </List.Row>

      {/* 스케치 백 대상 지갑(개) */}
      <List.Row $ratio={sketchBackColumn.month[4].ratio}>
        {data.targetWalletCnt}
      </List.Row>

      {/* 스케치 백 총 개수(개) */}
      <List.Row $ratio={sketchBackColumn.month[5].ratio}>
        {data.totalCnt}
      </List.Row>
    </Info.Content>
  );
};

export default SketchBackMonthList;
