import { PLACEHOLDER } from "constants/baseStyle";
import { HTTP_METHOD, POST_WITHDRAW_LIST } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useEffect, useState } from "react";
import { Search, Wrapper } from "template/SearchTemplate.style";
import { dateFormat } from "components/dateFormat";

// 정산 -> 리워드 인출 :: 리워드 검색 (http://localhost:3000/super/calculate/reward-send)
const RewardSendSearch = ({
  setRewardSendList,
  setPage,
  currentPage,
  pageSize,
  setSearchData,
  searchData,
}) => {
  const toast = useToastCustom();

  const [id, setId] = useState("");
  const [txId, setTxId] = useState("");
  const [acctUserNm, setAcctUserNm] = useState("");
  const [acctCd, setAcctCd] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState(
    dateFormat(new Date(), false, 0, 0).slice(0, 10)
  );

  const { mutate } = useMutationCustom(POST_WITHDRAW_LIST, HTTP_METHOD.POST);

  // page, size 바뀔때
  useEffect(() => {
    if (!Object.keys(searchData)[0]) return;

    const researchData = { ...searchData, pageSize, currentPage };

    mutate(researchData, {
      onSuccess: (data) => {
        setRewardSendList(data);
      },

      onError: () => {
        toast("데이터 조회 실패", "error");
      },
    });
  }, [currentPage, pageSize]);

  const handleSearch = () => {
    setPage(1);

    const data = {
      id,
      useType: "W002",
      state: "00",
      txId,
      acctUserNm,
      acctCd,
      cellNumber,
      fromDt: fromDt && dateFormat(fromDt, true),
      toDt: dateFormat(toDt, true, 1),
      pageSize,
      currentPage,
    };

    setSearchData(data);

    mutate(data, {
      onSuccess: (data) => {
        setRewardSendList(data);
      },
    });
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  return (
    <Wrapper style={{ width: "83%" }}>
      <Search.Text>리워드 인출 검색</Search.Text>
      <Search.Container style={{ gap: "22rem" }}>
        <Search.Content style={{ width: "100%" }}>
          {/* 번호 */}
          <Search.InputWrapper>
            <div>번호</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setId(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 회원명 */}
          <Search.InputWrapper>
            <div>회원명</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setAcctUserNm(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 회원코드 */}
          <Search.InputWrapper>
            <div>회원코드</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setAcctCd(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 휴대전화번호 */}
          <Search.InputWrapper>
            <div>휴대전화번호</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setCellNumber(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 지갑 주소 */}
          <Search.InputWrapper style={{ width: "38rem" }}>
            <div>txId</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setTxId(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 신청일자 */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>신청일자 (부터)</div>
            <Search.Input
              type="date"
              defaultValue={fromDt}
              onChange={(e) => setFromDt(e.target.value)}
            ></Search.Input>
          </Search.InputWrapper>

          {/* 신청일자 */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>신청일자 (까지)</div>
            <Search.Input
              type="date"
              defaultValue={toDt.slice(0, 10)}
              onChange={(e) => setToDt(e.target.value)}
            ></Search.Input>
          </Search.InputWrapper>
        </Search.Content>

        {/* 조회 버튼 */}
        <Search.Btn onClick={handleSearch}>조회</Search.Btn>
      </Search.Container>
    </Wrapper>
  );
};

export default RewardSendSearch;
