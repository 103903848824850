import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 30%;
  padding: 2rem 1.2rem 0 1.2rem;
  position: relative;
`;

export const Schedule = {
  Title: styled.div`
    font-size: 1.5rem;
    font-weight: ${FONT_WEIGHT.BOLD};
  `,

  AddWrapper: styled.div`
    margin-top: 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  `,

  DateWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem 1rem;
    background-color: #4253f2;
    border-radius: 0.5rem;
    color: white;
    font-weight: ${FONT_WEIGHT.BOLD};
  `,

  AddBtn: styled.img`
    width: 1.2rem;
    height: 1.2rem;
    cursor: pointer;
  `,
};

export const List = {
  Wrapper: styled.div`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 30rem;
    overflow-y: scroll;
    padding-right: 0.5rem;
    margin-left: 0.5rem;
  `,
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    cursor: pointer;
    font-size: 1.3rem;
  `,
  TitleWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    background-color: ${(props) =>
      props.$shareYn === "Y"
        ? "#FFDE9F"
        : props.$isOpen
        ? "#eff1ff"
        : "#F8F8F8"};
    border-radius: 0.3rem;
    height: fit-content;
    z-index: 3;
  `,
  TitleCon1: styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 0.1rem 0;

    & > img {
      width: 1.5rem;
      height: 1.5rem;
    }
  `,

  TitleText: styled.div`
    text-overflow: ${(props) => (props.$isOpen ? "inherit" : "ellipsis")};
    max-width: 15rem;
    overflow: hidden;
    word-break: ${(props) => (props.$isOpen ? "break-all" : "keep-all")};
    font-size: 1rem;
    line-height: 1.5rem;
  `,

  TitleColor: styled.div`
    width: 1.1rem;
    height: 1.1rem;
    border-radius: 50%;
    background-color: ${(props) => props.$color};
  `,
  TitleCon2: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
  `,

  Btn: styled.img`
    width: 1rem;
    height: 1rem;
  `,

  BtnWrapper: styled.div`
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: ${(props) => (props.$isOpen ? "#cfd5ff" : "#e4e4e4")};
    }
  `,

  ContentWrapper: styled.div`
    padding: 1rem;
    background-color: ${(props) =>
      props.$shareYn === "Y" ? "#FFDE9F" : "#eff1ff"};
    border-radius: 0.3rem;
    line-height: 1.5rem;
    display: ${(props) => (props.$isOpen ? "block" : "none")};
    animation-name: ${(props) => (props.$isOpen ? "slideDown" : "slideUp")};
    animation-duration: 0.4s;
    animation-timing-function: ease-out;
    word-break: break-all;
    font-size: 1rem;
    white-space: pre-wrap;

    @keyframes slideDown {
      0% {
        margin-top: -20%;
        opacity: 0;
        display: none;
      }

      30% {
        opacity: 0.05;
      }

      40% {
        opacity: 0.5;
      }

      50% {
        opacity: 1;
      }

      100% {
        margin-top: 0;
        opacity: 1;
        z-index: 0;
      }
    }

    @keyframes slideUp {
      0% {
        margin-top: 0;
        opacity: 1;
        z-index: 0;
      }

      50% {
        opacity: 1;
      }

      60% {
        opacity: 0.5;
      }

      70% {
        opacity: 0.05;
      }

      100% {
        margin-top: -20%;
        opacity: 0;
      }
    }
  `,

  JoinWrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    & > span {
      color: #4253f2;
    }
  `,

  SharedWrapper: styled.div`
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 0.9rem;

    & > span {
      color: #4253f2;
    }
  `,
};

export const EmptyList = {
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10rem;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    gap: 1rem;

    & > img {
      width: 4rem;
      height: 4rem;
    }

    & > div {
      color: #d6d6d6;
      font-size: 1.3rem;
    }
  `,
};
