import { dateFormat } from "components/dateFormat";
import { PLACEHOLDER } from "constants/baseStyle";
import { HTTP_METHOD, POST_FIND_STORE_SUPER } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useEffect, useState } from "react";

import { Search, Wrapper } from "template/SearchTemplate.style";

// 승인 -> 가맹점 검색 :: 가맹점 조회 (http://localhost:3000/super/accept/store)
export const AcceptStoreSearch = ({
  setAcceptStoreList,
  setPage,
  currentPage,
  pageSize,
  setSearchData,
  searchData,
}) => {
  const toast = useToastCustom();

  const [storeNm, setStoreNm] = useState("");
  const [storeId, setStoreId] = useState("");
  const [ceoNm, setCeoNm] = useState("");
  const [ceoCellNumber, setCeoCellNumber] = useState("");
  const [confirmState, setConfirmState] = useState("");
  const [contactNum, setContactNum] = useState("");
  const [refererCd, setRefererCd] = useState("");
  const [serviceYn, setServiceYn] = useState("");
  const [feeRate, setFeeRate] = useState("");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState(dateFormat(new Date(), false, 0, 0).slice(0, 10));

  const { mutate } = useMutationCustom(POST_FIND_STORE_SUPER, HTTP_METHOD.POST);

  // page, size 바뀔때
  useEffect(() => {
    if (!Object.keys(searchData)[0]) return;

    const researchData = { ...searchData, pageSize, currentPage };
    setSearchData(researchData);

    mutate(researchData, {
      onSuccess: (data) => {
        setAcceptStoreList(data);
      },
      onError: () => {
        toast("데이터 조회 실패", "error");
      },
    });
  }, [currentPage, pageSize]);

  const handleSearch = () => {
    setPage(1);

    const data = {
      storeNm,
      ceoNm,
      storeId,
      ceoCellNumber,
      serviceYn,
      fromDt: fromDt && dateFormat(fromDt, true),
      toDt: dateFormat(toDt, true, 1),
      feeRate,
      contactNum,
      refererCd,
      confirmState,
      pageSize,
      currentPage,
    };

    setSearchData(data);

    mutate(data, {
      onSuccess: (data) => {
        setAcceptStoreList(data);
      },
    });
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  return (
    <Wrapper style={{ width: "70%" }}>
      <Search.Text>가맹점 조회</Search.Text>
      <Search.Container>
        <Search.Content style={{ width: "100%" }}>
          {/* 가맹점명 */}
          <Search.InputWrapper>
            <div>가맹점명</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setStoreNm(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 가맹점 코드 */}
          <Search.InputWrapper>
            <div>가맹점코드</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setStoreId(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 대표자명 */}
          <Search.InputWrapper>
            <div>대표자명</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setCeoNm(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 휴대전화번호 */}
          <Search.InputWrapper>
            <div>휴대전화번호</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setCeoCellNumber(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 사업장 전화번호 */}
          <Search.InputWrapper>
            <div>사업장 전화번호</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setContactNum(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 추천인코드 */}
          <Search.InputWrapper>
            <div>추천인코드</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setRefererCd(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 서비스 유무 */}
          <Search.InputWrapper>
            <div>서비스 유무</div>
            <Search.Select onChange={(e) => setServiceYn(e.target.value)}>
              <Search.Option value="">전체</Search.Option>
              <Search.Option value="Y">Y</Search.Option>
              <Search.Option value="N">N</Search.Option>
            </Search.Select>
          </Search.InputWrapper>

          {/* 승인상태 */}
          <Search.InputWrapper>
            <div>승인상태</div>
            <Search.Select onChange={(e) => setConfirmState(e.target.value)}>
              <Search.Option value="">전체</Search.Option>
              <Search.Option value="00">가승인</Search.Option>
              <Search.Option value="10">재승인</Search.Option>
              <Search.Option value="30">승인거절</Search.Option>
              <Search.Option value="90">최종승인</Search.Option>
              <Search.Option value="99">탈퇴</Search.Option>
            </Search.Select>
          </Search.InputWrapper>

          {/* 수수료율 */}
          <Search.InputWrapper>
            <div>수수료율</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setFeeRate(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 빈칸 자리매꾸기용*/}
          <Search.InputWrapper style={{ visibility: "hidden" }}>
            <div>빈칸</div>
          </Search.InputWrapper>

          {/* 가입일자 (부터) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>가입일자 (부터)</div>
            <Search.Input type="date" defaultValue={fromDt} onChange={(e) => setFromDt(e.target.value)}></Search.Input>
          </Search.InputWrapper>

          {/* 가입일자 (까지) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>가입일자 (까지)</div>
            <Search.Input
              type="date"
              defaultValue={toDt.slice(0, 10)}
              onChange={(e) => setToDt(e.target.value)}
            ></Search.Input>
          </Search.InputWrapper>
        </Search.Content>

        {/* 조회 버튼 */}
        <Search.Btn onClick={handleSearch} style={{ background: "#FEBD17" }}>
          조회
        </Search.Btn>
      </Search.Container>
    </Wrapper>
  );
};
