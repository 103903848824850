import { Info, List } from "template/ListTemplate.style";
import paymentColumn from "../paymentByUserColumn.json";
import { useState } from "react";
import { PaymentDetailRow } from "pages/Payment/PaymentWallet/PaymentWallet.style";
import { dateFormat } from "components/dateFormat";
import { costFormat } from "components/costForamt";
import { SYMBOL } from "constants/pathConstant";

// 회원검색 (검증 후 회원 정보 바로가기) :: 지갑정보 -> 결제 낱개 (http://localhost:3000/manage/user/:code)
const PaymentByUserDetail = ({ data }) => {
  const [clicked, setClicked] = useState(false);

  return (
    <>
      <Info.Content
        onClick={() => setClicked(!clicked)}
        isClicked={clicked}
        style={{ color: clicked && "#4253F2", cursor: "pointer" }}
      >
        {/* 번호 */}
        <List.Row $ratio={paymentColumn.column[0].ratio}>{data.id}</List.Row>

        {/* 거래일시 */}
        <List.Row $ratio={paymentColumn.column[1].ratio}>
          {dateFormat(data.paymentDt).slice(0, 11)}
        </List.Row>

        {/* 가맹점명 */}
        <List.Row $ratio={paymentColumn.column[2].ratio}>
          <List.RowElement>
            <p>{data.storeNm}</p>
            <p>({data.storeCd})</p>
          </List.RowElement>
        </List.Row>

        {/* 결제포인트 */}
        <List.Row $ratio={paymentColumn.column[3].ratio}>
          <List.RowElement>
            <p>{costFormat(data.sketchPayment, SYMBOL)}</p>
            <p>({costFormat(data.krwPayment, "원")})</p>
          </List.RowElement>
        </List.Row>

        {/* 결제금액 */}
        <List.Row $ratio={paymentColumn.column[4].ratio}>
          <List.RowElement>
            <p>{costFormat(data.sketchBnsSeq1, SYMBOL)}</p>
            <p>({costFormat(data.krwBnsSeq1, "원")})</p>
          </List.RowElement>
        </List.Row>

        {/* 시세 */}
        <List.Row $ratio={paymentColumn.column[5].ratio}>
          <List.RowElement>
            <p>{costFormat(data.krwPerSketch)}</p>
          </List.RowElement>
        </List.Row>

        {/* 수수료율 */}
        <List.Row $ratio={paymentColumn.column[6].ratio}>
          {data.storeFeeRate}%
        </List.Row>

        {/* 배송비 */}
        <List.Row $ratio={paymentColumn.column[7].ratio}>
          {costFormat(data.ssDeliveryCost)}
        </List.Row>

        {/* 결제 수단 */}
        <List.Row $ratio={paymentColumn.column[8].ratio}>
          {data.symbol}
        </List.Row>

        {/* 총 리워드 */}
        <List.Row $ratio={paymentColumn.column[9].ratio}>
          <List.RowElement>
            <p>{costFormat(data?.ssLastPricePaySketch, SYMBOL)}</p>
            <p>({costFormat(data?.ssLastPricePay)} 원)</p>
          </List.RowElement>
        </List.Row>

        {/* 상태 */}
        <List.Row $ratio={paymentColumn.column[10].ratio}>
          {data.paymentState}
        </List.Row>

        {/* 승인번호 */}
        <List.Row $ratio={paymentColumn.column[11].ratio}>
          {data.reqId}
        </List.Row>
      </Info.Content>

      {clicked && (
        <PaymentDetailRow.Wrapper>
          <PaymentDetailRow.Container>
            <PaymentDetailRow.Header>
              {paymentColumn.detailColumn.map((v, idx) => (
                <PaymentDetailRow.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </PaymentDetailRow.Row>
              ))}
            </PaymentDetailRow.Header>

            <PaymentDetailRow.Data>
              {/* 고객페이백 */}
              <PaymentDetailRow.Row
                $ratio={paymentColumn.detailColumn[0].ratio}
              >
                <List.RowElement>
                  <p>{costFormat(data.sketchBnsSeq2, SYMBOL)}</p>
                  <p>({costFormat(data.krwBnsSeq2, "원")})</p>
                </List.RowElement>
              </PaymentDetailRow.Row>

              {/* 스토어 추천보너스 */}
              <PaymentDetailRow.Row
                $ratio={paymentColumn.detailColumn[1].ratio}
              >
                <List.RowElement>
                  <p>{costFormat(data.sketchBnsSeq3, SYMBOL)}</p>
                  <p>({costFormat(data.krwBnsSeq3, "원")})</p>
                </List.RowElement>
              </PaymentDetailRow.Row>

              {/* 회사 보너스 */}
              <PaymentDetailRow.Row
                $ratio={paymentColumn.detailColumn[2].ratio}
              >
                <List.RowElement>
                  <p>{costFormat(data.sketchBnsSeq4, SYMBOL)}</p>
                  <p>({costFormat(data.krwBnsSeq4, "원")})</p>
                </List.RowElement>
              </PaymentDetailRow.Row>

              {/* 추천보너스 */}
              <PaymentDetailRow.Row
                $ratio={paymentColumn.detailColumn[3].ratio}
              >
                <List.RowElement>
                  <p>{costFormat(data.sketchBnsSeq5, SYMBOL)}</p>
                  <p>({costFormat(data.krwBnsSeq5, "원")})</p>
                </List.RowElement>
              </PaymentDetailRow.Row>

              {/* 추천 */}
              <PaymentDetailRow.Row
                $ratio={paymentColumn.detailColumn[4].ratio}
              >
                {data.refererNm && (
                  <List.RowElement>
                    <p>{data.refererNm}</p>
                    <p>({data.refererCd})</p>
                  </List.RowElement>
                )}
              </PaymentDetailRow.Row>

              {/* 스토어 추천 */}
              <PaymentDetailRow.Row
                $ratio={paymentColumn.detailColumn[5].ratio}
              >
                {data.storeRefererNm && (
                  <List.RowElement>
                    <p>{data.storeRefererNm}</p>
                    <p>({data.storeRefererCd})</p>
                  </List.RowElement>
                )}
              </PaymentDetailRow.Row>
            </PaymentDetailRow.Data>
          </PaymentDetailRow.Container>
        </PaymentDetailRow.Wrapper>
      )}
    </>
  );
};

export default PaymentByUserDetail;
