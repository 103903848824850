import { useRecoilValue } from "recoil";
import { UserLogsState } from "recoil/atom/UserDetailPageState";
import { UserState } from "recoil/atom/UserState";
import codeData from "./userLogsJson.json";
import { dateFormat } from "components/dateFormat";
import { LogsModal } from "../LogsModal.style";
import { useEffect, useState } from "react";
import { loginData } from "recoil/atom/LoginState";
import { useToastCustom } from "hooks/useToastCustom";
import { useMutationCustom } from "hooks/useMutationCustom";
import { HTTP_METHOD, POST_DELETE_USER_LOG, POST_MODIFY_USER_LOG } from "constants/pathConstant";
import { getUserParamsFromUri } from "utils/crypto";

export const UserLogsModal = ({ selected, setLogsAll, largeCd }) => {
  const toast = useToastCustom();
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [actionNotes, setActionNotes] = useState("");
  const [logsData, setLogsData] = useState([]);
  const YM = useRecoilValue(UserLogsState.YM);
  const YMD = useRecoilValue(UserLogsState.YMD);

  const logs = useRecoilValue(UserLogsState.UserLogs);
  const userDetailData = useRecoilValue(UserState.userDetail);
  const currentDate = useRecoilValue(UserLogsState.YMD);
  const { name } = useRecoilValue(loginData);

  const { acctCd } = getUserParamsFromUri();

  // 메모 수정
  const { mutate: mutateModifyLog } = useMutationCustom(POST_MODIFY_USER_LOG, HTTP_METHOD.POST);

  const handleMemoEdit = (id) => {
    mutateModifyLog(
      { id, actionNotes, acctCd, largeCd },
      {
        onSuccess: (response) => {
          setLogsAll(response);
          toast("메모가 수정되었습니다.", "success");
          setIsEdit(false);
        },
      }
    );
  };

  // 메모 삭제
  const { mutate: mutateDeleteLog } = useMutationCustom(POST_DELETE_USER_LOG, HTTP_METHOD.POST);

  const handleMemoDelete = (id) => {
    if (window.confirm("메모를 삭제하시겠습니까?")) {
      mutateDeleteLog(
        { id, acctCd, largeCd },
        {
          onSuccess: (response) => {
            setLogsAll(response);
            toast("메모가 삭제되었습니다.", "success");
          },
        }
      );
    } else {
    }
  };

  useEffect(() => {
    if (selected) {
      setLogsData(logs[YM][YMD]);
    } else {
      const tmp = Object.values(logs[YM][YMD]).reduce((acc, curr) => acc.concat(curr), []);
      setLogsData(tmp);
    }
  }, [logs]);

  return (
    <LogsModal.Wrapper>
      <LogsModal.Title>
        {userDetailData.acctUserNm} ({userDetailData.acctCd})_{currentDate}_{selected ? codeData[selected] : "전체"}{" "}
        메모
      </LogsModal.Title>

      <LogsModal.Container>
        <div>
          {logsData &&
            logsData.map((v, idx) => (
              <LogsModal.List key={idx}>
                <div>
                  <LogsModal.Writer>
                    <div>{dateFormat(v.crtDt)}</div>
                    <div>|</div>
                    <div>
                      {v.regId}
                      {v.editableCount > 0 ? "_수정됨" : ""}
                    </div>
                  </LogsModal.Writer>
                  {isEdit && v.id === editId ? (
                    <LogsModal.SettingBox>
                      <p
                        onClick={() => {
                          handleMemoEdit(v.id);
                        }}
                      >
                        수정
                      </p>
                      <p
                        onClick={() => {
                          setIsEdit(false);
                        }}
                      >
                        취소
                      </p>
                    </LogsModal.SettingBox>
                  ) : (
                    <LogsModal.SettingBox>
                      <p
                        onClick={() => {
                          if (v.editableYn === "N") return toast("해당글은 수정할 수 있습니다.", "error");
                          if (name !== v.regId) return toast("작성자만 수정할 수 있습니다.", "error");
                          setIsEdit(true);
                          setEditId(v.id);
                        }}
                      >
                        수정
                      </p>
                      <p
                        onClick={() => {
                          if (v.editableYn === "N") return toast("해당글은 삭제할 수 있습니다.", "error");
                          if (name !== v.regId) return toast("작성자만 삭제할 수 있습니다.", "error");
                          handleMemoDelete(v.id);
                        }}
                      >
                        삭제
                      </p>
                    </LogsModal.SettingBox>
                  )}
                </div>

                {isEdit && v.id === editId ? (
                  <textarea
                    defaultValue={v.actionNotes}
                    onChange={(e) => {
                      setActionNotes(e.target.value);
                    }}
                  />
                ) : (
                  <LogsModal.Content>{v.actionNotes}</LogsModal.Content>
                )}
              </LogsModal.List>
            ))}
        </div>
      </LogsModal.Container>
    </LogsModal.Wrapper>
  );
};
