import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  padding-top: 6rem;
  margin: 0 auto;

  & > p {
    font-size: 1.6rem;
  }
`;

export const SfuelTableName = styled.div`
  display: flex;
  font-size: 1.5rem;
  width: 50%;
  gap: 5rem;
  justify-content: center;

  & > p {
    text-align: center;
  }

  & > :nth-child(1) {
    flex: 3;
  }

  & > :nth-child(2) {
    flex: 1;
  }

  & > :nth-child(3) {
    flex: 1;
  }

  & > :nth-child(4) {
    flex: 1;
  }
`;

export const SfuelStateContainer = styled.div`
  width: 30%;
  display: flex;
  font-size: 1.6rem;
  justify-content: space-between;
  background: #fff;
  padding: 2rem;
  border-radius: 1rem;
  align-items: center;

  & > div {
    display: flex;
    gap: 1rem;
  }

  & > :nth-last-child(1) {
    background: #4253f2;
    color: #fff;
    padding: 1rem;
    border-radius: 1rem;
    cursor: pointer;
  }
`;
