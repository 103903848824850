import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 0.6rem;
  border: 1px solid #bbb;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  border-bottom: 1px solid #bbb;
  padding: 1.3rem;
  justify-content: space-between;
  align-items: center;
  background: #f3f3f3;
  gap: 15rem;

  & > strong {
    color: #000;
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 1.6rem;
    margin-top: 0.3rem;
  }
`;
