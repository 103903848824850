import { Info, List, Wrapper } from "template/ListTemplate.style";
import assetPayColumn from "../assetPayColumn.json";
import { costFormat } from "components/costForamt";

// 페이 자산내역 :: 요약 (http://localhost:3000/super/asset/pay)
const AssetPaySummary = ({ assetPayList }) => {
  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>
          페이 자산내역 요약
          {` (${assetPayList.acctUserNm ? assetPayList.acctUserNm : "전체"})`}
        </List.Text>
        <List.BtnContainer></List.BtnContainer>
      </List.TitleContainer>

      <List.Container style={{ minHeight: "0" }}>
        <List.Content>
          {assetPayColumn.balanceInfo.map((v, idx) => (
            <List.Row $ratio={v.ratio} key={idx}>
              {v.title}
            </List.Row>
          ))}
        </List.Content>

        <Info.Wrapper>
          <Info.Content>
            {/* 결제에서 사용한 충전 스케치 */}
            <List.Row $ratio={assetPayColumn.balanceInfo[0].ratio}>
              {costFormat(assetPayList.usedPaymentLockBalance)}
            </List.Row>

            {/* 결제에서 사용한 리워드 스케치 */}
            <List.Row $ratio={assetPayColumn.balanceInfo[1].ratio}>
              {costFormat(assetPayList.usedPaymentRewardBalance)}
            </List.Row>

            {/* 인출한 충전 스케치 */}
            <List.Row $ratio={assetPayColumn.balanceInfo[2].ratio}>
              {costFormat(assetPayList.withdrawLockBalance)}
            </List.Row>

            {/* 인출한 리워드 스케치 */}
            <List.Row $ratio={assetPayColumn.balanceInfo[3].ratio}>
              {costFormat(assetPayList.withdrawRewardBalance)}
            </List.Row>

            {/* 총 충전 스케치 */}
            <List.Row $ratio={assetPayColumn.balanceInfo[4].ratio}>
              {costFormat(assetPayList.chargeTotalLockBalance)}
            </List.Row>

            {/* 총 리워드 스케치 */}
            <List.Row $ratio={assetPayColumn.balanceInfo[5].ratio}>
              {costFormat(assetPayList.getTotalRewardBalance)}
            </List.Row>

            {/* 환불해서 회수한 리워드 스케치 */}
            <List.Row $ratio={assetPayColumn.balanceInfo[6].ratio}>
              {costFormat(assetPayList.cancelRewardBalance)}
            </List.Row>

            {/* 잔여 충전 스케치 */}
            <List.Row $ratio={assetPayColumn.balanceInfo[7].ratio}>
              {costFormat(assetPayList.amount)}
            </List.Row>

            {/* 잔여 리워드 스케치 */}
            <List.Row $ratio={assetPayColumn.balanceInfo[8].ratio}>
              {costFormat(assetPayList.rewardAmount)}
            </List.Row>

            {/* 잔여 총 스케치 */}
            <List.Row $ratio={assetPayColumn.balanceInfo[9].ratio}>
              {costFormat(assetPayList.totalAmount)}
            </List.Row>
          </Info.Content>
        </Info.Wrapper>
      </List.Container>
    </Wrapper>
  );
};

export default AssetPaySummary;
