import { useRecoilState } from "recoil";
import { modalState } from "recoil/atom/ModalState";
import { Wrapper } from "./Modal.style";

export const Modal = () => {
  const [modal, setModal] = useRecoilState(modalState);

  if (!modal.isOpen) return;

  const modalCloseHandler = (e) => {
    if (e.target === e.currentTarget) {
      if (modal.callback instanceof Function) modal.callback(false);
      setModal({ ...modal, isOpen: false });
    }
  };

  return (
    <Wrapper style={modal.style} onClick={(e) => modalCloseHandler(e)}>
      {modal.children}
    </Wrapper>
  );
};
