import { useCopyCustom } from "hooks/useCopyCustom";
import { Button, Container, Copy, Wrapper } from "./style";
import {
  GET_SFUEL_BALANCE_TOTAL,
  GET_SFUEL_COMPANY_WALLET,
  HTTP_METHOD,
  POST_WALLET_COLLECT,
} from "constants/pathConstant";
import { useEffect, useState } from "react";
import { useQueryCustom } from "hooks/useQueryCustom";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useQueryClient } from "@tanstack/react-query";

const SfuelWalletInfo = () => {
  const copy = useCopyCustom();
  const queryClient = useQueryClient();

  const [walletInfo, setWalletInfo] = useState({});
  const [balanceInfo, setBalanceInfo] = useState({});

  const { data } = useQueryCustom(
    ["companyWallet"],
    GET_SFUEL_COMPANY_WALLET
  )();

  const { data: balanceData } = useQueryCustom(
    ["balanceData"],
    GET_SFUEL_BALANCE_TOTAL
  )();

  useEffect(() => {
    if (!data || !balanceData) return;
    setWalletInfo(data);
    setBalanceInfo(balanceData);
  }, [data, balanceInfo, walletInfo, balanceData]);

  const { mutate } = useMutationCustom(POST_WALLET_COLLECT, HTTP_METHOD.POST);

  const handleCollectSfuel = () => {
    mutate({
      onSuccess: () => {
        queryClient.invalidateQueries(["companyWallet"]);
        queryClient.invalidateQueries(["balanceData"]);
      },
    });
  };

  return (
    <Wrapper>
      <strong>회사 지갑 정보</strong>
      <Container>
        <section>
          <p>지갑주소</p>
          <Copy
            onClick={() => {
              copy(walletInfo.address);
            }}
            cursor={true}
          >
            {walletInfo.address}
          </Copy>
        </section>
        <section>
          <p>잔액</p>
          <p>{walletInfo.balance}</p>
        </section>
        <section>
          <div>
            <p>모은 지갑 개수</p>
            <p>{balanceInfo.walletSize}개</p>
          </div>
          <div>
            <p>모은 sfuel 개수</p>
            <p>{balanceInfo.totalSfuel}개</p>
          </div>
        </section>
        <div>
          <Button onClick={handleCollectSfuel}>모으기</Button>
        </div>
      </Container>
    </Wrapper>
  );
};

export default SfuelWalletInfo;
