const localStorageKey = "user";

export const getUserFromLocalStorage = () => {
  const data = localStorage.getItem(localStorageKey);

  try {
    return JSON.parse(data);
  } catch {
    return "";
  }
};

export const setUserInLocalStorage = (data) => {
  localStorage.setItem(localStorageKey, JSON.stringify(data));
};
