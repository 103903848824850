import { Info, List } from "template/ListTemplate.style";
import sketchDropColumn from "../sketchDropColumn.json";
import { costFormat } from "components/costForamt";

// 정산 관리 -> 스케치 드랍 :: 상세 리스트 낱개  (http://localhost:3000/super/calculate/sketch-drop)
const SketchDropDetailList = ({ data }) => {
  return (
    <Info.Content>
      {/* 번호 */}
      <List.Row $ratio={sketchDropColumn.detail[0].ratio}>{data.id}</List.Row>

      {/* 회원코드 */}
      <List.Row $ratio={sketchDropColumn.detail[1].ratio}>
        {data.acctCd}
      </List.Row>

      {/* 회원명 */}
      <List.Row $ratio={sketchDropColumn.detail[2].ratio}>
        {data.acctUserNm}
      </List.Row>

      {/* 보유 */}
      <List.Row $ratio={sketchDropColumn.detail[3].ratio}>
        {costFormat(data.havingAmount)}
      </List.Row>

      {/* 사용 */}
      <List.Row $ratio={sketchDropColumn.detail[4].ratio}>
        {costFormat(data.usedAmount)}
      </List.Row>

      {/* 합계 */}
      <List.Row $ratio={sketchDropColumn.detail[5].ratio}>
        {costFormat(data.txValue)}
      </List.Row>

      {/* 일자 */}
      <List.Row $ratio={sketchDropColumn.detail[6].ratio}>
        {data.crtDt}
      </List.Row>
    </Info.Content>
  );
};

export default SketchDropDetailList;
