import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const MouseHoverEffect = styled.div`
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 0.6rem;
  padding: 1rem;
  position: absolute;
  z-index: 1;
  top: -0.5rem;
  left: 7.5rem;
  opacity: 0;
  transition: opacity 0.3s;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  font-weight: ${FONT_WEIGHT.BOLD};
`;

export const List = {
  TitleContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  TextContainer: styled.div`
    display: flex;
    gap: 1rem;
  `,

  BtnContainer: styled.div`
    display: flex;
    gap: 1rem;
  `,

  Text: styled.div`
    font-size: 2.2rem;

    & > span {
      margin-left: 1rem;
      font-size: 1.2rem;
      color: rgba(136, 136, 136, 1);
    }
  `,

  Container: styled.div`
    width: 100%;
    min-height: 30rem;
    background-color: white;
    border-radius: 1rem;
    overflow: hidden;
  `,

  ModalScrollContainer: styled.div`
    width: 100%;
    min-height: 30rem;
    background-color: white;
    border-radius: 1rem;
    overflow: hidden;
    max-height: 36rem;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }

    &::-webkit-scrollbar-button:vertical:start:decrement,
    &::-webkit-scrollbar-button:vertical:start:increment {
      display: block;
      height: 0.5rem;
    }

    &::-webkit-scrollbar-button:vertical:end:decrement {
      display: block;
      height: 0.8rem;
    }
  `,

  Content: styled.div`
    width: 100%;
    padding: 2rem 1rem 1rem;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    border-bottom: 0.1rem solid #e2e2e2;
    font-weight: ${FONT_WEIGHT.MEDIUM};
  `,

  Row: styled.span`
    flex: ${(props) => (props.$ratio ? props.$ratio : 1)};
    text-align: center;
    color: ${(props) => props.color && props.color};
    cursor: ${(props) => props.cursor || "normal"};
    position: relative;
    display: flex;
    justify-content: center;
    word-break: break-all;
    align-items: center;
    padding: 0.5rem;

    &:hover ${MouseHoverEffect} {
      visibility: visible;
      opacity: 1;
    }
  `,

  RowElement: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    & > :not(:first-child) {
      font-size: 1rem;
    }

    > p {
      display: flex;
      justify-content: center;
    }
  `,

  Column: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    > p {
      display: flex;
      justify-content: center;
      gap: 0.5rem;
    }
  `,
};

export const Info = {
  Wrapper: styled.div`
    width: 100%;

    & > div:nth-child(2n + 1) {
      background-color: #f8f8fa;
    }
  `,

  Content: styled.div`
    width: 100%;
    padding: 1.1rem 1rem 1rem;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: ${FONT_WEIGHT.LIGHT};
    color: black;
  `,
};
