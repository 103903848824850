import { SortCount } from "./PageHandler.style";

const PageHandler = ({ setPageSize, setPage }) => {
  return (
    <SortCount.Select
      defaultValue={10}
      onChange={(e) => {
        setPageSize(Number(e.target.value));
        setPage(1);
      }}
    >
      <SortCount.Option value={10}>10개씩 보기</SortCount.Option>
      <SortCount.Option value={20}>20개씩 보기</SortCount.Option>
      <SortCount.Option value={50}>50개씩 보기</SortCount.Option>
      <SortCount.Option value={100}>100개씩 보기</SortCount.Option>
    </SortCount.Select>
  );
};

export default PageHandler;
