import { useState } from "react";
import CurrentVersion from "./CurrentVersion";
import VersionList from "./VersionList";
import { Wrapper } from "./style";

// 시스템 관리 -> 버전 관리 :: (http://localhost:3000/super/system/version1)
const Version = () => {
  const [currentData, setCurrentData] = useState([]);
  return (
    <Wrapper>
      <CurrentVersion
        currentData={currentData}
        setCurrentData={setCurrentData}
      />
      <VersionList setCurrentData={setCurrentData} />
    </Wrapper>
  );
};

export default Version;
