import React from "react";
import storeColumn from "./superStoreColumn.json";
import { Info, List } from "template/ListTemplate.style";
import { DetailBtn } from "../StoreList.style";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import { NAV_USER_DETAIL_BASE_PAY } from "constants/pathConstant";
import { uriEncoder } from "utils/crypto";
import { dateFormat } from "components/dateFormat";

// 검색 -> 가맹점 검색 :: 가맹점 목록 리스트 낱개 (http://localhost:3000/super/search/store)
export const SuperStoreInfoListDetail = React.memo(
  ({
    id,
    crtDt,
    storeNm,
    storeId,
    ceoNm,
    ceoCellNumber,
    contactNum,
    ceoEmail,
    refererCd,
    feeRate,
    serviceYn,
    state,
    ownerAcctId,
    ownerAcctIndexId,
    confirmStateNm,
    confirmState,
    rootStoreId,
  }) => {
    const nav = useNavigationCustom();

    const navToDetailHandler = (code) => {
      const query = `?storeId=${storeId}`;
      nav(NAV_USER_DETAIL_BASE_PAY + code + query);
    };

    return (
      <Info.Content>
        {/* 번호 */}
        <List.Row $ratio={storeColumn.data[0].ratio}>{id}</List.Row>

        {/* 가입일자 */}
        <List.Row $ratio={storeColumn.data[1].ratio}>
          {dateFormat(crtDt).slice(0, 10)}
        </List.Row>

        {/* 가맹점명 */}
        <List.Row $ratio={storeColumn.data[2].ratio}>{storeNm}</List.Row>

        {/* 가맹점코드 */}
        <List.Row $ratio={storeColumn.data[3].ratio}>{storeId}</List.Row>

        {/* 메인스토어 */}
        <List.Row $ratio={storeColumn.data[4].ratio}>{rootStoreId}</List.Row>

        {/* 대표자명 */}
        <List.Row $ratio={storeColumn.data[5].ratio}>{ceoNm}</List.Row>

        {/* 전화번호 */}
        <List.Row $ratio={storeColumn.data[6].ratio}>{ceoCellNumber}</List.Row>

        {/* 사업장 전화번호 */}
        <List.Row $ratio={storeColumn.data[7].ratio}>{contactNum}</List.Row>

        {/* 이메일 */}
        <List.Row $ratio={storeColumn.data[8].ratio}>{ceoEmail}</List.Row>

        {/* 추천인코드 */}
        <List.Row $ratio={storeColumn.data[9].ratio}>{refererCd}</List.Row>

        {/* 수수료율 */}
        <List.Row $ratio={storeColumn.data[10].ratio}>{feeRate}</List.Row>

        {/* 서비스유무 */}
        <List.Row $ratio={storeColumn.data[11].ratio}>{serviceYn}</List.Row>

        {/* 승인상태 */}
        <List.Row
          color={storeColumn.confirmStateColor[confirmState]}
          $ratio={storeColumn.data[12].ratio}
          style={{ fontWeight: "bold" }}
        >
          {confirmStateNm}
        </List.Row>

        {/* 바로가기 버튼 */}
        <List.Row $ratio={storeColumn.data[12].ratio}>
          <DetailBtn.Wrapper
            onClick={() =>
              navToDetailHandler(
                uriEncoder(ownerAcctId, ownerAcctIndexId, state)
              )
            }
          >
            바로가기
          </DetailBtn.Wrapper>
        </List.Row>
      </Info.Content>
    );
  }
);
