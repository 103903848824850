import { dateFormat } from "components/dateFormat";
import { PLACEHOLDER } from "constants/baseStyle";
import { HTTP_METHOD, POST_FIND_DROP_USER } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useEffect, useState } from "react";
import { Search, Wrapper } from "template/SearchTemplate.style";
import dropUserColumn from "../dropUserColumn.json";
import { getUserParamsFromUri } from "utils/crypto";
import { dateFormatDay, dateFormatEnd, dateFormatStart } from "components/newDateFormat";

// 검색 -> 탈퇴 회원관리 :: 탈퇴 회원 조회 (http://localhost:3000/super/search/gone-user)
export const DropUserSearch = ({
  setDropUserList,
  setPage,
  currentPage,
  pageSize,
  setSearchData,
  searchData,
}) => {
  const toast = useToastCustom();

  const { id: mainId } = getUserParamsFromUri();

  const [acctNm, setAcctNm] = useState("");
  const [acctCd, setAcctCd] = useState("");
  const [refererNm, setRefererNm] = useState("");
  const [refererCd, setRefererCd] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [walletDefault, setWalletDefault] = useState("");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState(dateFormatDay(new Date()));
  const [fromReqDt, setFromReqDt] = useState("");
  const [toReqDt, setToReqDt] = useState(dateFormatDay(new Date()));

  const { mutate } = useMutationCustom(POST_FIND_DROP_USER, HTTP_METHOD.POST);

  // page, size 바뀔때
  useEffect(() => {
    if (!Object.keys(searchData)[0]) return;

    const researchData = { ...searchData, pageSize, currentPage };

    mutate(researchData, {
      onSuccess: (data) => {
        setDropUserList(data);
      },
      onError: () => {
        toast("데이터 조회 실패", "error");
      },
    });
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (mainId) {
      handleSearch(mainId);
    } else {
      handleSearch();
    }
  }, [mainId]);

  const handleSearch = (mainId) => {
    setPage(1);

    const searchCond = {
      id: mainId || "",
      acctNm,
      acctCd,
      refererNm,
      refererCd,
      cellNumber,
      email,
      walletDefault,
      state,
      fromDt: fromDt && dateFormatStart(fromDt),
      toDt: dateFormatEnd(toDt),
      fromReqDt: fromReqDt && dateFormatStart(fromReqDt),
      toReqDt: dateFormatEnd(toReqDt),
      pageSize,
      currentPage,
    };

    setSearchData(searchCond);

    mutate(searchCond, {
      onSuccess: (data) => {
        setDropUserList(data);
      },

      onError: () => {
        toast("데이터 조회 실패", "error");
      },
    });
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  return (
    <Wrapper>
      <Search.Text>탈퇴 회원 조회</Search.Text>
      <Search.Container>
        <Search.Content style={{ width: "90rem" }}>
          <Search.InputWrapper>
            {/* 회원명 */}
            <div>회원명</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setAcctNm(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          <Search.InputWrapper>
            {/* 회원코드 */}
            <div>회원코드</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setAcctCd(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          <Search.InputWrapper>
            {/* 추천인명 */}
            <div>추천인명</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setRefererNm(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          <Search.InputWrapper>
            {/* 추천인코드 */}
            <div>추천인코드</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setRefererCd(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          <Search.InputWrapper>
            {/* 전화번호 */}
            <div>전화번호</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setCellNumber(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          <Search.InputWrapper>
            {/* 이메일 */}
            <div>이메일</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setEmail(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 상태 */}
          <Search.InputWrapper>
            <div>상태</div>
            <Search.Select onChange={(e) => setState(e.target.value)}>
              <Search.Option value="">전체</Search.Option>
              {Object.entries(dropUserColumn.state).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </Search.Select>
          </Search.InputWrapper>

          <Search.InputWrapper style={{ width: "25rem" }}>
            {/* 지갑주소 */}
            <div>메인지갑주소</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setWalletDefault(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 가입일자 (부터) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>가입일자 (부터)</div>
            <Search.Input
              type="date"
              defaultValue={fromDt}
              onChange={(e) => setFromDt(e.target.value)}
            ></Search.Input>
          </Search.InputWrapper>

          {/* 가입일자 (까지) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>가입일자 (까지)</div>
            <Search.Input
              type="date"
              defaultValue={toDt.slice(0, 10)}
              onChange={(e) => setToDt(e.target.value)}
            ></Search.Input>
          </Search.InputWrapper>

          {/* 탈퇴일자 (부터) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>탈퇴신청일자 (부터)</div>
            <Search.Input
              type="date"
              defaultValue={fromReqDt}
              onChange={(e) => setFromReqDt(e.target.value)}
            ></Search.Input>
          </Search.InputWrapper>

          {/* 탈퇴일자 (까지) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>탈퇴신청일자 (까지)</div>
            <Search.Input
              type="date"
              defaultValue={toReqDt.slice(0, 10)}
              onChange={(e) => setToReqDt(e.target.value)}
            ></Search.Input>
          </Search.InputWrapper>
        </Search.Content>

        {/* 조회 버튼 */}
        <Search.Btn onClick={() => handleSearch()}>조회</Search.Btn>
      </Search.Container>
    </Wrapper>
  );
};
