import { useLocation } from "react-router";
import VersionUpload from "../VersionUpload";

// 시스템 관리 -> 버전 관리 :: 버전 수정 (http://localhost:3000/super/system/version1-modify)
const VersionModify = () => {
  const location = useLocation();

  return <VersionUpload modifyData={location.state.data} />;
};

export default VersionModify;
