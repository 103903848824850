import { POST_USER_LOG, HTTP_METHOD } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { UserLogsState } from "recoil/atom/UserDetailPageState";
import { getUserParamsFromUri } from "utils/crypto";
import { AllCategoryList } from "./AllCategoryList";
import { CategoryList } from "./CategoryList";
import { EmptyCategoryList } from "./EmptyCategoryList";
import { BigCategory, CategoryDiv, SmallCategory, SmallCategoryDiv, Wrapper } from "./UserModifyInfo.style";
import categoryJson from "../userModifyData.json";

// 회원검색 (검증 후 회원 정보 바로가기) :: 회원/가맹점 정보 수정 -> 최상단 수정내역 컴포넌트 (http://localhost:3000/manage/user/:code)
export const UserModifyInfo = ({ logsAll, setLogsAll }) => {
  const [actionCd, setActionCd] = useState("");
  const [largeCd, setLargeCd] = useState("");

  // const [logsAll, setLogsAll] = useState([]);
  const setLogs = useSetRecoilState(UserLogsState.UserLogs);
  const setYM = useSetRecoilState(UserLogsState.YM);
  const setYMD = useSetRecoilState(UserLogsState.YMD);

  // get 카테고리별 메모
  const { mutate } = useMutationCustom(POST_USER_LOG, HTTP_METHOD.POST);

  const handleCategory = (category) => {
    if (category === largeCd) return;
    setLargeCd(category);
    const { acctCd } = getUserParamsFromUri();

    const data = {
      largeCd: category,
      actionCd: "",
      acctCd,
    };

    mutate(data, {
      onSuccess: (response) => {
        setLogsAll(response);
      },
    });
  };

  useEffect(() => {
    const transformData = (data) => {
      return data.reduce((acc, current) => {
        const yearMonth = current.yearMonth;
        const day = current.yearMonthDay.split(" ")[0].split("-").slice(1).join("-");

        if (!acc[yearMonth]) {
          acc[yearMonth] = {};
        }

        if (!acc[yearMonth][day]) {
          acc[yearMonth][day] = [];
        }

        acc[yearMonth][day].push(current);
        return acc;
      }, {});
    };

    if (actionCd !== "") {
      const result = transformData(logsAll.filter((v) => v.actionCd === actionCd));
      setLogs(result);
    } else {
      const result = transformData(logsAll);
      setLogs(result);
    }
  }, [actionCd, largeCd, logsAll]);

  useEffect(() => {
    setYM(null);
    setYMD(null);
  }, [largeCd, actionCd]);

  useEffect(() => {
    setActionCd("");
  }, [largeCd]);

  return (
    <Wrapper>
      <BigCategory>
        <CategoryDiv
          selected={largeCd === categoryJson.largeCode["user"]}
          onClick={() => handleCategory(categoryJson.largeCode["user"])}
        >
          회원
        </CategoryDiv>
        <CategoryDiv
          selected={largeCd === categoryJson.largeCode["store"]}
          onClick={() => handleCategory(categoryJson.largeCode["store"])}
        >
          가맹점
        </CategoryDiv>
      </BigCategory>
      <SmallCategory>
        {largeCd === categoryJson.largeCode["user"] && (
          <>
            {categoryJson.column[categoryJson.largeCode["user"]].map((v, idx) => (
              <SmallCategoryDiv selected={actionCd === v.code} onClick={() => setActionCd(v.code)} key={idx}>
                {v.title}
              </SmallCategoryDiv>
            ))}
          </>
        )}

        {largeCd === categoryJson.largeCode["store"] && (
          <>
            {categoryJson.column[categoryJson.largeCode["store"]].map((v, idx) => (
              <SmallCategoryDiv selected={actionCd === v.code} onClick={() => setActionCd(v.code)} key={idx}>
                {v.title}
              </SmallCategoryDiv>
            ))}
          </>
        )}
      </SmallCategory>
      {/* 리스트 */}

      {/* 전체 조건 */}
      {actionCd === "" ? (
        // 전체 카테고리
        <AllCategoryList setLogsAll={setLogsAll} largeCd={largeCd} />
      ) : largeCd ? (
        // 서브 카테고리
        <CategoryList selected={actionCd} logsAll={logsAll} setLogsAll={setLogsAll} largeCd={largeCd} />
      ) : (
        // Empty List
        <EmptyCategoryList />
      )}
    </Wrapper>
  );
};
