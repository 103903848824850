import { useEffect, useState } from "react";
import { Wrapper } from "./Calendar.style";
import { CalendarList } from "./CalendarList/CalendarList";
import { ScheduleList } from "./ScheduleList/ScheduleList";
import { useMutationCustom } from "hooks/useMutationCustom";
import {
  HTTP_METHOD,
  POST_MAIN_SCHEDULE_FETCH_COUNT,
} from "constants/pathConstant";
import { useToastCustom } from "hooks/useToastCustom";
import { dateFormat } from "components/dateFormat";

const Calendar = () => {
  const [date, setDate] = useState(new Date());
  const [today] = useState(new Date());
  const [calendarCountObj, setCalendarCountObj] = useState({});

  const toast = useToastCustom();

  const { mutate: getCalendarCountList } = useMutationCustom(
    POST_MAIN_SCHEDULE_FETCH_COUNT,
    HTTP_METHOD.POST
  );

  useEffect(() => {
    const lastMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    const nextMonth = new Date(date.getFullYear(), date.getMonth() + 2, 0);

    const fetchData = {
      fromDt: dateFormat(lastMonth, true, 0),
      toDt: dateFormat(nextMonth, true, 1),
    };

    getCalendarCountList(fetchData, {
      onSuccess: (response) => {
        const newObj = { ...calendarCountObj };
        response.forEach((item) => {
          const scheduleDate = new Date(item.scheduleDate);
          const year = scheduleDate.getFullYear();
          const month = scheduleDate.getMonth() + 1;
          const date = scheduleDate.getDate();
          if (!newObj[year]) {
            newObj[year] = {};
          }

          if (!newObj[year][month]) {
            newObj[year][month] = {};
          }

          if (!newObj[year][month][date]) {
            newObj[year][month][date] = {};
          }

          newObj[year][month][date]["0"] = item.scheduleLevel0Count;
          newObj[year][month][date]["1"] = item.scheduleLevel1Count;
          newObj[year][month][date]["2"] = item.scheduleLevel2Count;
          newObj[year][month][date]["9"] = item.scheduleLevel9Count;
        });
        setCalendarCountObj(newObj);
      },

      onError: () => {
        toast("일정 불러오기 실패", "error");
      },
    });
  }, [date]);

  return (
    <Wrapper>
      <CalendarList
        date={date}
        setDate={setDate}
        today={today}
        calendarCountObj={calendarCountObj}
      />
      <ScheduleList date={date} setCalendarCountObj={setCalendarCountObj} />
    </Wrapper>
  );
};

export default Calendar;
