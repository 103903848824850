import React, { useState } from "react";
import { Wrapper } from "./AssetPay.style";
import AssetPaySearch from "./AssetPaySearch/AssetPaySearch";
import AssetPaySummary from "./AssetPaySummary/AssetPaySummary";
import AssetPayDetail from "./AssetPayDetail/AssetPayDetail";
import { PaginationBox } from "components/Pagination/PaginationBox";

// 페이 자산내역 :: (http://localhost:3000/super/asset/pay)
const AssetPay = () => {
  const [assetPayList, setAssetPayList] = useState({
    summary: {},
    list: [],
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});
  const [isSearch, setIsSearch] = useState(false);

  return (
    <Wrapper>
      <div>
        <AssetPaySearch
          setAssetPayList={setAssetPayList}
          setPage={setPage}
          currentPage={page}
          pageSize={size}
          setSearchData={setSearchData}
          searchData={searchData}
          setIsSearch={setIsSearch}
        />

        {assetPayList.summary && (
          <AssetPaySummary assetPayList={assetPayList.summary} />
        )}

        <AssetPayDetail
          assetPayList={assetPayList}
          setPage={setPage}
          setSize={setSize}
          searchData={searchData}
          isSearch={isSearch}
        />

        {isSearch && assetPayList.list && (
          <PaginationBox
            totalItemsCount={assetPayList.list[0]?.cnt}
            page={page}
            setPage={setPage}
            size={size}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default AssetPay;
