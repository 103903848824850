const {
  IMG_CDN_URL,
  POLYGON_SCAN_TX,
  ETH_SCAN_TX,
  BTC_SCAN_TX,
} = require("constants/pathConstant");
const { NavImgBox } = require("./Navigation.style");

/**
 *
 * @param {string} txId
 * @param {string} scanUrl
 * @returns
 */
export const NavTxId = (txId, scanUrl = "POL") => {
  const navLink = () => {
    if (scanUrl === "POL") return POLYGON_SCAN_TX;
    if (scanUrl === "ETH") return ETH_SCAN_TX;
    if (scanUrl === "BTC") return BTC_SCAN_TX;
  };

  const scanNavHandler = (e) => {
    e.stopPropagation();
    window.open(navLink() + txId);
  };

  return (
    <NavImgBox
      onClick={(e) => {
        scanNavHandler(e);
      }}
    >
      <img
        src={`${IMG_CDN_URL}/file/a391e219nav_icon-min.webp`}
        alt="nav_icon"
      />
    </NavImgBox>
  );
};
