import PageHandler from "components/PageHandler/PageHandler";
import versionColumn from "../versionColumn.json";
import { useEffect, useState } from "react";
import { Info, List, Wrapper } from "template/ListTemplate.style";
import { useMutationCustom } from "hooks/useMutationCustom";
import { HTTP_METHOD, POST_APP_VERSION_FETCH } from "constants/pathConstant";
import { TypeBox, TypeSelectBox, CreateBox } from "./style";
import VersionDetail from "./VersionDetail";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import { PaginationBox } from "components/Pagination/PaginationBox";
import { VersionModal } from "components/Modal/VersionModal";
import EmptyScreen from "components/EmptyScreen/EmptyScreen";

// 시스템 관리 -> 버전 관리 :: 버전 리스트 (http://localhost:3000/super/system/version1)
const VersionList = ({ setCurrentData }) => {
  const nav = useNavigationCustom();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [selectData, setSelectData] = useState({});
  const [detailData, setDetailData] = useState([]);
  const [reqType, setReqType] = useState("00");

  const { mutate } = useMutationCustom(
    POST_APP_VERSION_FETCH,
    HTTP_METHOD.POST
  );

  useEffect(() => {
    fetchData();
  }, [reqType, page, size]);

  useEffect(() => {
    setPage(1);
  }, [reqType]);

  const fetchData = () => {
    const data = {
      reqType,
      useYn: "",
      pageSize: size,
      currentPage: page,
    };

    setSearchData(data);

    mutate(data, {
      onSuccess: (res) => {
        setDetailData(res);
      },
    });
  };

  return (
    <Wrapper>
      <List.TitleContainer>
        <TypeBox>
          <List.Text>버전 상세</List.Text>
          <TypeSelectBox onChange={(e) => setReqType(e.target.value)}>
            {Object.entries(versionColumn.type).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </TypeSelectBox>
        </TypeBox>
        <List.BtnContainer>
          <PageHandler setPage={setPage} setPageSize={setSize} />
        </List.BtnContainer>
      </List.TitleContainer>

      <List.Container>
        {!!detailData.length ? (
          <>
            <List.Content>
              {versionColumn.detailData.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>
            <Info.Wrapper>
              {detailData?.map((v, idx) => (
                <VersionDetail
                  key={idx}
                  data={v}
                  searchUpdate={mutate}
                  searchData={searchData}
                  setDetailData={setDetailData}
                  setIsOpen={setIsOpen}
                  setSelectData={setSelectData}
                  setCurrentData={setCurrentData}
                />
              ))}
            </Info.Wrapper>
          </>
        ) : (
          <EmptyScreen
            text="현재 관리중인 버전이 없습니다."
            height="30rem"
            fontSize="1.4rem"
            imgWidth="11rem"
            imgHeight="11rem"
          />
        )}
      </List.Container>

      <CreateBox
        onClick={() => {
          nav("/super/system/version1-upload");
        }}
      >
        <div>작성하기</div>
      </CreateBox>

      {!!detailData.length && (
        <PaginationBox
          totalItemsCount={detailData[0]?.cnt}
          page={page}
          setPage={setPage}
          size={size}
        />
      )}

      {isOpen && <VersionModal data={selectData} setIsOpen={setIsOpen} />}
    </Wrapper>
  );
};

export default VersionList;
