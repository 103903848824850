import { dateFormat } from "components/dateFormat";
import { Info, List } from "template/ListTemplate.style";
import paymentColumn from "./paymentColumn.json";

const PaymentCancelDetail = ({ data }) => {
  return (
    <Info.Content>
      {/* 번호 */}
      <List.Row $ratio={paymentColumn.cancelDetail[0].ratio}>{data.reqSeq}</List.Row>

      {/* 승인번호 */}
      <List.Row $ratio={paymentColumn.cancelDetail[1].ratio}>{data.reqId}</List.Row>

      {/* 회원코드 */}
      <List.Row $ratio={paymentColumn.cancelDetail[2].ratio}>{data.acctCd}</List.Row>

      {/* 유형 */}
      <List.Row $ratio={paymentColumn.cancelDetail[3].ratio}>{data.usedNm}</List.Row>

      {/* 페이백 */}
      <List.Row $ratio={paymentColumn.cancelDetail[4].ratio}>{data.cancelSeq2}</List.Row>

      {/* 가맹점추천 */}
      <List.Row $ratio={paymentColumn.cancelDetail[5].ratio}>{data.cancelSeq3}</List.Row>

      {/* 회사 */}
      <List.Row $ratio={paymentColumn.cancelDetail[6].ratio}>{data.cancelSeq4}</List.Row>

      {/* 추천인 */}
      <List.Row $ratio={paymentColumn.cancelDetail[7].ratio}>{data.cancelSeq5}</List.Row>

      {/* 환수일자 */}
      <List.Row $ratio={paymentColumn.cancelDetail[8].ratio}>{dateFormat(data.crtDt)}</List.Row>
    </Info.Content>
  );
};

export default PaymentCancelDetail;
