import { ModalContaier, ModalWrapper } from "./style";

export const PopupModal = ({ data, setIsOpen }) => {
  return (
    <>
      <ModalWrapper onClick={() => setIsOpen((prev) => !prev)} />
      <ModalContaier>
        <div>
          <img src={data.linkImgKor} alt="popup_img_kr" />
        </div>
        <div>
          <img src={data.linkImgEng} alt="popup_img_eng" />
        </div>
      </ModalContaier>
    </>
  );
};
