import { ListDefault } from "components/ListDefault/ListDefault";
import { Info, List, Wrapper } from "template/ListTemplate.style";
import userColumn from "./walletAddress.json";
import WalletUserDetailList from "./WalletAddressDetailList";

// 회원검색 :: 회원 목록 리스트 (http://localhost:3000/manage/user/wallet)
const WalletAddressDetail = ({ walletAddressList }) => {
  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>지갑 생성 여부</List.Text>
      </List.TitleContainer>

      <List.Container>
        {walletAddressList.memberName ? (
          <>
            <List.Content>
              {userColumn.data.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>

            <Info.Wrapper>
              <WalletUserDetailList data={walletAddressList} />
            </Info.Wrapper>
          </>
        ) : (
          <ListDefault text={"회원을"} />
        )}
      </List.Container>
    </Wrapper>
  );
};

export default WalletAddressDetail;
