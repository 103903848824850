import CoinDetail from "./CoinDetail";
import { Container, Row, Wrapper } from "../CoinList.style";
import coinColumn from "../coinColumn.json";
import { Info, List } from "template/ListTemplate.style";

// 시스템 관리 -> 코인 관리 :: 코인 리스트 (http://localhost:3000/super/system/coin)
const CoinInfoList = ({ coinList, fetchData }) => {
  return (
    <Wrapper>
      <List.Text>코인 관리</List.Text>

      <Container>
        <List.Content>
          {coinColumn.data.map((v, idx) => (
            <Row $ratio={v.ratio} key={idx}>
              {v.title}
            </Row>
          ))}
        </List.Content>

        <Info.Wrapper>
          {coinList.map((v, idx) => (
            <CoinDetail data={v} key={idx} fetchData={fetchData} />
          ))}
        </Info.Wrapper>
      </Container>
    </Wrapper>
  );
};

export default CoinInfoList;
