import React from "react";
import { Info, List } from "template/ListTemplate.style";
import { uriEncoder } from "utils/crypto";
import userColumn from "./superUserColumn.json";
import { DetailBtn } from "../UserList.style";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import { NAV_USER_DETAIL_BASE_PAY } from "constants/pathConstant";
import WalletSlice from "components/WalletSlice/WalletSlice";

// 검색 -> 회원검색 :: 회원 목록 리스트 낱개 (http://localhost:3000/super/search/user)
export const SuperUserInfoListDetail = React.memo(
  ({
    id,
    crtDt,
    acctUserNm,
    acctCd,
    refererNm,
    refererCd,
    cellNumber,
    email,
    walletDefault,
    stateNm,
    state,
    storeId,
    birthDay,
  }) => {
    const nav = useNavigationCustom();

    const handleNavToDetail = (code) => {
      nav(NAV_USER_DETAIL_BASE_PAY + code);
    };

    const handleStateColor = (state) => {
      if (state === "정상") return "#21F650";
      if (state === "탈퇴") return "#F00";
      if (state === "탈퇴 신청") return "#888";
    };

    return (
      <Info.Content>
        {/* 번호 */}
        <List.Row $ratio={userColumn.data[0].ratio}>{id}</List.Row>

        {/* 가입일자 */}
        <List.Row $ratio={userColumn.data[1].ratio}>
          {crtDt.slice(0, 10)}
        </List.Row>

        {/* 회원명 */}
        <List.Row $ratio={userColumn.data[2].ratio}>{acctUserNm}</List.Row>

        {/* 회원코드 */}
        <List.Row $ratio={userColumn.data[3].ratio}>{acctCd}</List.Row>

        {/* 생년월일 */}
        <List.Row $ratio={userColumn.data[4].ratio}>{birthDay}</List.Row>

        {/* 추천인명 */}
        <List.Row $ratio={userColumn.data[5].ratio}>{refererNm}</List.Row>

        {/* 추천인코드 */}
        <List.Row $ratio={userColumn.data[6].ratio}>{refererCd}</List.Row>

        {/* 전화번호 */}
        <List.Row $ratio={userColumn.data[7].ratio}>{cellNumber}</List.Row>

        {/* 이메일 */}
        <List.Row $ratio={userColumn.data[8].ratio}>{email}</List.Row>

        {/* 지갑주소 */}
        <List.Row $ratio={userColumn.data[9].ratio}>
          {WalletSlice(walletDefault)}
        </List.Row>

        {/* 활동상태 */}
        <List.Row
          $ratio={userColumn.data[10].ratio}
          style={{ color: handleStateColor(stateNm) }}
        >
          {stateNm}
        </List.Row>

        {/* 스토어 ID */}
        <List.Row $ratio={userColumn.data[11].ratio}>{storeId}</List.Row>

        {/* 바로가기 버튼 */}
        <List.Row $ratio={userColumn.data[12].ratio}>
          <DetailBtn.Wrapper
            onClick={() => handleNavToDetail(uriEncoder(acctCd, id, state))}
          >
            바로가기
          </DetailBtn.Wrapper>
        </List.Row>
      </Info.Content>
    );
  }
);
