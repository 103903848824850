import { IMG_CDN_URL, POLYGON_SCAN_TX } from "constants/pathConstant";
import { useState } from "react";
import { Info, List } from "template/ListTemplate.style";
import withdrawColumn from "../withdrawByUserColumn.json";
import { dateFormat } from "components/dateFormat";
import { NavImgBox, WithdrawDetailRow } from "../../style";
import { costFormat } from "components/costForamt";

// 회원검색 (검증 후 회원 정보 바로가기) :: 지갑정보 -> 인출 낱개 (http://localhost:3000/manage/user/:code)
const WithdrawByUserDetail = ({ data }) => {
  const [clicked, setClicked] = useState(false);

  const polygonNavHandler = (txId, e) => {
    e.stopPropagation();
    window.open(POLYGON_SCAN_TX + txId);
  };
  return (
    <>
      <Info.Content
        onClick={() => setClicked(!clicked)}
        isClicked={clicked}
        style={{ color: clicked && "#4253F2", cursor: "pointer" }}
      >
        {/* 번호 */}
        <List.Row $ratio={withdrawColumn?.data[0]?.ratio}>{data.id}</List.Row>

        {/* 구분 */}
        <List.Row $ratio={withdrawColumn?.data[1]?.ratio}>
          {data.usedNm}
        </List.Row>

        {/* 수량 */}
        <List.Row $ratio={withdrawColumn?.data[2]?.ratio}>
          {costFormat(data.amount)}
        </List.Row>

        {/* 수수료 */}
        <List.Row $ratio={withdrawColumn?.data[3]?.ratio}>
          {costFormat(data.fee)}
        </List.Row>

        {/* 신청 시간 */}
        <List.Row
          $ratio={withdrawColumn?.data[4]?.ratio}
          style={{ fontSize: "1rem" }}
        >
          {dateFormat(data.crtDt)}
        </List.Row>

        {/* 최종 반영 시간 */}
        <List.Row
          $ratio={withdrawColumn?.data[5]?.ratio}
          style={{ fontSize: "1rem" }}
        >
          {dateFormat(data.updDt)}
        </List.Row>

        {/* 상태 */}
        <List.Row
          $ratio={withdrawColumn?.data[6]?.ratio}
          color={withdrawColumn.stateColor[data.state]}
          // style={{ fontWeight: "bold", wordBreak: "keep-all" }}
        >
          {withdrawColumn.state[data.state]}
        </List.Row>

        {/* 수량 */}
        <List.Row $ratio={withdrawColumn?.data[7]?.ratio}>
          {data.reqAdmId}
        </List.Row>

        {/* 바로가기 */}
        <List.Row
          $ratio={withdrawColumn?.data[8].ratio}
          cursor={"pointer"}
          onClick={(e) => polygonNavHandler(data.txId, e)}
        >
          <NavImgBox>
            <img
              src={`${IMG_CDN_URL}/file/a391e219nav_icon-min.webp`}
              alt="nav_icon"
              style={{ width: "100%", height: "100%" }}
            />
          </NavImgBox>
        </List.Row>
      </Info.Content>

      {clicked && (
        <WithdrawDetailRow.Wrapper>
          <WithdrawDetailRow.Container>
            <WithdrawDetailRow.Header>
              {withdrawColumn?.detailData.map((v, idx) => (
                <WithdrawDetailRow.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </WithdrawDetailRow.Row>
              ))}
            </WithdrawDetailRow.Header>

            <WithdrawDetailRow.Data>
              {/* 인출 수수료에서 사용된 락 밸런스 */}
              <WithdrawDetailRow.Row
                $ratio={withdrawColumn.detailData[0].ratio}
              >
                {data.useLockFee}
              </WithdrawDetailRow.Row>

              {/* 인출 수수료에서 사용된 리워드 밸런스 */}
              <WithdrawDetailRow.Row
                $ratio={withdrawColumn.detailData[0].ratio}
              >
                {data.useRewardFee}
              </WithdrawDetailRow.Row>

              {/* 인출에서 사용된 전체 락 밸런스 */}
              <WithdrawDetailRow.Row
                $ratio={withdrawColumn.detailData[0].ratio}
              >
                {data.lockTotalAmount}
              </WithdrawDetailRow.Row>

              {/* 인출에서 사용된 전체 리워드 밸런스 */}
              <WithdrawDetailRow.Row
                $ratio={withdrawColumn.detailData[0].ratio}
              >
                {data.rewardTotalAmount}
              </WithdrawDetailRow.Row>

              {/* 인출에서 사용된 전체 밸런스 */}
              <WithdrawDetailRow.Row
                $ratio={withdrawColumn.detailData[0].ratio}
              >
                {data.withdrawTotalAmount}
              </WithdrawDetailRow.Row>
            </WithdrawDetailRow.Data>
          </WithdrawDetailRow.Container>
        </WithdrawDetailRow.Wrapper>
      )}
    </>
  );
};

export default WithdrawByUserDetail;
