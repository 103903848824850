import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-top: 6rem;
  align-items: center;

  & > div {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
`;

export const VerifyUser = {
  Wrapper: styled.div`
    padding: 0 1rem;
    display: flex;
    gap: 0.3rem;
    border-radius: 0.4rem;
    background: #4253f2;
    align-items: center;
    justify-content: center;
    height: 2rem;
  `,

  Img: styled.img`
    width: 1.2rem;
    height: 1.2rem;
  `,

  Text: styled.div`
    color: white;
    font-size: 1rem;
    font-weight: ${FONT_WEIGHT.BOLD};
  `,
};

export const PaymentDetailRow = {
  Wrapper: styled.div`
    padding: 1rem;
    padding-left: 5rem;
  `,

  Container: styled.div`
    width: 100%;
    border: 0.1rem solid black;
  `,

  Header: styled.div`
    padding: 0.5rem 1rem;
    height: 2.5rem;
    color: white;
    background: #4253f2;
    display: flex;
    align-items: center;
  `,

  Data: styled.div`
    padding: 0.5rem 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
  `,

  Row: styled.div`
    flex: ${(props) => props.$ratio};
    text-align: center;
  `,
};

export const RowElement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  & > :not(:first-child) {
    font-size: 1rem;
  }

  > p {
    display: flex;
    justify-content: center;
  }
`;

export const NavImgBox = styled.div`
  width: 1.2rem;
  height: 1.2rem;

  > img {
    width: 100%;
    height: 100%;
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;

  & > div:nth-child(2n + 1) {
    background-color: ${(props) => (props.cancelBg ? "#none" : "#f8f8fa")};
  }
`;

export const ClickInfo = {
  Wrapper: styled.div`
    padding: 1rem;
    padding-left: 5rem;
    font-size: 1.2rem;
  `,

  Container: styled.div`
    width: 100%;
    border: 0.1rem solid black;
  `,

  Header: styled.div`
    padding: 0.5rem 1rem;
    color: white;
    background: #4253f2;
    display: flex;
    align-items: center;
  `,
  Data: styled.div`
    padding: 0.5rem 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
  `,

  Row: styled.div`
    flex: ${(props) => props.$ratio};
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
  `,
};
