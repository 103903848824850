import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 1rem;
  border: 1px solid #f6f6f6;
  background: #fff;
  box-shadow: 0px 0.4rem 0.4rem 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 4rem 4rem 3rem;

  & > p {
    font-size: 1.2rem;
    color: #888;
  }
`;

export const Title = styled.div`
  font-size: 2rem;
  font-weight: ${FONT_WEIGHT.BOLD};
  text-align: center;

  & > span {
    color: red;
  }
`;

export const Sub = styled.div`
  color: #888;
  font-size: 1.5rem;
  margin: 2rem 0 0.5rem;
`;

export const BtnWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 0.6rem;
  justify-content: space-between;
  align-items: end;
`;

export const Btn = styled.div`
  width: 6rem;
  height: 3.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  font-weight: ${FONT_WEIGHT.BOLD};
  cursor: pointer;
  background-color: #bbb;
  color: white;
`;

export const QuitBtn = styled(Btn)`
  background-color: ${(props) => (props.quitCheck ? "#4253f2" : "#ddd")};
  color: ${(props) => (props.quitCheck ? "white" : "white")};
  cursor: ${(props) => (props.quitCheck ? "pointer" : "default")};
`;

export const TextAreaBox = styled.div`
  font-size: 1.5rem;
  width: 100%;

  & > textarea {
    min-width: 100%;
    min-height: 10rem;
    padding: 1rem;
    box-sizing: border-box;
  }
`;

export const QuitCheckBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 1.2rem;
`;

export const SubmitBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: end;

  & > p {
    color: #888;
  }

  & > div {
    display: flex;
    gap: 0.5rem;
  }
`;

export const CheckBtn = styled.div`
  background: #2db9c2;
  padding: 0.5rem;
  color: #fff;
  border-radius: 0.6rem;
  cursor: pointer;
`;

export const CheckUnlockBtn = styled(CheckBtn)`
  background-color: ${(props) => (props.settleCheck ? "#2db9c2" : "#ddd")};
  color: ${(props) => (props.settleCheck ? "white" : "white")};
  cursor: ${(props) => (props.settleCheck ? "pointer" : "default")};
`;

export const BalanceBox = styled.div`
  margin-top: 2rem;
  font-size: 1.2rem;

  & > div {
    display: flex;
    justify-content: space-between;

    & > :nth-child(1) {
      color: #f00;
    }

    & > :nth-child(2) {
      color: #888;
    }
  }
`;
