import React, { useState } from "react";
import { Info, List } from "template/ListTemplate.style";
import { Verify, VerifyUser } from "../UserList.style";
import { uriEncoder } from "utils/crypto";
import userColumn from "./userColumn.json";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import {
  HTTP_METHOD,
  NAV_USER_DETAIL_BASE_PAY,
  POST_VERIFY_CELLNUMBER,
  // VERIFY_TYPE,
} from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";

// 회원검색 :: 회원 목록 리스트 낱개 (http://localhost:3000/manage/user)
export const UserInfoListDetail = ({ data: props }) => {
  const toast = useToastCustom();
  const nav = useNavigationCustom();

  const [typedNumber, setTypedNumber] = useState("");

  const handleNavToDetail = (code) => {
    nav(NAV_USER_DETAIL_BASE_PAY + code);
  };

  const { mutate } = useMutationCustom(
    POST_VERIFY_CELLNUMBER,
    HTTP_METHOD.POST
  );

  const verifyCellNumber = () => {
    if (typedNumber.length !== 4)
      return toast("전화번호를 제대로 입력하세요.", "error");

    const acctCd = props.acctCd;
    // const verifyType = VERIFY_TYPE.USER;
    const cellNumber = props.cellNumber + typedNumber;
    const state = props.state;
    // const id = props.id;

    const data = {
      acctCd,
      // verifyType,
      cellNumber,
      state,
      // id,
    };

    mutate(data, {
      onSuccess: (response) => {
        if (response.verifyResult) {
          handleNavToDetail(uriEncoder(props.acctCd, props.id, props.state));
        } else {
          toast("전화번호가 다릅니다.", "error");
        }
      },

      onError: () => {
        toast("다시 시도하세요.", "error");
      },
    });
  };

  const handleEnter = (key) => {
    if (key === "Enter") verifyCellNumber();
  };

  return (
    <Info.Content>
      {/* 번호 */}
      <List.Row $ratio={userColumn.data[0].ratio}>{props.id}</List.Row>

      {/* 가입일자 */}
      <List.Row $ratio={userColumn.data[1].ratio}>
        {props.crtDt.slice(0, 16)}
      </List.Row>

      {/* 회원명 */}
      <List.Row $ratio={userColumn.data[2].ratio}>{props.acctUserNm}</List.Row>

      {/* 회원코드 */}
      <List.Row $ratio={userColumn.data[3].ratio}>{props.acctCd}</List.Row>

      {/* 회원코드 */}
      <List.Row $ratio={userColumn.data[4].ratio}>{props.birthDay}</List.Row>

      {/* 휴대전화 */}
      <List.Row
        $ratio={userColumn.data[5].ratio}
        style={{
          gap: "0.3rem",
        }}
      >
        {props.cellNumber}
        <Verify.Input
          onChange={(e) => setTypedNumber(e.target.value)}
          onKeyUp={(e) => handleEnter(e.key)}
        />
      </List.Row>

      {/* 활동상태 */}
      <List.Row $ratio={userColumn.data[6].ratio}>{props.stateNm}</List.Row>

      {/* 고객정보 검증 버튼 */}
      <List.Row
        $ratio={userColumn.data[7].ratio}
        onClick={verifyCellNumber}
        style={{ cursor: "pointer" }}
      >
        <VerifyUser.Wrapper>
          <VerifyUser.Img src="/assets/imgs/userList_verify.svg" />
          <VerifyUser.Text>고객정보 검증</VerifyUser.Text>
        </VerifyUser.Wrapper>
      </List.Row>
    </Info.Content>
  );
};
