import { Info, List } from "template/ListTemplate.style";
import sketchDropColumn from "../../sketchDropColumn.json";
import { costFormat } from "components/costForamt";
import { useMutationCustom } from "hooks/useMutationCustom";
import { HTTP_METHOD, POST_SKETCH_DROP_DETAIL } from "constants/pathConstant";
import { useEffect } from "react";

// 정산 관리 -> 스케치 드랍 :: 연월별 드랍 낱개  (http://localhost:3000/super/calculate/sketch-drop)
const SketchDropSelectList = ({
  data,
  setSketchDropDetailRes,
  userData,
  detailPage,
  searchDetailReq,
  setSearchDetailReq,
  setDetailPage,
  detailSize,
  sortOption,
}) => {
  const { mutate } = useMutationCustom(
    POST_SKETCH_DROP_DETAIL,
    HTTP_METHOD.POST
  );

  useEffect(() => {
    if (!Object.keys(searchDetailReq)[0]) return;

    const researchData = {
      ...searchDetailReq,
      currentPage: detailPage,
      pageSize: detailSize,
      sortOption,
    };
    setSearchDetailReq(researchData);

    mutate(researchData, {
      onSuccess: (data) => {
        setSketchDropDetailRes(data.list);
      },
    });
  }, [detailPage, detailSize, sortOption]);

  const handleSelected = () => {
    setDetailPage(1);

    const requestData = {
      acctUserNm: userData.memberName || "",
      acctCd: userData.memberCode || "",
      pageSize: detailSize,
      currentPage: 1,
      yymm: data.yymm,
      sortOption,
    };

    setSearchDetailReq(requestData);

    mutate(requestData, {
      onSuccess: (response) => {
        setSketchDropDetailRes(response.list);
      },
    });
  };

  return (
    <Info.Content onClick={handleSelected}>
      {/* 번호 */}
      <List.Row $ratio={sketchDropColumn.select[0].ratio}>
        {costFormat(data.idx)}
      </List.Row>

      {/* 일자 */}
      <List.Row $ratio={sketchDropColumn.select[1].ratio}>{data.yymm}</List.Row>

      {/* 보유 */}
      <List.Row $ratio={sketchDropColumn.select[2].ratio}>
        {costFormat(data.havingAmount)}
      </List.Row>

      {/* 사용 */}
      <List.Row $ratio={sketchDropColumn.select[3].ratio}>
        {costFormat(data.usedAmount)}
      </List.Row>

      {/* 합계 */}
      <List.Row $ratio={sketchDropColumn.select[4].ratio}>
        {costFormat(data.txValue)}
      </List.Row>

      {/* 대상 인원 */}
      <List.Row $ratio={sketchDropColumn.select[5].ratio}>
        {costFormat(data.cntMembers)}
      </List.Row>

      {/* 건수 */}
      <List.Row $ratio={sketchDropColumn.select[6].ratio}>
        {costFormat(data.cnt)}
      </List.Row>
    </Info.Content>
  );
};

export default SketchDropSelectList;
