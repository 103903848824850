import { Info, List } from "template/ListTemplate.style";
import swapSummaryColumn from "../swapSummaryColumn.json";
import { costFormat } from "components/costForamt";

// 브릿지 -> 스왑 :: 요약 낱개 (http://localhost:3000/super/bridge/swap)
const SwapSummaryDetail = ({ data }) => {
  return (
    <Info.Content>
      {/* 총 스왑 금액 */}
      <List.Row $ratio={swapSummaryColumn.summary[0].ratio}>
        {costFormat(data?.totalAmount)}
      </List.Row>

      {/* 총 스왑 신청 금액 */}
      <List.Row $ratio={swapSummaryColumn.summary[1].ratio}>
        {costFormat(data?.reqAmount)}
      </List.Row>

      {/* 총 스왑 수수료 */}
      <List.Row $ratio={swapSummaryColumn.summary[2].ratio}>
        {costFormat(data?.feeAmount)}
      </List.Row>

      {/* 총 개수 */}
      <List.Row $ratio={swapSummaryColumn.summary[3].ratio}>
        {costFormat(data?.cnt)}
      </List.Row>

      {/* 성공 개수 */}
      <List.Row $ratio={swapSummaryColumn.summary[3].ratio}>
        {costFormat(data?.successCnt)}
      </List.Row>

      {/* 나머지 개수 */}
      <List.Row $ratio={swapSummaryColumn.summary[3].ratio}>
        {costFormat(data?.failCnt)}
      </List.Row>
    </Info.Content>
  );
};

export default SwapSummaryDetail;
