import React, { useState } from "react";
import { Info, List } from "template/ListTemplate.style";
import storeColumn from "./storeColumn.json";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import {
  HTTP_METHOD,
  NAV_USER_DETAIL_BASE_PAY,
  POST_VERIFY_CELLNUMBER,
  VERIFY_TYPE,
} from "constants/pathConstant";
import { uriEncoder } from "utils/crypto";
import { Verify, VerifyUser } from "pages/User/UserList.style";
import { useToastCustom } from "hooks/useToastCustom";
import { useMutationCustom } from "hooks/useMutationCustom";
import { dateFormat } from "components/dateFormat";

// 가맹점 검색 :: 가맹점 목록 리스트 낱개 (http://localhost:3000/manage/store)
export const StoreInfoListDetail = ({ data: props }) => {
  const toast = useToastCustom();
  const nav = useNavigationCustom();

  const [typedNumber, setTypedNumber] = useState("");

  const navToDetailHandler = (code) => {
    const storeId = props.storeId;
    const query = `?storeId=${storeId}`;

    nav(NAV_USER_DETAIL_BASE_PAY + code + query);
  };

  const { mutate } = useMutationCustom(
    POST_VERIFY_CELLNUMBER,
    HTTP_METHOD.POST
  );

  const verifyCellNumber = () => {
    if (typedNumber.length !== 4)
      return toast("전화번호를 제대로 입력하세요.", "error");

    const storeId = props.storeId;
    const verifyType = VERIFY_TYPE.STORE;
    const cellNumber = props.cellNumber + typedNumber;
    const state = props.state;
    const id = props.id;

    const data = {
      storeId,
      verifyType,
      cellNumber,
      state,
      id,
    };

    mutate(data, {
      onSuccess: (response) => {
        if (response.verifyResult === "true") {
          navToDetailHandler(
            uriEncoder(props.acctCd, props.acctIndexId, props.state)
          );
        } else {
          toast("전화번호가 다릅니다.", "error");
        }
      },

      onError: () => {
        toast("다시 시도하세요.", "error");
      },
    });
  };

  const handleEnter = (key) => {
    if (key === "Enter") verifyCellNumber();
  };

  return (
    <Info.Content>
      {/* 번호 */}
      <List.Row $ratio={storeColumn.data[0].ratio}>{props.id}</List.Row>

      {/* 생성일자 */}
      <List.Row $ratio={storeColumn.data[1].ratio}>
        {dateFormat(props.crtDt)}
      </List.Row>

      {/* 가맹점명 */}
      <List.Row $ratio={storeColumn.data[2].ratio}>{props.storeNm}</List.Row>

      {/* 점포코드 */}
      <List.Row $ratio={storeColumn.data[3].ratio}>{props.storeId}</List.Row>

      {/* 대표자명 */}
      <List.Row $ratio={storeColumn.data[4].ratio}>{props.ceoNm}</List.Row>

      {/* 대표자 휴대전화 */}
      <List.Row
        $ratio={storeColumn.data[5].ratio}
        style={{
          gap: "0.3rem",
        }}
      >
        {props.cellNumber}
        <Verify.Input
          onChange={(e) => setTypedNumber(e.target.value)}
          onKeyUp={(e) => handleEnter(e.key)}
        />
      </List.Row>

      {/* 서비스 유무 */}
      <List.Row $ratio={storeColumn.data[6].ratio}>{props.stateNm}</List.Row>

      {/* 고객정보 검증 버튼 */}
      <List.Row
        $ratio={storeColumn.data[7].ratio}
        onClick={verifyCellNumber}
        style={{ cursor: "pointer" }}
      >
        <VerifyUser.Wrapper style={{ backgroundColor: "#FEBD17" }}>
          <VerifyUser.Img src="/assets/imgs/userList_verify.svg" />
          <VerifyUser.Text>고객정보 검증</VerifyUser.Text>
        </VerifyUser.Wrapper>
      </List.Row>
    </Info.Content>
  );
};
