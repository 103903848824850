import { POST_APP_VERSION2_PATCH_SERVER_UPDATE_YN } from "constants/pathConstant";
import { ModalVersionCheckContainer, ModalWrapper } from "./style";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useState } from "react";

const VersionCheckModal = ({ setIsOpen, clickVersionCheck, id, fetchData }) => {
  const toast = useToastCustom();
  const [serverCheckValue, setServerCheckValue] = useState("");

  const confirmMessage = clickVersionCheck
    ? {
        message: '점검을 종료하시려면 아래 빈칸에 "점검 종료"라고 적어주세요.',
        inputText: "점검 종료",
        updateYn: "N",
        successMessage: "점검이 종료되었습니다.",
      }
    : {
        message:
          '점검을 진행하시려면 아래 빈칸에 "점검 시작"이라고 적어주세요.',
        inputText: "점검 시작",
        updateYn: "Y",
        successMessage: "점검이 시작되었습니다.",
      };

  const { mutate: mutateServerUpdate } = useMutationCustom(
    POST_APP_VERSION2_PATCH_SERVER_UPDATE_YN
  );

  const serverCheckHnadler = () => {
    const sanitizedInput = serverCheckValue.replace(/\s+/g, "");
    const expectedInput = confirmMessage.inputText.replace(/\s+/g, "");

    if (sanitizedInput !== expectedInput) {
      return toast("문구를 정확하게 입력해주세요.", "error");
    }

    mutateServerUpdate(
      { id, serverUpdateYn: confirmMessage.updateYn },
      {
        onSuccess: () => {
          toast(confirmMessage.successMessage, "success");
          fetchData();
          setIsOpen(false);
        },
        onError: () => {
          toast("처리에 실패하였습니다.", "error");
        },
      }
    );
  };

  const handleEnter = (key) => {
    if (key === "Enter") serverCheckHnadler();
  };

  return (
    <>
      <ModalWrapper
        onClick={() => {
          setIsOpen(false);
        }}
      ></ModalWrapper>
      <ModalVersionCheckContainer>
        <div>
          <p>{confirmMessage.message}</p>
          <input
            onChange={(e) => setServerCheckValue(e.target.value)}
            onKeyUp={(e) => handleEnter(e.key)}
          />
          <div onClick={serverCheckHnadler}>확인</div>
        </div>
      </ModalVersionCheckContainer>
    </>
  );
};

export default VersionCheckModal;
