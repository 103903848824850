import { FONT_WEIGHT } from "constants/baseStyle";
import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

export const BigCategory = styled.div`
  width: 22.2rem;
  height: 3.4rem;
  display: flex;
  padding: 0.3rem;
  border-radius: 0.7rem;
  border: 0.2rem solid #fff;
  background: #f3f3f3;
`;

export const CategoryDiv = styled.div`
  width: 10.8rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.4rem;
  font-size: 1.2rem;
  font-weight: ${FONT_WEIGHT.BOLD};
  color: ${(props) => (props.selected ? "white" : "black")};
  background-color: ${(props) => (props.selected ? "#4253F2" : "")};
`;

export const SmallCategory = styled.div`
  display: flex;
  padding: 0.2rem;
  width: fit-content;
`;

export const SmallCategoryDiv = styled.div`
  padding-right: 0.5rem;
  border-bottom: ${(props) =>
    props.selected ? "0.2rem solid #4253F2" : "0.2rem solid #aaa"};
  cursor: pointer;
  color: ${(props) => (props.selected ? "black" : "#aaa")};
`;

export const MemoWrapper = styled.div`
  border: 0.1rem solid #eee;
  border-radius: 0.4rem;
  display: flex;
  height: 19rem;
`;

export const YYMM = styled.div`
  flex: 5;
  height: 100%;
  overflow: hidden auto;
  border-right: 1px solid #eee;
`;

export const YYMMrow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem 0.7rem;
  gap: 0.6rem;
  white-space: nowrap;
  font-size: 1.2rem;
  cursor: pointer;

  & > img {
    width: 1rem;
    height: 0.8rem;
  }
`;

export const YYMMDD = styled.div`
  flex: 3.5;
  height: 100%;
  overflow: hidden auto;
  border-right: 1px solid #eee;
`;

export const YYMMDDrow = styled.div`
  width: 100%;
  padding: 1rem 0.7rem;
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: ${FONT_WEIGHT.LIGHT};
  color: ${(props) => (props.selected ? "#4253F2" : "black")};
`;

export const Memo = {
  Wrapper: styled.div`
    background-color: #f8f8fa;
    flex: 10;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    overflow-y: auto;
    cursor: pointer;
  `,

  Row: styled.div`
    font-size: 1.2rem;
    font-weight: ${FONT_WEIGHT.LIGHT};
  `,

  ViewModalBtn: styled.div``,
};
