import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Main = {
  Wrapper: styled.div`
    margin: 1.3rem;
    border: 0.1rem solid #eee;
    border-radius: 0.6rem;
  `,

  Content: styled.div`
    width: 100%;
    padding: 1rem 0rem;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    border-bottom: 0.1rem solid #e2e2e2;
    font-weight: ${FONT_WEIGHT.MEDIUM};
  `,

  Row: styled.span`
    flex: ${(props) => (props.$ratio ? props.$ratio : 1)};
    text-align: center;
    color: ${(props) => props.color && props.color};
    cursor: ${(props) => props.cursor || "default"};
    position: relative;
    display: flex;
    justify-content: center;
    word-break: break-all;
    align-items: center;
    padding: 0.5rem;
  `,
};

export const MainDetail = {
  Wrapper: styled.div`
    width: 100%;
    height: ${(props) => (props.height ? props.height : "17rem")};
    overflow-y: ${(props) => (props.max ? "auto" : "hidden")};

    & > div:nth-child(2n + 1) {
      background-color: #f8f8fa;
    }
  `,

  Content: styled.div`
    width: 100%;
    padding: 1rem 0rem;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: ${FONT_WEIGHT.LIGHT};
    color: black;
  `,
};
