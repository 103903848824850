import { FONT_WEIGHT } from "constants/baseStyle";
import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const Container = {
  Text: styled.div`
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 2.2rem;
  `,

  Bottom: styled.div`
    display: flex;
    gap: 0.6rem;
  `,
};

export const Box = {
  Wrapper: styled.div`
    width: 14rem;
    height: 14.4rem;
    border-radius: 1rem;
    background: #fff;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
  `,

  TopCircle: styled.img`
    width: 1.6rem;
    height: 1.6rem;
  `,

  Title: styled.div`
    width: 100%;
    font-size: 1.6rem;
    text-align: center;
  `,

  Referral: styled.div`
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
  `,
};

export const Content = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    padding: 0 1rem;
  `,

  Img: styled.img`
    width: 1.4rem;
    height: 1.4rem;
  `,

  Text: styled.div`
    margin-left: 0.4rem;
  `,

  People: styled.div`
    margin-left: 3rem;
    font-size: 1.4rem;
    border-bottom: ${(props) =>
      props.$isZero === "0" ? "none" : "0.1rem solid black"};
    cursor: pointer;
  `,
};
