import { useEffect, useState } from "react";
import { SearchBox, Wrapper } from "./style";
import PageHandler from "components/PageHandler/PageHandler";
import { useMutationCustom } from "hooks/useMutationCustom";
import { HTTP_METHOD } from "constants/pathConstant";
import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { useToastCustom } from "hooks/useToastCustom";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import {
  dateFormatDay,
  dateFormatEnd,
  dateFormatStart,
} from "components/newDateFormat";

// 회원검색 :: 회원검색 상세보기 -> 지갑정보 (http://localhost:3000/manage/user/:code)
const SearchCategory = ({
  API_ADDRESS,
  data,
  setResData,
  setPage,
  setSize,
  page,
  size,
  resData,
  summary = false,
  XLSX_DOWNLOAD,
}) => {
  const toast = useToastCustom();
  const excelDownload = useExcelDownload();

  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState(dateFormatDay(new Date()));
  const [searchData, setSearchData] = useState({});

  const { mutate } = useMutationCustom(API_ADDRESS, HTTP_METHOD.POST, {});
  useEffect(() => {
    handleSearch();
  }, [page, size]);

  const handleExcelDownload = () => {
    if (!resData.listCount)
      return toast("다운로드할 데이터가 없습니다", "error");

    const excelData = {
      ...searchData,
      pageSize: resData.listCount,
      currentPage: 1,
    };

    mutate(excelData, {
      onSuccess: (response) => {
        const excelData = {
          data: response.list,
          headers: XLSX_DOWNLOAD.header,
          fileName: XLSX_DOWNLOAD.filename,
        };

        excelDownload(excelData);
      },

      onError: () => {
        toast("전체 데이터 조회 실패", "error");
      },
    });
  };

  const handleSearch = () => {
    const searchData = {
      ...data,
      fromDt: fromDt && dateFormatStart(fromDt),
      toDt: dateFormatEnd(toDt),
    };

    setSearchData(searchData);

    mutate(searchData, {
      onSuccess: (res) => {
        setResData(res);
      },
    });
  };

  return (
    <Wrapper>
      <div>
        <SearchBox>
          <input
            type="date"
            defaultValue={fromDt}
            onChange={(e) => setFromDt(e.target.value)}
          />
          {"-"}
          <input
            type="date"
            defaultValue={toDt.slice(0, 10)}
            onChange={(e) => setToDt(e.target.value)}
          />
          <div onClick={handleSearch}>검색</div>
        </SearchBox>
      </div>

      <div>
        {summary || (
          <>
            <PageHandler setPage={setPage} setPageSize={setSize} />
            <ExcelDownloadBtn onClick={handleExcelDownload} />
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default SearchCategory;
