import styled from "styled-components";

export const SortCount = {
  Select: styled.select`
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
  `,

  Option: styled.option``,
};
