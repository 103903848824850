import { Info, List } from "template/ListTemplate.style";
import paymentWalletColumn from "../paymentWalletColumn.json";
import { costFormat } from "components/costForamt";
import { NavTxId } from "components/Navigation/NavTxId";
import { useState } from "react";
import PaymentWalletClick from "./PaymentWalletClick";
import { ClickInfo } from "../PaymentWallet.style";

// 결제 내역 :: 결제 내역 리스트 낱개 (http://localhost:3000/super/payment/wallet)
const PaymentWalletDetailList = ({ data }) => {
  const getStateColor = (state) => {
    if (state === "실패") return "#fe1717";
    if (state === "성공") return "#21F650";
    if (state === "진행중") return "#555";
  };

  const [clicked, setClicked] = useState(false);

  return (
    <>
      <Info.Content
        onClick={() => {
          setClicked((prev) => !prev);
        }}
        style={{ cursor: "pointer" }}
      >
        {/* 번호 */}
        <List.Row $ratio={paymentWalletColumn.column[0].ratio}>
          {data.id}
        </List.Row>

        {/* 일자 */}
        <List.Row $ratio={paymentWalletColumn.column[1].ratio}>
          {data.createDate}
        </List.Row>

        {/* 회원명 */}
        <List.Row $ratio={paymentWalletColumn.column[2].ratio}>
          <List.RowElement>
            <p>{data.memberName}</p>
            <p>({data.memberCode})</p>
          </List.RowElement>
        </List.Row>

        {/* 결제 코인 타입 */}
        <List.Row $ratio={paymentWalletColumn.column[3].ratio}>
          <List.RowElement>
            <p>{data.coinType}</p>
          </List.RowElement>
        </List.Row>

        {/* 실제전송수량*/}
        <List.Row $ratio={paymentWalletColumn.column[4].ratio}>
          <List.RowElement>
            <p>{costFormat(data.amount)}</p>
            <p>({costFormat(data.amountKrw)}원)</p>
          </List.RowElement>
        </List.Row>

        {/* 결제원수량 */}
        <List.Row $ratio={paymentWalletColumn.column[5].ratio}>
          <List.RowElement>
            <p>{costFormat(data.originAmount)}</p>
            <p>({costFormat(data.originAmountKrw)}원)</p>
          </List.RowElement>
        </List.Row>

        {/* 배송비(원) */}
        <List.Row $ratio={paymentWalletColumn.column[6].ratio}>
          <p>{costFormat(data.deliveryKrw)}</p>
        </List.Row>

        {/* 시세(원) */}
        <List.Row $ratio={paymentWalletColumn.column[7].ratio}>
          <p>{costFormat(data.exchangeRateKrw)}</p>
        </List.Row>

        {/* 예상 스케치백 */}
        <List.Row $ratio={paymentWalletColumn.column[8].ratio}>
          <List.RowElement>
            <p>{costFormat(data.estimatedSbackAmount)}</p>
            <p>({costFormat(data.estimatedSbackAmountKrw)}원)</p>
          </List.RowElement>
        </List.Row>

        {/* 유형 */}
        <List.Row $ratio={paymentWalletColumn.column[9].ratio}>
          <List.RowElement>
            <p>{data.typeId}</p>
            {data.cancelAll && <p>({data.cancelAll})</p>}
          </List.RowElement>
        </List.Row>

        {/* 상태 */}
        <List.Row
          $ratio={paymentWalletColumn.column[10].ratio}
          color={getStateColor(data.txTypeId)}
        >
          <List.RowElement>
            <p>{data.txTypeId}</p>
          </List.RowElement>
        </List.Row>

        {/* 주문번호 */}
        <List.Row $ratio={paymentWalletColumn.column[11].ratio}>
          {data.ssOrderId}
        </List.Row>

        {/* 바로가기 */}
        <List.Row $ratio={paymentWalletColumn.column[12].ratio}>
          {NavTxId(data.txId, data.coinType === "ETH" ? "ETH" : "POL")}
        </List.Row>
      </Info.Content>

      {clicked && (
        <ClickInfo.Wrapper>
          <ClickInfo.Container>
            <ClickInfo.Header>
              {paymentWalletColumn.clickInfo.map((v, idx) => (
                <ClickInfo.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </ClickInfo.Row>
              ))}
            </ClickInfo.Header>

            <PaymentWalletClick data={data} />
          </ClickInfo.Container>
        </ClickInfo.Wrapper>
      )}
    </>
  );
};

export default PaymentWalletDetailList;
