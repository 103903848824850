import { Layout } from "Layout/Layout";
import { useLoginCheck } from "hooks/login/useLoginCheck";
import { useRouteRoleCheck } from "hooks/useRouteRoleCheck";
import { AcceptStoreList } from "pages/Accept/Store/AcceptStoreList";
import { CreateUser } from "pages/CreateUser/CreateUser";
import { DropUser } from "pages/DropUser/DropMember";
import { Home } from "pages/Home/Home";
import { Login } from "pages/Login/Login";
import SfuelManage from "pages/Sfuel/SfuelManage";
import SfuelWork from "pages/Sfuel/SfuleWork";
import { StoreList } from "pages/Store/StoreList";
import { SuperStoreList } from "pages/Store/SuperStoreList";
import { WithdrawList } from "pages/Swap/withdraw/WithdrawList";

import { ActivityLog } from "pages/SystemManage/ActivityLog/ActivityLog";
import { SuperUserList } from "pages/User/SuperUserList";
import { UserDetail } from "pages/User/UserDetail/UserDetail";
import WalletUserInfo from "pages/WalletUser/WalletUserInfo/WalletUserInfo";
import { UserList } from "pages/User/UserList";
// import { AssetWallet } from "pages/Asset/AssetWallet/AssetWallet";
import { useEffect } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router";
import { useRecoilValue } from "recoil";
import { loginRole, loginState } from "recoil/atom/LoginState";
import Popup from "pages/Support/Popup";
import PopupUpload from "pages/Support/Popup/PopupUpload";
import PopupModify from "pages/Support/Popup/PopupModify";
import Notice from "pages/Support/Notice";
import NoticeUpload from "pages/Support/Notice/NoticeUpload";
import NoticeModify from "pages/Support/Notice/NoticeModify";
import Faq from "pages/Support/Faq";
import FaqUpload from "pages/Support/Faq/FaqUpload";
import FaqModify from "pages/Support/Faq/FaqModify";
import RewardSendList from "pages/RewardSend/RewardSend";
import SwapList from "pages/Swap/swap/SwapList";
import ChargeList from "pages/Swap/charge/ChargeList";
import Version from "pages/SystemManage/Version";
import VersionUpload from "pages/SystemManage/Version/VersionUpload";
import VersionModify from "pages/SystemManage/Version/VersionModify";
import { SettleList } from "pages/Calculate/Settle/SettleList";
import CoinList from "pages/SystemManage/Coin/CoinList";
import { AdminList } from "pages/SystemManage/Admin/AdminList";
import SketchDrop from "pages/Calculate/SketchDrop/SketchDrop";
import Version2 from "pages/SystemManage/Version2";
import VersionUpload2 from "pages/SystemManage/Version2/VersionUpload2";
import VersionModify2 from "pages/SystemManage/Version2/VersionModify2";
import ManageUi from "pages/SystemManage/ManageUi";
import SketchBack from "pages/Calculate/SketchBack/SketchBack";
import AssetWallet from "pages/Asset/AssetWallet/AssetWallet";
import PaymentWallet from "pages/Payment/PaymentWallet/PaymentWallet";
import AssetPay from "pages/Asset/AssetPay/AssetPay";
import PaymentPay from "pages/Payment/PaymentPay/PaymentPay";
import Popup2 from "pages/Support2/Popup";
import PopupUpload2 from "pages/Support2/Popup/PopupUpload";
import PopupModify2 from "pages/Support2/Popup/PopupModify";
import Notice2 from "pages/Support2/Notice";
import NoticeUpload2 from "pages/Support2/Notice/NoticeUpload";
import NoticeModify2 from "pages/Support2/Notice/NoticeModify";
import Faq2 from "pages/Support2/Faq";
import FaqUpload2 from "pages/Support2/Faq/FaqUpload";
import FaqModify2 from "pages/Support2/Faq/FaqModify";
import WalletUser from "pages/WalletUser/WalletUser";
import SuperWalletUser from "pages/WalletUser/SuperWalletUser";
import Migration from "pages/Swap/migration/Migration";
import WalletDropUser from "pages/WalletDropUser/WalletDropUser";
import File from "pages/Support2/File";
import PaymentMonthly from "pages/Payment/PaymentMonthly/PaymentMonthly";
import WalletAddress from "pages/WalletAddress/WalletAddress.jsx";

export const Router = () => {
  const nav = useNavigate();
  const location = useLocation();
  const loginCheck = useLoginCheck();
  const routeRoleCheck = useRouteRoleCheck();

  const userRole = useRecoilValue(loginRole);
  const loginData = useRecoilValue(loginState);
  useEffect(() => {
    if (!nav) return;
    if (!loginData.isLogin) nav("/login");
  }, [loginData, nav]);

  useEffect(() => {
    if (!loginCheck) return;
    loginCheck();
  }, []);

  useEffect(() => {
    if (!userRole[0]) return;
    routeRoleCheck();
  }, [location.pathname, userRole]);

  const routes = [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/main",
          element: <Home />,
        },
        {
          path: "/manage/user/pay",
          element: <UserList />,
        },
        {
          path: "/manage/user/wallet",
          element: <WalletUser />,
        },
        {
          path: "/super/search/user/pay",
          element: <SuperUserList />,
        },
        {
          path: "/super/search/user/wallet",
          element: <SuperWalletUser />,
        },
        {
          path: "/manage/user/pay/:code",
          element: <UserDetail />,
        },
        {
          path: "/manage/user/wallet/:code",
          element: <WalletUserInfo />,
        },
        {
          path: "/manage/store",
          element: <StoreList />,
        },
        {
          path: "/super/search/store",
          element: <SuperStoreList />,
        },
        {
          path: "/super/system/admin",
          element: <AdminList />,
        },
        {
          path: "/super/system/log",
          element: <ActivityLog />,
        },
        {
          path: "/super/bridge/charge",
          element: <ChargeList />,
        },
        {
          path: "/super/bridge/withdraw",
          element: <WithdrawList />,
        },
        {
          path: "/super/bridge/withdraw/:code",
          element: <WithdrawList />,
        },
        {
          path: "/super/bridge/swap",
          element: <SwapList />,
        },
        {
          path: "/super/bridge/swap/:code",
          element: <SwapList />,
        },
        {
          path: "/super/bridge/migration",
          element: <Migration />,
        },
        {
          path: "/dev/create-user",
          element: <CreateUser />,
        },
        {
          path: "/super/accept/store/*",
          element: <AcceptStoreList />,
        },
        {
          path: "/super/payment/wallet",
          element: <PaymentWallet />,
        },
        {
          path: "/super/payment/month",
          element: <PaymentMonthly />,
        },
        {
          path: "/super/payment/pay",
          element: <PaymentPay />,
        },
        {
          path: "/super/asset/wallet",
          element: <AssetWallet />,
        },
        {
          path: "/super/asset/pay",
          element: <AssetPay />,
        },
        {
          path: "/super/search/gone-user",
          element: <DropUser />,
        },
        {
          path: "/super/search/gone-user/:code",
          element: <DropUser />,
        },
        {
          path: "/super/search/gone-member",
          element: <WalletDropUser />,
        },
        {
          path: "/sfuel/manage",
          element: <SfuelManage />,
        },
        {
          path: "/sfuel/work",
          element: <SfuelWork />,
        },

        {
          path: "/super/create/popup",
          element: <Popup />,
        },
        {
          path: "/super/create/popup-upload",
          element: <PopupUpload />,
        },
        {
          path: "/super/create/popup-modify",
          element: <PopupModify />,
        },
        {
          path: "/super/create/notice",
          element: <Notice />,
        },
        {
          path: "/super/create/notice-upload",
          element: <NoticeUpload />,
        },
        {
          path: "/super/create/notice-modify",
          element: <NoticeModify />,
        },
        {
          path: "/super/create/faq",
          element: <Faq />,
        },
        {
          path: "/super/create/faq-upload",
          element: <FaqUpload />,
        },
        {
          path: "/super/create/faq-modify",
          element: <FaqModify />,
        },

        {
          path: "/super/support/popup",
          element: <Popup2 />,
        },
        {
          path: "/super/support/popup-upload",
          element: <PopupUpload2 />,
        },
        {
          path: "/super/support/popup-modify",
          element: <PopupModify2 />,
        },
        {
          path: "/super/support/notice",
          element: <Notice2 />,
        },
        {
          path: "/super/support/notice-upload",
          element: <NoticeUpload2 />,
        },
        {
          path: "/super/support/notice-modify",
          element: <NoticeModify2 />,
        },
        {
          path: "/super/support/faq",
          element: <Faq2 />,
        },
        {
          path: "/super/support/faq-upload",
          element: <FaqUpload2 />,
        },
        {
          path: "/super/support/faq-modify",
          element: <FaqModify2 />,
        },

        {
          path: "/super/system/version1",
          element: <Version />,
        },
        {
          path: "/super/system/version1-upload",
          element: <VersionUpload />,
        },
        {
          path: "/super/system/version1-modify",
          element: <VersionModify />,
        },
        {
          path: "/super/system/version2",
          element: <Version2 />,
        },
        {
          path: "/super/system/version2-upload",
          element: <VersionUpload2 />,
        },
        {
          path: "/super/system/version2-modify",
          element: <VersionModify2 />,
        },
        {
          path: "/super/calculate/reward-send",
          element: <RewardSendList />,
        },
        {
          path: "/super/calculate/settle",
          element: <SettleList />,
        },
        {
          path: "/super/calculate/sketch-drop",
          element: <SketchDrop />,
        },
        {
          path: "/super/system/coin",
          element: <CoinList />,
        },
        {
          path: "/super/system/ui",
          element: <ManageUi />,
        },
        {
          path: "/super/calculate/sketch-back",
          element: <SketchBack />,
        },
        {
          path: "/super/support/file",
          element: <File />,
        },

        {
          path: "/super/asset/addressWallet",
          element: <WalletAddress />,
        },
      ],
    },
  ];
  return useRoutes(routes);
};
