import { axiosInstance } from "api/api";
import { HTTP_METHOD, POST_ADMIN_ME, POST_LOGIN, SYMBOL } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loginData, loginRole, loginState } from "recoil/atom/LoginState";
import { Main, Wrapper } from "./Login.style";
import { setUserInLocalStorage } from "utils/localStorage";

// 최초 접속시 (http://localhost:3000/login)
export const Login = () => {
  const isRemember = false;

  const nav = useNavigationCustom();
  const toast = useToastCustom();

  const [email, setEmail] = useState(null);
  const [pw, setPw] = useState(null);
  const [visibility, setVisibility] = useState("hidden");

  const [loginStatus, setLoginStatus] = useRecoilState(loginState);
  const setRole = useSetRecoilState(loginRole);
  const setName = useSetRecoilState(loginData);

  const { mutate } = useMutationCustom(POST_LOGIN, HTTP_METHOD.POST);
  const keyDownHandler = (key) => {
    setVisibility("hidden");
    if (key === "Enter") loginHandler(email, pw, isRemember);
  };

  const loginHandler = (_email, _pw, _isRemember) => {
    const data = {
      adminId: _email,
      password: _pw,
    };

    mutate(data, {
      onSuccess: async (data) => {
        setUserInLocalStorage(data);

        const flag = await axiosInstance.get(POST_ADMIN_ME).then((userResult) => {
          if (userResult.isActive === 0) {
            toast("접근 차단 계정", "error");

            return false;
          }
          setName(userResult);
          setRole(userResult.roles.split(","));

          return true;
        });

        if (!flag) return;

        setLoginStatus({ isLogin: true });
        nav("/main");
      },

      onError: () => setVisibility("visible"),
    });
  };

  useEffect(() => {
    if (loginStatus.isLogin) nav("/");
  }, []);

  return (
    <Wrapper>
      <Main.Wrapper>
        <Main.Logo>
          <img src="https://sketch-htm-s3.s3.ap-northeast-2.amazonaws.com/file/1a336084logo.svg" alt="login logo" />
          {SYMBOL}
        </Main.Logo>

        {/* 아이디 */}
        <Main.InputWrapper>
          아이디
          <input
            type="email"
            placeholder="아이디를 입력해 주세요"
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => keyDownHandler(e.key)}
          />
        </Main.InputWrapper>

        {/* 비밀번호 */}
        <Main.InputWrapper>
          비밀번호
          <input
            type="password"
            placeholder="비밀번호를 입력해 주세요"
            onChange={(e) => setPw(e.target.value)}
            onKeyDown={(e) => keyDownHandler(e.key)}
          />
          <Main.Validate style={{ visibility: visibility }}>잘못된 정보입니다.</Main.Validate>
        </Main.InputWrapper>

        {/* <Main.Remember>
          <input type="checkbox" onChange={() => setIsRemember(!isRemember)} />
          로그인 정보 기억하기
        </Main.Remember> */}

        {/* 로그인 버튼 */}
        <Main.LoginBtn onClick={() => loginHandler(email, pw, isRemember)}>로그인</Main.LoginBtn>
      </Main.Wrapper>
    </Wrapper>
  );
};
