import axios from "axios";
import { BASE_URL, POST_REISSUE } from "constants/pathConstant";
import {
  getUserFromLocalStorage,
  setUserInLocalStorage,
} from "utils/localStorage";

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
});

axiosInstance.interceptors.request.use(
  async function (config) {
    // request 전 공통 config 설정
    // 헤더 커스텀할 경우 직접 넣어줘야함
    if (!config.headers) {
      config.headers["Content-Type"] = "application/json; charset=utf-8";
    }

    let loginData = getUserFromLocalStorage();

    if (loginData) {
      // jwt 시간 만료되었을때

      if (loginData.accessTokenExpiresIn < new Date().getTime() + 1000) {
        const refreshToken = loginData.refreshToken;
        const accessToken = loginData.accessToken;
        const data = { accessToken, refreshToken };

        await axios
          .post(POST_REISSUE, data)
          .then(async (result) => {
            /// loginData 다시 세팅
            loginData = result;

            setUserInLocalStorage(result);
          })
          .catch(() => {
            window.location.href = "/login";

            localStorage.removeItem("user");
          });
      }

      config.headers.Authorization = `${loginData.grantType} ${loginData.accessToken}`;
    }

    // ip 헤더에 넣어주기
    // const response = await axios.get("https://api64.ipify.org/?format=json");
    // config.headers["ip"] = response.data.ip;

    // TODO: 하드코딩 삭제
    config.headers["ip"] = "1.217.21.162";

    return config;
  },
  function (error) {
    // 요청 에러 직전 호출됩니다.
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    // success response callback

    return response.data;
  },
  function (error) {
    /*
        http status가 200이 아닌 경우
        응답 에러 직전 호출됩니다.
    */
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          const storageData = getUserFromLocalStorage();
          const refreshToken = storageData?.refreshToken;
          const accessToken = storageData?.accessToken;
          const data = { accessToken, refreshToken };

          axios
            .post(POST_REISSUE, data)
            .then(async (result) => {
              setUserInLocalStorage(result);
              // alert("다시 시도해주세요.");
            })
            .catch(() => {
              // window.location.href = "/login";
              // localStorage.removeItem("user");
              // alert("다시 시도해주세요.");
            });
          break;
        case 403:
          // alert("권한이 없습니다. 관리자에게 문의하세요.");
          if (!localStorage.user) window.location.href = "/login";
          break;
        default:
          return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);
