import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 1rem;
  border: 1px solid #f6f6f6;
  background: #fff;
  box-shadow: 0px 0.4rem 0.4rem 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 4rem 4rem 3rem;
`;

export const Title = styled.div`
  font-size: 2rem;
  font-weight: ${FONT_WEIGHT.BOLD};
  text-align: center;

  & > p > span {
    color: red;
  }
`;

export const Sub = styled.div`
  color: #888;
  margin: 2rem 0 0.5rem;
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 2rem;
  gap: 0.6rem;
`;

export const Btn = styled.div`
  width: 6rem;
  height: 3.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  font-weight: ${FONT_WEIGHT.BOLD};
  cursor: pointer;
  background-color: ${(props) => props.$bg || "#BBB"};
  color: ${(props) => props.color || "black"};
`;

export const TextAreaBox = styled.div`
  font-size: 1.5rem;
  width: 100%;

  & > textarea {
    min-width: 100%;
    min-height: 10rem;
    padding: 1rem;
    box-sizing: border-box;
  }
`;
