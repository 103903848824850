import { HTTP_METHOD, POST_SIGNUP, SYMBOL } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useState } from "react";
import { Main, Wrapper } from "./CreateUser.style";

export const CreateUser = () => {
  const [adminId, setAdminId] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [name, setName] = useState("");
  const [memo, setMemo] = useState("");
  const [visibility, setVisibility] = useState("hidden");
  const [error, setError] = useState("");

  // 아이디 생성 :: (http://localhost:3000/dev/create-user)
  const keyDownHandler = (key) => {
    setVisibility("visible");
    if (key === "Enter") signupHandler();
  };

  const { mutate } = useMutationCustom(POST_SIGNUP, HTTP_METHOD.POST);

  const signupHandler = () => {
    if (password !== passwordCheck) return setError("비밀번호를 확인해주세요.");

    const data = {
      name,
      adminId,
      password,
      memo,
    };

    mutate(data, {
      onSuccess: () => {
        alert("아이디 생성 완료");
        setName("");
        setAdminId("");
        setPassword("");
        setPasswordCheck("");
        setMemo("");
      },

      onError: (e) => {
        setError(e.response.data.message);
        setVisibility("visible");
      },
    });
  };

  return (
    <Wrapper>
      <Main.Wrapper>
        <Main.Logo>
          <img src="https://sketch-htm-s3.s3.ap-northeast-2.amazonaws.com/file/1a336084logo.svg" alt="login logo" />
          {SYMBOL}
        </Main.Logo>
        {/* 이름 */}
        <Main.InputWrapper>
          이름
          <input
            type="text"
            placeholder="이름을 입력해 주세요"
            onChange={(e) => setName(e.target.value)}
            onKeyDown={(e) => keyDownHandler(e.key)}
            value={name}
          />
        </Main.InputWrapper>

        {/* 아이디 */}
        <Main.InputWrapper>
          아이디
          <input
            type="text"
            placeholder="아이디를 입력해 주세요"
            onChange={(e) => setAdminId(e.target.value)}
            onKeyDown={(e) => keyDownHandler(e.key)}
            value={adminId}
          />
          <Main.Validate style={{ color: "#888" }}>*4~16자의 영문(소문자), 숫자만 사용 가능</Main.Validate>
        </Main.InputWrapper>

        {/* 비밀번호 */}
        <Main.InputWrapper>
          비밀번호
          <input
            type="password"
            placeholder="비밀번호를 입력해 주세요"
            onChange={(e) => {
              setPassword(e.target.value);
              setError("");
            }}
            onKeyDown={(e) => keyDownHandler(e.key)}
            value={password}
          />
          <Main.Validate style={{ color: "#888" }}>
            *4~16자의 영문,대소문자, 숫자, 특수문자를 자유롭게 조합하여 설정
          </Main.Validate>
        </Main.InputWrapper>

        {/* 비밀번호 */}
        <Main.InputWrapper>
          비밀번호 확인
          <input
            type="password"
            placeholder="비밀번호를 다시 한 번 입력해 주세요"
            onChange={(e) => {
              setPasswordCheck(e.target.value);
              setError("");
            }}
            onKeyDown={(e) => keyDownHandler(e.key)}
            value={passwordCheck}
          />
          <Main.Validate style={{ color: "#888" }}></Main.Validate>
        </Main.InputWrapper>

        {/* 메모 */}
        <Main.InputWrapper>
          메모
          <input
            type="text"
            placeholder="메모를 입력해 주세요"
            onChange={(e) => setMemo(e.target.value)}
            onKeyDown={(e) => keyDownHandler(e.key)}
            value={memo}
          />
          <Main.Validate style={{ visibility: visibility }}>{error}</Main.Validate>
        </Main.InputWrapper>

        {/* 아이디 생성 버튼 */}
        <Main.LoginBtn onClick={() => signupHandler(name, adminId, password)}>아이디 생성</Main.LoginBtn>
      </Main.Wrapper>
    </Wrapper>
  );
};
