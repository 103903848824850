import { Loading } from "components/Loading/Loading";
import { POST_WALLET_USER_RECOMMEND_INFO } from "constants/pathConstant";
import { TYPE_TO_TITLE } from "constants/referralType";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useLayoutEffect, useState } from "react";
import { getUserParamsFromUri } from "utils/crypto";
import { UserModal } from "./UserReferralModal.style";

export const UserReferralModal = ({ type }) => {
  const [referralData, setReferralData] = useState([]);

  const { mutate: referralMutation, isLoading: referralLoading } =
    useMutationCustom(POST_WALLET_USER_RECOMMEND_INFO);

  useLayoutEffect(() => {
    const { acctCd, state } = getUserParamsFromUri();
    const data = { memberCode: acctCd, recommendType: type, state };

    referralMutation(data, {
      onSuccess: (response) => {
        setReferralData(response);
      },
    });
  }, []);

  if (referralLoading) return <Loading />;

  return (
    <>
      {referralData && (
        <UserModal.Wrapper>
          <UserModal.Title>
            <span>{TYPE_TO_TITLE[type]}</span>
            <span>{referralData.length}명</span>
          </UserModal.Title>
          <UserModal.Container>
            {referralData.map((v, idx) => (
              <UserModal.Content key={idx}>
                {/* <span>{v.refererDt}</span> */}
                <span>{`${v.memberName} (${v.memberCode})`}</span>
                {/* <span>{v.storeName}</span> */}
              </UserModal.Content>
            ))}
          </UserModal.Container>
        </UserModal.Wrapper>
      )}
    </>
  );
};
