import { useEffect, useState } from "react";
import {
  BottomBtn,
  BottomWrapper,
  Name,
  Rectangle,
  SelectWrapper,
  TableWrapper,
  Wrapper,
  SelectBoxWrapper,
  ContentBox,
} from "./style";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import {
  POST_APP_VERSION2_CREATE,
  POST_APP_VERSION2_MODIFY,
} from "constants/pathConstant";
import { axiosInstance } from "api/api";
import { useMutation } from "@tanstack/react-query";
import { useToastCustom } from "hooks/useToastCustom";
import versionColumn from "../versionColumn2.json";
import { VersionModal } from "components/Modal/VersionModal";
import "react-quill/dist/quill.snow.css";
import SmartEditor from "components/SmartEditor/SmartEditor";

// 시스템 관리 -> 버전2 관리 :: 버전2 등록 (http://localhost:3000/super/system/version2-upload)
const VersionUpload2 = ({ modifyData }) => {
  const nav = useNavigationCustom();
  const toast = useToastCustom();

  // 기본 upload state
  const [androidForceUpdYn, setAndroidForceUpdYn] = useState("Y");
  const [iosForceUpdYn, setIosForceUpdYn] = useState("Y");
  const [androidVersion, setAndroidVersion] = useState("");
  const [nextAndroidVersion, setNextAndroidVersion] = useState("");
  const [iosVersion, setIosVersion] = useState("");
  const [nextIosVersion, setNextIosVersion] = useState("");
  const [devKey, setDevKey] = useState("");
  const [message, setMessage] = useState("");
  const [messageEng, setMessageEng] = useState("");
  const [serverUpdateMessage, setServerUpdateMessage] = useState("");
  const [serverUpdateMessageEng, setServerUpdateMessageEng] = useState("");
  const [reqType, setReqType] = useState("00");

  // 수정시 추가 state
  const [id, setId] = useState("");
  const [useYn, setUseYn] = useState("");

  // 모달 state
  const [isOpen, setIsOpen] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const previewHandler = () => {
    setIsOpen((prev) => !prev);
    setPreviewData({
      message,
      messageEng,
      serverUpdateMessage,
      serverUpdateMessageEng,
    });
  };

  useEffect(() => {
    if (!modifyData) return;

    setId(modifyData.id);
    setAndroidForceUpdYn(modifyData.androidForceUpdYn);
    setIosForceUpdYn(modifyData.iosForceUpdYn);
    setAndroidVersion(modifyData.androidVersion);
    setNextAndroidVersion(modifyData.nextAndroidVersion);
    setIosVersion(modifyData.iosVersion);
    setNextIosVersion(modifyData.nextIosVersion);
    setDevKey(modifyData.devKey);
    setMessage(modifyData.message);
    setMessageEng(modifyData.messageEng);
    setReqType(modifyData.reqType);
    setUseYn(modifyData.useYn);
    setServerUpdateMessage(modifyData.serverUpdateMessage);
    setServerUpdateMessageEng(modifyData.serverUpdateMessageEng);
  }, []);

  // 이미지 업로드 핸들러

  const { mutate } = useMutation(
    (data) => axiosInstance.post(POST_APP_VERSION2_CREATE, data),
    {
      onSuccess: () => {
        toast("정상적으로 등록되었습니다.", "success");
        nav("/super/system/version2");
      },
    }
  );

  const { mutate: updateMutate } = useMutation(
    (data) => axiosInstance.post(POST_APP_VERSION2_MODIFY, data),
    {
      onSuccess: () => {
        toast("정상적으로 수정되었습니다.", "success");
        nav("/super/system/version2");
      },
    }
  );

  const uploadHandler = async () => {
    if (!androidVersion || !iosVersion || !message || !messageEng)
      return toast("필수 값을 모두 입력해주세요.", "error");

    const data = {
      androidForceUpdYn,
      iosForceUpdYn,
      androidVersion,
      nextAndroidVersion,
      iosVersion,
      nextIosVersion,
      devKey,
      message,
      messageEng,
      reqType,
      id: modifyData && id,
      useYn: modifyData && useYn,
      serverUpdateMessage,
      serverUpdateMessageEng,
    };

    setPreviewData(data);

    if (modifyData) {
      updateMutate(data);
    } else {
      mutate(data);
    }
  };

  return (
    <Wrapper>
      <Name>Version</Name>
      <TableWrapper>
        <span>
          <Rectangle />
          작성하기
        </span>
        <div>
          <div>
            <SelectBoxWrapper>
              <div>구분</div>
              <SelectWrapper>
                <select
                  onChange={(e) => setReqType(e.target.value)}
                  defaultValue={modifyData?.reqType || reqType}
                  key={modifyData?.reqType || reqType}
                >
                  {Object.entries(versionColumn.type).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </SelectWrapper>
            </SelectBoxWrapper>
          </div>

          <div>
            <SelectBoxWrapper>
              <div style={{ fontSize: "1.8rem" }}>AOS 강제 업데이트 여부</div>
              <SelectWrapper>
                <select
                  onChange={(e) => setAndroidForceUpdYn(e.target.value)}
                  defaultValue={
                    modifyData?.androidForceUpdYn || androidForceUpdYn
                  }
                  key={modifyData?.androidForceUpdYn || androidForceUpdYn}
                >
                  <option value={"Y"}>Y</option>
                  <option value={"N"}>N</option>
                </select>
              </SelectWrapper>
            </SelectBoxWrapper>
            <SelectBoxWrapper>
              <div>현재 AOS 버전</div>
              <SelectWrapper>
                <input
                  type="text"
                  onChange={(e) => setAndroidVersion(e.target.value)}
                  placeholder="ex) 1.0.0"
                  defaultValue={modifyData?.aosLinkTarget || androidVersion}
                />
              </SelectWrapper>
            </SelectBoxWrapper>
          </div>

          <div>
            <SelectBoxWrapper>
              <div style={{ fontSize: "1.8rem" }}>IOS 강제 업데이트 여부</div>
              <SelectWrapper>
                <select
                  onChange={(e) => setIosForceUpdYn(e.target.value)}
                  defaultValue={modifyData?.iosForceUpdYn || iosForceUpdYn}
                  key={modifyData?.iosForceUpdYn || iosForceUpdYn}
                >
                  <option value={"Y"}>Y</option>
                  <option value={"N"}>N</option>
                </select>
              </SelectWrapper>
            </SelectBoxWrapper>
            <SelectBoxWrapper>
              <div>현재 IOS 버전</div>
              <SelectWrapper>
                <input
                  type="text"
                  onChange={(e) => setIosVersion(e.target.value)}
                  placeholder="ex) 1.0.0"
                  defaultValue={modifyData?.iosVersion || iosVersion}
                />
              </SelectWrapper>
            </SelectBoxWrapper>
          </div>

          <div>
            <SelectBoxWrapper>
              <div>다음 AOS 버전</div>
              <SelectWrapper>
                <input
                  type="text"
                  onChange={(e) => setNextAndroidVersion(e.target.value)}
                  placeholder="ex) 1.0.0"
                  defaultValue={
                    modifyData?.nextAndroidVersion || nextAndroidVersion
                  }
                />
              </SelectWrapper>
            </SelectBoxWrapper>
            <SelectBoxWrapper>
              <div>다음 IOS 버전</div>
              <SelectWrapper>
                <input
                  type="text"
                  onChange={(e) => setNextIosVersion(e.target.value)}
                  placeholder="ex) 1.0.0"
                  defaultValue={modifyData?.nextIosVersion || nextIosVersion}
                />
              </SelectWrapper>
            </SelectBoxWrapper>
          </div>

          <SelectBoxWrapper>
            <div>DevKey</div>
            <SelectWrapper>
              <input
                type="text"
                onChange={(e) => setDevKey(e.target.value)}
                placeholder="ex) 1.0.0"
                defaultValue={modifyData?.devKey || devKey}
              />
            </SelectWrapper>
          </SelectBoxWrapper>
        </div>
      </TableWrapper>

      <ContentBox>
        <SmartEditor
          category={"내용(한국어)"}
          setMessage={setMessage}
          messageValue={message}
          toolbarId={"contentsKr"}
        />

        <SmartEditor
          category={"내용(영어)"}
          setMessage={setMessageEng}
          messageValue={messageEng}
          toolbarId={"contentsEng"}
        />

        <SmartEditor
          category={"서버 업데이트시 문구(한글)"}
          setMessage={setServerUpdateMessage}
          messageValue={serverUpdateMessage}
          toolbarId={"updateContentsKr"}
        />

        <SmartEditor
          category={"서버 업데이트시 문구(영어)"}
          setMessage={setServerUpdateMessageEng}
          messageValue={serverUpdateMessageEng}
          toolbarId={"updateContentsEng"}
        />
      </ContentBox>

      <BottomWrapper>
        <div>
          <BottomBtn onClick={() => nav("/super/system/version2")}>
            목록
          </BottomBtn>
        </div>
        <div>
          <BottomBtn
            onClick={previewHandler}
            bgColor={"#2db9c2"}
            fontColor={"white"}
          >
            미리보기
          </BottomBtn>
          <BottomBtn
            onClick={uploadHandler}
            bgColor={"#4253f2"}
            fontColor={"white"}
          >
            저장
          </BottomBtn>
        </div>
      </BottomWrapper>

      {isOpen && <VersionModal data={previewData} setIsOpen={setIsOpen} />}
    </Wrapper>
  );
};

export default VersionUpload2;
