import { Loading } from "components/Loading/Loading";
import { UserCallLogsModal } from "components/Modal/UserDetailModal/callLogs/UserCallLogsModal";
import { HTTP_METHOD, POST_USER_LOG } from "constants/pathConstant";
import { useOpenCustomModal } from "hooks/modal/useOpenCustomModal";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { UserLogsState } from "recoil/atom/UserDetailPageState";
import { getUserParamsFromUri } from "utils/crypto";
import { Memo, MemoTop, YYMM, YYMMDD, YYMMDDrow, YYMMrow } from "./UserCallInfo.style";

// 회원검색 (검증 후 회원 정보 바로가기) :: 회원/가맹점 정보 수정 -> 하단 통화 내용 / 특이사항 기록 로그 (http://localhost:3000/manage/user/:code)
export const CallInfoList = ({ callLogsAll, setCallLogsAll }) => {
  const openCallLogsModal = useOpenCustomModal(UserCallLogsModal);

  const [YM, setYM] = useState("");
  const [YMD, setYMD] = useState("");

  const [callLogs, setCallLogs] = useRecoilState(UserLogsState.UserCallLogs);

  const validYM = callLogs && YM && callLogs[YM];
  const validYMD = validYM && YMD && callLogs[YM][YMD];

  // get 통화내용
  const { mutate: mutateGetAcctLogs, isLoading } = useMutationCustom(POST_USER_LOG, HTTP_METHOD.POST);

  useEffect(() => {
    const largeCd = "T";
    const actionCd = "T0001";
    const { acctCd } = getUserParamsFromUri();
    const data = { largeCd, actionCd, acctCd };

    mutateGetAcctLogs(data, {
      onSuccess: (response) => {
        setCallLogsAll(response);
      },
    });
  }, []);

  useEffect(() => {
    const transformData = (data) => {
      return data.reduce((acc, current) => {
        const yearMonth = current.yearMonth;
        const day = current.yearMonthDay.split(" ")[0].split("-").slice(1).join("-");

        if (!acc[yearMonth]) {
          acc[yearMonth] = {};
        }

        if (!acc[yearMonth][day]) {
          acc[yearMonth][day] = [];
        }

        acc[yearMonth][day].push(current);
        return acc;
      }, {});
    };

    const result = transformData(callLogsAll);
    setCallLogs(result);
  }, [callLogsAll]);

  const handleOpenCallLogsModal = () => {
    if (!YM || !YMD || !callLogs[YM] || !callLogs[YM][YMD]) return;
    openCallLogsModal({ YM, YMD, setCallLogsAll });
  };

  if (isLoading) return <Loading />;

  return (
    <MemoTop>
      <YYMM>
        {callLogs &&
          Object.keys(callLogs).map((v, idx) => (
            <YYMMrow key={idx} onClick={() => setYM(v)}>
              {v === YM ? (
                <img src="/assets/imgs/userModify_checked_btn.svg" alt="" />
              ) : (
                <img src="/assets/imgs/userModify_unChecked_btn.svg" alt="" />
              )}
              <div>{v}</div>
            </YYMMrow>
          ))}
      </YYMM>
      <YYMMDD>
        {validYM &&
          Object.keys(callLogs[YM]).map((v, idx) => (
            <YYMMDDrow key={idx} onClick={() => setYMD(v)} selected={v === YMD}>
              {v}
            </YYMMDDrow>
          ))}
      </YYMMDD>
      <Memo.Wrapper onClick={handleOpenCallLogsModal}>
        {validYMD && callLogs[YM][YMD].map((v, idx) => <Memo.Row key={idx}>{v.actionNotes}</Memo.Row>)}
      </Memo.Wrapper>
    </MemoTop>
  );
};
