import { useCloseRootModal } from "hooks/modal/useCloseRootModal";
import { Wrapper } from "./style";
import { IMG_CDN_URL } from "constants/pathConstant";

const ImgModal = ({ src }) => {
  const closeRootModal = useCloseRootModal();
  return (
    <Wrapper onClick={() => closeRootModal()}>
      <img src={`${IMG_CDN_URL}${src}`} alt="state_process_img" />
    </Wrapper>
  );
};

export default ImgModal;
