import { Info, List } from "template/ListTemplate.style";
import sketchDropColumn from "../../sketchDropColumn.json";
import { costFormat } from "components/costForamt";

// 정산 관리 -> 스케치 드랍 :: 요약 낱개 (http://localhost:3000/super/calculate/sketch-drop)
const SketchDropSummaryList = ({ data }) => {
  return (
    <Info.Content>
      {/* 보유 */}
      <List.Row $ratio={sketchDropColumn.summary[0].ratio}>
        {costFormat(data.havingAmount)}
      </List.Row>

      {/* 사용 */}
      <List.Row $ratio={sketchDropColumn.summary[1].ratio}>
        {costFormat(data.usedAmount)}
      </List.Row>

      {/* 합계 */}
      <List.Row $ratio={sketchDropColumn.summary[2].ratio}>
        {costFormat(data.txValue)}
      </List.Row>

      {/* 대상 인원 */}
      <List.Row $ratio={sketchDropColumn.summary[3].ratio}>
        {costFormat(data.cntMembers)}
      </List.Row>

      {/* 건수 */}
      <List.Row $ratio={sketchDropColumn.summary[4].ratio}>
        {costFormat(data.cnt)}
      </List.Row>
    </Info.Content>
  );
};

export default SketchDropSummaryList;
