import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RootLoading } from "components/Modal/Loading/RootLoading";
import { Modal } from "components/Modal/Modal";
import { useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useSetRecoilState } from "recoil";
import { loginState } from "recoil/atom/LoginState";
import { Router } from "router/router";
import { getUserFromLocalStorage } from "utils/localStorage";

function App() {
  // 리액트 쿼리 사용을 위한 client 정의
  const queryClient = new QueryClient();

  // 로그인 정보 기억하기 한 사용자인지 체크
  const setLogin = useSetRecoilState(loginState);

  useEffect(() => {
    // 초기 로그인 과정
    const loginData = getUserFromLocalStorage();

    if (!loginData) {
      setLogin({ isLogin: false });
    } else {
      setLogin({ isLogin: true });
    }

    console.log("App.js Rendering");
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <ToastContainer />
        <BrowserRouter>
          <Modal />
          <RootLoading />
          <ReactQueryDevtools initialIsOpen={false} />
          <Router />
        </BrowserRouter>
      </CookiesProvider>
    </QueryClientProvider>
  );
}

export default App;
