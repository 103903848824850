import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
`;

export const Main = {
  Wrapper: styled.div`
    width: 34rem;
    height: 46rem;
    background-color: white;
    padding: 0 3rem;
  `,

  Logo: styled.div`
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2.2rem;
    gap: 0.5rem;
    font-weight: ${FONT_WEIGHT.BOLD};

    & > img {
      width: 8rem;
      height: 8rem;
    }
  `,

  InputWrapper: styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 0.4rem;
    font-size: 1rem;
    font-weight: ${FONT_WEIGHT.MEDIUM};

    & > input {
      font-size: 1.6rem;
      padding: 1rem;
      font-weight: ${FONT_WEIGHT.BOLD};
      border-radius: 0.6rem;
      border: 0.1rem solid #999;
      outline: none;

      &::placeholder {
        font-weight: 400;
        color: #bbb;
      }
    }
  `,

  Validate: styled.div`
    color: red;
    font-size: 1.2rem;
  `,

  Remember: styled.div`
    display: flex;
    align-items: center;
    margin-top: 1rem;
    gap: 0.4rem;
    font-size: 1.2rem;
    font-weight: ${FONT_WEIGHT.MEDIUM};

    & > input {
      margin: 0;
      width: 1.6rem;
      height: 1.6rem;
    }
  `,

  LoginBtn: styled.div`
    width: 100%;
    height: 4.5rem;
    border-radius: 0.6rem;
    background-color: #4253f2;
    color: white;
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    cursor: pointer;
  `,
};
