import { useToastCustom } from "./useToastCustom";

export const useCopyCustom = () => {
  const toast = useToastCustom();

  return async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast(`지갑 주소가 복사되었습니다. ${text}`, "success");
    } catch (e) {
      toast("복사에 실패하였습니다", "error");
    }
  };
};
