import { useState } from "react";
import { Container, Content, Wrapper } from "../style";
import SearchCategory from "../../SearchCategory";
import { PaginationBox } from "components/Pagination/PaginationBox";
import { POST_WITHDRAW_LIST } from "constants/pathConstant";
import { Info, List } from "template/ListTemplate.style";
import withdrawColumn from "./withdrawByUserColumn.json";
import WithdrawByUserDetail from "./WithdrawByUserDetail";
import { WITHDRAW_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";

// 회원검색 (검증 후 회원 정보 바로가기) :: 지갑정보 -> 인출 (http://localhost:3000/manage/user/:code)
const WithdrawByUser = ({ acctCd }) => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [resData, setResData] = useState([]);

  const API_ADDRESS = POST_WITHDRAW_LIST;
  const XLSX_DOWNLOAD = WITHDRAW_XLSX_DOWNLOAD;

  const data = {
    id: "",
    useType: "",
    state: "90",
    txId: "",
    acctUserNm: "",
    acctCd,
    cellNumber: "",
    currentPage: page,
    pageSize: size,
  };

  return (
    <Wrapper>
      <SearchCategory
        API_ADDRESS={API_ADDRESS}
        data={data}
        setResData={setResData}
        setPage={setPage}
        setSize={setSize}
        page={page}
        size={size}
        resData={resData}
        XLSX_DOWNLOAD={XLSX_DOWNLOAD}
        withdraw={true}
      />

      <Container>
        <Content>
          {withdrawColumn.data.map((v, idx) => (
            <List.Row $ratio={v.ratio} key={idx}>
              {v.title}
            </List.Row>
          ))}
        </Content>

        <Info.Wrapper>
          {resData.map((v, idx) => (
            <WithdrawByUserDetail data={v} key={idx} />
          ))}
        </Info.Wrapper>
      </Container>

      {resData.length !== 0 && (
        <PaginationBox
          totalItemsCount={resData[0]?.cnt}
          page={page}
          setPage={setPage}
          size={size}
        />
      )}
    </Wrapper>
  );
};

export default WithdrawByUser;
