import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { UserState } from "recoil/atom/UserState";
import { UserLogsState } from "recoil/atom/UserDetailPageState";
import { dateFormat } from "components/dateFormat";
import { LogsModal } from "../LogsModal.style";
import { loginData } from "recoil/atom/LoginState";
import { useMutationCustom } from "hooks/useMutationCustom";
import { HTTP_METHOD, POST_DELETE_USER_LOG, POST_MODIFY_USER_LOG } from "constants/pathConstant";
import { useToastCustom } from "hooks/useToastCustom";
import { getUserParamsFromUri } from "utils/crypto";

export const UserCallLogsModal = ({ YM, YMD, setCallLogsAll }) => {
  const toast = useToastCustom();
  const { acctCd } = getUserParamsFromUri();

  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [actionNotes, setActionNotes] = useState("");

  const callLogs = useRecoilValue(UserLogsState.UserCallLogs);
  const userDetailData = useRecoilValue(UserState.userDetail);
  const { name } = useRecoilValue(loginData);

  const { mutate: mutateModifyLog } = useMutationCustom(POST_MODIFY_USER_LOG, HTTP_METHOD.POST);

  const handleMemoEdit = (id) => {
    mutateModifyLog(
      { id, actionNotes, acctCd },
      {
        onSuccess: (resposne) => {
          setCallLogsAll(resposne.filter((v) => v.largeCd === "T"));
          toast("메모가 수정되었습니다.", "success");
          setIsEdit(false);
        },
      }
    );
  };

  const { mutate: mutateDeleteLog } = useMutationCustom(POST_DELETE_USER_LOG, HTTP_METHOD.POST);

  const handleMemoDelete = (id) => {
    if (window.confirm("메모를 삭제하시겠습니까?")) {
      mutateDeleteLog(
        { id, acctCd },
        {
          onSuccess: (resposne) => {
            setCallLogsAll(resposne.filter((v) => v.largeCd === "T"));
            toast("메모가 삭제되었습니다.", "success");
          },
        }
      );
    } else {
    }
  };

  if (!YM || !YMD) return;

  return (
    <LogsModal.Wrapper>
      <LogsModal.Title>
        {userDetailData.acctUserNm} ({userDetailData.acctCd})_ 통화내용 / 특이사항 기록
      </LogsModal.Title>

      <LogsModal.Container>
        <div>
          {callLogs[YM][YMD] &&
            callLogs[YM][YMD].map((v, idx) => (
              <LogsModal.List key={idx}>
                <div>
                  <LogsModal.Writer>
                    <div>{dateFormat(v.crtDt)}</div>
                    <div>|</div>
                    <div>
                      {v.regId}
                      {v.editableCount > 0 ? "_수정됨" : ""}
                    </div>
                  </LogsModal.Writer>
                  {isEdit && v.id === editId ? (
                    <LogsModal.SettingBox>
                      <p
                        onClick={() => {
                          handleMemoEdit(v.id);
                        }}
                      >
                        수정
                      </p>
                      <p
                        onClick={() => {
                          setIsEdit(false);
                        }}
                      >
                        취소
                      </p>
                    </LogsModal.SettingBox>
                  ) : (
                    <LogsModal.SettingBox>
                      <p
                        onClick={() => {
                          if (v.editableYn === "N") return toast("해당글은 수정할 수 있습니다.", "error");
                          if (name !== v.regId) return toast("작성자만 수정할 수 있습니다.", "error");
                          setIsEdit(true);
                          setEditId(v.id);
                        }}
                      >
                        수정
                      </p>
                      <p
                        onClick={() => {
                          if (v.editableYn === "N") return toast("해당글은 삭제할 수 있습니다.", "error");
                          if (name !== v.regId) return toast("작성자만 삭제할 수 있습니다.", "error");
                          handleMemoDelete(v.id);
                        }}
                      >
                        삭제
                      </p>
                    </LogsModal.SettingBox>
                  )}
                </div>

                {isEdit && v.id === editId ? (
                  <textarea
                    defaultValue={v.actionNotes}
                    onChange={(e) => {
                      setActionNotes(e.target.value);
                    }}
                  />
                ) : (
                  <LogsModal.Content>{v.actionNotes}</LogsModal.Content>
                )}
              </LogsModal.List>
            ))}
        </div>
      </LogsModal.Container>
    </LogsModal.Wrapper>
  );
};
