import SfuelProcess from "./SfuelProcess";
import SfuelWalletInfo from "./SfuelWalletInfo";
import { Wrapper } from "./style";

const SfuelManage = () => {
  return (
    <Wrapper>
      <SfuelWalletInfo />
      <SfuelProcess />
    </Wrapper>
  );
};

export default SfuelManage;
