import { Info } from "template/ListTemplate.style";
import {
  Btn,
  ImgDiv,
  ImgInput,
  ImgLabel,
  Row,
} from "../ManageUiUpload/ManageUiUpload.style";
import manageUiColumn from "../manageUiColumn.json";
import { useEffect, useRef, useState } from "react";
import { BtnBox } from "pages/DropUser/DropUser.style";
import { useToastCustom } from "hooks/useToastCustom";
import { useMutationCustom } from "hooks/useMutationCustom";
import {
  IMG_CDN_URL,
  POST_MANAGE_UI_DELETE,
  POST_MANAGE_UI_MODIFY,
} from "constants/pathConstant";
import { useMutation } from "@tanstack/react-query";
import { axiosInstance } from "api/api";

const ManageUiDetail = ({ data, fetchData }) => {
  const imgRef = useRef();
  const toast = useToastCustom();

  const [isEdit, setIsEdit] = useState(false);
  const [type, setType] = useState("");
  const [typeDetail, setTypeDetail] = useState("");
  const [description, setDescription] = useState("");
  const [useYn, setUseYn] = useState("");
  const [imgPreview, setImgPreview] = useState("");
  const [isModifiedFile, setIsModifiedFile] = useState(false);

  const uiType = data.uiType.split("-");
  useEffect(() => {
    setType(uiType[0]);
    setTypeDetail(uiType[1]);
    setDescription(data.description);
    setUseYn(data.useYn);
    setImgPreview(data.sampleImgUrl);
  }, [isEdit]);

  // 수정 이미지 업로드
  const imgModifyHandler = (e) => {
    const modifyImg = e.target.files[0];

    if (!modifyImg) return;
    if (modifyImg.size / 1000 / 1000 > 5)
      return toast("파일 크기는 최대 5MB입니다.", "error");

    const fileReader = new FileReader();
    fileReader.readAsDataURL(modifyImg);
    fileReader.onloadend = () => {
      setImgPreview(fileReader.result);
      setIsModifiedFile(true);
    };
  };

  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const { mutate: mutateModifyUi } = useMutation(
    (data) => axiosInstance.post(POST_MANAGE_UI_MODIFY, data, config),
    {
      onSuccess: () => {
        toast("수정되었습니다.", "success");
        fetchData();
        setIsEdit(false);
      },
      onError: () => {
        toast("수정에 실패하였습니다.", "error");
        setIsEdit(false);
      },
    }
  );

  // 수정
  const modifyHandler = (id) => {
    setIsEdit(true);

    const data = {
      id,
      uiType: `${type}-${typeDetail}`,
      description,
      useYn,
      value: "",
      isModifiedFile,
    };

    const formData = new FormData();

    for (const key in data) {
      formData.append(
        key,
        typeof data[key] === "object" ? JSON.stringify(data[key]) : data[key]
      );
    }

    formData.append(
      "sampleImgUrl",
      isModifiedFile ? imgRef.current.files[0] : new Blob()
    );

    mutateModifyUi(formData);
  };

  const { mutate: mutateDeleteUi } = useMutationCustom(POST_MANAGE_UI_DELETE);

  // 삭제
  const deleteHandler = (id) => {
    if (window.confirm("삭제하시겠습니까?")) {
      mutateDeleteUi(
        { id },
        {
          onSuccess: () => {
            toast("삭제되었습니다.", "success");
            fetchData();
          },
          onError: () => {
            toast("삭제에 실패하였습니다.", "error");
          },
        }
      );
    } else {
      window.alert("취소되었습니다.");
    }
  };

  return (
    <Info.Content>
      <Row $ratio={manageUiColumn.data[0].ratio}>
        {isEdit ? (
          <>
            <ImgDiv>
              <img
                src={
                  isModifiedFile
                    ? imgPreview
                    : `${IMG_CDN_URL}/${data.sampleImgUrl}`
                }
                alt="ui_img_preview"
              />
            </ImgDiv>
            <ImgInput
              id="ui_img_edit"
              type="file"
              onChange={(e) => imgModifyHandler(e)}
              accept="image/*"
              ref={imgRef}
            />
            <ImgLabel htmlFor="ui_img_edit">파일선택</ImgLabel>
          </>
        ) : (
          <img
            src={`${IMG_CDN_URL}/${data.sampleImgUrl}`}
            alt="ui_img_preview"
          />
        )}
      </Row>

      <Row $ratio={manageUiColumn.data[1].ratio}>
        {isEdit ? (
          <select
            defaultValue={uiType[0] || type}
            onChange={(e) => setType(e.target.value)}
            key={uiType[0] || type}
          >
            {Object.entries(manageUiColumn.uiType).map(([key, value]) => (
              <option value={key} key={key}>
                {value}
              </option>
            ))}
          </select>
        ) : (
          manageUiColumn.uiType[uiType[0]]
        )}
      </Row>

      <Row $ratio={manageUiColumn.data[2].ratio}>
        {isEdit ? (
          <input
            defaultValue={uiType[1]}
            onChange={(e) => setTypeDetail(e.target.value)}
            placeholder="필수"
          />
        ) : (
          uiType[1]
        )}
      </Row>

      <Row $ratio={manageUiColumn.data[3].ratio}>
        {isEdit ? (
          <input
            defaultValue={data.description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="선택"
          />
        ) : (
          data.description
        )}
      </Row>

      <Row $ratio={manageUiColumn.data[4].ratio}>
        {isEdit ? (
          <select
            defaultValue={data.useYn || useYn}
            onChange={(e) => setUseYn(e.target.value)}
            key={data.useYn || useYn}
          >
            {Object.entries(manageUiColumn.useYn).map(([key, value]) => (
              <option value={key} key={key}>
                {value}
              </option>
            ))}
          </select>
        ) : (
          manageUiColumn.useYn[data.useYn]
        )}
      </Row>

      <Row $ratio={manageUiColumn.data[5].ratio}>
        {isEdit ? (
          <BtnBox>
            <Btn
              onClick={() => {
                setIsEdit(false);
              }}
            >
              취소
            </Btn>
            <Btn
              background="#4253f2"
              onClick={() => {
                modifyHandler(data.id);
              }}
            >
              수정
            </Btn>
          </BtnBox>
        ) : (
          <BtnBox>
            <Btn
              onClick={() => {
                setIsEdit(true);
                setIsModifiedFile(false);
              }}
            >
              수정
            </Btn>
            <Btn
              background="transparent"
              color="#F00"
              border="#bbb"
              onClick={() => {
                deleteHandler(data.id);
              }}
            >
              삭제
            </Btn>
          </BtnBox>
        )}
      </Row>
    </Info.Content>
  );
};

export default ManageUiDetail;
