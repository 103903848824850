import { Wrapper } from "./TodoProperty.style";
import Todo from "./Todo/Todo";
import Property from "./Property/Property";

// 메인 :: TODO/자산 (http://localhost:3000/main)
const TodoProperty = () => {
  return (
    <Wrapper>
      {/* <Todo /> */}
      <Property />
    </Wrapper>
  );
};

export default TodoProperty;
