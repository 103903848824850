import React, { useState } from "react";
import { Container, Wrapper } from "./UserModify.style";
import { UserModifyInfo } from "./UserModifyInfo/UserModifyInfo";
import { UserCallInfo } from "./UserCallInfo/UserCallInfo";
import { UserModifyByMemo } from "./UserModifyByMemo/UserModifyByMemo";

// 회원검색 (검증 후 회원 정보 바로가기) :: 회원/가맹점 정보 수정 (http://localhost:3000/manage/user/:code)
export const UserModify = () => {
  const [logsAll, setLogsAll] = useState([]);

  return (
    <Wrapper>
      <Container.Top>
        <Container.Text>회원/가맹점 정보 수정</Container.Text>
      </Container.Top>
      <Container.Content>
        <UserModifyInfo logsAll={logsAll} setLogsAll={setLogsAll} />
        <UserModifyByMemo setLogsAll={setLogsAll} />
        <UserCallInfo />
      </Container.Content>
    </Wrapper>
  );
};
