import { useLocation } from "react-router";
import FaqUpload from "../FaqUpload";

// 고객 지원 -> FAQ 관리 :: FAQ 수정 (http://localhost:3000/super/create/faq-modify)
const FaqModify2 = () => {
  const location = useLocation();

  return <FaqUpload modifyData={location.state.data} />;
};

export default FaqModify2;
