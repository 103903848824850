import { useState } from "react";
import { Container, Header, List, Wrapper } from "./WalletInfo.style";

import PaymentByUser from "./WalletCategory/PaymentByUser";
import WithdrawByUser from "./WalletCategory/WithdrawByUser";
import ChargeByUser from "./WalletCategory/ChargeByUser";
import SummaryByUser from "./WalletCategory/SummaryByUser";

// 회원검색 (검증 후 회원 정보 바로가기) :: 지갑정보 (http://localhost:3000/manage/user/:code)
export const WalletInfo = ({ acctCd }) => {
  const [walletCategory, setWalletCategory] = useState("summary");

  return (
    <Wrapper>
      <Container.Top>
        <Container.Text>지갑정보</Container.Text>
      </Container.Top>
      <Container.Content>
        <Header.Wrapper>
          <Header.SmallCategory>
            <Header.SmallCategoryDiv
              selected={walletCategory === "summary"}
              onClick={() => setWalletCategory("summary")}
            >
              요약
            </Header.SmallCategoryDiv>
            <Header.SmallCategoryDiv
              onClick={() => setWalletCategory("charge")}
              selected={walletCategory === "charge"}
            >
              충전
            </Header.SmallCategoryDiv>
            <Header.SmallCategoryDiv
              onClick={() => setWalletCategory("withdraw")}
              selected={walletCategory === "withdraw"}
            >
              인출
            </Header.SmallCategoryDiv>
            <Header.SmallCategoryDiv
              onClick={() => setWalletCategory("payment")}
              selected={walletCategory === "payment"}
            >
              결제
            </Header.SmallCategoryDiv>
          </Header.SmallCategory>
        </Header.Wrapper>

        <List.Wrapper>
          {/* 요약 */}
          {walletCategory === "summary" && (
            <SummaryByUser acctCd={acctCd}></SummaryByUser>
          )}

          {/* 충전 */}
          {walletCategory === "charge" && (
            <ChargeByUser acctCd={acctCd}></ChargeByUser>
          )}

          {/* 인출 */}
          {walletCategory === "withdraw" && (
            <WithdrawByUser acctCd={acctCd}></WithdrawByUser>
          )}

          {/* 결제 */}
          {walletCategory === "payment" && (
            <PaymentByUser acctCd={acctCd}></PaymentByUser>
          )}
        </List.Wrapper>
      </Container.Content>
    </Wrapper>
  );
};
