const getFileNameWithDate = (string) => {
  const today = new Date();

  return `${string} ${
    today.getFullYear() + "." + (today.getMonth() + 1) + "." + today.getDate()
  }`;
};

export const TEMPLATE = {
  header: [
    { header: "", key: "memberCode" },
    { header: "", key: "cellNumber" },
    { header: "", key: "email" },
    { header: "", key: "reqTypeNm" },
    { header: "", key: "memberCode" },
    { header: "", key: "cellNumber" },
    { header: "", key: "email" },
    { header: "", key: "reqTypeNm" },
    { header: "", key: "memberCode" },
    { header: "", key: "cellNumber" },
    { header: "", key: "email" },
    { header: "", key: "reqTypeNm" },
  ],

  filename: getFileNameWithDate("storeList"),
};

/**
 * http://localhost:3000/super/bridge/charge
 */
export const CHARGE_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "회원명", key: "acctUserNm" },
    { header: "회원코드", key: "acctCd" },
    { header: "생년월일", key: "birthDay" },
    { header: "휴대전화번호", key: "cellNumber" },
    { header: "구분", key: "usedNm" },
    { header: "신청 수량(SKETCH)", key: "amount" },
    { header: "상태", key: "stateNm" },
    { header: "주문번호", key: "reqId" },
    { header: "보낸주소(회사)", key: "fromWa" },
    { header: "받은주소(유저)", key: "toWa" },
    { header: "충전신청일자", key: "crtDt" },
    { header: "최종반영일자", key: "updDt" },
    { header: "txId", key: "txId" },
  ],

  filename: getFileNameWithDate("sketch_charge"),
};

/**
 * http://localhost:3000/super/bridge/withdraw
 */
export const WITHDRAW_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "회원명", key: "acctUserNm" },
    { header: "회원코드", key: "acctCd" },
    { header: "생년월일", key: "birthDay" },
    { header: "휴대전화번호", key: "cellNumber" },
    { header: "유형", key: "useTypeNm" },
    { header: "세부유형", key: "usedNm" },
    { header: "신청수량(SKETCH)", key: "amount" },
    { header: "수수료(SKETCH)", key: "fee" },
    { header: "인출에서 사용된 전체 밸런스", key: "withdrawTotalAmount" },
    { header: "인출 수수료에서 사용된 충전 스케치", key: "useLockFee" },
    { header: "인출 수수료에서 사용된 리워드 스케치", key: "useRewardFee" },
    { header: "인출에서 사용된 전체 충전 스케치", key: "lockTotalAmount" },
    { header: "인출에서 사용된 전체 리워드 스케치", key: "rewardTotalAmount" },
    { header: "상태", key: "stateNm" },
    { header: "From", key: "fromWa" },
    { header: "To", key: "toWa" },
    { header: "txId", key: "txId" },
    { header: "신청일자", key: "crtDt" },
    { header: "완료일자", key: "updDt" },
    { header: "주문번호", key: "reqId" },
  ],

  filename: getFileNameWithDate("sketch_withdraw"),
};

/**
 * http://localhost:3000/super/bridge/swap
 */
export const SWAP_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "회원명", key: "acctUserNm" },
    { header: "회원코드", key: "acctCd" },
    { header: "생년월일", key: "birthDay" },
    { header: "휴대전화번호", key: "cellNumber" },
    { header: "유형", key: "useTypeNm" },
    { header: "수량(SKET)", key: "amount" },
    { header: "수수료 (SKETCH)", key: "sketFee" },
    { header: "수수료 (eth)", key: "ethFee" },
    { header: "실제 전송 수량(SKETCH)", key: "totalAmount" },
    { header: "상태", key: "stateNm" },
    { header: "보낸주소(회사)", key: "fromWa" },
    { header: "받은주소(유저)", key: "toWa" },
    { header: "신청일자", key: "crtDt" },
    { header: "최종반영일자", key: "updDt" },
    { header: "txId(유저)", key: "userToComTxid" },
    { header: "txId(회사)", key: "comToUserTxid" },
  ],

  filename: getFileNameWithDate("sketch_swap"),
};
/**
 * http://localhost:3000/super/accept/store
 */
export const ACCEPT_STORE_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "가입일자", key: "memberName" },
    { header: "승인상태", key: "memberCode" },
    { header: "가맹점명", key: "cellNumber" },
    { header: "가맹점코드", key: "email" },
    { header: "대표자명", key: "reqTypeNm" },
    { header: "사업장 전화번호", key: "stateNm" },
    { header: "주소", key: "fromWa" },
    { header: "URL", key: "toWa" },
    { header: "추천인코드", key: "sumAmountFromwa" },
    { header: "수수료율", key: "sumAmountToWa" },
  ],

  filename: getFileNameWithDate("store_accept"),
};

/**
 * http://localhost:3000/super/search/store
 */

export const SUPER_STORE_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "가입일자", key: "crtDt" },
    { header: "가맹점명", key: "storeNm" },
    { header: "가맹점코드", key: "storeId" },
    { header: "대표자명", key: "ceoNm" },
    { header: "휴대전화번호", key: "ceoCellNumber" },
    { header: "사업장 전화번호", key: "contactNum" },
    { header: "이메일", key: "ceoEmail" },
    { header: "추천인코드", key: "refererCd" },
    { header: "수수료율", key: "feeRate" },
    { header: "서비스유무", key: "serviceYn" },
    { header: "승인상태", key: "confirmStateNm" },
  ],

  filename: getFileNameWithDate("storeList_super"),
};

/**
 * http://localhost:3000/manage/store
 */
export const STORE_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "생성일자", key: "crtDt" },
    { header: "가맹점명", key: "storeNm" },
    { header: "가맹점코드", key: "storeId" },
    { header: "대표자명", key: "ceoNm" },
    { header: "대표자 휴대전화", key: "cellNumber" },
    { header: "서비스 유무", key: "stateNm" },
  ],

  filename: getFileNameWithDate("storeList"),
};

/**
 * http://localhost:3000/super/search/user
 */
export const SUPER_USER_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "가입일자", key: "crtDt" },
    { header: "회원명", key: "acctUserNm" },
    { header: "회원코드", key: "acctCd" },
    { header: "추천인명", key: "refererNm" },
    { header: "추천인코드", key: "refererCd" },
    { header: "생년월일", key: "birthDay" },
    { header: "휴대전화번호", key: "cellNumber" },
    { header: "이메일", key: "email" },
    { header: "지갑주소", key: "walletDefault" },
    { header: "활동상태", key: "stateNm" },
    { header: "스토어 ID", key: "storeId" },
  ],

  filename: getFileNameWithDate("userList_super"),
};

/**
 * http://localhost:3000/super/search/user/wallet
 */
export const SUPER_WALLET_USER_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "가입일자", key: "createDate" },
    { header: "회원명", key: "memberName" },
    { header: "회원코드", key: "memberCode" },
    { header: "추천인명", key: "recommenderName" },
    { header: "추천인코드", key: "recommenderCode" },
    { header: "생년월일", key: "birthDay" },
    { header: "휴대전화번호", key: "mobile" },
    { header: "이메일", key: "email" },
    { header: "지갑주소", key: "walletAddress" },
    { header: "활동상태", key: "state" },
  ],

  filename: getFileNameWithDate("wallet_user_list_super"),
};

/**
 * http://localhost:3000/manage/user/pay
 */
export const USER_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "회원명", key: "acctUserNm" },
    { header: "회원코드", key: "acctCd" },
    { header: "생년월일", key: "birthDay" },
    { header: "휴대전화", key: "cellNumber" },
    { header: "활동상태", key: "stateNm" },
    { header: "가입일자", key: "crtDt" },
  ],

  filename: getFileNameWithDate("userList"),
};

/**
 * http://localhost:3000/manage/user/wallet
 */
export const WALLET_USER_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "가입일자", key: "createDate" },
    { header: "회원명", key: "memberName" },
    { header: "회원코드", key: "memberCode" },
    { header: "생년월일", key: "birthDay" },
    { header: "휴대전화", key: "mobile" },
    { header: "활동상태", key: "state" },
  ],

  filename: getFileNameWithDate("wallet_user_list"),
};

export const DROP_USER_XLSX_DOWNLOAD = {
  header: [
    { header: "번호.", key: "id" },
    { header: "가입일자", key: "crtDt" },
    { header: "탈퇴신청일자", key: "reqQuitDt" },
    { header: "탈퇴일자", key: "quitDt" },
    { header: "회원명", key: "acctUserNm" },
    { header: "회원코드", key: "acctCd" },
    { header: "추천인명", key: "refererNm" },
    { header: "추천인코드", key: "refererCd" },
    { header: "생년월일", key: "birthDay" },
    { header: "휴대전화", key: "cellNumber" },
    { header: "이메일", key: "email" },
    { header: "지갑주소", key: "walletDefault" },
    { header: "상태", key: "stateNm" },
    { header: "탈퇴 신청 횟수", key: "reqCnt" },
  ],

  filename: getFileNameWithDate("dropUserList"),
};

/**
 * http://localhost:3000/manage/user/{hash}
 */
export const WALLET_SEND_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "시간", key: "crtDt" },
    { header: "보낸금액", key: "txValue" },
    { header: "수수료", key: "fee" },
    { header: "txId", key: "txId" },
  ],

  filename: getFileNameWithDate("wallet_sendList"),
};

/**
 * http://localhost:3000/manage/user/{hash}
 */
export const WALLET_GET_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "시간", key: "crtDt" },
    { header: "받은금액", key: "txValue" },
    { header: "txId", key: "txId" },
  ],

  filename: getFileNameWithDate("wallet_getList"),
};

/**
 * http://localhost:3000/manage/user/{hash}
 */
export const WALLET_PAYMENT_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "시간", key: "crtDt" },
    { header: "가맹점명", key: "ssName" },
    { header: "결제금액", key: "ssOrderAmountFiat" },
    { header: "시세(1Sketch당)", key: "sketRate" },
    { header: "수수료율", key: "feeRate" },
    { header: "스케치백", key: "sketchBack" },
    { header: "승인번호", key: "ssOrderId" },
    { header: "가맹점코드", key: "ssCd" },
    { header: "사업장 전화번호", key: "contactNum" },
    { header: "홈페이지", key: "linkUrl" },
    { header: "주소", key: "addr" },
  ],

  filename: getFileNameWithDate("wallet_payment"),
};

/**
 * http://localhost:3000/super/payment/pay
 */
export const PAYMENT_PAY_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "결제일자", key: "paymentDt" },
    { header: "가맹점명", key: "storeNm" },
    { header: "가맹점코드", key: "storeCd" },
    { header: "결제고객명", key: "customerNm" },
    { header: "결제고객코드", key: "customerCd" },
    { header: "생년월일", key: "birthDay" },
    { header: "결제(SKETCH)", key: "sketchPayment" },
    { header: "결제(원)", key: "krwPayment" },
    { header: "업체정산(업체수익)(SKETCH)", key: "sketchBnsSeq1" },
    { header: "업체정산(업체수익)(원)", key: "krwBnsSeq1" },
    { header: "시세(원)", key: "krwPerSketch" },
    { header: "수수료율", key: "storeFeeRate" },
    { header: "스케치백(SKETCH)", key: "sketchBnsSeq2" },
    { header: "스케치백(원)", key: "krwBnsSeq2" },
    { header: "스토어 추천 보너스(SKETCH)", key: "sketchBnsSeq3" },
    { header: "스토어 추천 보너스(원)", key: "krwBnsSeq3" },
    { header: "회사보너스(SKETCH)", key: "sketchBnsSeq4" },
    { header: "회사보너스(원)", key: "krwBnsSeq4" },
    { header: "추천인보너스(SKETCH)", key: "sketchBnsSeq5" },
    { header: "추천인보너스(원)", key: "krwBnsSeq5" },
    { header: "추천인", key: "refererNm" },
    { header: "추천인코드", key: "refererCd" },
    { header: "스토어추천인명", key: "storeRefererNm" },
    { header: "스토어추천인코드", key: "storeRefererCd" },
    { header: "결제받은 스토어 지갑 주소", key: "storeWalletAddr" },
    { header: "상태", key: "paymentState" },
    { header: "승인번호", key: "reqId" },
  ],

  filename: getFileNameWithDate("payment_wallet"),
};

/**
 * http://localhost:3000/super/payment/wallet
 */
export const PAYMENT_WALLET_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "가맹점코드", key: "ssCd" },
    { header: "일자", key: "createDate" },
    { header: "유형", key: "typeId" },
    { header: "유형상세", key: "cancelAll" },
    { header: "상태", key: "txTypeId" },
    { header: "주문번호", key: "ssOrderId" },
    { header: "회원명", key: "memberName" },
    { header: "회원코드", key: "memberCode" },
    { header: "생년월일", key: "birthDay" },
    { header: "추천인", key: "recommenderName" },
    { header: "추천인코드", key: "recommenderCode" },
    { header: "결제코인타입", key: "coinType" },
    { header: "실제 전송 수량(전송수수료 가감된 수량)", key: "amount" },
    { header: "결제 원 수량", key: "originAmount" },
    { header: "실제 전송 수량(원)", key: "amountKrw" },
    { header: "결제 원 수량(원)", key: "originAmountKrw" },
    { header: "배송비(원)", key: "deliveryKrw" },
    { header: "사용한 멤버포인트(원)", key: "memberPoint" },
    { header: "사용한 팝콘포인트(원)", key: "popcornPoint" },
    { header: "시세(원)", key: "exchangeRateKrw" },
    { header: "상품 마진(원)", key: "ssLastPricePayAmountKrw" },
    { header: "예상 스케치백(수량)", key: "estimatedSbackAmount" },
    { header: "예상 스케치백(원)", key: "estimatedSbackAmountKrw" },
    { header: "회사 리워드(SKETCH)", key: "estimatedCompanyBackAmount" },
    { header: "회사 리워드(원)", key: "estimatedCompanyBackAmountKrw" },
    { header: "회사수수료(수량)", key: "companyFee" },
    { header: "고객수수료(수량)", key: "personalFee" },
    { header: "송금수수료 타입", key: "transactionFeeType" },
    { header: "송금수수료(수량)", key: "transactionFee" },
    { header: "보낸 주소", key: "fromWalletAddress" },
    { header: "받은 주소", key: "toWalletAddress" },
    { header: "txId", key: "txId" },
  ],

  filename: getFileNameWithDate("payment_wallet"),
};

/**
 * http://localhost:3000/super/asset/pay
 */
export const ASSET_PAY_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "회원명", key: "acctUserNm" },
    { header: "회원코드", key: "acctCd" },
    { header: "가맹점명", key: "ssName" },
    { header: "가맹점코드", key: "ssCd" },
    { header: "승인일자", key: "crtDt" },
    { header: "유형", key: "useTypeNm" },
    { header: "세부유형", key: "usedNm" },
    { header: "SKETCH 수량", key: "txValue" },
    { header: "원화 가치", key: "exchangeFromKrw" },
    { header: "1Sketch 당 원화", key: "exchangeRateKrw" },
    { header: "인출 수수료 (sketch)", key: "fee" },
    { header: "상태", key: "stateNm" },
    { header: "사용한 재화종류", key: "symbol" },
    { header: "txId", key: "txId" },
  ],

  filename: getFileNameWithDate("asset_wallet"),
};

/**
 * http://localhost:3000/super/asset/wallet
 */
export const ASSET_WALLET_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "회원명", key: "memberName" },
    { header: "회원코드", key: "memberCode" },
    { header: "가맹점코드", key: "ssCd" },
    { header: "유형", key: "typeId" },
    { header: "유형상세", key: "cancelAll" },
    { header: "거래 코인 타입", key: "coinId" },
    { header: "수량", key: "amount" },
    { header: "송금수수료 타입", key: "transactionFeeType" },
    { header: "송금수수료", key: "transactionFee" },
    { header: "시세(원)", key: "exchangeRateKrw" },
    { header: "거래일자", key: "createDate" },
    { header: "상태", key: "txTypeId" },
    { header: "보낸 지갑주소", key: "fromWalletAddress" },
    { header: "받은 지갑주소", key: "toWalletAddress" },
    { header: "txId", key: "txId" },
  ],

  filename: getFileNameWithDate("asset_wallet"),
};

/**
 * http://localhost:3000/super/calculate/settle
 */
export const WALLET_SETTLE_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "회원명", key: "acctUserNm" },
    { header: "회원코드", key: "acctCd" },
    { header: "생년월일", key: "birthDay" },
    { header: "휴대전화번호", key: "cellNumber" },
    { header: "구분", key: "usedNm" },
    { header: "회수 수량", key: "txValue" },
    { header: "시세(원)", key: "exchangeRateSketchKrw" },
    { header: "회수일자", key: "crtDt" },
    { header: "상태", key: "stateNm" },
    { header: "txId", key: "txId" },
    { header: "보낸주소(유저)", key: "sendWa" },
    { header: "받은주소 (회사)", key: "recvWa" },
    { header: "주문 번호", key: "reqId" },
  ],

  filename: getFileNameWithDate("wallet_settle"),
};

/**
 * http://localhost:3000/super/calculate/sketch-drop
 */
export const SKETCH_DROP_SELECT_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "idx" },
    { header: "일자(연월)", key: "yymm" },
    { header: "보유(SKETCH)", key: "havingAmount" },
    { header: "사용(SKETCH)", key: "usedAmount" },
    { header: "합계(SKETCH)", key: "txValue" },
    { header: "대상 인원(명)", key: "cntMembers" },
    { header: "건수(개)", key: "cnt" },
  ],

  filename: getFileNameWithDate("sketch_drop"),
};

/**
 * http://localhost:3000/super/calculate/sketch-drop
 */
export const SKETCH_DROP_DETAIL_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "회원코드", key: "acctCd" },
    { header: "회원명", key: "acctUserNm" },
    { header: "보유(SKETCH)", key: "havingAmount" },
    { header: "사용(SKETCH)", key: "usedAmount" },
    { header: "합계(SKETCH)", key: "txValue" },
    { header: "일자", key: "crtDt" },
  ],

  filename: getFileNameWithDate("sketch_drop_detail"),
};

/**
 * http://localhost:3000/super/calculate/sketch-back
 */
export const SKETCH_BACK_MONTH_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "idx" },
    { header: "일자(연월)", key: "reqDate" },
    { header: "스케치 백 대상 지갑(개)", key: "targetWalletCnt" },
    { header: "스케치 합계(SKETCH)", key: "totalAmountSketch" },
    { header: "원화 합계(원)", key: "totalAmountKrw" },
    { header: "스케치 백 총 건수", key: "totalCnt" },
  ],

  filename: getFileNameWithDate("sketch_back_month"),
};

/**
 * http://localhost:3000/super/calculate/sketch-back
 */
export const SKETCH_BACK_DETAIL_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "일자(연월)", key: "reqDate" },
    { header: "주문번호", key: "ebitmallOrderId" },
    { header: "결제자", key: "memberName" },
    { header: "보낸 주소", key: "fromWalletAddress" },
    { header: "받은 주소", key: "toWalletAddress" },
    { header: "스케치백 개수(SKETCH)", key: "amount" },
    { header: "수수료", key: "transactionFee" },
    { header: "상태", key: "txState" },
    { header: "제외 여부", key: "excludeYn" },
    { header: "수정 날짜", key: "modifiedDate" },
    { header: "수정자", key: "modifyAdmin" },
    { header: "txId", key: "txId" },
    { header: "트랜잭션 날짜", key: "txDate" },
    { header: "실제 전송한 스케치백 개수", key: "transactionValue" },
    { header: "원화 환율 (1스케치당 n원)", key: "exchangeRateKrw" },
    { header: "usd 환율 (1스케치당 nUsd)", key: "exchangeRateUsd" },
    { header: "스케치백 총 원화 금액", key: "exchangeFromKrw" },
    { header: "스케치백 총 달러 금액", key: "exchangeFromUsd" },
  ],

  filename: getFileNameWithDate("sketch_back_detail"),
};

/**
 * http://localhost:3000/manage/user/wallet/{hash}
 */
export const WALLET_INFO_SUMMARY_XLSX_DOWNLOAD = {
  header: [
    { header: "결제에서 사용한 스케치", key: "paymentAmount" },
    { header: "사용 스케치 드랍", key: "usedAmount" },
    { header: "보유 스케치 드랍", key: "havingAmount" },
    { header: "스케치백", key: "sbackAmount" },
  ],

  filename: getFileNameWithDate("wallet_info_sumary"),
};

/**
 * http://localhost:3000/manage/user/wallet/{hash}
 */
export const WALLET_INFO_PAYMENT_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "결제 일자", key: "createDate" },
    { header: "스토어 코드", key: "ssCd" },
    { header: "결제에서 사용한 스케치", key: "paymentAmount" },
    { header: "결제 취소 금액", key: "cancellationAmount" },
    { header: "환불 해줄 금액", key: "refundAmount" },
    { header: "시세(원)", key: "exchangeRateKrw" },
    { header: "상태", key: "typeId" },
    { header: "상태상세", key: "cancelAll" },
    { header: "txId", key: "txId" },
  ],

  filename: getFileNameWithDate("wallet_info_payment"),
};

/**
 * http://localhost:3000/manage/user/wallet/{hash}
 */
export const WALLET_INFO_DROP_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "요청일자", key: "createDate" },
    { header: "회원명", key: "memberName" },
    { header: "회원코드", key: "memberCode" },
    { header: "사용스케치드랍", key: "usedAmount" },
    { header: "보유스케치드랍", key: "havingAmount" },
    { header: "총 스케치드랍(수량)", key: "txValue" },
    { header: "총 스케치드랍(원)", key: "exchangeFromKrw" },
    { header: "시세(원)", key: "exchangeRateKrw" },
    { header: "지급일자", key: "updateDate" },
    { header: "상태", key: "state" },
    { header: "txId", key: "txId" },
  ],

  filename: getFileNameWithDate("wallet_info_drop"),
};

/**
 * http://localhost:3000/manage/user/wallet/{hash}
 */
export const WALLET_INFO_BACK_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "요청일자", key: "createDate" },
    { header: "회원명", key: "memberName" },
    { header: "회원코드", key: "memberCode" },
    { header: "스케치백(수량)", key: "amount" },
    { header: "스케치백(원)", key: "exchangeFromKrw" },
    { header: "시세(원)", key: "exchangeRateKrw" },
    { header: "지급일자", key: "updateDate" },
    { header: "txId", key: "txId" },
  ],

  filename: getFileNameWithDate("wallet_info_back"),
};

/**
 * http://localhost:3000/super/search/gone-member
 */
export const WALLET_MEMBER_DROP_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "id" },
    { header: "가입일자", key: "createDate" },
    { header: "탈퇴일자", key: "deleteDate" },
    { header: "생년월일", key: "birthDay" },
    { header: "회원명", key: "memberName" },
    { header: "회원코드", key: "memberCode" },
    { header: "추천인명", key: "recommenderName" },
    { header: "추천인코드", key: "recommenderCode" },
    { header: "휴대전화번호", key: "mobile" },
    { header: "이메일", key: "email" },
    { header: "지갑주소", key: "walletAddress" },
  ],

  filename: getFileNameWithDate("wallet_member_drop"),
};

/**
 * http://localhost:3000/super/payment/month
 */
export const PAYMENT_MONTH_XLSX_DOWNLOAD = {
  header: [
    { header: "일자(연월)", key: "yearMonth" },
    { header: "결제금액(Sketch)", key: "sketchOrderAmount" },
    { header: "결제금액(pol)", key: "polOrderAmount" },
    { header: "결제금액(eth)", key: "ethOrderAmount" },
    { header: "결제취소금액(Sketch)", key: "sketchCancelAmount" },
    { header: "결제취소금액(pol)", key: "polCancelAmount" },
    { header: "결제취소금액(eth)", key: "ethCancelAmount" },
    { header: "총 금액(Sketch)", key: "sketchResultAmount" },
    { header: "총 금액(pol)", key: "polResultAmount" },
    { header: "총 금액(eth)", key: "ethResultAmount" },
  ],

  filename: getFileNameWithDate("payment_month"),
};

/**
 * http://localhost:3000/super/payment/month
 */
export const PAYMENT_MONTH_DETAIL_XLSX_DOWNLOAD = {
  header: [
    { header: "번호", key: "memberId" },
    { header: "회원명", key: "memberName" },
    { header: "회원코드", key: "memberCode" },
    { header: "결제금액(Sketch)", key: "sketchOrderAmount" },
    { header: "결제금액(pol)", key: "polOrderAmount" },
    { header: "결제금액(eth)", key: "ethOrderAmount" },
    { header: "결제취소금액(Sketch)", key: "sketchCancelAmount" },
    { header: "결제취소금액(pol)", key: "polCancelAmount" },
    { header: "결제취소금액(eth)", key: "ethCancelAmount" },
    { header: "총 금액(Sketch)", key: "sketchResultAmount" },
    { header: "총 금액(pol)", key: "polResultAmount" },
    { header: "총 금액(eth)", key: "ethResultAmount" },
    { header: "상태", key: "state" },
    { header: "지갑주소", key: "walletAddress" },
  ],

  filename: getFileNameWithDate("payment_month_detail"),
};
