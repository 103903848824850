import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 1rem;
  border: 0.1rem solid #000;
  background: #fff;
  box-shadow: 0rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.02);
  padding: 1rem;
  overflow: hidden;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  font-size: 1.2rem;
  width: 63rem;
`;

export const Top = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.7rem;
  `,

  Label: styled.label``,

  Text: styled.div``,

  Input: styled.input`
    width: 1.2rem;
    height: 1.2rem;
    accent-color: green;
  `,
};

export const Header = {
  Wrapper: styled.div`
    display: flex;
    height: 2rem;
    border-top: 0.1rem solid #e2e2e2;
    border-bottom: 0.1rem solid #e2e2e2;
    color: rgba(143, 143, 143, 1);
    text-align: center;
    align-items: center;
    margin-bottom: 0.7rem;
    min-width: 12rem;
  `,

  Title: styled.div`
    flex: ${(props) => props.$ratio || 1};
  `,
};

export const Body = {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem 0;
    margin-bottom: 2rem;
  `,

  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,
};

export const Roles = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
  `,

  Input: styled.input`
    width: 1.2rem;
    height: 1.2rem;
    accent-color: black;
  `,

  Text: styled.label`
    cursor: pointer;
  `,

  Container: styled.div`
    display: flex;
    align-items: center;
    gap: 0.3rem;
  `,
};

export const SaveBtn = {
  Wrapper: styled.div`
    padding: 1.5rem;
    display: flex;
    flex-direction: row-reverse;
  `,

  Btn: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #4253f2;
    padding: 0.7rem 1rem;
    border-radius: 0.4rem;
    cursor: pointer;

    &:hover {
      background-color: black;
      color: white;
    }
  `,
};
