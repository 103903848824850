import { ModalContaier, ModalWrapper } from "./style";

export const FaqModal = ({ data, setIsOpen }) => {
  return (
    <>
      <ModalWrapper onClick={() => setIsOpen((prev) => !prev)} />
      <ModalContaier>
        <div>
          <div>{data.question_kr}</div>
          <div dangerouslySetInnerHTML={{ __html: data.answer_kr }}></div>
        </div>
        <div>
          <div>{data.question_eng}</div>
          <div dangerouslySetInnerHTML={{ __html: data.answer_eng }}></div>
        </div>
      </ModalContaier>
    </>
  );
};
