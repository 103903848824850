import styled from "styled-components";

export const Wrapper = styled.div`
  width: 90%;
  margin: 6rem auto 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const NavImgBox = styled.div`
  width: 1.2rem;
  height: 1.2rem;

  > img {
    width: 100%;
    height: 100%;
  }
`;

export const Btn = styled.div`
  width: 10rem;
  height: 3rem;
  border-radius: 0.5rem;
  background: ${(props) => (props.disable ? "#bbb" : "#2db9c2")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  cursor: ${(props) => (props.disable ? "default" : "pointer")};
  pointer-events: ${(props) => (props.disable ? "none" : "auto")};
`;

export const RetryBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  color: white;
  border-radius: 0.4rem;
  cursor: pointer;
  background: #bbb;
`;
