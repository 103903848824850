import {
  HTTP_METHOD,
  POST_ACCT_QUIT_BALANCE,
  POST_DELETE_USER_ACCT,
  POST_FIND_DROP_USER,
  POST_SETTLE_QUIT_ACCT,
  POST_UNLOCK_QUIT_ACCT,
  SYMBOL,
} from "constants/pathConstant";
import { useCloseRootModal } from "hooks/modal/useCloseRootModal";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import {
  BalanceBox,
  Btn,
  BtnWrapper,
  CheckBtn,
  CheckUnlockBtn,
  QuitBtn,
  QuitCheckBox,
  Sub,
  SubmitBox,
  TextAreaBox,
  Title,
  Wrapper,
} from "./DeleteNumberModal.style";
import { useEffect, useState } from "react";
import { costFormat } from "components/costForamt";

export const DeleteAccountModal = ({ acctUserNm, acctCd, email, searchData, setDropUserList }) => {
  const closeRootModal = useCloseRootModal();
  const toast = useToastCustom();

  const [acctQuitMemo, setaAcctQuitMemo] = useState("");
  const [acctBalanceList, setAccountBalanceList] = useState({});
  const [settleState, setSettleState] = useState("");
  const [unlockState, setUnlockState] = useState("");

  // 회원 밸런스 정보 불러오기
  const { mutate: mutateGetQuitAcctBalance } = useMutationCustom(POST_ACCT_QUIT_BALANCE, HTTP_METHOD.POST);

  useEffect(() => {
    setSettleState(acctBalanceList.settleState);
    setUnlockState(acctBalanceList.unlockState);
  }, [acctBalanceList.settleState, acctBalanceList.unlockState]);

  useEffect(() => {
    mutateGetQuitAcctBalance(
      { acctCd },
      {
        onSuccess: (res) => {
          setAccountBalanceList(res);
        },
      }
    );
  }, []);

  // 회수 처리
  const { mutate: mutateSettleQuit } = useMutationCustom(POST_SETTLE_QUIT_ACCT, HTTP_METHOD.POST);

  const handleSettle = () => {
    if (settleState === "90") return toast("이미 처리 완료되었습니다.", "error");

    mutateSettleQuit(
      { acctCd },
      {
        onSuccess: () => {
          toast("회수 처리가 완료되었습니다.", "success");
          setSettleState("90");
        },
        onError: () => {
          toast("회수 처리에 실패하였습니다. 다시 시도해주세요.", "error");
        },
      }
    );
  };

  // 인출 처리
  const { mutate: mutateUnlockQuit } = useMutationCustom(POST_UNLOCK_QUIT_ACCT, HTTP_METHOD.POST);

  const handleUnlock = () => {
    if (settleState !== "90") return toast("회수 처리를 먼저 진행해주세요.", "error");

    if (unlockState === "90") return toast("이미 처리 완료되었습니다.", "error");

    mutateUnlockQuit(
      { acctCd },
      {
        onSuccess: () => {
          toast("인출 처리가 완료되었습니다.", "success");
          setUnlockState("90");
        },
        onError: () => {
          toast("인출 처리에 실패하였습니다. 다시 시도해주세요.", "error");
        },
      }
    );
  };

  // 탈퇴 처리
  const { mutate } = useMutationCustom(POST_DELETE_USER_ACCT, HTTP_METHOD.POST);

  const { mutate: updateData } = useMutationCustom(POST_FIND_DROP_USER, HTTP_METHOD.POST);

  const deleteCellNumber = () => {
    if (settleState !== "90" || unlockState !== "90") return toast("회수와 인출 처리를 먼저 진행해주세요.", "error");

    const data = { acctCd, email, acctQuitMemo };
    mutate(data, {
      onSuccess: () => {
        updateData(searchData, {
          onSuccess: (res) => {
            setDropUserList(res);
            toast("탈퇴가 성공적으로 처리되었습니다.", "success");
            closeRootModal();
          },
        });
      },

      onError: () => {
        toast("탈퇴 처리에 실패하였습니다.", "error");
        closeRootModal();
      },
    });
  };

  return (
    <Wrapper>
      <Title>
        <span>{acctUserNm}</span>님을 탈퇴처리 하시겠습니까?
      </Title>
      <TextAreaBox>
        <Sub>탈퇴 사유</Sub>
        <textarea onChange={(e) => setaAcctQuitMemo(e.target.value)} />
      </TextAreaBox>
      <p>
        해당 회원의 인증방법과 고유값이 리셋됩니다. <br /> 신중히 선택해주세요
      </p>

      <BalanceBox>
        <div>
          <p>예상 회수금액: {costFormat(acctBalanceList.settleBalance, SYMBOL)}</p>
          <p>{acctBalanceList.settleAdmId}</p>
        </div>
        <div>
          <p>예상 인출금액: {costFormat(acctBalanceList.unlockBalance, SYMBOL)}</p>
          <p>{acctBalanceList.unlockAdmId}</p>
        </div>
      </BalanceBox>

      <BtnWrapper>
        <QuitCheckBox>
          <CheckBtn onClick={handleSettle}>회수 처리</CheckBtn>
          <CheckUnlockBtn onClick={handleUnlock} settleCheck={settleState === "90"}>
            인출 처리
          </CheckUnlockBtn>
        </QuitCheckBox>
        <SubmitBox>
          <div>
            <Btn $bg={"#ECECEC"} color={"black"} onClick={closeRootModal}>
              취소
            </Btn>
            <QuitBtn quitCheck={settleState === "90" && unlockState === "90"} onClick={deleteCellNumber}>
              탈퇴
            </QuitBtn>
          </div>
          <p>{"회수, 인출, 탈퇴 순서대로 진행해주세요."}</p>
        </SubmitBox>
      </BtnWrapper>
    </Wrapper>
  );
};
