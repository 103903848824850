import { modalBaseStyle } from "constants/modalBaseStyle";
import { useSetRecoilState } from "recoil";
import { modalState } from "recoil/atom/ModalState";

export const useOpenCustomModal = (ModalComponent) => {
  const setModal = useSetRecoilState(modalState);
  const style = modalBaseStyle;

  return (prop = {}) => {
    const children = <ModalComponent {...prop} />;
    setModal({ isOpen: true, style, children });
  };
};
