import { dateFormat } from "./dateFormat";

export const xlsxDateFormat = (response) => {
  const updatedResponse = response.map((v) => {
    const updatedValue = {};

    for (const key in v) {
      if (key.includes("Dt")) {
        updatedValue[key] = dateFormat(v[key]);
      } else {
        updatedValue[key] = v[key];
      }
    }

    return updatedValue;
  });

  return updatedResponse;
};
