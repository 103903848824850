const now = new Date();
const localTime = (now.getTimezoneOffset() / 60) * -1;

export const dateFormat = (date, search = false, addDate = 0, time = localTime) => {
  let currentDate = date ? new Date(date) : new Date();

  // 날짜에 addDate를 더해주기
  currentDate.setDate(currentDate.getDate() + addDate);

  if (search) {
    // 시간 설정
    currentDate.setHours(0 - time, 0, 0, 0);
  } else {
    // 로컬 시간 설정
    currentDate.setHours(currentDate.getHours() + time);
  }

  // 날짜와 시간 추출

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");

  // 포맷팅된 날짜 반환
  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
  return formattedDate;
};
