import { Info, List, Wrapper } from "template/ListTemplate.style";
import paymentPayColumn from "../paymentPayColumn.json";
import { costFormat } from "components/costForamt";
import { SYMBOL } from "constants/pathConstant";

// 결제 내역 :: 요약 (http://localhost:3000/super/payment/pay)
const PaymentPaySummary = ({ paymentPayList }) => {
  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>
          결제내역 요약
          {` (${paymentPayList.storeNm ? paymentPayList.storeNm : "전체"})`}
        </List.Text>
        <List.BtnContainer></List.BtnContainer>
      </List.TitleContainer>

      <List.Container style={{ minHeight: "0" }}>
        <List.Content>
          {paymentPayColumn.summary.map((v, idx) => (
            <List.Row $ratio={v.ratio} key={idx}>
              {v.title}
            </List.Row>
          ))}
        </List.Content>

        <Info.Wrapper>
          <Info.Content>
            {/* 결제 포인트 */}
            <List.Row $ratio={paymentPayColumn.summary[0].ratio}>
              <List.RowElement>
                <p>{costFormat(paymentPayList.sketchPayment, SYMBOL)}</p>
                <p>({costFormat(paymentPayList.krwPayment, "원")})</p>
              </List.RowElement>
            </List.Row>

            {/* 결제 금액 */}
            <List.Row $ratio={paymentPayColumn.summary[1].ratio}>
              <List.RowElement>
                <p>{costFormat(paymentPayList.sketchBnsSeq1, SYMBOL)}</p>
                <p>({costFormat(paymentPayList.krwBnsSeq1, "원")})</p>
              </List.RowElement>
            </List.Row>

            {/* 고객 페이백 */}
            <List.Row $ratio={paymentPayColumn.summary[2].ratio}>
              <List.RowElement>
                <p>{costFormat(paymentPayList.sketchBnsSeq2, SYMBOL)}</p>
                <p>({costFormat(paymentPayList.krwBnsSeq2, "원")})</p>
              </List.RowElement>
            </List.Row>

            {/* 스토어 추천 보너스 */}
            <List.Row $ratio={paymentPayColumn.summary[3].ratio}>
              <List.RowElement>
                <p>{costFormat(paymentPayList.sketchBnsSeq3, SYMBOL)}</p>
                <p>({costFormat(paymentPayList.krwBnsSeq4, "원")})</p>
              </List.RowElement>
            </List.Row>

            {/* 회사 보너스 */}
            <List.Row $ratio={paymentPayColumn.summary[4].ratio}>
              <List.RowElement>
                <p>{costFormat(paymentPayList.sketchBnsSeq4, SYMBOL)}</p>
                <p>({costFormat(paymentPayList.krwBnsSeq4, "원")})</p>
              </List.RowElement>
            </List.Row>

            {/* 추천인 보너스 */}
            <List.Row $ratio={paymentPayColumn.summary[5].ratio}>
              <List.RowElement>
                <p>{costFormat(paymentPayList.sketchBnsSeq5, SYMBOL)}</p>
                <p>({costFormat(paymentPayList.krwBnsSeq5, "원")})</p>
              </List.RowElement>
            </List.Row>

            {/* 배송비 */}
            <List.Row $ratio={paymentPayColumn.summary[6].ratio}>
              {costFormat(paymentPayList.ssDeliveryCost)}
            </List.Row>

            {/* 총 리워드 */}
            <List.Row $ratio={paymentPayColumn.summary[7].ratio}>
              <List.RowElement>
                <p>{costFormat(paymentPayList.ssLastPricePaySketch, SYMBOL)}</p>
                <p>({costFormat(paymentPayList.ssLastPricePay)} 원)</p>
              </List.RowElement>
            </List.Row>
          </Info.Content>
        </Info.Wrapper>
      </List.Container>
    </Wrapper>
  );
};

export default PaymentPaySummary;
