import { Info, List } from "template/ListTemplate.style";
import sketchBackColumn from "../sketchBackColumn.json";
import { costFormat } from "components/costForamt";
import {
  POST_SKETCH_BACK_DETAIL,
  POST_SKETCH_BACK_FAIL_EXECUTE,
} from "constants/pathConstant";
import { RetryBtn } from "../SketchBack.style";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { NavTxId } from "components/Navigation/NavTxId";
import WalletSlice from "components/WalletSlice/WalletSlice";

const SketchBackDetailList = ({
  data,
  checked,
  checkHandler,
  searchDetailReq,
  setSketchBackDetailRes,
}) => {
  const toast = useToastCustom();

  const getStateColor = (state) => {
    if (state === "실패") return "#fe1717";
    if (state === "성공") return "#21F650";
    if (state === "진행중") return "#555";
  };

  const { mutate: mutateSearchDetail } = useMutationCustom(
    POST_SKETCH_BACK_DETAIL
  );

  const { mutate } = useMutationCustom(POST_SKETCH_BACK_FAIL_EXECUTE);

  // 스케치백 재시도
  const retryExecuteHandler = () => {
    if (window.confirm("해당 건의 스케치백을 재시도하시겠습니까?")) {
      mutate(
        { list: [data.id] },
        {
          onSuccess: () => {
            toast("처리에 성공하였습니다.", "success");
            mutateSearchDetail(searchDetailReq, {
              onSuccess: (res) => {
                setSketchBackDetailRes(res);
              },
            });
          },
          onError: () => {
            toast("처리에 실패하였습니다.", "error");
          },
        }
      );
    }
  };

  return (
    <Info.Content>
      {/* 체크박스 */}
      <List.Row $ratio={sketchBackColumn.detail[0].ratio}>
        <input
          type="checkbox"
          checked={checked.includes(data.id)}
          onChange={(e) => {
            checkHandler(e, data.id);
          }}
        />
      </List.Row>

      {/* 번호 */}
      <List.Row $ratio={sketchBackColumn.detail[0].ratio}>{data.id}</List.Row>

      {/* 일자 */}
      <List.Row $ratio={sketchBackColumn.detail[1].ratio}>
        {data.reqDate}
      </List.Row>

      {/* 결제자 */}
      <List.Row $ratio={sketchBackColumn.detail[2].ratio}>
        {data.memberName}
      </List.Row>

      {/* 주문번호 */}
      <List.Row $ratio={sketchBackColumn.detail[3].ratio}>
        {data.ebitmallOrderId}
      </List.Row>

      {/* 보낸 주소 */}
      <List.Row $ratio={sketchBackColumn.detail[4].ratio}>
        {data.fromWalletAddress && WalletSlice(data.fromWalletAddress)}
      </List.Row>

      {/* 받은 주소 */}
      <List.Row $ratio={sketchBackColumn.detail[5].ratio}>
        {data.toWalletAddress && WalletSlice(data.toWalletAddress)}
      </List.Row>

      {/* 스케치백 개수(SKETCH) */}
      <List.Row $ratio={sketchBackColumn.detail[6].ratio}>
        {costFormat(data.amount)}
      </List.Row>

      {/* 수수료 */}
      <List.Row $ratio={sketchBackColumn.detail[7].ratio}>
        {costFormat(data.transactionFee)}
      </List.Row>

      {/* 상태 */}
      <List.Row
        $ratio={sketchBackColumn.detail[8].ratio}
        color={getStateColor(data.txState)}
      >
        {data.txState}
      </List.Row>

      {/* 재전송 */}
      <List.Row $ratio={sketchBackColumn.detail[9].ratio}>
        {data.txState === "실패" && (
          <RetryBtn onClick={retryExecuteHandler}>재시도</RetryBtn>
        )}
      </List.Row>

      {/* 스케치백 처리일자 */}
      <List.Row $ratio={sketchBackColumn.detail[10].ratio}>
        <List.RowElement>
          <p>{data.executeAdmin}</p>
          <p>{data.txDate}</p>
        </List.RowElement>
      </List.Row>

      {/* 대상 여부 */}
      <List.Row $ratio={sketchBackColumn.detail[11].ratio}>
        {data.excludeYn}
      </List.Row>

      {/* 제외 처리자 */}
      <List.Row $ratio={sketchBackColumn.detail[12].ratio}>
        <List.RowElement>
          <p>{data.modifyAdmin}</p>
          <p>{data.modifiedDate}</p>
        </List.RowElement>
      </List.Row>

      {/* 바로가기 txid */}
      <List.Row $ratio={sketchBackColumn.detail[13].ratio}>
        {NavTxId(data.txId)}
      </List.Row>
    </Info.Content>
  );
};

export default SketchBackDetailList;
