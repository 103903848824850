import React, { useState } from "react";

import { Wrapper } from "./WalletDropUser.style";
import WalletDropUserSearch from "./WalletDropUserSearch/WalletDropUserSearch";
import WalletDropUserDetail from "./WalletDropUserDetail/WalletDropUserDetail";
import { PaginationBox } from "components/Pagination/PaginationBox";

// 검색 -> 탈퇴 회원관리 :: (http://localhost:3000/super/search/gone-user)
const WalletDropUser = () => {
  const [dropUserList, setDropUserList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});

  return (
    <Wrapper>
      <div>
        <WalletDropUserSearch
          setDropUserList={setDropUserList}
          setPage={setPage}
          currentPage={page}
          pageSize={size}
          setSearchData={setSearchData}
          searchData={searchData}
        />
        <WalletDropUserDetail
          dropUserList={dropUserList}
          setPage={setPage}
          setSize={setSize}
          searchData={searchData}
          setDropUserList={setDropUserList}
        />

        {dropUserList.length !== 0 && (
          <PaginationBox
            totalItemsCount={dropUserList[0]?.cnt}
            page={page}
            setPage={setPage}
            size={size}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default WalletDropUser;
