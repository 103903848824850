import { Info, List } from "template/ListTemplate.style";
import { Container, Row } from "../ManageUiUpload/ManageUiUpload.style";
import { Wrapper } from "./ManageUiInfoList.style";
import manageUiColumn from "../manageUiColumn.json";
import ManageUiDetail from "./ManageUiDetail";

const ManageUiInfoList = ({ manageUiList, fetchData }) => {
  return (
    <Wrapper>
      <List.Text>UI 관리</List.Text>

      <Container>
        <List.Content>
          {manageUiColumn.data.map((v, idx) => (
            <Row $ratio={v.ratio} key={idx}>
              {v.title}
            </Row>
          ))}
        </List.Content>

        <Info.Wrapper>
          {manageUiList?.list.map((v, idx) => (
            <ManageUiDetail data={v} key={idx} fetchData={fetchData} />
          ))}
        </Info.Wrapper>
      </Container>
    </Wrapper>
  );
};

export default ManageUiInfoList;
