import styled from "styled-components";

export const TypeBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const TypeSelectBox = styled.select`
  border: 1px solid #d1d1d1;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  background: #fff;
  padding: 1rem;
`;

export const CreateBox = styled.div`
  display: flex;
  justify-content: end;

  & > div {
    background: #2db9c2;
    color: #fff;
    padding: 1rem;
    border-radius: 1rem;
    cursor: pointer;
    font-size: 1.6rem;
  }
`;
