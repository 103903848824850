import { IMG_CDN_URL, POLYGON_SCAN_TX } from "constants/pathConstant";
import { Info, List, MouseHoverEffect } from "template/ListTemplate.style";
import { NavImgBox } from "./SettleInfo.style";
import swapColumn from "./settleColumn.json";
import settleState from "../settleState.json";
import { dateFormat } from "components/dateFormat";
import { costFormat } from "components/costForamt";
import WalletSlice from "components/WalletSlice/WalletSlice";

// 정산 관리 -> 회수 :: 회수 내역 목록 낱개 (http://localhost:3000/super/calculate/settle)
export const SettleInfoDetail = ({ data }) => {
  // =================== scan nav
  const polygonNavHandler = (txId, e) => {
    e.stopPropagation();
    window.open(POLYGON_SCAN_TX + txId);
  };

  return (
    <>
      <Info.Content>
        {/* 번호 */}
        <List.Row $ratio={swapColumn?.data[0]?.ratio}>{data.id}</List.Row>

        {/* 회원명 */}
        <List.Row $ratio={swapColumn?.data[1]?.ratio}>
          {data.acctUserNm}
        </List.Row>

        {/* 회원코드 */}
        <List.Row $ratio={swapColumn?.data[2]?.ratio}>{data.acctCd}</List.Row>

        {/* 생년월일 */}
        <List.Row $ratio={swapColumn?.data[3]?.ratio}>{data.birthDay}</List.Row>

        {/* 휴대폰 번호 */}
        <List.Row $ratio={swapColumn?.data[4]?.ratio}>
          {data.cellNumber}
        </List.Row>

        {/* 구분 */}
        <List.Row $ratio={swapColumn?.data[5]?.ratio}>{data.usedNm}</List.Row>

        {/* 회수 수량 */}
        <List.Row $ratio={swapColumn?.data[6]?.ratio}>
          {costFormat(data.txValue)}
        </List.Row>

        {/* 시세 */}
        <List.Row $ratio={swapColumn?.data[7]?.ratio}>
          {costFormat(data.exchangeRateSketchKrw)}
        </List.Row>

        {/* 보낸 주소 */}
        <List.Row $ratio={swapColumn?.data[8]?.ratio}>
          {WalletSlice(data.sendWa)}
        </List.Row>

        {/* 받은 주소 */}
        <List.Row $ratio={swapColumn?.data[9]?.ratio}>
          {WalletSlice(data.recvWa)}
        </List.Row>

        {/* 회수 시간 */}
        <List.Row
          $ratio={swapColumn?.data[10]?.ratio}
          style={{ fontSize: "1rem" }}
        >
          {dateFormat(data.crtDt)}
        </List.Row>

        {/* 상태 */}
        <List.Row
          $ratio={swapColumn?.data[11]?.ratio}
          color={settleState.stateColor[data.state]}
        >
          {data.stateNm}
          {(data.state === "30" || data.state === "40") && (
            <MouseHoverEffect>{data.stateMemo}</MouseHoverEffect>
          )}
        </List.Row>

        {/* 바로가기 */}
        <List.Row
          $ratio={swapColumn?.data[12].ratio}
          cursor={"pointer"}
          onClick={(e) => polygonNavHandler(data.txId, e)}
        >
          <NavImgBox>
            <img
              src={`${IMG_CDN_URL}/file/a391e219nav_icon-min.webp`}
              alt="nav_icon"
              style={{ width: "100%", height: "100%" }}
            />
          </NavImgBox>
        </List.Row>
      </Info.Content>
    </>
  );
};
