import roleJson from "constants/authRoles.json";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loginData, loginRole, loginState } from "recoil/atom/LoginState";
import { End, Logo, Wrapper } from "./Header.style";
import { HeaderMenu } from "./HeaderMenu";
import { SYMBOL } from "constants/pathConstant";

export const Header = () => {
  const nav = useNavigate();

  const [selectedMenu, setSelectedMenu] = useState("");
  const [hoverMenu, setHoverMenu] = useState("");

  const setUser = useSetRecoilState(loginState);
  const userRole = useRecoilValue(loginRole);
  const userData = useRecoilValue(loginData);

  const menu = useMemo(() => {
    if (!userRole[0]) return;
    const menuList = [];

    if (userRole.includes("DEV") || userRole.includes("SUPER")) {
      Object.values(roleJson.menu).forEach((menu) => {
        menuList.push(menu);
      });
    } else {
      const activeMenu = {};
      for (const role of userRole) {
        Object.values(roleJson.menu).forEach((menu) => {
          if (menu.role.includes(role)) {
            activeMenu[menu.url] = menu;
          }
        });
      }

      Object.values(activeMenu).forEach((menu) => {
        const filtering = { ...menu };
        filtering.sub = menu.sub.filter((v) => userRole.includes(v.role) && v.isActive === "1");
        menuList.push(filtering);
      });
    }

    /**
     * 권한이 있다면 NONE 메뉴 빼주기
     */
    if (menuList.length >= 2 || userRole.includes("DEV") || userRole.includes("SUPER")) {
      const index = menuList.findIndex((v) => v.role[0] === "NONE");
      menuList.splice(index, 1);
    }

    return menuList;
  }, [userRole]);

  const logoutHandler = () => {
    localStorage.removeItem("user");

    setUser({ isLogin: false });
  };

  const location = useLocation();
  useEffect(() => {
    setSelectedMenu(location.pathname);
  }, [location]);

  const logoClickHandler = () => {
    nav("/main");
  };

  const moveHandler = (to) => {
    nav(to);
    setHoverMenu("");
  };

  if (!menu) return;

  return (
    <Wrapper>
      <Logo.Wrapper onClick={logoClickHandler}>
        <Logo.Img src="https://sketch-htm-s3.s3.ap-northeast-2.amazonaws.com/file/1a336084logo.svg" alt="" />
        <Logo.Text>{SYMBOL}</Logo.Text>
      </Logo.Wrapper>

      <HeaderMenu
        menu={menu}
        hoverMenu={hoverMenu}
        setHoverMenu={setHoverMenu}
        selectedMenu={selectedMenu}
        moveHandler={moveHandler}
      />

      <End.Wrapper>
        <End.Text>
          <span>{userData.name}</span>님 반갑습니다
        </End.Text>
        <End.Btn onClick={logoutHandler}>로그아웃</End.Btn>
      </End.Wrapper>
    </Wrapper>
  );
};
