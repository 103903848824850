import { Wrapper } from "../style";
import SearchCategory from "../../SearchCategory";
import { useState } from "react";
import { POST_WALLET_INFO_SUMMARY, SYMBOL } from "constants/pathConstant";
import { Content, InfoWrapper, ListColumn, ListContaier } from "./style";
import summaryColumn from "./summaryByUserColumn.json";
import { costFormat } from "components/costForamt";
import { WALLET_INFO_SUMMARY_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";

// 회원검색 (검증 후 회원 정보 바로가기) :: 지갑정보 -> 요약 (http://localhost:3000/manage/user/:code)
const SummaryByUser = ({ id }) => {
  const [resData, setResData] = useState([]);

  const data = {
    id,
    pageSize: 10,
    currentPage: 1,
  };

  return (
    <Wrapper>
      <SearchCategory
        API_ADDRESS={POST_WALLET_INFO_SUMMARY}
        data={data}
        setResData={setResData}
        summary={true}
        XLSX_DOWNLOAD={WALLET_INFO_SUMMARY_XLSX_DOWNLOAD}
      />

      <ListContaier>
        <Content>
          {summaryColumn.balanceInfo.map((v, idx) => (
            <ListColumn key={idx}>{v.title}</ListColumn>
          ))}
        </Content>

        <InfoWrapper>
          <p>
            <span>{costFormat(resData.paymentAmount)}</span>
            <span>{SYMBOL}</span>
          </p>
          <p>
            <span>{costFormat(resData.usedAmount)}</span>
            <span>{SYMBOL}</span>
          </p>
          <p>
            <span>{costFormat(resData.havingAmount)}</span>
            <span>{SYMBOL}</span>
          </p>
          <p>
            <span>{costFormat(resData.sbackAmount)}</span>
            <span>{SYMBOL}</span>
          </p>
        </InfoWrapper>
      </ListContaier>
    </Wrapper>
  );
};

export default SummaryByUser;
