import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 96%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  font-weight: ${FONT_WEIGHT.BOLD};
  padding-top: 6rem;
`;

export const Container = styled.div`
  width: 100%;
  min-height: 10rem;
  background-color: white;
  border-radius: 1rem;
  overflow: hidden;
`;

export const Row = styled.span`
  flex: ${(props) => (props.$ratio ? props.$ratio : 1)};
  text-align: center;
  color: ${(props) => props.color && props.color};
  cursor: ${(props) => props.cursor || "normal"};
  position: relative;
  display: flex;
  justify-content: center;
  word-break: break-all;
  align-items: center;
  padding: 0.5rem;
  height: 3rem;

  > input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #000;
    font-size: 1.5rem;
  }

  > select {
    width: 50%;
    height: 130%;
    font-size: 1.5rem;
  }
`;

export const InactiveBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  color: white;
  border-radius: 0.4rem;
  cursor: pointer;
  background: #bbb;
`;

export const ActiveBtn = styled(InactiveBtn)`
  background: #4253f2;
`;

export const BtnBox = styled.div`
  display: flex;
  gap: 1rem;
`;
