import React, { useState } from "react";
import { Modal } from "./RefuseStoreModal.style";
import { useCloseRootModal } from "hooks/modal/useCloseRootModal";
import { useMutationCustom } from "hooks/useMutationCustom";
import { HTTP_METHOD, POST_MODIFY_STORE_STATE } from "constants/pathConstant";
import { useToastCustom } from "hooks/useToastCustom";

export const RefuseStoreModal = ({ data, setAcceptState, acceptState }) => {
  const closeRootModal = useCloseRootModal();

  const [text, setText] = useState();
  const [isSendMsg, setIsSendMsg] = useState(false);

  const toast = useToastCustom();
  const { mutate: updateStateMutate } = useMutationCustom(
    POST_MODIFY_STORE_STATE,
    HTTP_METHOD.POST
  );

  const handleConfirm = () => {
    setIsSendMsg(true);
  };

  const handleSendMsg = () => {
    const request = { ...data, title: "스토어 심사 부적격", content: text };

    if (window.confirm("승인 거절 처리 하시겠습니까?")) {
      updateStateMutate(request, {
        onSuccess: () => {
          toast("승인 거절 처리 성공", "success");
          setAcceptState(data.confirmState);
          closeRootModal();
        },

        onError: () => {
          toast("업데이트 실패", "error");
          closeRootModal();
        },
      });
    }
  };

  const handleCloseModal = () => {
    closeRootModal();
  };

  return (
    <Modal.Wrapper>
      <Modal.Text>
        해당 가맹주에게 거절 내용과 알림을 보내시겠습니까?
      </Modal.Text>

      {isSendMsg ? (
        <Modal.SendText>{text}</Modal.SendText>
      ) : (
        <Modal.TextArea
          placeholder="내용을 입력하세요"
          onChange={(e) => setText(e.target.value)}
        />
      )}

      <Modal.BtnWrapper>
        <Modal.Btn
          color="white"
          $bg={"#4253F2"}
          onClick={isSendMsg ? handleSendMsg : handleConfirm}
        >
          전송
        </Modal.Btn>
        <Modal.Btn color="black" $bg={"#ECECEC"} onClick={handleCloseModal}>
          취소
        </Modal.Btn>
      </Modal.BtnWrapper>
    </Modal.Wrapper>
  );
};
