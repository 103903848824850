import React from "react";
import {
  CalendarDetail,
  CalendarHeader,
  Header,
  Wrapper,
} from "./CalendarList.style";
import scheduleJson from "../ScheduleList/ScheduleJson.json";
import { IMG_CDN_URL } from "constants/pathConstant";
const dayKor = ["일", "월", "화", "수", "목", "금", "토"];
const dayEng = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const getFirstDayIndex = (date) => {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    .toString()
    .substring(0, 3);
  return dayEng.indexOf(firstDay);
};

const getLastDay = (date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0)
    .toString()
    .substring(8, 10);
};

const getLastMonthLastDay = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 0)
    .toString()
    .substring(8, 10);
};

const isSameDate = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const CalendarList = ({ date, setDate, today, calendarCountObj }) => {
  const moveMonthHandler = (move) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + move);
    setDate(newDate);
  };

  const firstDayIndex = getFirstDayIndex(date);
  const lastDay = getLastDay(date);
  const lastMonthLastDay = getLastMonthLastDay(date);

  const renderCalendarHeader = () => {
    return (
      <CalendarHeader.Wrapper>
        {dayKor.map((day, i) => (
          <CalendarHeader.Header key={i}>{day}</CalendarHeader.Header>
        ))}
      </CalendarHeader.Wrapper>
    );
  };

  const renderCalendarBody = () => {
    return new Array(42).fill(0).map((_, i) => {
      const dayNumber = i - firstDayIndex + 1;
      const isNotInMonth = dayNumber > lastDay || dayNumber < 1;
      const displayNumber = isNotInMonth
        ? dayNumber > lastDay
          ? dayNumber - lastDay
          : dayNumber + Number(lastMonthLastDay)
        : dayNumber;

      const currentDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        dayNumber
      );

      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const day = currentDate.getDate();
      if (!calendarCountObj[year]) {
        calendarCountObj[year] = {};
      }
      if (!calendarCountObj[year][month]) {
        calendarCountObj[year][month] = {};
      }
      if (!calendarCountObj[year][month][day]) {
        calendarCountObj[year][month][day] = 0;
      }

      const count0 =
        calendarCountObj[currentDate.getFullYear()][currentDate.getMonth() + 1][
          currentDate.getDate()
        ]["0"];

      const count1 =
        calendarCountObj[currentDate.getFullYear()][currentDate.getMonth() + 1][
          currentDate.getDate()
        ]["1"];
      const count2 =
        calendarCountObj[currentDate.getFullYear()][currentDate.getMonth() + 1][
          currentDate.getDate()
        ]["2"];

      const count9 =
        calendarCountObj[currentDate.getFullYear()][currentDate.getMonth() + 1][
          currentDate.getDate()
        ]["9"];

      return (
        <CalendarDetail.Body
          key={i}
          $notInMonth={isNotInMonth}
          $isToday={isSameDate(today, currentDate)}
          $isSelectDate={isSameDate(date, currentDate)}
          onClick={() => setDate(currentDate)}
        >
          <div>
            {displayNumber}
            <span>
              {count9 > 0 ? (
                <img
                  src={`${IMG_CDN_URL}/file/38d008d5speaker_icon.svg`}
                  alt="공지사항 아이콘"
                />
              ) : (
                ""
              )}
            </span>
          </div>

          <CalendarDetail.CounterWrapper>
            {count0 > 0 ? (
              <CalendarDetail.CounterContainer $notInMonth={isNotInMonth}>
                <CalendarDetail.CounterDot $bgColor={scheduleJson[0].color} />
                <CalendarDetail.Count $color={scheduleJson[0].color}>
                  {count0}
                </CalendarDetail.Count>
              </CalendarDetail.CounterContainer>
            ) : (
              ""
            )}

            {count1 > 0 ? (
              <CalendarDetail.CounterContainer $notInMonth={isNotInMonth}>
                <CalendarDetail.CounterDot $bgColor={scheduleJson[1].color} />
                <CalendarDetail.Count $color={scheduleJson[1].color}>
                  {count1}
                </CalendarDetail.Count>
              </CalendarDetail.CounterContainer>
            ) : (
              ""
            )}

            {count2 > 0 ? (
              <CalendarDetail.CounterContainer $notInMonth={isNotInMonth}>
                <CalendarDetail.CounterDot $bgColor={scheduleJson[2].color} />
                <CalendarDetail.Count $color={scheduleJson[2].color}>
                  {count2}
                </CalendarDetail.Count>
              </CalendarDetail.CounterContainer>
            ) : (
              ""
            )}
          </CalendarDetail.CounterWrapper>
        </CalendarDetail.Body>
      );
    });
  };

  return (
    <Wrapper>
      <Header.Wrapper>
        <span onClick={() => moveMonthHandler(-1)}>{"<"}</span>
        &nbsp;&nbsp;&nbsp;{date.getFullYear()}년 {date.getMonth() + 1}월
        &nbsp;&nbsp;&nbsp;
        <span onClick={() => moveMonthHandler(1)}>{">"}</span>
      </Header.Wrapper>

      {renderCalendarHeader()}
      <CalendarDetail.Wrapper>{renderCalendarBody()}</CalendarDetail.Wrapper>
    </Wrapper>
  );
};
