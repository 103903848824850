import React, { useState } from "react";
import { Wrapper } from "./UserList.style";
import { SuperUserInfoList } from "./UserInfoList/SuperUserInfoList";
import { SuperUserSearch } from "./UserSearch/SuperUserSearch";
import { PaginationBox } from "components/Pagination/PaginationBox";

// 검색 -> 회원검색 :: (http://localhost:3000/super/search/user)
export const SuperUserList = () => {
  const [superUserList, setSuperUserList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});

  return (
    <Wrapper>
      <div style={{ width: "80%" }}>
        <SuperUserSearch
          setSuperUserList={setSuperUserList}
          setPage={setPage}
          currentPage={page}
          pageSize={size}
          setSearchData={setSearchData}
          searchData={searchData}
        />
        <SuperUserInfoList
          superUserList={superUserList}
          setPage={setPage}
          setSize={setSize}
          searchData={searchData}
        />

        {superUserList.length !== 0 && (
          <PaginationBox
            totalItemsCount={superUserList[0]?.cnt}
            page={page}
            setPage={setPage}
            size={size}
          />
        )}
      </div>
    </Wrapper>
  );
};
