import { Info, List } from "template/ListTemplate.style";
import chargeSummaryColumn from "../chargeSummaryColumn.json";
import { costFormat } from "components/costForamt";

// 브릿지 -> 충전 :: 요약 내역 낱개 (http://localhost:3000/super/bridge/charge)
const ChargeSummaryDetail = ({ data }) => {
  return (
    <Info.Content>
      {/* 총 충전 금액 */}
      <List.Row $ratio={chargeSummaryColumn.summary[0].ratio}>
        {costFormat(data?.totalAmount)}
      </List.Row>

      {/* 성공한 충전 금액 */}
      <List.Row $ratio={chargeSummaryColumn.summary[1].ratio}>
        {costFormat(data?.successAmount)}
      </List.Row>

      {/* 실패한 충전 금액 */}
      <List.Row $ratio={chargeSummaryColumn.summary[2].ratio}>
        {costFormat(data?.failAmount)}
      </List.Row>

      {/* 총 충전 횟수 */}
      <List.Row $ratio={chargeSummaryColumn.summary[3].ratio}>
        {data?.cnt}
      </List.Row>
    </Info.Content>
  );
};

export default ChargeSummaryDetail;
