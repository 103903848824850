import styled from "styled-components";

export const NavImgBox = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;

  > img {
    width: 100%;
    height: 100%;
  }
`;
