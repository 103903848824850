import React, { useState } from "react";
import { Info, List } from "template/ListTemplate.style";
import { Verify, VerifyUser } from "../UserList.style";
import { uriEncoder } from "utils/crypto";
import userColumn from "./userColumn.json";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import {
  NAV_USER_DETAIL_BASE_WALLET,
  POST_WALLET_USER_VERIFY_MOBILE,
} from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";

// 회원검색 :: 회원 목록 리스트 낱개 (http://localhost:3000/manage/user/wallet)
const WalletUserDetailList = ({ data }) => {
  const toast = useToastCustom();
  const nav = useNavigationCustom();

  const [typedNumber, setTypedNumber] = useState("");

  const navToDetailHandler = (code) => {
    nav(NAV_USER_DETAIL_BASE_WALLET + code);
  };

  const { mutate } = useMutationCustom(POST_WALLET_USER_VERIFY_MOBILE);

  const verifyCellNumber = () => {
    if (typedNumber.length !== 4)
      return toast("전화번호를 제대로 입력하세요.", "error");

    const verifyData = {
      mobile: data.mobile + typedNumber,
      state: data.state,
      id: data.id,
    };
    mutate(verifyData, {
      onSuccess: (response) => {
        if (response.verifyResult === true) {
          navToDetailHandler(uriEncoder(data.memberCode, data.id, data.state));
        } else {
          toast("전화번호가 다릅니다.", "error");
        }
      },

      onError: () => {
        toast("다시 시도하세요.", "error");
      },
    });
  };

  const handleEnter = (key) => {
    if (key === "Enter") verifyCellNumber();
  };

  return (
    <Info.Content>
      {/* 번호 */}
      <List.Row $ratio={userColumn.data[0].ratio}>{data.id}</List.Row>

      {/* 가입일자 */}
      <List.Row $ratio={userColumn.data[1].ratio}>{data.createDate}</List.Row>

      {/* 회원명 */}
      <List.Row $ratio={userColumn.data[2].ratio}>{data.memberName}</List.Row>

      {/* 회원코드 */}
      <List.Row $ratio={userColumn.data[3].ratio}>{data.memberCode}</List.Row>

      {/* 생년월일 */}
      <List.Row $ratio={userColumn.data[4].ratio}>{data.birthDay}</List.Row>

      {/* 휴대전화 */}
      <List.Row
        $ratio={userColumn.data[5].ratio}
        style={{
          gap: "0.3rem",
        }}
      >
        {data.mobile}
        <Verify.Input
          onChange={(e) => setTypedNumber(e.target.value)}
          onKeyUp={(e) => handleEnter(e.key)}
        />
      </List.Row>

      {/* 활동상태 */}
      <List.Row $ratio={userColumn.data[6].ratio}>{data.state}</List.Row>

      {/* 고객정보 검증 버튼 */}
      <List.Row
        $ratio={userColumn.data[7].ratio}
        onClick={verifyCellNumber}
        style={{ cursor: "pointer" }}
      >
        <VerifyUser.Wrapper>
          <VerifyUser.Img src="/assets/imgs/userList_verify.svg" />
          <VerifyUser.Text>고객정보 검증</VerifyUser.Text>
        </VerifyUser.Wrapper>
      </List.Row>
    </Info.Content>
  );
};

export default WalletUserDetailList;
