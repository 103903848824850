import { Info, List } from "template/ListTemplate.style";
import popupColumn from "../popupColumn.json";
import { ActiveMark, CheckBox } from "../style";
import { useNavigate } from "react-router";
import domainColumn from "../../popupDomainColumn.json";
import { dateFormat } from "components/dateFormat";

// 고객 지원 -> 팝업 관리 :: 팝업 목록 리스트 낱개 (http://localhost:3000/super/create/popup)
const PopupDetail = ({ data, checkHandler, checked, setIsOpen, setId }) => {
  const nav = useNavigate();

  const openHandelr = (id) => {
    setIsOpen((prev) => !prev);
    setId(id);
  };

  const activeMark = data.activeYn === "Y" && data.showYn === "Y";

  return (
    <>
      <Info.Content>
        {/* 체크박스 */}
        <List.Row>
          <CheckBox
            type="checkbox"
            $ratio={1}
            onChange={(e) => checkHandler(e, data.id)}
            checked={checked.includes(data.id)}
          />
        </List.Row>

        {/* 번호 */}
        <List.Row $ratio={popupColumn.data[0].ratio}>{data.id}</List.Row>

        {/* 구분 */}
        <List.Row $ratio={popupColumn.data[1].ratio}>
          {domainColumn.domain[data.domain]}
        </List.Row>

        {/* 제목 */}
        <List.Row
          $ratio={popupColumn.data[2].ratio}
          style={{ cursor: "pointer" }}
          onClick={() => openHandelr(data.id)}
        >
          {data.title}
        </List.Row>

        {/* 작성자 */}
        <List.Row $ratio={popupColumn.data[3].ratio}>{data.writer}</List.Row>

        {/* 게시일 */}
        <List.Row $ratio={popupColumn.data[4].ratio}>
          {dateFormat(data.begDt).slice(0, 16)}
        </List.Row>

        {/* 종료일 */}
        <List.Row $ratio={popupColumn.data[5].ratio}>
          {dateFormat(data.endDt).slice(0, 16)}
        </List.Row>

        {/* 게시 여부 */}
        <List.Row $ratio={popupColumn.data[6].ratio}>{data.activeYn}</List.Row>

        {/* 마지막 수정인 */}
        <List.Row $ratio={popupColumn.data[7].ratio}>{data.modifier}</List.Row>

        {/* 공개 여부 */}
        <List.Row $ratio={popupColumn.data[8].ratio}>{data.showYn}</List.Row>

        {/* 활성화 여부 */}
        <List.Row $ratio={popupColumn.data[9].ratio}>
          <ActiveMark isActive={activeMark}></ActiveMark>
        </List.Row>

        {/* 수정 버튼 */}
        <List.Row
          $ratio={popupColumn.data[10].ratio}
          style={{
            cursor: "pointer",
            background: "#ddd",
            borderRadius: "2rem",
          }}
          onClick={() => {
            nav(`/super/support/popup-modify`, { state: { data } });
          }}
        >
          수정
        </List.Row>
      </Info.Content>
    </>
  );
};

export default PopupDetail;
