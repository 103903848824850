import styled from "styled-components";

export const BottomContainer = styled.div`
  width: 100%;
  height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DeleteContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  font-size: 1.5rem;
`;

export const DeleteTextBox = styled.select`
  width: 17rem;
  height: 4rem;
  border-radius: 0.6rem;
  border: 1px solid #d1d1d1;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  font-size: 1.3rem;
`;
export const BottomBtn = styled.div`
  width: 10rem;
  height: 4rem;
  border-radius: 0.5rem;
  background: #2db9c2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
`;

export const CheckBox = styled.input`
  width: 1.6rem;
  height: 1.6rem;
`;

export const ActiveMark = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background: ${(props) => (props.isActive ? "#21F650" : "#ddd")};
  border-radius: 100%;
`;
