import React from "react";
import { Info, List } from "template/ListTemplate.style";
import dropUserColumn from "../walletDropUserColumn.json";
import { DetailBtn, RowElement } from "../WalletDropUser.style";
import WalletSlice from "components/WalletSlice/WalletSlice";
import { NAV_USER_DETAIL_BASE_WALLET } from "constants/pathConstant";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import { uriEncoder } from "utils/crypto";

// 검색 -> 탈퇴 회원관리 :: 회원 목록 낱개 (http://localhost:3000/super/search/gone-user)
const WalletDropUserDetailList = React.memo(
  ({
    id,
    deleteDate,
    memberName,
    memberCode,
    recommenderName,
    recommenderCode,
    mobile,
    email,
    walletAddress,
    birthDay,
    state,
    createDate,
  }) => {
    const nav = useNavigationCustom();
    const navToDetailHandler = (code) => {
      nav(NAV_USER_DETAIL_BASE_WALLET + code);
    };

    return (
      <>
        <Info.Content>
          {/* 번호 */}
          <List.Row $ratio={dropUserColumn.data[0].ratio}>{id}</List.Row>

          {/* 가입일자 */}
          <List.Row $ratio={dropUserColumn.data[1].ratio}>
            {createDate}
          </List.Row>

          {/* 탈퇴일자 */}
          <List.Row $ratio={dropUserColumn.data[2].ratio}>
            {deleteDate && deleteDate}
          </List.Row>

          {/* 생년월일 */}
          <List.Row $ratio={dropUserColumn.data[3].ratio}>{birthDay}</List.Row>

          {/* 회원명 */}
          <List.Row $ratio={dropUserColumn.data[4].ratio}>
            <RowElement>
              <p>{memberName}</p>
              <p>({memberCode})</p>
            </RowElement>
          </List.Row>

          {/* 추천인명 */}
          <List.Row $ratio={dropUserColumn.data[5].ratio}>
            <RowElement>
              <p>{recommenderName}</p>
              <p>{recommenderName && `(${recommenderCode})`}</p>
            </RowElement>
          </List.Row>

          {/* 휴대전화번호 */}
          <List.Row $ratio={dropUserColumn.data[6].ratio}>{mobile}</List.Row>

          {/* 이메일 */}
          <List.Row $ratio={dropUserColumn.data[7].ratio}>{email}</List.Row>

          {/* 지갑주소 */}
          <List.Row $ratio={dropUserColumn.data[8].ratio}>
            {WalletSlice(walletAddress)}
          </List.Row>

          {/* 회원정보 바로가기 버튼 */}
          <List.Row $ratio={dropUserColumn.data[9].ratio}>
            {/* TODO: api 수정되면 정상 -> state로 변경하기 */}
            <DetailBtn
              onClick={() =>
                navToDetailHandler(uriEncoder(memberCode, id, state))
              }
            >
              바로가기
            </DetailBtn>
          </List.Row>
        </Info.Content>
      </>
    );
  }
);

export default WalletDropUserDetailList;
