import { PLACEHOLDER } from "constants/baseStyle";
import { POST_POPUP2_LIST_FETCH } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useEffect, useState } from "react";
import { Search, Wrapper } from "template/SearchTemplate.style";
import domainColumn from "../popupDomainColumn.json";
import { dateFormat } from "components/dateFormat";

// 고객 지원 -> 팝업 관리 :: 팝업 조회 (http://localhost:3000/super/create/popup)
const PopupSearch = ({
  setPopupList,
  setPage,
  currentPage,
  pageSize,
  setChecked,
  setSearchData,
}) => {
  const toast = useToastCustom();

  const [titleAndDesc, setTitleAndDesc] = useState("");
  const [begDt, setBegDt] = useState("");
  const [endDt, setEndDt] = useState("");
  const [showYn, setShowYn] = useState("");
  const [writer, setWriter] = useState("");
  const [domain, setDomain] = useState("");

  const { mutate } = useMutationCustom(POST_POPUP2_LIST_FETCH);

  const fetchData = () => {
    const data = {
      titleAndDesc,
      begDt: begDt && dateFormat(begDt, true),
      endDt: endDt && dateFormat(endDt, true, 1),
      showYn,
      currentPage,
      pageSize,
      writer,
      domain,
    };

    setSearchData(data);

    mutate(data, {
      onSuccess: (res) => {
        setPopupList(res);
        setChecked([]);
      },

      onError: () => {
        toast("데이터 조회 실패", "error");
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, [pageSize, currentPage]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  return (
    <Wrapper>
      <Search.Text>팝업 조회</Search.Text>
      <Search.Container style={{ gap: "28rem" }}>
        <Search.Content>
          {/* 제목/설명 */}
          <Search.InputWrapper>
            <div>제목/설명</div>
            <Search.Input
              placeholder={PLACEHOLDER.SOME}
              onChange={(e) => setTitleAndDesc(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 작성자 */}
          <Search.InputWrapper>
            <div>작성자</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setWriter(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>

          {/* 게시여부 */}
          <Search.InputWrapper>
            <div>공개여부</div>
            <Search.Select onChange={(e) => setShowYn(e.target.value)}>
              <Search.Option value="">전체</Search.Option>
              <Search.Option value="Y">공개</Search.Option>
              <Search.Option value="N">비공개</Search.Option>
            </Search.Select>
          </Search.InputWrapper>

          {/* 도메인 */}
          <Search.InputWrapper>
            <div>도메인</div>
            <Search.Select onChange={(e) => setDomain(e.target.value)}>
              <Search.Option value="">전체</Search.Option>
              {Object.entries(domainColumn.domain).map(([key, value]) => (
                <Search.Option key={key} value={key}>
                  {value}
                </Search.Option>
              ))}
            </Search.Select>
          </Search.InputWrapper>

          {/* 게시일자 */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>게시일자</div>
            <Search.Input
              type="date"
              defaultValue={begDt}
              onChange={(e) => setBegDt(e.target.value)}
            ></Search.Input>
          </Search.InputWrapper>

          {/* 종료일자 */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>종료일자</div>
            <Search.Input
              type="date"
              defaultValue={endDt}
              onChange={(e) => setEndDt(e.target.value)}
            ></Search.Input>
          </Search.InputWrapper>
        </Search.Content>

        {/* 조회 버튼 */}
        <Search.Btn onClick={handleSearch}>조회</Search.Btn>
      </Search.Container>
    </Wrapper>
  );
};

export default PopupSearch;
