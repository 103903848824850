import SearchStoreModal from "components/Modal/SearchStoreModal";
import SearchUserModal from "components/Modal/SearchUserModal";
import { dateFormat } from "components/dateFormat";
import { PLACEHOLDER } from "constants/baseStyle";
import { POST_FIND_PAYMENT } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useEffect, useState } from "react";
import { Search, Wrapper } from "template/SearchTemplate.style";

// 결제 내역 :: 결제내역 조회 (http://localhost:3000/super/payment/pay)
const PaymentPaySearch = ({
  setPaymentPayList,
  setPage,
  currentPage,
  pageSize,
  setSearchData,
  searchData,
  setIsSearch,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUserSearch, setIsUserSearch] = useState(false);

  const [acctNm, setAcctNm] = useState("");
  const [acctCd, setAcctCd] = useState("");
  const [state, setState] = useState("");
  const [reqId, setReqId] = useState("");
  const [storeFeeRate, setStoreFeeRate] = useState("");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState(
    dateFormat(new Date(), false, 0, 0).slice(0, 10)
  );

  const [userData, setUserData] = useState({});
  const [storeData, setStoreData] = useState({});

  useEffect(() => {
    setAcctNm("");
    setAcctCd("");
    setState("");
    setReqId("");
    setStoreFeeRate("");
    setFromDt("");
    setToDt(dateFormat(new Date()).slice(0, 19));
  }, [storeData]);

  const { mutate } = useMutationCustom(POST_FIND_PAYMENT);

  useEffect(() => {
    if (!Object.keys(searchData)[0]) return;
    const researchData = { ...searchData, currentPage, pageSize };
    setSearchData(researchData);

    mutate(researchData, {
      onSuccess: (data) => {
        setPaymentPayList(data);
      },
    });
  }, [pageSize, currentPage]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (storeNm = "", storeCd = "") => {
    const data = {
      storeNm: storeData.storeNm || storeNm,
      storeCd: storeData.storeCd || storeCd,
      acctNm: userData.acctUserNm || acctNm,
      acctCd: userData.acctCd || acctCd,
      state,
      reqId,
      storeFeeRate,
      fromDt: fromDt && dateFormat(fromDt, true),
      toDt: dateFormat(toDt, true, 1),
      pageSize,
      currentPage,
    };

    setSearchData(data);

    mutate(data, {
      onSuccess: (data) => {
        setPaymentPayList(data);
      },
    });
  };

  const handleSearch = () => {
    setPage(1);
    setIsSearch(true);
    fetchData(storeData.storeNm, storeData.storeCd);
  };

  const handleEnter = (key) => {
    if (key === "Enter") handleSearch();
  };

  return (
    <Wrapper>
      <Search.Text>페이 결제내역 조회</Search.Text>
      <Search.Container>
        <Search.Content>
          {/* 가맹점명 */}
          <Search.InputWrapper
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            <div>가맹점명</div>
            <Search.Input
              style={{ cursor: "pointer" }}
              placeholder="눌러서 조회"
              readOnly
              value={storeData.storeNm}
              onKeyUp={(e) => handleEnter(e.key)}
            />
          </Search.InputWrapper>
          {/* 가맹점코드 */}
          <Search.InputWrapper>
            <div>가맹점코드</div>
            <Search.Input
              placeholder="자동 입력"
              onKeyUp={(e) => handleEnter(e.key)}
              disabled
              value={storeData.storeCd}
            />
          </Search.InputWrapper>
          {/* 결제고객 */}
          <Search.InputWrapper
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsUserSearch((prev) => !prev);
            }}
          >
            <div>결제고객</div>
            <Search.Input
              style={{ cursor: "pointer" }}
              placeholder={"눌러서 조회"}
              onKeyUp={(e) => handleEnter(e.key)}
              value={userData.acctUserNm}
              readOnly
            />
          </Search.InputWrapper>
          {/* 결제고객코드 */}
          <Search.InputWrapper>
            <div>결제고객코드</div>
            <Search.Input
              placeholder={"자동 입력"}
              onChange={(e) => setAcctCd(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
              value={userData.acctCd}
              disabled
            />
          </Search.InputWrapper>
          {/* 승인번호 */}
          <Search.InputWrapper>
            <div>승인번호</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setReqId(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
              value={reqId}
            />
          </Search.InputWrapper>
          {/* 수수료율 */}
          <Search.InputWrapper>
            <div>수수료율</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => setStoreFeeRate(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
              value={storeFeeRate}
            />
          </Search.InputWrapper>
          {/* 가입일자 (부터) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>결제일자 (부터)</div>
            <Search.Input
              type="date"
              defaultValue={fromDt}
              onChange={(e) => setFromDt(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            ></Search.Input>
          </Search.InputWrapper>
          {/* 가입일자 (까지) */}
          <Search.InputWrapper style={{ padding: "0.5rem" }}>
            <div>결제일자 (까지)</div>
            <Search.Input
              type="date"
              defaultValue={toDt.slice(0, 10)}
              onChange={(e) => setToDt(e.target.value)}
              onKeyUp={(e) => handleEnter(e.key)}
            ></Search.Input>
          </Search.InputWrapper>
          {/* 상태 */}
          <Search.InputWrapper>
            <div>상태</div>
            <Search.Select
              onChange={(e) => setState(e.target.value)}
              value={state}
            >
              <Search.Option value="">전체</Search.Option>
              <Search.Option value="성공">성공</Search.Option>
              <Search.Option value="실패">실패</Search.Option>
              <Search.Option value="부분 취소">부분 취소</Search.Option>
              <Search.Option value="전체 취소">전체 취소</Search.Option>
            </Search.Select>
          </Search.InputWrapper>
        </Search.Content>
        <Search.Btn onClick={() => handleSearch()}>조회</Search.Btn>
      </Search.Container>

      {isOpen && (
        <SearchStoreModal setIsOpen={setIsOpen} setStoreData={setStoreData} />
      )}

      {isUserSearch && (
        <SearchUserModal
          setIsOpen={setIsUserSearch}
          setUserData={setUserData}
        />
      )}
    </Wrapper>
  );
};

export default PaymentPaySearch;
