import { useState } from "react";
import { Container, Content, Wrapper } from "../style";
import SearchCategory from "../../SearchCategory";
import { Info, List } from "template/ListTemplate.style";
import { PaginationBox } from "components/Pagination/PaginationBox";
import { POST_FIND_PAYMENT } from "constants/pathConstant";
import paymentColumn from "./paymentByUserColumn.json";
import PaymentByUserDetail from "./PaymentByUserDetail";
import { PAYMENT_PAY_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";

// 회원검색 (검증 후 회원 정보 바로가기) :: 지갑정보 -> 결제 (http://localhost:3000/manage/user/:code)
const PaymentByUser = ({ acctCd }) => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [resData, setResData] = useState([]);

  const API_ADDRESS = POST_FIND_PAYMENT;
  const XLSX_DOWNLOAD = PAYMENT_PAY_XLSX_DOWNLOAD;

  const data = {
    storeNm: "",
    storeCd: "",
    acctNm: "",
    acctCd,
    state: "",
    reqId: "",
    storeFeeRate: "",
    currentPage: page,
    pageSize: size,
  };

  return (
    <Wrapper>
      <SearchCategory
        API_ADDRESS={API_ADDRESS}
        data={data}
        setResData={setResData}
        setPage={setPage}
        setSize={setSize}
        page={page}
        size={size}
        resData={resData}
        XLSX_DOWNLOAD={XLSX_DOWNLOAD}
      />

      <Container>
        <Content>
          {paymentColumn.column.map((v, idx) => (
            <List.Row $ratio={v.ratio} key={idx}>
              {v.title}
            </List.Row>
          ))}
        </Content>

        <Info.Wrapper>
          {resData?.map((v, idx) => (
            <PaymentByUserDetail data={v} key={idx} />
          ))}
        </Info.Wrapper>
      </Container>

      {resData.length !== 0 && (
        <PaginationBox
          totalItemsCount={resData[0]?.cnt}
          page={page}
          setPage={setPage}
          size={size}
        />
      )}
    </Wrapper>
  );
};

export default PaymentByUser;
