import { useCopyCustom } from "hooks/useCopyCustom";

const WalletSlice = (walletData, num) => {
  const copy = useCopyCustom();

  const walletSlice = (walletData, num = 5) => {
    if (!walletData) return;
    return walletData.slice(0, num) + "..." + walletData.slice(-num);
  };

  return (
    <p
      onClick={(e) => {
        e.stopPropagation();
        copy(walletData);
      }}
      style={{ cursor: "pointer" }}
    >
      {walletSlice(walletData, num)}
    </p>
  );
};
export default WalletSlice;
