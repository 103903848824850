import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 6rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const DetailBtn = {
  Wrapper: styled.div`
    width: 100%;
    border-radius: 10rem;
    border: 1px solid #febd17;
    cursor: pointer;
    color: #febd17;
    font-size: 1rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    padding: 0.6rem 0.8rem;
  `,
};
