import { styled } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
`;
