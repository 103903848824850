import React, { useState } from "react";
import { Wrapper } from "./Settle.style";
import { SettleSearch } from "./SettleSearch/SettleSearch";
import { SettleInfoList } from "./SettleInfoList/SettleInfoList";
import { PaginationBox } from "components/Pagination/PaginationBox";

// 정산 관리 -> 회수 :: (http://localhost:3000/super/calculate/settle)
export const SettleList = () => {
  const [settleList, setSettleList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});

  return (
    <Wrapper>
      <div>
        <SettleSearch
          setSettleList={setSettleList}
          setPage={setPage}
          currentPage={page}
          pageSize={size}
          setSearchData={setSearchData}
          searchData={searchData}
        />
        <SettleInfoList
          settleList={settleList}
          setPage={setPage}
          setSize={setSize}
          searchData={searchData}
        />

        {settleList.length !== 0 && (
          <PaginationBox
            totalItemsCount={settleList[0]?.cnt}
            page={page}
            setPage={setPage}
            size={size}
          />
        )}
      </div>
    </Wrapper>
  );
};
