import { Info, List } from "template/ListTemplate.style";
import versionColumn from "../../versionColumn2.json";
import { dateFormat } from "components/dateFormat";
import { useMutationCustom } from "hooks/useMutationCustom";
import {
  HTTP_METHOD,
  POST_APP_VERSION2_PATCH_USE_YN,
} from "constants/pathConstant";
import { useToastCustom } from "hooks/useToastCustom";
import { useNavigate } from "react-router";
import { ToggleBackground, ToggleCircle, ToggleContainer } from "../../style";

// 시스템 관리 -> 버전2 관리 :: 버전2 리스트 낱개 (http://localhost:3000/super/system/version2)
const VersionDetail2 = ({
  data,
  searchUpdate,
  searchData,
  setDetailData,
  setIsOpen,
  setSelectData,
  setCurrentData,
}) => {
  const nav = useNavigate();
  const toast = useToastCustom();

  const openHandelr = () => {
    setIsOpen((prev) => !prev);
    setSelectData(data);
  };

  const createEllipsisContent = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    const textContent = tempDiv.textContent || tempDiv.innerText || "";
    return textContent;
  };

  const { mutate } = useMutationCustom(
    POST_APP_VERSION2_PATCH_USE_YN,
    HTTP_METHOD.POST
  );

  const activeHandler = (id, reqType, useYn) => {
    if (useYn === "Y")
      return toast("현재 사용중인 버전을 제거할 수 없습니다.", "error");

    if (window.confirm("해당 버전을 적용하시겠습니까?")) {
      const data = {
        id,
        reqType,
      };

      mutate(data, {
        onSuccess: () => {
          // 첫 번째 searchUpdate 호출
          searchUpdate(searchData, {
            onSuccess: (res1) => {
              setDetailData(res1);
              toast("변경이 정상적으로 처리되었습니다.", "success");

              // 첫 번째 호출 성공 후 두 번째 searchUpdate 호출
              searchUpdate(
                { ...searchData, reqType: "", useYn: "Y" },
                {
                  onSuccess: (res2) => {
                    setCurrentData(res2);
                  },
                }
              );
            },
          });
        },
      });
    }
  };

  return (
    <Info.Content style={{ lineHeight: "1.5rem" }}>
      {/* 번호 */}
      <List.Row $ratio={versionColumn.detailData[0].ratio}>{data.id}</List.Row>

      {/* 구분 */}
      <List.Row $ratio={versionColumn.detailData[1].ratio}>
        {versionColumn.type[data.reqType]}
      </List.Row>

      {/* 적용일자 */}
      <List.Row $ratio={versionColumn.detailData[2].ratio}>
        {data.useDt && dateFormat(data.useDt).slice(0, 16)}
      </List.Row>

      {/* 메세지 */}
      <List.Row
        $ratio={versionColumn.detailData[3].ratio}
        style={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          display: "block",
          cursor: "pointer",
        }}
        onClick={() => openHandelr(data.id)}
      >
        {createEllipsisContent(data.message)}
      </List.Row>

      {/* 강제 업데이트 (AOS) */}
      <List.Row $ratio={versionColumn.detailData[4].ratio}>
        {data.androidForceUpdYn}
      </List.Row>

      {/* AOS 버전 */}
      <List.Row $ratio={versionColumn.detailData[5].ratio}>
        {data.androidVersion}
      </List.Row>

      {/* 다음 AOS 버전 */}
      <List.Row $ratio={versionColumn.detailData[6].ratio}>
        {data.nextAndroidVersion}
      </List.Row>

      {/* 강제 업데이트 여부 (IOS) */}
      <List.Row $ratio={versionColumn.detailData[7].ratio}>
        {data.iosForceUpdYn}
      </List.Row>

      {/* IOS 버전 */}
      <List.Row $ratio={versionColumn.detailData[8].ratio}>
        {data.iosVersion}
      </List.Row>

      {/* 다음 IOS 버전 */}
      <List.Row $ratio={versionColumn.detailData[9].ratio}>
        {data.nextIosVersion}
      </List.Row>

      {/* Dev Key */}
      <List.Row $ratio={versionColumn.detailData[10].ratio}>
        {data.devKey}
      </List.Row>

      {/* 등록자 */}
      <List.Row $ratio={versionColumn.detailData[11].ratio}>
        {data.adminId}
      </List.Row>

      {/* 수정자 */}
      <List.Row $ratio={versionColumn.detailData[12].ratio}>
        {data.modifiedAdmin && data.modifiedAdmin}
      </List.Row>

      {/* 사용 */}
      <List.Row $ratio={versionColumn.detailData[13].ratio}>
        <>
          <ToggleContainer
            onClick={() => activeHandler(data.id, data.reqType, data.useYn)}
          >
            <ToggleBackground isUse={data.useYn === "Y"} />
            <ToggleCircle isUse={data.useYn === "Y"} />
          </ToggleContainer>
        </>
      </List.Row>

      {/* 수정 */}
      <List.Row
        $ratio={versionColumn.detailData[14].ratio}
        style={{
          cursor: "pointer",
          background: "#ddd",
          borderRadius: "2rem",
        }}
        onClick={() => {
          nav(`/super/system/version2-modify`, { state: { data } });
        }}
      >
        수정
      </List.Row>
    </Info.Content>
  );
};

export default VersionDetail2;
