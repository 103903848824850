import { useNavigate } from "react-router";

export const useNavigationCustom = () => {
  const nav = useNavigate();

  return (to) => {
    nav(to);
    window.scrollTo({ top: 0 });
  };
};
