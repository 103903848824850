import { Loading } from "components/Loading/Loading";
import { UserReferralModal } from "components/Modal/UserDetailModal/referral/UserReferralModal";
import { REFERRAL_TYPE } from "constants/referralType";
import { useOpenCustomModal } from "hooks/modal/useOpenCustomModal";
import { useRecoilValue } from "recoil";
import { UserState } from "recoil/atom/UserState";
import { Box, Container, Content, Wrapper } from "./Referral.style";

// 회원검색 (검증 후 회원 정보 바로가기) :: 추천 현황 (http://localhost:3000/manage/user/:code)
export const Referral = ({ isLoading }) => {
  const openReferralModal = useOpenCustomModal(UserReferralModal);

  const setUserDetail = useRecoilValue(UserState.userDetail);

  const openReferralModalHandler = (type, count) => {
    if (count === "0") return;

    openReferralModal({ type });
  };

  if (isLoading) return <Loading />;

  return (
    <Wrapper>
      <Container.Text>추천 현황</Container.Text>
      <Container.Bottom>
        <Box.Wrapper>
          <Box.TopCircle src="/assets/imgs/referral_green_circle.svg" />
          {/* 월렛 */}
          <Box.Title>월렛</Box.Title>
          <Box.Referral>
            <Content.Wrapper>
              {/* 월렛 - 나의추천 */}
              <Content.Img src="/assets/imgs/referral_smile.svg" />
              <Content.Text>나의 추천</Content.Text>
              <Content.People
                $isZero={setUserDetail.recToOtherCnt}
                onClick={() =>
                  openReferralModalHandler(
                    REFERRAL_TYPE.meToOtherW,
                    setUserDetail.recToOtherCnt
                  )
                }
              >
                {`${setUserDetail.recToOtherCnt}명`}
              </Content.People>
            </Content.Wrapper>

            <Content.Wrapper>
              {/* 월렛 - 나를 추천 */}
              <Content.Img src="/assets/imgs/referral_happy.svg" />
              <Content.Text>나를 추천</Content.Text>
              <Content.People
                $isZero={setUserDetail.recForMeCnt}
                onClick={() =>
                  openReferralModalHandler(
                    REFERRAL_TYPE.otherToMeW,
                    setUserDetail.recForMeCnt
                  )
                }
              >
                {`${setUserDetail.recForMeCnt}명`}
              </Content.People>
            </Content.Wrapper>
          </Box.Referral>
        </Box.Wrapper>

        <Box.Wrapper>
          <Box.TopCircle src="/assets/imgs/referral_blue_circle.svg" />
          <Box.Title>체인(가맹점)</Box.Title>
          <Box.Referral>
            <Content.Wrapper>
              {/* 체인 - 나의 추천 */}
              <Content.Img src="/assets/imgs/referral_smile.svg" />
              <Content.Text>나의 추천</Content.Text>
              <Content.People
                $isZero={setUserDetail.recToOtherStoreCnt}
                onClick={() =>
                  openReferralModalHandler(
                    REFERRAL_TYPE.meToOtherS,
                    setUserDetail.recToOtherStoreCnt
                  )
                }
              >
                {`${setUserDetail.recToOtherStoreCnt}건`}
              </Content.People>
            </Content.Wrapper>

            <Content.Wrapper>
              {/* 체인 - 나를 추천 */}
              <Content.Img src="/assets/imgs/referral_happy.svg" />
              <Content.Text>나를 추천</Content.Text>
              <Content.People
                $isZero={setUserDetail.recForMyStoreCnt}
                onClick={() =>
                  openReferralModalHandler(
                    REFERRAL_TYPE.otherToMeS,
                    setUserDetail.recForMyStoreCnt
                  )
                }
              >
                {`${setUserDetail.recForMyStoreCnt}건`}
              </Content.People>
            </Content.Wrapper>
          </Box.Referral>
        </Box.Wrapper>
      </Container.Bottom>
    </Wrapper>
  );
};
