import {
  BottomBtn,
  BottomContainer,
  CheckBox,
  DeleteContainer,
  DeleteTextBox,
} from "./style";
import faqColumn from "./faqColumn.json";
import { Wrapper, Info, List } from "template/ListTemplate.style";
import PageHandler from "components/PageHandler/PageHandler";
import FaqDetail from "./FaqDetail";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import { useState } from "react";
import { useMutationCustom } from "hooks/useMutationCustom";
import {
  POST_FAQ2_DELETE,
  POST_FAQ2_FETCH,
  POST_FAQ2_PATCH_USE_YN,
} from "constants/pathConstant";
import { useToastCustom } from "hooks/useToastCustom";
import Faq2Modal from "components/Modal/Faq2Modal";

// 고객 지원 -> FAQ 관리 :: FAQ 목록 리스트 (http://localhost:3000/super/create/faq)
const FaqList = ({
  setPage,
  setSize,
  faqList,
  setChecked,
  checked,
  searchData,
  setFaqList,
}) => {
  const nav = useNavigationCustom();
  const toast = useToastCustom();

  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState("");
  const [state, setState] = useState("on");

  const selectedData = faqList?.find((v) => v.id === id);

  // 전체 선택
  const allCheckHandler = (e) => {
    if (e.target.checked) {
      const tempArr = [];
      faqList.forEach((el) => tempArr.push(el.id));
      setChecked(tempArr);
    } else {
      setChecked([]);
    }
  };

  // 단일 선택
  const checkHandler = (e, id) => {
    if (e.target.checked) {
      setChecked((prev) => [...prev, id]);
    } else {
      setChecked(checked.filter((el) => el !== id));
    }
  };

  const { mutate } = useMutationCustom(POST_FAQ2_DELETE);

  const { mutate: searchUpdate } = useMutationCustom(POST_FAQ2_FETCH);

  const { mutate: stateUpdate } = useMutationCustom(POST_FAQ2_PATCH_USE_YN);

  const stateHandler = () => {
    if (state === "delete") {
      if (!checked.length) return toast("삭제할 항목을 선택해주세요.", "error");

      if (window.confirm(`${checked.length}건을 삭제 하시겠습니까?`)) {
        mutate(
          { ids: checked.map(Number) },
          {
            onSuccess: () => {
              searchUpdate(searchData, {
                onSuccess: (res) => {
                  setFaqList(res);
                  setChecked([]);
                  toast("삭제가 완료되었습니다.", "success");
                },
              });
            },
            onError: () => {
              toast("업데이트 에러. 관리자에게 문의하세요", "error");
            },
          }
        );
      }
    } else {
      if (!checked.length) return toast("변경할 항목을 선택해주세요.", "error");

      if (window.confirm(`${checked.length}건을 변경 하시겠습니까?`)) {
        stateUpdate(
          { ids: checked.map(Number), showYn: state === "on" ? "Y" : "N" },
          {
            onSuccess: () => {
              searchUpdate(searchData, {
                onSuccess: (res) => {
                  setFaqList(res);
                  setChecked([]);
                  toast("변경이 완료되었습니다.", "success");
                },
              });
            },
            onError: () => {
              toast("업데이트 에러. 관리자에게 문의하세요", "error");
            },
          }
        );
      }
    }
  };

  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>FAQ 목록</List.Text>
        <List.BtnContainer>
          {/* 페이지 핸들러 */}
          <PageHandler setPage={setPage} setPageSize={setSize} />
        </List.BtnContainer>
      </List.TitleContainer>

      <List.Container>
        <List.Content>
          <List.Row>
            <CheckBox
              type="checkbox"
              $ratio={1}
              onChange={(e) => allCheckHandler(e)}
              checked={
                checked.length !== 0 && checked.length === faqList.length
              }
            />
          </List.Row>
          {faqColumn.data.map((v, idx) => (
            <List.Row $ratio={v.ratio} key={idx}>
              {v.title}
            </List.Row>
          ))}
        </List.Content>
        <Info.Wrapper>
          {faqList?.map((v, idx) => (
            <FaqDetail
              key={idx}
              data={v}
              checkHandler={checkHandler}
              checked={checked}
              setIsOpen={setIsOpen}
              setId={setId}
            />
          ))}
        </Info.Wrapper>
      </List.Container>

      <BottomContainer>
        <DeleteContainer>
          <span>선택한 항목을</span>
          <DeleteTextBox onChange={(e) => setState(e.target.value)}>
            <option value="on">공개</option>
            <option value="off">비공개</option>
            <option value="delete">삭제</option>
          </DeleteTextBox>
          <BottomBtn onClick={stateHandler}>적용</BottomBtn>
        </DeleteContainer>
        <BottomBtn onClick={() => nav("/super/support/faq-upload")}>
          작성하기
        </BottomBtn>
      </BottomContainer>

      {isOpen && <Faq2Modal data={selectedData} setIsOpen={setIsOpen} />}
    </Wrapper>
  );
};

export default FaqList;
