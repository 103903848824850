import { Info, List } from "template/ListTemplate.style";
import paymentColumn from "./paymentColumn.json";
import PaymentDetail from "./PaymentDetail";
import { Container, ModalContaier, ModalWrapper, Text } from "./style";
import PaymentCancelDetail from "./PaymentCancelDateil";

const PaymentDetailModal = ({ data, cancelData, setIsOpen, setCancelBg }) => {
  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  const groupedData = groupBy(cancelData, "reqSeq");

  const transformedData = Object.keys(groupedData).map((reqSeq) => {
    const items = groupedData[reqSeq];
    const baseItem = {
      reqId: items[0].reqId,
      reqSeq: reqSeq,
      acctCd: items[0].acctCd,
      usedNm: items[0].usedNm,
      cancelSeq5: "",
      cancelSeq4: "",
      cancelSeq3: "",
      cancelSeq2: "",
      crtDt: items[0].crtDt,
    };

    items.forEach((item) => {
      baseItem[`cancelSeq${item.cancelSeq}`] = item.txValue;
    });

    return baseItem;
  });

  return (
    <>
      <ModalWrapper
        onClick={() => {
          setIsOpen(false);
          setCancelBg(false);
        }}
      />
      <ModalContaier>
        <p>주문 취소 내역 ({data.customerNm})</p>
        <div>
          <List.TitleContainer>
            <Text>결제내역</Text>
            <List.BtnContainer></List.BtnContainer>
          </List.TitleContainer>

          <Container>
            {/* List Column */}
            <List.Content>
              {paymentColumn.column.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>
            <Info.Wrapper>
              <PaymentDetail data={data} />
            </Info.Wrapper>
          </Container>
        </div>

        <div>
          <List.TitleContainer>
            <Text>취소 상세내역</Text>
            <List.BtnContainer></List.BtnContainer>
          </List.TitleContainer>

          <Container>
            {/* List Column */}
            <List.Content>
              {paymentColumn.cancelDetail.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>
            <Info.Wrapper>
              {transformedData.map((v, idx) => (
                <PaymentCancelDetail key={idx} data={v} />
              ))}
              <></>
            </Info.Wrapper>
          </Container>
        </div>
      </ModalContaier>
    </>
  );
};

export default PaymentDetailModal;
