import { Info, List } from "template/ListTemplate.style";
import noticeColumn from "../noticeColumn.json";
import noticeDomainColumn from "../../noticeDomainColumn.json";
import { CheckBox } from "../style";
import { useNavigate } from "react-router";
import { dateFormat } from "components/dateFormat";

// 고객 지원 -> 공지 관리 :: 공지 목록 리스트 낱개 (http://localhost:3000/super/create/notice)
const NoticeDetail = ({ data, checkHandler, checked, setIsOpen, setId }) => {
  const nav = useNavigate();

  const openHandelr = (id) => {
    setIsOpen((prev) => !prev);
    setId(id);
  };

  return (
    <>
      <Info.Content>
        {/* 체크박스 */}
        <List.Row>
          <CheckBox
            type="checkbox"
            $ratio={1}
            onChange={(e) => checkHandler(e, data.id)}
            checked={checked.includes(data.id)}
          />
        </List.Row>

        {/* 번호 */}
        <List.Row $ratio={noticeColumn.data[0].ratio}>{data.id}</List.Row>

        {/* 구분 */}
        <List.Row $ratio={noticeColumn.data[1].ratio}>
          {noticeDomainColumn.reqType[data.reqType]}
        </List.Row>

        {/* 제목 */}
        <List.Row
          $ratio={noticeColumn.data[2].ratio}
          style={{ cursor: "pointer" }}
          onClick={() => openHandelr(data.id)}
        >
          {data.titleKr}
        </List.Row>

        {/* 작성자 */}
        <List.Row $ratio={noticeColumn.data[3].ratio}>{data.crtId}</List.Row>

        {/* 게시일 */}
        <List.Row $ratio={noticeColumn.data[4].ratio}>
          {dateFormat(data.begDt).slice(0, 16)}
        </List.Row>

        {/* 공개 여부 */}
        <List.Row $ratio={noticeColumn.data[5].ratio}>{data.showYn}</List.Row>

        {/* 마지막 수정인 */}
        <List.Row $ratio={noticeColumn.data[6].ratio}>
          {data.updId || data.crtId}
        </List.Row>

        {/* 마지막 수정일 */}
        <List.Row $ratio={noticeColumn.data[7].ratio}>
          {dateFormat(data.endDt || data.crtDt).slice(0, 16)}
        </List.Row>

        {/* 알림 여부 */}
        <List.Row $ratio={noticeColumn.data[8].ratio}>{data.alertYn}</List.Row>

        {/* 수정 버튼 */}
        <List.Row
          $ratio={noticeColumn.data[9].ratio}
          style={{
            cursor: "pointer",
            background: "#ddd",
            padding: "1rem 0",
            borderRadius: "2rem",
          }}
          onClick={() => {
            nav(`/super/support/notice-modify`, { state: { data } });
          }}
        >
          수정
        </List.Row>
      </Info.Content>
    </>
  );
};

export default NoticeDetail;
