import { IMG_CDN_URL, POLYGON_SCAN_TX } from "constants/pathConstant";
import { Info, List, MouseHoverEffect } from "template/ListTemplate.style";
import { NavImgBox } from "./ChargeInfo.style";
import chargeColumn from "./chargeColumn.json";
import chargeState from "../chargeState.json";
import { dateFormat } from "components/dateFormat";
import { costFormat } from "components/costForamt";

// 브릿지 -> 충전 :: 충전 신청/처리 목록 낱개 (http://localhost:3000/super/bridge/charge)
const ChargeInfoDetail = ({ data }) => {
  const polygonNavHandler = (txId, e) => {
    e.stopPropagation();
    window.open(POLYGON_SCAN_TX + txId);
  };

  return (
    <>
      <Info.Content>
        {/* 번호 */}
        <List.Row $ratio={chargeColumn?.data[0]?.ratio}>{data.id}</List.Row>

        {/* 회원명 */}
        <List.Row $ratio={chargeColumn?.data[1]?.ratio}>
          {data.acctUserNm}
        </List.Row>

        {/* 회원코드 */}
        <List.Row $ratio={chargeColumn?.data[2]?.ratio}>{data.acctCd}</List.Row>

        {/* 생년월일 */}
        <List.Row $ratio={chargeColumn?.data[3]?.ratio}>
          {data.birthDay}
        </List.Row>

        {/* 휴대폰 번호 */}
        <List.Row $ratio={chargeColumn?.data[4]?.ratio}>
          {data.cellNumber}
        </List.Row>

        {/* 구분 */}
        <List.Row $ratio={chargeColumn?.data[5]?.ratio}>{data.usedNm}</List.Row>

        {/* 수량 */}
        <List.Row $ratio={chargeColumn?.data[6]?.ratio}>
          {costFormat(data.amount)}
        </List.Row>

        {/* 신청 시간 */}
        <List.Row
          $ratio={chargeColumn?.data[7]?.ratio}
          style={{ fontSize: "1rem" }}
        >
          {dateFormat(data.crtDt)}
        </List.Row>

        {/* 최종 반영 시간 */}
        <List.Row
          $ratio={chargeColumn?.data[8]?.ratio}
          style={{ fontSize: "1rem" }}
        >
          {dateFormat(data.updDt)}
        </List.Row>

        {/* 상태 */}
        <List.Row
          $ratio={chargeColumn?.data[9]?.ratio}
          color={chargeState.stateColor[data.state]}
        >
          {data.stateNm}
          {(data.state === "30" || data.state === "40") && (
            <MouseHoverEffect>{data.stateMemo}</MouseHoverEffect>
          )}
        </List.Row>

        {/* 바로가기 */}
        <List.Row
          $ratio={chargeColumn?.data[10].ratio}
          cursor={"pointer"}
          onClick={(e) => polygonNavHandler(data.txId, e)}
        >
          <NavImgBox>
            <img
              src={`${IMG_CDN_URL}/file/a391e219nav_icon-min.webp`}
              alt="nav_icon"
              style={{ width: "100%", height: "100%" }}
            />
          </NavImgBox>
        </List.Row>
      </Info.Content>
    </>
  );
};

export default ChargeInfoDetail;
