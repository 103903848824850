import { Info, List } from "template/ListTemplate.style";
import paymentMonthlyColumn from "../paymentMonthlyColumn.json";
import { costFormat } from "components/costForamt";
import WalletSlice from "components/WalletSlice/WalletSlice";
import { getColor } from "../getColor";

const PaymentMonthlyDetailList = ({ data }) => {
  return (
    <Info.Content>
      {/* 회원번호 */}
      <List.Row $ratio={paymentMonthlyColumn.detail[0].ratio}>
        {data.memberId}
      </List.Row>

      {/* 회원명 */}
      <List.Row $ratio={paymentMonthlyColumn.detail[1].ratio}>
        {data.memberName}
      </List.Row>

      {/* 회원코드 */}
      <List.Row $ratio={paymentMonthlyColumn.detail[2].ratio}>
        {data.memberCode}
      </List.Row>

      {/* 결제금액 */}
      <List.Row $ratio={paymentMonthlyColumn.detail[3].ratio}>
        <List.Column>
          <p>
            <span>{costFormat(data.sketchOrderAmount)}</span>
            <span style={{ color: getColor("Sketch") }}>Sketch</span>
          </p>
          <p>
            <span>{costFormat(data.polOrderAmount)}</span>
            <span style={{ color: getColor("POL") }}>POL</span>
          </p>
          <p>
            <span>{costFormat(data.ethOrderAmount)}</span>
            <span>ETH</span>
          </p>
        </List.Column>
      </List.Row>

      {/* 결제취소금액 */}
      <List.Row $ratio={paymentMonthlyColumn.detail[4].ratio}>
        <List.Column>
          <p>
            <span>{costFormat(data.sketchCancelAmount)}</span>
            <span style={{ color: getColor("Sketch") }}>Sketch</span>
          </p>
          <p>
            <span>{costFormat(data.polCancelAmount)}</span>
            <span style={{ color: getColor("POL") }}>POL</span>
          </p>
          <p>
            <span>{costFormat(data.ethCancelAmount)}</span>
            <span>ETH</span>
          </p>
        </List.Column>
      </List.Row>

      {/* 총금액 */}
      <List.Row $ratio={paymentMonthlyColumn.detail[5].ratio}>
        <List.Column>
          <List.Column>
            <p>
              <span>{costFormat(data.sketchResultAmount)}</span>
              <span style={{ color: getColor("Sketch") }}>Sketch</span>
            </p>
            <p>
              <span>{costFormat(data.polResultAmount)}</span>
              <span style={{ color: getColor("POL") }}>POL</span>
            </p>
            <p>
              <span>{costFormat(data.ethResultAmount)}</span>
              <span>ETH</span>
            </p>
          </List.Column>
        </List.Column>
      </List.Row>

      {/* 상태 */}
      <List.Row $ratio={paymentMonthlyColumn.detail[6].ratio}>
        {data.state}
      </List.Row>

      {/* 지갑주소 */}
      <List.Row $ratio={paymentMonthlyColumn.detail[7].ratio}>
        {WalletSlice(data.walletAddress)}
      </List.Row>
    </Info.Content>
  );
};

export default PaymentMonthlyDetailList;
