import { Wrapper } from "./SketchDropLeft.style";
import SketchDropSelect from "./SketchDropSelect/SketchDropSelect";
import SketchDropSummary from "./SketchDropSummary/SketchDropSummary";

// 정산 관리 -> 스케치 드랍 :: 요약 / 연월별 목록 (http://localhost:3000/super/calculate/sketch-drop)
const SketchDropLeft = ({
  sketchDropRes,
  isSearch,
  setSketchDropDetailRes,
  userData,
  page,
  setPage,
  detailPage,
  setDetailPage,
  searchDetailReq,
  setSearchDetailReq,
  searchReq,
  detailSize,
  sortOption,
}) => {
  return (
    <Wrapper>
      <SketchDropSummary data={sketchDropRes?.dropInfo} />
      <SketchDropSelect
        isSearch={isSearch}
        data={sketchDropRes?.list}
        setSketchDropDetailRes={setSketchDropDetailRes}
        userData={userData}
        page={page}
        setPage={setPage}
        detailPage={detailPage}
        setDetailPage={setDetailPage}
        searchDetailReq={searchDetailReq}
        setSearchDetailReq={setSearchDetailReq}
        searchReq={searchReq}
        detailSize={detailSize}
        sortOption={sortOption}
      />
    </Wrapper>
  );
};

export default SketchDropLeft;
