import { Header } from "components/Header/Header";
import { Outlet } from "react-router";
import { LayOutContainer } from "./Layout.style";

export const Layout = () => {
  return (
    <div>
      <Header />
      <LayOutContainer>
        {/* Pages content : Begin */}
        <Outlet />
        {/* Pages content : End */}
      </LayOutContainer>
    </div>
  );
};
