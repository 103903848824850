import styled from "styled-components";

export const Container = styled.div`
  width: 120rem;
  background-color: white;
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid #000;
`;

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: blur(5px);
  -webkit-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: fixed;
  background: rgba(0, 0, 0, 0.1);
  z-index: 22;
  background: transparent;
`;

export const ModalContaier = styled.div`
  z-index: 23;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5rem;
  flex-direction: column;
  background: #edeef6;
  width: 130rem;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  border-radius: 1rem;
  border: 1px solid #000;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  & > p {
    font-size: 2.2rem;
  }
`;

export const Text = styled.div`
  font-size: 1.8rem;
  margin-left: 1rem;
`;
