import {
  ETH_SCAN_TX,
  HTTP_METHOD,
  IMG_CDN_URL,
  POLYGON_SCAN_TX,
  POST_CHANGE_SWAP_STATE,
  POST_CONFIRM_SWAP,
  POST_MIGRATION_LIST,
  POST_RETRY_MIGRATION,
} from "constants/pathConstant";
import { Info, List, MouseHoverEffect } from "template/ListTemplate.style";
import { CheckBox, NavImgBox, RetryBtn } from "./SwapInfo.style";
import swapColumn from "./swapColumn.json";
import swapState from "../swapState.json";
import { dateFormat } from "components/dateFormat";
import { costFormat } from "components/costForamt";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useState } from "react";

// 브릿지 -> 스왑 :: 스왑 신청/처리 목록 낱개 (http://localhost:3000/super/bridge/swap)
const SwapInfoDetail = ({ data, setSwapList, searchData }) => {
  const toast = useToastCustom();

  const [isChecked, setIsChecked] = useState(false);

  // =================== scan nav
  const ethScanNavHandler = (txId, e) => {
    e.stopPropagation();
    window.open(ETH_SCAN_TX + txId);
  };

  const polygonScanNavHandler = (txId, e) => {
    e.stopPropagation();
    window.open(POLYGON_SCAN_TX + txId);
  };

  const { mutate } = useMutationCustom(POST_RETRY_MIGRATION, HTTP_METHOD.POST);

  const { mutate: updateMutate } = useMutationCustom(
    POST_MIGRATION_LIST,
    HTTP_METHOD.POST
  );

  const { mutate: updateStateMutate } = useMutationCustom(
    POST_CHANGE_SWAP_STATE,
    HTTP_METHOD.POST
  );

  const handleRetry = (e) => {
    e.stopPropagation();
    if (data.state !== "35" && data.state !== "30") return;

    if (data.state === "35") {
      if (window.confirm("수동 처리하시겠습니까?")) {
        mutate(
          { id: data.id },
          {
            onSuccess: () => {
              updateMutate(searchData, {
                onSuccess: (res) => {
                  setSwapList(res);
                  toast("수동 처리에 성공하였습니다.", "success");
                },
              });
            },

            onError: () => {
              toast("수동 처리에 실패하였습니다.", "error");
            },
          }
        );
      } else {
        alert("수동 처리를 취소하였습니다.");
      }
    } else {
      if (
        window.confirm(
          "해당 작업은 상태값을 스왑 신청 완료로 되돌립니다. \n내용을 정확하게 파악한 경우에만 확인 버튼을 눌러주세요."
        )
      ) {
        updateStateMutate(
          { id: data.id },
          {
            onSuccess: () => {
              updateMutate(searchData, {
                onSuccess: (res) => {
                  setSwapList(res);
                  toast("수동 처리에 성공하였습니다.", "success");
                },
              });
            },

            onError: () => {
              toast("수동 처리에 실패하였습니다.", "error");
            },
          }
        );
      } else {
        alert("수동 처리를 취소하였습니다.");
      }
    }
  };

  const { mutate: confirmMutate } = useMutationCustom(POST_CONFIRM_SWAP);

  // 에러 확인 체크박스
  const handleErrorCheck = (e) => {
    e.stopPropagation();

    if (
      window.confirm(
        "해당 내용을 확인 처리하시는 경우, \n메인페이지 TODO 목록에서 사라집니다. \n진행하시겠습니까?"
      )
    ) {
      confirmMutate(
        {
          id: data.id,
        },
        {
          onSuccess: () => {
            updateMutate(searchData, {
              onSuccess: (res) => {
                setSwapList(res);
                toast("처리에 성공하였습니다.", "success");
              },
            });
          },

          onError: () => {
            toast("처리에 실패하였습니다.", "error");
          },
        }
      );
    } else {
      alert("처리를 취소하였습니다.");
      setIsChecked(false);
    }
  };

  return (
    <>
      <Info.Content>
        {/* 번호 */}
        <List.Row $ratio={swapColumn?.data[0]?.ratio}>{data.id}</List.Row>

        {/* 회원명 */}
        <List.Row $ratio={swapColumn?.data[1]?.ratio}>
          {data.acctUserNm}
        </List.Row>

        {/* 회원코드 */}
        <List.Row $ratio={swapColumn?.data[2]?.ratio}>{data.acctCd}</List.Row>

        {/* 생년월일 */}
        <List.Row $ratio={swapColumn?.data[3]?.ratio}>{data.birthDay}</List.Row>

        {/* 휴대폰 번호 */}
        <List.Row $ratio={swapColumn?.data[4]?.ratio}>
          {data.cellNumber}
        </List.Row>

        {/* 수량 */}
        <List.Row $ratio={swapColumn?.data[5]?.ratio}>
          {costFormat(data.amount)}
        </List.Row>

        {/* 수수료 */}
        <List.Row $ratio={swapColumn?.data[6]?.ratio}>
          {costFormat(data.sketFee)}
        </List.Row>

        {/* 실제 전송 수량 */}
        <List.Row $ratio={swapColumn?.data[7]?.ratio}>
          {costFormat(data.totalAmount)}
        </List.Row>

        {/* 신청 시간 */}
        <List.Row
          $ratio={swapColumn?.data[8]?.ratio}
          style={{ fontSize: "1rem" }}
        >
          {dateFormat(data.crtDt)}
        </List.Row>

        {/* 최종 반영 시간 */}
        <List.Row
          $ratio={swapColumn?.data[9]?.ratio}
          style={{ fontSize: "1rem" }}
        >
          {dateFormat(data.updDt)}
        </List.Row>

        {/* 상태 */}
        <List.Row
          $ratio={swapColumn?.data[10]?.ratio}
          color={swapState.stateColor[data.state]}
        >
          {swapState.state[data.state]}
          {(data.state === "30" || data.state === "40") && (
            <MouseHoverEffect style={{ left: "7.5rem" }}>
              {data.stateMemo}
            </MouseHoverEffect>
          )}
        </List.Row>

        {/* 체크 박스 */}
        <List.Row $ratio={swapColumn?.data[11]?.ratio}>
          {(data.state === "30" ||
            data.state === "35" ||
            data.state === "40") && (
            <CheckBox
              type="checkbox"
              checked={isChecked}
              disabled={data.confirmYn === "Y"}
              onClick={(e) => {
                handleErrorCheck(e);
              }}
            />
          )}
        </List.Row>

        {/* 재시도 */}
        <List.Row
          $ratio={swapColumn?.data[12]?.ratio}
          onClick={(e) => handleRetry(e, data.state)}
        >
          {(data.state === "30" || data.state === "35") && (
            <RetryBtn>수동 처리</RetryBtn>
          )}
        </List.Row>

        {/* 바로가기 */}
        <List.Row
          $ratio={swapColumn?.data[13].ratio}
          cursor={"pointer"}
          onClick={(e) => ethScanNavHandler(data.userToComTxid, e)}
        >
          <NavImgBox>
            <img
              src={`${IMG_CDN_URL}/file/a391e219nav_icon-min.webp`}
              alt="nav_icon"
              style={{ width: "100%", height: "100%" }}
            />
          </NavImgBox>
        </List.Row>

        {/* 바로가기 */}
        <List.Row
          $ratio={swapColumn?.data[14].ratio}
          cursor={"pointer"}
          onClick={(e) => polygonScanNavHandler(data.comToUserTxid, e)}
        >
          <NavImgBox>
            <img
              src={`${IMG_CDN_URL}/file/a391e219nav_icon-min.webp`}
              alt="nav_icon"
            />
          </NavImgBox>
        </List.Row>
      </Info.Content>
    </>
  );
};

export default SwapInfoDetail;
