import {
  HTTP_METHOD,
  POST_WITHDRAW_LIST,
  POST_WITHDRAW_REWARD_CPL,
} from "constants/pathConstant";
import { useState } from "react";
import { Info, List } from "template/ListTemplate.style";
import { ResendBtn } from "./RewardSendInfo.style";
import rewardSendColumn from "./rewardSendColumn.json";
import rewardSendState from "../rewardSendState.json";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { dateFormat } from "components/dateFormat";
import { RewardSendDetailRow } from "../RewardSend.style";

// 정산 -> 리워드 인출 :: 리워드 인출 목록 낱개 (http://localhost:3000/super/calculate/reward-send)
const RewardSendInfoDetail = ({ data, setRewardSendList, searchData }) => {
  const toast = useToastCustom();

  const [clicked, setClicked] = useState(false);

  const { mutate: resend } = useMutationCustom(
    POST_WITHDRAW_REWARD_CPL,
    HTTP_METHOD.POST
  );

  const { mutate: searchUpdate } = useMutationCustom(
    POST_WITHDRAW_LIST,
    HTTP_METHOD.POST
  );

  const resendHandler = (id, e) => {
    e.stopPropagation();

    if (window.confirm("수동 처리 하시겠습니까?")) {
      resend(
        { id },
        {
          onSuccess: () => {
            searchUpdate(searchData, {
              onSuccess: (res) => {
                setRewardSendList(res);
                toast("수동 처리에 성공하였습니다.", "success");
              },
            });
          },
        }
      );
    } else {
      alert("수동 처리를 취소하였습니다.");
    }
  };

  return (
    <>
      <Info.Content
        onClick={() => setClicked(!clicked)}
        isClicked={clicked}
        style={{ color: clicked && "#4253F2", cursor: "pointer" }}
      >
        {/* 번호 */}
        <List.Row $ratio={rewardSendColumn?.data[0]?.ratio}>{data.id}</List.Row>

        {/* 회원명 */}
        <List.Row $ratio={rewardSendColumn?.data[1]?.ratio}>
          {data.acctUserNm}
        </List.Row>

        {/* 회원코드 */}
        <List.Row $ratio={rewardSendColumn?.data[2]?.ratio}>
          {data.acctCd}
        </List.Row>

        {/* 휴대폰 번호 */}
        <List.Row $ratio={rewardSendColumn?.data[3]?.ratio}>
          {data.cellNumber}
        </List.Row>

        {/* 구분 */}
        <List.Row $ratio={rewardSendColumn?.data[4]?.ratio}>
          {data.usedNm}
        </List.Row>

        {/* 수량 */}
        <List.Row $ratio={rewardSendColumn?.data[5]?.ratio}>
          {data.amount}
        </List.Row>

        {/* 수수료 */}
        <List.Row $ratio={rewardSendColumn?.data[6]?.ratio}>
          {data.fee}
        </List.Row>

        {/* 신청 시간 */}
        <List.Row
          $ratio={rewardSendColumn?.data[7]?.ratio}
          style={{ fontSize: "1rem" }}
        >
          {dateFormat(data.crtDt)}
        </List.Row>

        {/* 최종 반영 시간 */}
        <List.Row
          $ratio={rewardSendColumn?.data[8]?.ratio}
          style={{ fontSize: "1rem" }}
        >
          {dateFormat(data.updDt)}
        </List.Row>

        {/* 상태 */}
        <List.Row
          $ratio={rewardSendColumn?.data[9]?.ratio}
          color={rewardSendState.stateColor[data.state]}
          // style={{ fontWeight: "bold", wordBreak: "keep-all" }}
        >
          {rewardSendState.state[data.state]}
        </List.Row>

        {/* 수동인출 */}
        <List.Row $ratio={rewardSendColumn?.data[10]?.ratio}>
          <ResendBtn
            onClick={(e) => {
              resendHandler(data.id, e);
            }}
          >
            수동 처리
          </ResendBtn>
        </List.Row>
      </Info.Content>

      {clicked && (
        <RewardSendDetailRow.Wrapper>
          <RewardSendDetailRow.Container>
            <RewardSendDetailRow.Header>
              {rewardSendColumn?.detailData.map((v, idx) => (
                <RewardSendDetailRow.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </RewardSendDetailRow.Row>
              ))}
            </RewardSendDetailRow.Header>

            <RewardSendDetailRow.Data>
              {/* 인출 수수료에서 사용된 락 밸런스 */}
              <RewardSendDetailRow.Row
                $ratio={rewardSendColumn.detailData[0].ratio}
              >
                {data.useLockFee}
              </RewardSendDetailRow.Row>

              {/* 인출 수수료에서 사용된 리워드 밸런스 */}
              <RewardSendDetailRow.Row
                $ratio={rewardSendColumn.detailData[0].ratio}
              >
                {data.useRewardFee}
              </RewardSendDetailRow.Row>

              {/* 인출에서 사용된 전체 락 밸런스 */}
              <RewardSendDetailRow.Row
                $ratio={rewardSendColumn.detailData[0].ratio}
              >
                {data.lockTotalAmount}
              </RewardSendDetailRow.Row>

              {/* 인출에서 사용된 전체 리워드 밸런스 */}
              <RewardSendDetailRow.Row
                $ratio={rewardSendColumn.detailData[0].ratio}
              >
                {data.rewardTotalAmount}
              </RewardSendDetailRow.Row>

              {/* 인출에서 사용된 전체 밸런스 */}
              <RewardSendDetailRow.Row
                $ratio={rewardSendColumn.detailData[0].ratio}
              >
                {data.withdrawTotalAmount}
              </RewardSendDetailRow.Row>
            </RewardSendDetailRow.Data>
          </RewardSendDetailRow.Container>
        </RewardSendDetailRow.Wrapper>
      )}
    </>
  );
};

export default RewardSendInfoDetail;
