import React from "react";
import {
  HTTP_METHOD,
  IMG_CDN_URL,
  POLYGON_SCAN_TX,
  POST_CONFIRM_WITHDRAW,
  POST_HOLD_WHITDRAW_REWARD,
  POST_RETRY_WITHDRAW_CHARGE,
  POST_RETRY_WITHDRAW_REWARD,
  POST_WITHDRAW_LIST,
} from "constants/pathConstant";
import { useState } from "react";
import { Info, List, MouseHoverEffect } from "template/ListTemplate.style";
import {
  CheckBox,
  HoldeBtn,
  ImgBox,
  NavImgBox,
  RetryBtn,
} from "./WithdrawInfo.style";
import swapColumn from "./withdrawColumn.json";
import withdrawState from "../withdrawState.json";
import { dateFormat } from "components/dateFormat";
import { WithdrawDetailRow } from "../Withdraw.style";
import { costFormat } from "components/costForamt";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useOpenCustomModal } from "hooks/modal/useOpenCustomModal";
import { RestoreUserModal } from "components/Modal/RestoreUserModal/RestoreUserModal";
import MemoModal from "components/Modal/MemoModal/MemoModal";

// 브릿지 -> 출금 :: 출금 신청/처리 목록 낱개 (http://localhost:3000/super/bridge/withdraw)
export const WithdrawInfoDetail = ({
  data,
  setWithdrawList,
  searchData,
  isOngoing,
  setIsOngoing,
}) => {
  const toast = useToastCustom();
  const holdModal = useOpenCustomModal(RestoreUserModal);

  const [isChecked, setIsChecked] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [isMemoOpen, setIsMemoOpen] = useState(false);

  // =================== scan nav
  const polygonNavHandler = (txId, e) => {
    e.stopPropagation();
    window.open(POLYGON_SCAN_TX + txId);
  };

  const { mutate: retryWithdrawCharge } = useMutationCustom(
    POST_RETRY_WITHDRAW_CHARGE,
    HTTP_METHOD.POST
  );

  const { mutate: retryWithdrawReward } = useMutationCustom(
    POST_RETRY_WITHDRAW_REWARD,
    HTTP_METHOD.POST
  );

  const { mutate: searchUpdate } = useMutationCustom(
    POST_WITHDRAW_LIST,
    HTTP_METHOD.POST
  );

  const { mutate: mutateUpdateMemo } = useMutationCustom(
    POST_HOLD_WHITDRAW_REWARD,
    HTTP_METHOD.POST
  );

  const handleRetry = (e) => {
    e.stopPropagation();

    if (isOngoing) {
      alert("이전 작업이 진행중입니다. \n잠시 후에 다시 시도해주세요.");
      return;
    }

    if (
      window.confirm(
        "수동 처리하시겠습니까? \n다음 수동 처리는 10초 뒤에 진행하시기 바랍니다."
      )
    ) {
      setIsOngoing(true);

      setTimeout(() => {
        setIsOngoing(false);
      }, 10000);

      if (data.useType === "W001") {
        retryWithdrawCharge(
          { id: data.id },
          {
            onSuccess: () => {
              searchUpdate(searchData, {
                onSuccess: (res) => {
                  setWithdrawList(res);
                  toast("수동 처리에 성공하였습니다.", "success");
                },
              });
            },

            onError: () => {
              toast("수동 처리에 실패하였습니다.", "error");
            },
          }
        );
      } else {
        retryWithdrawReward(
          { id: data.id },
          {
            onSuccess: () => {
              searchUpdate(searchData, {
                onSuccess: (res) => {
                  setWithdrawList(res);
                  toast("수동 처리에 성공하였습니다.", "success");
                },
              });
            },

            onError: () => {
              toast("수동 처리에 실패하였습니다.", "error");
            },
          }
        );
      }
    } else {
      alert("수동 처리를 취소하였습니다.");
    }
  };

  // 보류
  const handleHold = (e) => {
    e.stopPropagation();

    holdModal({ id: data.id, searchData, setWithdrawList });
  };

  const { mutate: confirmMutate } = useMutationCustom(POST_CONFIRM_WITHDRAW);

  // 에러 확인 체크박스
  const handleErrorCheck = (e) => {
    e.stopPropagation();

    if (
      window.confirm(
        "해당 내용을 확인 처리하시는 경우, \n메인페이지 TODO 목록에서 사라집니다. \n진행하시겠습니까?"
      )
    ) {
      confirmMutate(
        {
          id: data.id,
        },
        {
          onSuccess: () => {
            searchUpdate(searchData, {
              onSuccess: (res) => {
                setWithdrawList(res);
                toast("처리에 성공하였습니다.", "success");
              },
            });
          },

          onError: () => {
            toast("처리에 실패하였습니다.", "error");
          },
        }
      );
    } else {
      alert("처리를 취소하였습니다.");
      setIsChecked(false);
    }
  };

  return (
    <>
      <Info.Content
        onClick={() => setClicked(!clicked)}
        isClicked={clicked}
        style={{ color: clicked && "#4253F2", cursor: "pointer" }}
      >
        {/* 번호 */}
        <List.Row $ratio={swapColumn?.data[0]?.ratio}>{data.id}</List.Row>

        {/* 회원명 */}
        <List.Row $ratio={swapColumn?.data[1]?.ratio}>
          {data.acctUserNm}
        </List.Row>

        {/* 회원코드 */}
        <List.Row $ratio={swapColumn?.data[2]?.ratio}>{data.acctCd}</List.Row>

        {/* 생년월일 */}
        <List.Row $ratio={swapColumn?.data[3]?.ratio}>{data.birthDay}</List.Row>

        {/* 휴대폰 번호 */}
        <List.Row $ratio={swapColumn?.data[4]?.ratio}>
          {data.cellNumber}
        </List.Row>

        {/* 유형 */}
        <List.Row $ratio={swapColumn?.data[5]?.ratio}>
          {data.useTypeNm}
        </List.Row>

        {/* 세부유형 */}
        <List.Row $ratio={swapColumn?.data[6]?.ratio}>{data.usedNm}</List.Row>

        {/* 수량 */}
        <List.Row $ratio={swapColumn?.data[7]?.ratio}>
          {costFormat(data.amount)}
        </List.Row>

        {/* 수수료 */}
        <List.Row $ratio={swapColumn?.data[8]?.ratio}>
          {costFormat(data.fee)}
        </List.Row>

        {/* 총 수량 */}
        <List.Row $ratio={swapColumn?.data[9]?.ratio}>
          {costFormat(data.withdrawTotalAmount)}
        </List.Row>

        {/* 신청 시간 */}
        <List.Row
          $ratio={swapColumn?.data[10]?.ratio}
          style={{ fontSize: "1rem" }}
        >
          {dateFormat(data.crtDt)}
        </List.Row>

        {/* 최종 반영 시간 */}
        <List.Row
          $ratio={swapColumn?.data[11]?.ratio}
          style={{ fontSize: "1rem" }}
        >
          {dateFormat(data.updDt)}
        </List.Row>

        {/* 상태 */}
        <List.Row
          $ratio={swapColumn?.data[12]?.ratio}
          color={clicked || withdrawState.stateColor[data.state]}
        >
          {withdrawState.state[data.state]}
          {(data.state === "30" || data.state === "40") && (
            <MouseHoverEffect style={{ left: "6rem" }}>
              {data.stateMemo}
            </MouseHoverEffect>
          )}
        </List.Row>

        {/* 체크 박스 */}
        <List.Row $ratio={swapColumn?.data[13]?.ratio}>
          {(data.state === "30" ||
            data.state === "09" ||
            data.state === "40") && (
            <CheckBox
              type="checkbox"
              checked={isChecked}
              disabled={data.confirmYn === "Y"}
              onClick={(e) => {
                handleErrorCheck(e);
              }}
            />
          )}
        </List.Row>

        {/* 재시도 */}
        <List.Row $ratio={swapColumn?.data[14]?.ratio} style={{ gap: ".5rem" }}>
          {(data.state === "00" ||
            data.state === "09" ||
            data.state === "30") && (
            <RetryBtn onClick={(e) => handleRetry(e)}>수동 처리</RetryBtn>
          )}
          {data.state === "00" && (
            <HoldeBtn onClick={(e) => handleHold(e)}>보류</HoldeBtn>
          )}
        </List.Row>

        {/* 수동 처리자 */}
        <List.Row $ratio={swapColumn?.data[15]?.ratio}>
          {data.reqAdmId}
        </List.Row>

        {/* 메모 */}
        <List.Row
          $ratio={swapColumn.data[16]?.ratio}
          onClick={(e) => {
            e.stopPropagation();
            setIsMemoOpen((prev) => !prev);
          }}
        >
          <ImgBox>
            {data.holdMemo ? (
              <img
                src={`${IMG_CDN_URL}/file/2da722b5memoY.png`}
                alt="memoImg"
              />
            ) : (
              <img src={`${IMG_CDN_URL}/file/8d24dcc4memo.png`} alt="memoImg" />
            )}
          </ImgBox>
          {isMemoOpen && (
            <MemoModal
              setIsOpen={setIsMemoOpen}
              memoData={{
                memo: data.holdMemo,
                id: data.id,
                state: data.state,
                admId: data.holdAdmId,
                updatedAt: data.holdDt,
              }}
              mutateUpdateData={searchUpdate}
              mutateUpdateMemo={mutateUpdateMemo}
              setUpdateMemoList={setWithdrawList}
              searchData={searchData}
              isWithdraw={true}
            />
          )}
        </List.Row>

        {/* 바로가기 */}
        <List.Row
          $ratio={swapColumn?.data[17].ratio}
          cursor={"pointer"}
          onClick={(e) => polygonNavHandler(data.txId, e)}
        >
          <NavImgBox>
            <img
              src={`${IMG_CDN_URL}/file/a391e219nav_icon-min.webp`}
              alt="nav_icon"
              style={{ width: "100%", height: "100%" }}
            />
          </NavImgBox>
        </List.Row>
      </Info.Content>

      {clicked && (
        <WithdrawDetailRow.Wrapper>
          <WithdrawDetailRow.Container>
            <WithdrawDetailRow.Header>
              {swapColumn?.detailData.map((v, idx) => (
                <WithdrawDetailRow.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </WithdrawDetailRow.Row>
              ))}
            </WithdrawDetailRow.Header>

            <WithdrawDetailRow.Data>
              {/* 인출 수수료에서 사용된 락 밸런스 */}
              <WithdrawDetailRow.Row $ratio={swapColumn.detailData[0].ratio}>
                {costFormat(data.useLockFee)}
              </WithdrawDetailRow.Row>

              {/* 인출 수수료에서 사용된 리워드 밸런스 */}
              <WithdrawDetailRow.Row $ratio={swapColumn.detailData[0].ratio}>
                {costFormat(data.useRewardFee)}
              </WithdrawDetailRow.Row>

              {/* 인출에서 사용된 전체 락 밸런스 */}
              <WithdrawDetailRow.Row $ratio={swapColumn.detailData[0].ratio}>
                {costFormat(data.lockTotalAmount)}
              </WithdrawDetailRow.Row>

              {/* 인출에서 사용된 전체 리워드 밸런스 */}
              <WithdrawDetailRow.Row $ratio={swapColumn.detailData[0].ratio}>
                {costFormat(data.rewardTotalAmount)}
              </WithdrawDetailRow.Row>

              {/* 인출에서 사용된 전체 밸런스 */}
              <WithdrawDetailRow.Row $ratio={swapColumn.detailData[0].ratio}>
                {costFormat(data.withdrawTotalAmount)}
              </WithdrawDetailRow.Row>
            </WithdrawDetailRow.Data>
          </WithdrawDetailRow.Container>
        </WithdrawDetailRow.Wrapper>
      )}
    </>
  );
};
