import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  background: #fff;
  border-radius: 0.6rem;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 2rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 1.3rem;

  & > :nth-child(1) {
    color: #000;
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 1.6rem;
    margin-top: 0.3rem;
  }

  & > :nth-child(2) {
    color: #747474;
  }
`;
