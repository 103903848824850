import { useCloseRootModal } from "hooks/modal/useCloseRootModal";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { RootLoadingState } from "recoil/atom/LoadingState";
import { styled } from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  background: rgba(0, 0, 0, 0.2);

  & > img {
    width: 10%;
    max-width: 5rem;
  }
`;

export const RootLoading = ({ time = 5000 }) => {
  const closeRootModal = useCloseRootModal();

  const [showLoading, setShowLoading] = useState(true);

  const rootLoading = useRecoilValue(RootLoadingState.isLoading);

  useEffect(() => {
    if (!rootLoading) return;
    const timer = setTimeout(() => {
      alert("서버 오류");
      setShowLoading(false);
      closeRootModal();
    }, time);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showLoading && rootLoading && (
        <Wrapper>
          <img src="/assets/imgs/loading.gif" alt="loading" />
        </Wrapper>
      )}
    </>
  );
};
