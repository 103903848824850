import { useState } from "react";
import { Wrapper } from "./SketchBack.style";
import SketchBackDetail from "./SketchBackDetail/SketchBackDetail";
import SketchBackMonth from "./SketchBackMonth/SketchBackMonth";
import SketchBackSearch from "./SketchBackSearch/SketchBackSearch";
import SketchBackSummary from "./SketchBackSummary/SketchBackSummary";

const SketchBack = () => {
  // 연월별 조회 state
  const [sketchBackRes, setSketchBackRes] = useState({ list: [], summary: {} });
  const [searchReq, setSearchReq] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [isSearch, setIsSearch] = useState(false);

  // 상세 조회 state
  const [sketchBackDetailRes, setSketchBackDetailRes] = useState([]);
  const [detailPage, setDetailPage] = useState(1);
  const [detailSize, setDetailSize] = useState(10);
  const [searchDetailReq, setSearchDetailReq] = useState({});
  const [checked, setChecked] = useState([]);
  const [txState, setTxState] = useState("");

  return (
    <Wrapper>
      <SketchBackSearch
        setSketchBackRes={setSketchBackRes}
        setSearchReq={setSearchReq}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setIsSearch={setIsSearch}
        searchReq={searchReq}
        setSketchBackDetailRes={setSketchBackDetailRes}
        setSearchDetailReq={setSearchDetailReq}
      />

      <SketchBackSummary data={sketchBackRes.summary} />
      <SketchBackMonth
        data={sketchBackRes}
        isSearch={isSearch}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setSketchBackDetailRes={setSketchBackDetailRes}
        detailPage={detailPage}
        setDetailPage={setDetailPage}
        searchReq={searchReq}
        setSearchDetailReq={setSearchDetailReq}
        setChecked={setChecked}
        searchDetailReq={searchDetailReq}
        detailSize={detailSize}
        txState={txState}
        setTxState={setTxState}
      />

      <SketchBackDetail
        data={sketchBackDetailRes}
        checked={checked}
        setChecked={setChecked}
        detailPage={detailPage}
        setDetailPage={setDetailPage}
        searchDetailReq={searchDetailReq}
        searchReq={searchReq}
        setSketchBackRes={setSketchBackRes}
        setSketchBackDetailRes={setSketchBackDetailRes}
        detailSize={detailSize}
        setDetailSize={setDetailSize}
        setTxState={setTxState}
        txState={txState}
      />
    </Wrapper>
  );
};

export default SketchBack;
