import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-top: 6rem;
  align-items: center;

  & > div {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
`;

export const VerifyUser = {
  Wrapper: styled.div`
    padding: 0 1rem;
    display: flex;
    gap: 0.3rem;
    border-radius: 0.4rem;
    background: #4253f2;
    align-items: center;
    justify-content: center;
    height: 2rem;
  `,

  Img: styled.img`
    width: 1.2rem;
    height: 1.2rem;
  `,

  Text: styled.div`
    color: white;
    font-size: 1rem;
    font-weight: ${FONT_WEIGHT.BOLD};
  `,
};

export const DetailBtn = {
  Wrapper: styled.div`
    width: 100%;
    border-radius: 10rem;
    border: 0.1rem solid #4253f2;
    cursor: pointer;
    color: #4253f2;
    font-size: 1rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    padding: 0.6rem 0.8rem;
  `,
};

export const Verify = {
  Input: styled.input`
    width: 3.6rem;
    background-color: rgba(244, 244, 244, 1);
    border: 0.1rem solid rgba(153, 153, 153, 1);
    border-radius: 0.4rem;
    padding-left: 0.5rem;
    outline: none;

    &:focus {
      background-color: white;
      border: 0.2rem solid rgba(66, 83, 242, 1);
    }
  `,
};
