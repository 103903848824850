import styled from "styled-components";

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: blur(5px);
  -webkit-filter: brightness(65%);
  backdrop-filter: blur(5px);
  position: fixed;
  z-index: 22;
`;

export const ModalContaier = styled.div`
  z-index: 23;
  position: fixed;
  top: 13%;
  left: 50%;
  transform: translateX(-50%);
  width: 50rem;
  display: flex;
  flex-direction: column;

  & > :nth-child(1) {
    width: 100%;
    display: flex;
    font-size: 1.6rem;
    justify-content: space-between;
    color: #fff;

    & > p {
      width: 100%;
      background: #6675ff;
      text-align: center;
      padding: 2rem;
    }

    & > :nth-child(1) {
      border-top-left-radius: 1.5rem;
    }

    & > :nth-child(2) {
      border-top-right-radius: 1.5rem;
      background: #4253f2;
    }
  }
`;

export const PopupList = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  height: 40rem;

  & > div {
    width: 50%;
    overflow-y: scroll;
  }

  & > :nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2rem 0;
  }
`;

export const PopupListImg = styled.div`
  width: 10rem;
  min-height: 10rem;
  box-shadow: rgba(33, 35, 38, 0.1) 10px 20px 10px -10px;

  > img {
    width: 100%;
    height: 100%;
  }
`;

export const PopupListTitle = styled.div`
  display: flex;
  font-size: 1.6rem;
  gap: 2rem;
  padding: 2rem;
  align-items: center;

  & > :nth-child(1) {
    width: 2rem;
    height: 2rem;

    > img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const Bottom = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 1.5rem 1.5rem;
  padding: 0 1rem;

  & > :nth-child(1) {
    > select {
      font-size: 1.6rem;
    }
  }

  & > :nth-child(2) {
    padding: 1rem 2rem;
    font-size: 1.4rem;
    background: #4253f2;
    color: #fff;
    margin: 2rem 0;
    border-radius: 1rem;
    cursor: pointer;
  }
`;
