import { Info, List } from "template/ListTemplate.style";
import dropColumn from "../dropByUserColumn.json";
import { costFormat } from "components/costForamt";
import { NavTxId } from "components/Navigation/NavTxId";
import { RowElement } from "pages/DropUser/DropUser.style";

// 회원검색 (검증 후 회원 정보 바로가기) :: 지갑정보 -> 스케치 드랍 낱개 (http://localhost:3000/manage/user/:code)
const DropByUserDetail = ({ data }) => {
  return (
    <>
      <Info.Content>
        {/* 번호 */}
        <List.Row $ratio={dropColumn?.data[0]?.ratio}>{data.id}</List.Row>

        {/* 요청일자 */}
        <List.Row $ratio={dropColumn?.data[1]?.ratio}>
          {data.createDate.slice(0, 11)}
        </List.Row>

        {/* 회원명 */}
        <List.Row $ratio={dropColumn?.data[2]?.ratio}>
          <RowElement>
            <p>{data.memberName}</p>
            <p>({data.memberCode})</p>
          </RowElement>
        </List.Row>

        {/* 사용스케치드랍 */}
        <List.Row $ratio={dropColumn?.data[3]?.ratio}>
          {costFormat(data.usedAmount)}
        </List.Row>

        {/* 보유스케치드랍 */}
        <List.Row $ratio={dropColumn?.data[4]?.ratio}>
          {costFormat(data.havingAmount)}
        </List.Row>

        {/* 총 스케치드랍(수량) */}
        <List.Row $ratio={dropColumn?.data[5]?.ratio}>
          {costFormat(data.txValue)}
        </List.Row>

        {/* 총 스케치드랍(원) */}
        <List.Row $ratio={dropColumn?.data[6]?.ratio}>
          {costFormat(data.exchangeFromKrw)}
        </List.Row>

        {/* 시세(원) */}
        <List.Row $ratio={dropColumn?.data[7]?.ratio}>
          {costFormat(data.exchangeRateKrw)}
        </List.Row>

        {/* 지급일자 */}
        <List.Row $ratio={dropColumn?.data[8]?.ratio}>
          {data.updateDate.slice(0, 11)}
        </List.Row>

        {/* 상태 */}
        <List.Row $ratio={dropColumn?.data[9]?.ratio}>{data.state}</List.Row>

        {/* txId */}
        <List.Row $ratio={dropColumn?.data[10]?.ratio}>
          {NavTxId(data.txId)}
        </List.Row>
      </Info.Content>
    </>
  );
};

export default DropByUserDetail;
