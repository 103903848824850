import { useState } from "react";
import { StoreInfoList } from "./StoreInfoList/StoreInfoList";
import { Wrapper } from "./StoreList.style";
import { StoreSearch } from "./StoreSearch/StoreSearch";
import { PaginationBox } from "components/Pagination/PaginationBox";

// 가맹점 검색 :: (http://localhost:3000/manage/store)
export const StoreList = () => {
  const [storeList, setStoreList] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchData, setSearchData] = useState({});

  return (
    <Wrapper>
      <StoreSearch
        setStoreList={setStoreList}
        setPage={setPage}
        currentPage={page}
        pageSize={size}
        setSearchData={setSearchData}
        searchData={searchData}
      />
      <StoreInfoList
        storeList={storeList}
        setPage={setPage}
        setSize={setSize}
        searchData={searchData}
      />

      {storeList.length !== 0 && (
        <PaginationBox
          totalItemsCount={storeList[0]?.cnt}
          page={page}
          setPage={setPage}
          size={size}
        />
      )}
    </Wrapper>
  );
};
