import { useState } from "react";
import { Container, Content, Wrapper } from "../style";
import SearchCategory from "../../SearchCategory";
import { PaginationBox } from "components/Pagination/PaginationBox";
import { POST_WALLET_INFO_DROP } from "constants/pathConstant";
import { Info, List } from "template/ListTemplate.style";
import dropColumn from "./dropByUserColumn.json";
import DropByUserDetail from "./DropByUserDetail";
import { WALLET_INFO_DROP_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";

// 회원검색 (검증 후 회원 정보 바로가기) :: 지갑정보 -> 스케치 드랍 (http://localhost:3000/manage/user/:code)
const DropByUser = ({ id }) => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [resData, setResData] = useState({ list: [], listCount: 0 });

  const data = {
    id,
    currentPage: page,
    pageSize: size,
  };

  return (
    <Wrapper>
      <SearchCategory
        API_ADDRESS={POST_WALLET_INFO_DROP}
        data={data}
        setResData={setResData}
        setPage={setPage}
        setSize={setSize}
        page={page}
        size={size}
        resData={resData}
        XLSX_DOWNLOAD={WALLET_INFO_DROP_XLSX_DOWNLOAD}
        withdraw={true}
      />

      <Container>
        <Content>
          {dropColumn.data.map((v, idx) => (
            <List.Row $ratio={v.ratio} key={idx}>
              {v.title}
            </List.Row>
          ))}
        </Content>

        <Info.Wrapper>
          {resData.list?.map((v, idx) => (
            <DropByUserDetail data={v} key={idx} />
          ))}
        </Info.Wrapper>
      </Container>

      {resData.listCount > 0 && (
        <PaginationBox
          totalItemsCount={resData.listCount}
          page={page}
          setPage={setPage}
          size={size}
        />
      )}
    </Wrapper>
  );
};

export default DropByUser;
