import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { ListDefault } from "components/ListDefault/ListDefault";
import { POST_WALLET_DELETE_MEMBER_FETCH } from "constants/pathConstant";
import { WALLET_MEMBER_DROP_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { Info, List, Wrapper } from "template/ListTemplate.style";
import dropUserColumn from "../walletDropUserColumn.json";
import PageHandler from "components/PageHandler/PageHandler";
import WalletDropUserDetailList from "./WalletDropUserDetailList";

// 검색 -> 탈퇴 회원관리 :: 회원 목록 (http://localhost:3000/super/search/gone-user)
const WalletDropUserDetail = ({
  setPage,
  setSize,
  dropUserList,
  searchData,
}) => {
  const excelDownload = useExcelDownload();
  const toast = useToastCustom();

  const { mutate } = useMutationCustom(POST_WALLET_DELETE_MEMBER_FETCH);

  const handleExcelDownload = () => {
    if (!dropUserList[0]?.cnt) {
      toast("액셀 다운로드 실패", "error");
      return;
    }

    const data = {
      ...searchData,
      pageSize: dropUserList[0]?.cnt,
      currentPage: 1,
    };

    mutate(data, {
      onSuccess: (response) => {
        const excelData = {
          data: response,
          headers: WALLET_MEMBER_DROP_XLSX_DOWNLOAD.header,
          fileName: WALLET_MEMBER_DROP_XLSX_DOWNLOAD.filename,
        };

        excelDownload(excelData);
      },

      onError: () => {
        toast("전체 데이터 조회 실패", "error");
      },
    });
  };

  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>
          월렛 탈퇴 회원 목록{" "}
          {dropUserList[0] && <span>(검색됨 : {dropUserList[0]?.cnt}개)</span>}
        </List.Text>

        <List.BtnContainer>
          {/* 페이지 핸들러 */}
          <PageHandler setPage={setPage} setPageSize={setSize} />
          {/* 엑셀 다운로드 버튼 */}
          <ExcelDownloadBtn onClick={handleExcelDownload} />
        </List.BtnContainer>
      </List.TitleContainer>

      {/* 리스트 */}
      <List.Container>
        {dropUserList.length === 0 ? (
          <ListDefault text={"회원을"} />
        ) : (
          <>
            {/* List Column */}
            <List.Content>
              {dropUserColumn.data.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>
            <Info.Wrapper>
              {/* 실제 데이터 */}
              {dropUserList.map((v, idx) => (
                <div key={idx}>
                  <WalletDropUserDetailList
                    key={v.id}
                    id={v.id}
                    deleteDate={v.deleteDate}
                    memberName={v.memberName}
                    memberCode={v.memberCode}
                    recommenderName={v.recommenderName}
                    recommenderCode={v.recommenderCode}
                    mobile={v.mobile}
                    email={v.email}
                    walletAddress={v.walletAddress}
                    birthDay={v.birthDay}
                    state={v.state}
                    createDate={v.createDate}
                  />
                </div>
              ))}
            </Info.Wrapper>
          </>
        )}
      </List.Container>
    </Wrapper>
  );
};

export default WalletDropUserDetail;
