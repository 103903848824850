import { useState } from "react";
import VersionList from "./VersionList2";
import { Wrapper } from "./style";
import CurrentVersion2 from "./CurrentVersion2";

// 시스템 관리 -> 버전2 관리 :: (http://localhost:3000/super/system/version2)
const Version2 = () => {
  const [currentData, setCurrentData] = useState([]);
  return (
    <Wrapper>
      <CurrentVersion2
        currentData={currentData}
        setCurrentData={setCurrentData}
      />
      <VersionList setCurrentData={setCurrentData} />
    </Wrapper>
  );
};

export default Version2;
