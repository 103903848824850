import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  height: 20rem;
  background: #fff;
  border-radius: 1rem;
  border: 3px solid #febd17;
  box-shadow: 0px 0.4rem 0.4rem 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0rem;
  left: -20rem;
  z-index: 3;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: default;

  & > p {
    font-size: 1.6rem;
    font-weight: ${FONT_WEIGHT.BOLD};
  }

  & > textarea {
    min-width: 20rem;
    min-height: 10rem;
    box-sizing: border-box;
    padding: 1rem;
    font-size: 1.2rem;
  }
`;

export const Btn = styled.div`
  display: flex;
  justify-content: end;
  font-weight: ${FONT_WEIGHT.BOLD};
  font-size: 1.2rem;
  gap: 0.5rem;

  & > div {
    padding: 0.5rem 1rem;
    background: #bbb;
    border-radius: 0.6rem;
    color: #000;
    cursor: pointer;
  }

  & > div:last-child {
    background: #febd17;
    color: #fff;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 2;
`;

export const AdminData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 1.1rem;
  font-weight: 400;
`;
