import { Info, List } from "template/ListTemplate.style";
import assetPayColumn from "../assetPayColumn.json";
import { dateFormat } from "components/dateFormat";
import { costFormat } from "components/costForamt";

// 페이 자산내역 :: 페이 자산내역 리스트 낱개 (http://localhost:3000/super/asset/pay)
const AssetPayDetailList = ({ data }) => {
  const stateToString = (state) => {
    if (state === "실패") return "#fe1717";
    if (state === "성공") return "#21F650";
  };

  return (
    <Info.Content>
      {/* 번호 */}
      <List.Row $ratio={assetPayColumn.data[0].ratio}>{data.id}</List.Row>

      {/* 회원명 */}
      <List.Row $ratio={assetPayColumn.data[1].ratio}>
        <List.RowElement>
          <p>{data.acctUserNm}</p>
          <p>{data.acctCd}</p>
        </List.RowElement>
      </List.Row>

      {/* 가맹점명 */}
      <List.Row $ratio={assetPayColumn.data[2].ratio}>
        {data.ssName && (
          <List.RowElement>
            <p>{data.ssName}</p>
            <p>({data.ssCd})</p>
          </List.RowElement>
        )}
      </List.Row>

      {/* 유형 */}
      <List.Row $ratio={assetPayColumn.data[3].ratio}>
        {data.useTypeNm}
      </List.Row>

      {/* 세부유형 */}
      <List.Row $ratio={assetPayColumn.data[4].ratio}>{data.usedNm}</List.Row>

      {/* 수량 */}
      <List.Row $ratio={assetPayColumn.data[5].ratio}>
        {costFormat(String(data.txValue))}
      </List.Row>

      {/* 인출 수수료 */}
      <List.Row $ratio={assetPayColumn.data[6].ratio}>
        {costFormat(String(data.fee))}
      </List.Row>

      {/* 1sket 당 krw 가격 */}
      <List.Row $ratio={assetPayColumn.data[7].ratio}>
        {costFormat(String(data.exchangeFromKrw))}
      </List.Row>

      {/* 승인일시 */}
      <List.Row $ratio={assetPayColumn.data[8].ratio}>
        {dateFormat(data.crtDt)}
      </List.Row>

      {/* 상태 */}
      <List.Row
        $ratio={assetPayColumn.data[9].ratio}
        color={stateToString(data.stateNm)}
      >
        {data.stateNm}
      </List.Row>
    </Info.Content>
  );
};

export default AssetPayDetailList;
