import { useEffect, useRef, useState } from "react";
import {
  BottomBtn,
  BottomWrapper,
  ContentWrapper,
  Name,
  Rectangle,
  SelectWrapper,
  TableWrapper,
  ThumbnailWrapper,
  UploadImage,
  UploadImageLabel,
  Wrapper,
  SelectBoxWrapper,
  CheckboxWrapper,
} from "./style";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import { POST_POPUP_CREATE, POST_POPUP_MODIFY } from "constants/pathConstant";
import { axiosInstance } from "api/api";
import { useMutation } from "@tanstack/react-query";
import { useToastCustom } from "hooks/useToastCustom";
import { PopupModal } from "components/Modal/PopupModal";
import domainColumn from "../popupDomainColumn.json";
import { dateFormat } from "components/dateFormat";

// 고객 지원 -> 팝업 관리 :: 팝업 등록 (http://localhost:3000/super/create/popup-upload)
const PopupUpload = ({ modifyData }) => {
  const nav = useNavigationCustom();
  const toast = useToastCustom();

  const imgRefEng = useRef(null);
  const imgRefKor = useRef(null);

  // 기본 upload state
  const useYn = "Y";
  const [fileKor, setFileKor] = useState("");
  const [fileEng, setFileEng] = useState("");
  const [extraDesc, setExtraDesc] = useState("");
  const [showYn, setShowYn] = useState("Y");
  const [begDt, setBegDt] = useState(dateFormat(new Date(), false, 0, 0));
  const [endDt, setEndDt] = useState(dateFormat(new Date(), false, 0, 0));
  const [title, setTitle] = useState("");
  const [aosLinkTarget, setAosLinkTarget] = useState("");
  const [iosLinkTarget, setIosLinkTarget] = useState("");
  const [domain, setDomain] = useState("00");
  const [checked, setChecked] = useState([]);

  // 수정시 추가 state
  const [id, setId] = useState("");
  const [isModifiedFile, setIsModifiedFile] = useState(false);

  // 모달 state
  const [isOpen, setIsOpen] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const previewHandler = () => {
    setIsOpen((prev) => !prev);
    setPreviewData({
      linkImgKor: fileKor,
      linkImgEng: fileEng,
    });
  };

  useEffect(() => {
    if (!modifyData) return;

    setId(modifyData.id);
    setBegDt(dateFormat(modifyData.begDt));
    setEndDt(dateFormat(modifyData.endDt));
    setFileKor(modifyData.linkImg);
    setAosLinkTarget(modifyData.aosLinkTarget);
    setIosLinkTarget(modifyData.iosLinkTarget);
    setDomain(modifyData.domain);
    setShowYn(modifyData.showYn);
    setFileKor(modifyData.linkImgKor);
    setFileEng(modifyData.linkImgEng);
    setExtraDesc(modifyData.extraDesc);
    setTitle(modifyData.title);
  }, []);

  // 이미지 업로드 핸들러
  const imgUploadHandler = (isKo, e) => {
    const file = e.target.files[0];
    // input에 안담기면
    if (!file) return;
    // 5MB보다 큰경우 return
    if (file.size / 1000 / 1000 > 5)
      return alert("파일 크기는 최대 5MB입니다.");

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (isKo) {
        setIsModifiedFile(true);
        setFileKor(reader.result);
      } else {
        setIsModifiedFile(true);
        setFileEng(reader.result);
      }
    };
  };

  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };

  const { mutate } = useMutation(
    (data) => axiosInstance.post(POST_POPUP_CREATE, data, config),
    {
      onSuccess: () => {
        toast("정상적으로 등록되었습니다.", "success");
        nav("/super/create/popup");
      },
    }
  );

  const { mutate: updateMutate } = useMutation(
    (data) => axiosInstance.post(POST_POPUP_MODIFY, data, config),
    {
      onSuccess: () => {
        toast("정상적으로 수정되었습니다.", "success");
        nav("/super/create/popup");
      },
    }
  );

  const uploadHandler = async () => {
    if (modifyData) {
      if (!begDt || !endDt || !title) {
        return toast("필수 값을 모두 입력해주세요.", "error");
      }
      if (
        isModifiedFile &&
        (!imgRefKor.current.files[0] || !imgRefEng.current.files[0])
      ) {
        return toast("이미지는 일괄적으로 수정되어야 합니다.", "error");
      }
    } else {
      if (
        !title ||
        !imgRefKor.current.files[0] ||
        !imgRefEng.current.files[0]
      ) {
        return toast("필수 값을 모두 입력해주세요.", "error");
      }
    }

    const data = {
      extraDesc,
      useYn,
      showYn,
      begDt: dateFormat(begDt, false, 0, -9),
      endDt: dateFormat(endDt, false, 0, -9),
      title,
      aosLinkTarget,
      iosLinkTarget,
      ...(modifyData && { domain }),
      ...(modifyData && { id }),
      ...(modifyData && { isModifiedFile }),
    };

    setPreviewData(data);
    const formData = new FormData();

    for (const key in data) {
      formData.append(
        key,
        typeof data[key] === "object" ? JSON.stringify(data[key]) : data[key]
      );
    }
    formData.append("fileKor", imgRefKor.current.files[0]);
    formData.append("fileEng", imgRefEng.current.files[0]);

    !modifyData &&
      checked.forEach((domain) => formData.append("domain", domain));

    if (modifyData) {
      updateMutate(formData);
    } else {
      mutate(formData);
    }
  };

  const checkHandler = (e, id) => {
    if (e.target.checked) {
      setChecked((prev) => [...prev, id]);
    } else {
      setChecked(checked.filter((el) => el !== id));
    }
  };

  return (
    <Wrapper>
      <Name>Popup</Name>
      <TableWrapper>
        <span>
          <Rectangle />
          작성하기
        </span>

        <div>
          <div>
            <SelectBoxWrapper>
              <div>제목</div>
              <SelectWrapper>
                <input
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="제목을 입력해주세요"
                  defaultValue={modifyData?.title || title}
                />
              </SelectWrapper>
            </SelectBoxWrapper>
          </div>

          <div>
            <SelectBoxWrapper>
              <div>종류</div>
              {modifyData ? (
                <SelectWrapper>
                  <select
                    onChange={(e) => setDomain(e.target.value)}
                    defaultValue={modifyData?.domain || domain}
                    key={modifyData?.domain || domain}
                  >
                    {Object.entries(domainColumn.domain).map(([key, value]) => (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    ))}
                  </select>
                </SelectWrapper>
              ) : (
                <CheckboxWrapper>
                  {Object.entries(domainColumn.domain).map(([key, value]) => (
                    <div key={key}>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          checkHandler(e, key);
                        }}
                      />
                      <p>{value}</p>
                    </div>
                  ))}
                </CheckboxWrapper>
              )}
            </SelectBoxWrapper>
            <SelectBoxWrapper>
              <div>공개 여부</div>
              <SelectWrapper>
                <select
                  onChange={(e) => setShowYn(e.target.value)}
                  defaultValue={modifyData?.showYn || showYn}
                  key={modifyData?.showYn || showYn}
                >
                  <option value={"Y"}>공개</option>
                  <option value={"N"}>비공개</option>
                </select>
              </SelectWrapper>
            </SelectBoxWrapper>
          </div>

          <div>
            <SelectBoxWrapper>
              <div>게시일</div>
              <SelectWrapper>
                <input
                  type="datetime-local"
                  onChange={(e) => setBegDt(new Date(e.target.value))}
                  defaultValue={
                    modifyData ? dateFormat(modifyData?.begDt) : begDt
                  }
                  // max={modifyData ? endDt : endDt && endDt.slice(0, 19)}
                />
              </SelectWrapper>
            </SelectBoxWrapper>
            <SelectBoxWrapper>
              <div>종료일</div>
              <SelectWrapper>
                <input
                  type="datetime-local"
                  onChange={(e) => setEndDt(new Date(e.target.value))}
                  defaultValue={
                    modifyData ? dateFormat(modifyData?.endDt) : endDt
                  }
                  // min={modifyData ? begDt : begDt && begDt.slice(0, 19)}
                />
              </SelectWrapper>
            </SelectBoxWrapper>
          </div>

          <div>
            <SelectBoxWrapper>
              <div>링크(AOS)</div>
              <SelectWrapper>
                <input
                  type="text"
                  onChange={(e) => setAosLinkTarget(e.target.value)}
                  placeholder="팝업 클릭시 이동할 링크(선택)"
                  defaultValue={modifyData?.aosLinkTarget || aosLinkTarget}
                />
              </SelectWrapper>
            </SelectBoxWrapper>
            <SelectBoxWrapper>
              <div>링크(IOS)</div>
              <SelectWrapper>
                <input
                  type="text"
                  onChange={(e) => setIosLinkTarget(e.target.value)}
                  placeholder="팝업 클릭시 이동할 링크(선택)"
                  defaultValue={modifyData?.iosLinkTarget || iosLinkTarget}
                />
              </SelectWrapper>
            </SelectBoxWrapper>
          </div>
          <p>링크가 하나일 경우에는 한 곳에만 입력해도 업로드 가능합니다.</p>
        </div>
      </TableWrapper>

      <div>
        <ContentWrapper>
          <span>
            <Rectangle />
            내용
          </span>
          <textarea
            onChange={(e) => setExtraDesc(e.target.value)}
            defaultValue={modifyData?.extraDesc || extraDesc}
            placeholder="내용을 입력해주세요(선택)"
          />
        </ContentWrapper>
      </div>

      {/* 이미지 */}
      <div>
        <ThumbnailWrapper>
          <div>한글 팝업 이미지</div>
          <div>
            <img src={fileKor} alt="thumbnail" />
            <UploadImage
              type="file"
              id="imgKor"
              onChange={(e) => imgUploadHandler(true, e)}
              accept="image/*"
              ref={imgRefKor}
            />
            <UploadImageLabel htmlFor="imgKor">파일 선택</UploadImageLabel>
          </div>
        </ThumbnailWrapper>
        <ThumbnailWrapper>
          <div>영문 팝업 이미지</div>
          <div>
            <img src={fileEng} alt="thumbnail" />
            <UploadImage
              type="file"
              id="imgEng"
              onChange={(e) => imgUploadHandler(false, e)}
              accept="image/*"
              ref={imgRefEng}
            />
            <UploadImageLabel htmlFor="imgEng">파일 선택</UploadImageLabel>
          </div>
        </ThumbnailWrapper>
      </div>

      <BottomWrapper>
        <div>
          <BottomBtn onClick={() => nav("/super/create/popup")}>목록</BottomBtn>
        </div>
        <div>
          <BottomBtn
            onClick={previewHandler}
            bgColor={"#2db9c2"}
            fontColor={"white"}
          >
            미리보기
          </BottomBtn>
          <BottomBtn
            onClick={uploadHandler}
            bgColor={"#4253f2"}
            fontColor={"white"}
          >
            저장
          </BottomBtn>
        </div>
      </BottomWrapper>

      {isOpen && <PopupModal data={previewData} setIsOpen={setIsOpen} />}
    </Wrapper>
  );
};

export default PopupUpload;
