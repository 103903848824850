import { IMG_CDN_URL, POLYGON_SCAN_TX } from "constants/pathConstant";
import { Info, List } from "template/ListTemplate.style";
import chargeColumn from "../chargeByUserColumn.json";
import { dateFormat } from "components/dateFormat";
import { NavImgBox } from "../../style";
import { costFormat } from "components/costForamt";

// 회원검색 (검증 후 회원 정보 바로가기) :: 지갑정보 -> 충전 낱개 (http://localhost:3000/manage/user/:code)
const ChargeByUserDetail = ({ data }) => {
  const polygonNavHandler = (txId, e) => {
    e.stopPropagation();
    window.open(POLYGON_SCAN_TX + txId);
  };

  return (
    <Info.Content>
      {/* 번호 */}
      <List.Row $ratio={chargeColumn?.data[0]?.ratio}>{data.id}</List.Row>

      {/* 구분 */}
      <List.Row $ratio={chargeColumn?.data[1]?.ratio}>{data.usedNm}</List.Row>

      {/* 수량 */}
      <List.Row $ratio={chargeColumn?.data[2]?.ratio}>
        {costFormat(data.amount)}
      </List.Row>

      {/* 신청 시간 */}
      <List.Row
        $ratio={chargeColumn?.data[3]?.ratio}
        style={{ fontSize: "1rem" }}
      >
        {dateFormat(data.crtDt)}
      </List.Row>

      {/* 최종 반영 시간 */}
      <List.Row
        $ratio={chargeColumn?.data[4]?.ratio}
        style={{ fontSize: "1rem" }}
      >
        {dateFormat(data.updDt)}
      </List.Row>

      {/* 상태 */}
      <List.Row
        $ratio={chargeColumn?.data[5]?.ratio}
        color={chargeColumn.stateColor[data.state]}
      >
        {chargeColumn.state[data.state]}
      </List.Row>

      {/* 바로가기 */}
      <List.Row
        $ratio={chargeColumn?.data[6].ratio}
        cursor={"pointer"}
        onClick={(e) => polygonNavHandler(data.txId, e)}
      >
        <NavImgBox>
          <img
            src={`${IMG_CDN_URL}/file/a391e219nav_icon-min.webp`}
            alt="nav_icon"
            style={{ width: "100%", height: "100%" }}
          />
        </NavImgBox>
      </List.Row>
    </Info.Content>
  );
};

export default ChargeByUserDetail;
