import { Info, List, Wrapper } from "template/ListTemplate.style";
import sketchBackColumn from "../sketchBackColumn.json";
import { costFormat } from "components/costForamt";

const SketchBackSummary = ({ data }) => {
  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>스케치 백 요약</List.Text>
      </List.TitleContainer>

      <List.Container style={{ minHeight: 0 }}>
        <List.Content>
          {sketchBackColumn?.summary.map((v, idx) => (
            <List.Row $ratio={v.ratio} key={idx}>
              {v.title}
            </List.Row>
          ))}
        </List.Content>

        <Info.Wrapper>
          <Info.Content>
            <List.Row $ratio={sketchBackColumn.summary[0].ratio}>
              {costFormat(data.totalAmountSketch)}
            </List.Row>

            <List.Row $ratio={sketchBackColumn.summary[1].ratio}>
              {costFormat(data.totalAmountKrw)}
            </List.Row>

            <List.Row $ratio={sketchBackColumn.summary[2].ratio}>
              {costFormat(data.reqDateCnt)}
            </List.Row>

            <List.Row $ratio={sketchBackColumn.summary[3].ratio}>
              {costFormat(data.targetWalletCnt)}
            </List.Row>

            <List.Row $ratio={sketchBackColumn.summary[4].ratio}>
              {costFormat(data.totalCnt)}
            </List.Row>
          </Info.Content>
        </Info.Wrapper>
      </List.Container>
    </Wrapper>
  );
};

export default SketchBackSummary;
