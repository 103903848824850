import { styled } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 6rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const RewardSendDetailRow = {
  Wrapper: styled.div`
    padding: 1rem;
    padding-left: 5rem;
  `,

  Container: styled.div`
    width: 100%;
    border: 0.1rem solid black;
  `,

  Header: styled.div`
    padding: 0.5rem 1rem;
    height: 2.5rem;
    color: white;
    background: #4253f2;
    display: flex;
    align-items: center;
  `,

  Data: styled.div`
    padding: 0.5rem 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
  `,

  Row: styled.div`
    flex: ${(props) => props.$ratio};
    text-align: center;
  `,
};

export const RowElement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  & > :not(:first-child) {
    font-size: 1rem;
  }
`;

export const NavImgBox = styled.div`
  width: 1.2rem;
  height: 1.2rem;

  > img {
    width: 100%;
    height: 100%;
  }
`;
