import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { ListDefault } from "components/ListDefault/ListDefault";
import { Info, List, Wrapper } from "template/ListTemplate.style";
import { SuperStoreInfoListDetail } from "./SuperStoreInfoListDetail";
import storeColumn from "./superStoreColumn.json";
import { useToastCustom } from "hooks/useToastCustom";
import { useMutationCustom } from "hooks/useMutationCustom";
import { HTTP_METHOD, POST_FIND_STORE_SUPER } from "constants/pathConstant";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import { SUPER_STORE_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";
import PageHandler from "components/PageHandler/PageHandler";

// 검색 -> 가맹점 검색 :: 가맹점 목록 리스트 (http://localhost:3000/super/search/store)
export const SuperStoreInfoList = ({
  setPage,
  setSize,
  superStoreList,
  searchData,
}) => {
  const toast = useToastCustom();
  const excelDownload = useExcelDownload();

  const { mutate } = useMutationCustom(POST_FIND_STORE_SUPER, HTTP_METHOD.POST);

  // Excel 다운로드
  const handleExcelDownload = () => {
    if (!superStoreList[0]?.cnt)
      return toast("다운로드할 데이터가 없습니다", "error");

    const researchCond = {
      ...searchData,
      pageSize: superStoreList[0]?.cnt,
      currentPage: 1,
    };

    mutate(researchCond, {
      onSuccess: (response) => {
        const excelData = {
          data: response,
          headers: SUPER_STORE_XLSX_DOWNLOAD.header,
          fileName: SUPER_STORE_XLSX_DOWNLOAD.filename,
        };

        excelDownload(excelData);
      },

      onError: () => {
        toast("데이터 전체 조회 실패", "error");
      },
    });
  };

  return (
    <Wrapper style={{ width: "90%" }}>
      <List.TitleContainer>
        <List.Text>
          가맹점 목록{" "}
          {superStoreList[0] && (
            <span>(검색됨 : {superStoreList[0]?.cnt}개)</span>
          )}
        </List.Text>
        <List.BtnContainer>
          {/* 페이지 핸들러 */}
          <PageHandler setPage={setPage} setPageSize={setSize} />
          {/* 엑셀 다운로드 버튼 */}
          <ExcelDownloadBtn onClick={handleExcelDownload} />
        </List.BtnContainer>
      </List.TitleContainer>

      <List.Container>
        {superStoreList.length === 0 ? (
          //  default page
          <ListDefault text={"가맹점을"} />
        ) : (
          <>
            {/* List Column */}
            <List.Content>
              {storeColumn.data.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>
            <Info.Wrapper>
              {superStoreList.map((v) => (
                <SuperStoreInfoListDetail
                  key={v.id}
                  id={v.id}
                  crtDt={v.crtDt}
                  storeNm={v.storeNm}
                  storeId={v.storeId}
                  ceoNm={v.ceoNm}
                  ceoCellNumber={v.ceoCellNumber}
                  contactNum={v.contactNum}
                  ceoEmail={v.ceoEmail}
                  refererCd={v.refererCd}
                  feeRate={v.feeRate}
                  serviceYn={v.serviceYn}
                  state={v.state}
                  confirmStateNm={v.confirmStateNm}
                  ownerAcctId={v.ownerAcctId}
                  ownerAcctIndexId={v.ownerAcctIndexId}
                  confirmState={v.confirmState}
                  rootStoreId={v.rootStoreId}
                />
              ))}
            </Info.Wrapper>
          </>
        )}
      </List.Container>
    </Wrapper>
  );
};
