import { Info, List, Wrapper } from "template/ListTemplate.style";
import sketchDropColumn from "../../sketchDropColumn.json";
import SketchDropSummaryList from "./SketchDropSummaryList";

// 정산 관리 -> 스케치 드랍 :: 요약  (http://localhost:3000/super/calculate/sketch-drop)
const SketchDropSummary = ({ data }) => {
  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>
          스케치 전체 드랍 요약
          {` (${data.acctUserNm ? data.acctUserNm : "전체"})`}
        </List.Text>
      </List.TitleContainer>

      <List.Container style={{ minHeight: "0" }}>
        <List.Content>
          {sketchDropColumn?.summary.map((v, idx) => (
            <List.Row $ratio={v.ratio} key={idx}>
              {v.title}
            </List.Row>
          ))}
        </List.Content>

        <Info.Wrapper>
          <SketchDropSummaryList data={data} />
        </Info.Wrapper>
      </List.Container>
    </Wrapper>
  );
};

export default SketchDropSummary;
