import {
  HTTP_METHOD,
  NAV_USER_DETAIL_BASE_PAY,
  POST_DROP_USER_HISTORY,
} from "constants/pathConstant";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import { DetailBtn } from "pages/User/UserList.style";
import React, { useState } from "react";
import { Info, List } from "template/ListTemplate.style";
import { uriEncoder } from "utils/crypto";
import dropUserColumn from "../dropUserColumn.json";
import {
  BtnBox,
  DeleteBtn,
  DoneBtn,
  DropUserDetailRow,
  RestoreBtn,
  RowElement,
} from "../DropUser.style";
import { useOpenCustomModal } from "hooks/modal/useOpenCustomModal";
import { DeleteAccountModal } from "components/Modal/DeleteNumberModal/DeleteNumberModal";
import { dateFormat } from "components/dateFormat";
import DropUserClickDetail from "./DropUserClickDetail";
import { RestoreUserModal } from "components/Modal/RestoreUserModal/RestoreUserModal";
import { useMutationCustom } from "hooks/useMutationCustom";
import WalletSlice from "components/WalletSlice/WalletSlice";

// 검색 -> 탈퇴 회원관리 :: 회원 목록 낱개 (http://localhost:3000/super/search/gone-user)
export const DropUserInfoLIstDetail = React.memo(
  ({
    id,
    crtDt,
    quitDt,
    acctUserNm,
    acctCd,
    refererNm,
    refererCd,
    cellNumber,
    email,
    walletDefault,
    state,
    stateNm,
    reqQuitDt,
    reqCnt,
    searchData,
    setDropUserList,
    birthDay,
  }) => {
    const nav = useNavigationCustom();
    const deleteModal = useOpenCustomModal(DeleteAccountModal);
    const restoreModal = useOpenCustomModal(RestoreUserModal);

    const [clicked, setClicked] = useState(false);
    const [history, setHistory] = useState([]);

    const navToDetailHandler = (code) => {
      nav(NAV_USER_DETAIL_BASE_PAY + code);
    };

    const deleteAccount = (e) => {
      e.stopPropagation();

      const data = { acctUserNm, acctCd, email, setDropUserList, searchData };
      if (!email) return;
      deleteModal(data);
    };

    const restoreAccount = (e) => {
      e.stopPropagation();

      const data = { acctCd, acctUserNm, setDropUserList, searchData };
      restoreModal(data);
    };

    const { mutate } = useMutationCustom(
      POST_DROP_USER_HISTORY,
      HTTP_METHOD.POST
    );

    const handleHistoryList = () => {
      if (!clicked) {
        setClicked(true);

        mutate(
          { acctCd },
          {
            onSuccess: (res) => {
              setHistory(res);
            },
          }
        );
      } else {
        setClicked(false);
      }
    };

    return (
      <>
        <Info.Content onClick={handleHistoryList} style={{ cursor: "pointer" }}>
          {/* 번호 */}
          <List.Row $ratio={dropUserColumn.data[0].ratio}>{id}</List.Row>

          {/* 가입일자 */}
          <List.Row $ratio={dropUserColumn.data[1].ratio}>
            {crtDt.slice(0, 10)}
          </List.Row>

          {/* 탈퇴신청일자 */}
          <List.Row $ratio={dropUserColumn.data[2].ratio}>
            {dateFormat(reqQuitDt).slice(0, 10)}
          </List.Row>

          {/* 탈퇴일자 */}
          <List.Row $ratio={dropUserColumn.data[3].ratio}>
            {quitDt && dateFormat(quitDt).slice(0, 10)}
          </List.Row>

          {/* 회원명 */}
          <List.Row $ratio={dropUserColumn.data[4].ratio}>
            <RowElement>
              <p>{acctUserNm}</p>
              <p>({acctCd})</p>
            </RowElement>
          </List.Row>

          {/* 추천인명 */}
          <List.Row $ratio={dropUserColumn.data[5].ratio}>
            <RowElement>
              <p>{refererNm}</p>
              <p>{refererNm && `(${refererCd})`}</p>
            </RowElement>
          </List.Row>

          {/* 생년월일 */}
          <List.Row $ratio={dropUserColumn.data[6].ratio}>{birthDay}</List.Row>

          {/* 휴대전화번호 */}
          <List.Row $ratio={dropUserColumn.data[7].ratio}>
            {cellNumber}
          </List.Row>

          {/* 이메일 */}
          <List.Row $ratio={dropUserColumn.data[8].ratio}>{email}</List.Row>

          {/* 지갑주소 */}
          <List.Row $ratio={dropUserColumn.data[9].ratio}>
            {WalletSlice(walletDefault)}
          </List.Row>

          {/* 상태 */}
          <List.Row $ratio={dropUserColumn.data[10].ratio}>{stateNm}</List.Row>

          {/* 탈퇴처리 */}
          <List.Row $ratio={dropUserColumn.data[11].ratio}>
            {stateNm === "탈퇴신청" ? (
              <BtnBox>
                <DeleteBtn
                  onClick={(e) => deleteAccount(e)}
                  disabled={stateNm === "탈퇴"}
                >
                  탈퇴
                </DeleteBtn>
                <RestoreBtn
                  onClick={(e) => restoreAccount(e)}
                  disabled={stateNm === "반려"}
                >
                  반려
                </RestoreBtn>
              </BtnBox>
            ) : (
              <DoneBtn>완료</DoneBtn>
            )}
          </List.Row>

          {/* 탈퇴 신청 횟수 */}
          <List.Row $ratio={dropUserColumn.data[12].ratio}>{reqCnt}</List.Row>

          {/* 회원정보 바로가기 버튼 */}
          <List.Row $ratio={dropUserColumn.data[13].ratio}>
            <DetailBtn.Wrapper
              onClick={() => navToDetailHandler(uriEncoder(acctCd, id, state))}
            >
              바로가기
            </DetailBtn.Wrapper>
          </List.Row>
        </Info.Content>

        {clicked && (
          <DropUserDetailRow.Wrapper>
            <DropUserDetailRow.Container>
              <DropUserDetailRow.Header>
                {dropUserColumn.detailData.map((v, idx) => (
                  <DropUserDetailRow.Row $ratio={v.ratio} key={idx}>
                    {v.title}
                  </DropUserDetailRow.Row>
                ))}
              </DropUserDetailRow.Header>

              {history.length !== 0 ? (
                history.map((v, idx) => (
                  <DropUserClickDetail
                    data={v}
                    key={idx}
                    idx={history.length - idx - 1}
                    updateMutate={mutate}
                    setHistory={setHistory}
                  />
                ))
              ) : (
                <DropUserDetailRow.Data>
                  <DropUserDetailRow.Row>데이터 없음</DropUserDetailRow.Row>
                </DropUserDetailRow.Data>
              )}
            </DropUserDetailRow.Container>
          </DropUserDetailRow.Wrapper>
        )}
      </>
    );
  }
);
