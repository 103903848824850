import styled from "styled-components";

export const StyledSVG = styled.svg`
  width: 100%;
  border: 0.2rem solid #eee;
  border-radius: 0.6rem;
  padding-top: 2rem;
  height: auto;

  & > g:nth-last-of-type(3) {
    padding-top: 1rem;
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  text-align: center;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  background: #000;
  border-radius: 0.6rem;
  pointer-events: none;
  visibility: hidden;
`;
