import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const Search = {
  Text: styled.div`
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 2.2rem;
    display: flex;
    gap: 0.8rem;
    align-items: center;
  `,

  Container: styled.div`
    border-radius: 1rem;
    background: #fff;
    min-width: 64rem;
    min-height: 7.4rem;
    display: flex;
    justify-content: space-between;
    padding: 1.7rem 2rem;
    gap: 4rem;
  `,

  Content: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 64rem;
  `,

  InputWrapper: styled.div`
    width: 12rem;
    height: 4rem;
    border-radius: 0.6rem;
    border: 1px solid #999;
    padding: 0.5rem 1rem;
    font-weight: ${FONT_WEIGHT.MEDIUM};
  `,

  Input: styled.input`
    border: none;
    outline: none;
    font-size: 1.4rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    width: 100%;

    &:disabled {
      background: transparent;
    }

    &::-webkit-inner-spin-button {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
    }
  `,

  Btn: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    min-width: 6rem;
    height: 4rem;
    font-size: 1.4rem;
    border-radius: 0.6rem;
    background: #4253f2;
  `,

  Select: styled.select`
    border: none;
    outline: none;
    font-size: 1.4rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    width: 10rem;
    background-color: transparent;
    height: 2rem;
    cursor: pointer;
  `,

  Option: styled.option`
    white-space: pre-line;
  `,
};

export const StateQuestion = styled.span`
  font-size: 1.2rem;
  color: #fff;
  background: #000;
  padding: 0.3rem 0.6rem;
  border-radius: 100%;
  cursor: pointer;
`;
