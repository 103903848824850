import styled from "styled-components";

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: blur(5px);
  -webkit-filter: brightness(65%);
  backdrop-filter: blur(5px);
  position: fixed;
  z-index: 22;
`;

export const ModalContaier = styled.div`
  z-index: 23;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 2rem;

  & > div {
    background: #fff;
    width: 50rem;
    height: 50rem;
    overflow-y: scroll;
    padding: 2rem;

    & > :nth-child(1) {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 4.2rem;
      border-bottom: 1px solid #d2d2d2;
      margin-bottom: 2rem;
    }

    & > :nth-child(2) {
      font-weight: 400;
      font-size: 1.5rem;
    }
  }
`;
