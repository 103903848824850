import { POST_NOTICE2_FETCH } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useEffect, useState } from "react";
import { Search, Wrapper } from "template/SearchTemplate.style";
import domainColumn from "../noticeDomainColumn.json";

// 고객 지원 -> 공지 관리 :: 공지 조회 (http://localhost:3000/super/create/notice)
const NoticeSearch = ({
  setNoticeList,
  setPage,
  currentPage,
  pageSize,
  setChecked,
  setSearchData,
}) => {
  const toast = useToastCustom();

  const [reqType, setrReqType] = useState("");

  const { mutate } = useMutationCustom(POST_NOTICE2_FETCH);

  const fetchData = () => {
    const data = {
      currentPage,
      pageSize,
      reqType,
    };

    setSearchData(data);

    mutate(data, {
      onSuccess: (res) => {
        setNoticeList(res);
        setChecked([]);
      },

      onError: () => {
        toast("데이터 조회 실패", "error");
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, [pageSize, currentPage]);

  const handleSearch = () => {
    setPage(1);
    fetchData();
  };

  return (
    <Wrapper>
      <Search.Text>공지 조회</Search.Text>
      <Search.Container>
        <Search.Content>
          <Search.InputWrapper>
            <div>앱 구분</div>
            <Search.Select onChange={(e) => setrReqType(e.target.value)}>
              <Search.Option value={""}>전체</Search.Option>
              {Object.entries(domainColumn.reqType).map(([key, value]) => (
                <Search.Option key={key} value={key}>
                  {value}
                </Search.Option>
              ))}
            </Search.Select>
          </Search.InputWrapper>
        </Search.Content>

        {/* 조회 버튼 */}
        <Search.Btn onClick={handleSearch}>조회</Search.Btn>
      </Search.Container>
    </Wrapper>
  );
};

export default NoticeSearch;
