import { useEffect, useState } from "react";
import CoinInfoList from "./CoinInfoList/CoinInfoList";
import CoinUpload from "./CoinUpload/CoinUpload";
import { HTTP_METHOD, POST_COIN_FETCH } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";

// 시스템 관리 -> 코인 관리 :: (http://localhost:3000/super/system/coin)
const CoinList = () => {
  const [coinList, setCoinList] = useState([]);

  const { mutate } = useMutationCustom(POST_COIN_FETCH, HTTP_METHOD.POST);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    mutate(
      {},
      {
        onSuccess: (res) => {
          setCoinList(res.list);
        },
      }
    );
  };

  return (
    <>
      <CoinInfoList coinList={coinList} fetchData={fetchData} />
      {/* <CoinUpload fetchData={fetchData} /> */}
    </>
  );
};

export default CoinList;
