import { FONT_WEIGHT } from "constants/baseStyle";
import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  align-items: end;

  & > div {
    display: flex;
    gap: 1rem;
  }
`;

export const SearchBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  font-size: 1.2rem;

  & > input {
    width: 10rem;
    height: 2.5rem;
    border: 0.1rem solid #999;
    border-radius: 0.6rem;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 2.6rem;
    color: white;
    border-radius: 0.6rem;
    background: #4253f2;
    font-size: 1.2rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    cursor: pointer;
  }
`;

export const RadioBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  & > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.6rem;
  }
`;
