import { FONT_WEIGHT } from "constants/baseStyle";
import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
`;

export const Container = {
  Top: styled.div`
    display: flex;
    gap: 0.4rem;
    align-items: center;
  `,

  Text: styled.div`
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 2.2rem;
  `,

  ModifyBtnWrapper: styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    gap: 0.3rem;
  `,

  ModifyBtn: styled.div`
    width: 3.2rem;
    height: 1.8rem;
    border-radius: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.$bg || "white"};
    color: white;
    font-weight: ${FONT_WEIGHT.BOLD};
    font-size: 1rem;
    cursor: pointer;
  `,

  Content: styled.div`
    width: 56rem;
    min-height: 14.4rem;
    border-radius: 1rem;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  `,

  Doc: styled.div`
    width: 20rem;
    border: 0.1rem solid black;
    height: 2rem;
  `,
};

export const Info = {
  Wrapper: styled.div`
    width: 28rem;
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid #e7e7e7;
    font-size: 1.1rem;
  `,

  Title: styled.div`
    width: 18rem;
    padding: 1rem;
    background: #f8f8fa;
    color: #888;
  `,

  Content: styled.div`
    padding: 1rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  `,

  ModifyWrapper: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;
    gap: 0.4rem;
  `,

  ContentInput: styled.input`
    width: 100%;
    border: ${(props) => (props.$modify ? "1px solid black" : "none")};
    outline: none;
    border-radius: 0.4rem;
    padding: 1rem;
    height: 2rem;
  `,

  ModifyBtn: styled.div`
    border-radius: 0.4rem;
    color: #fe4f4f;
    padding: 0.3rem 0.4rem;
    cursor: pointer;
    display: ${(props) => (props.$modified === "Y" ? "block" : "none")};
  `,
};
